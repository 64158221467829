import moment from 'moment'

const defaultState = {
	daterange: {
		startDate: moment().startOf('month'),
		endDate: moment(),
	},
	dataUsage: [],
	typeFilter: 'fltered',
}

const driver = (state = defaultState, action) => {
	switch (action.type) {
	case 'SET_REPORTS_DATAUSAGE': {
		return { ...state, ...{ dataUsage: action.payload } }
	}
	case 'SET_REPORTS_DATERANGE': {
		return { ...state, ...{ daterange: action.payload } }
	}
	case 'SET_REPORTS_FILTER': {
		return { ...state, ...{ typeFilter: action.payload } }
	}
	default:
		return state
	}
}

export default driver
