import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import { apiCall } from '../../../action/RouterActions'
import { sendGA } from '../../helpers/helperUsers'
import CustomButton from '../../CustomButton'

const ModalRequestContextVideo = (props) => {
	const { user, company, event, onHide, toggleLoader } = props;

	const [duration, setDuration] = useState(20);
	const [note, setNote] = useState('');

	const fillForm = (e) => {
		const { target } = e;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		if (name === "duration") setDuration(value);
		if (name === "note") setNote(value);
	}

	const sendRequest = (e) => {
		const userRole = user.roles[0]
		toggleLoader(true);

		const params = {
			event_id: event.id,
			duration,
		}
		if (note) {
			params.note = note
		}
		apiCall('PUT', '/events/context_video_clip', params)
			.then(() => {
				toggleLoader(false);
				toastr.success('', 'Custom Video Requested');
				sendGA({
					category: userRole,
					action: 'Request Additional Video',
					label: `${event.type}, Length: ${duration} seconds`,
				})
				onHide()
			})
			.catch((error) => {
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const { vehicle_name, device_id } = event;
	const partnerName = company.partner || user.partner_company_name;
	const companyType = company.type || user.company_type;

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="custom-request-modal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">
					{`Event Context Video Request - ${vehicle_name || device_id}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<p style={{ marginBottom: '30px' }}>The Event Context Video Request will capture additional video around this event in and upload it to the cloud. The video length requested will be split around the event time. For example, a 20 second video clip will result in 10 seconds before the event and 10 seconds after.</p>
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-5 form-group">
							<label className="control-label">Video Length:</label>
							<select placeholder="Select..." className="rosco-select" name="duration" value={duration} onChange={fillForm}>
								<option value="20">20 Seconds</option>
								{partnerName !== 'Rosco Vision WCS' && companyType !== 'WCS'
									&& <option value="30">30 Seconds</option> }
									<option value="60">1 Minute</option>
									<option value="120">2 Minutes</option>
									<option value="180">3 Minutes</option>
									<option value="240">4 Minutes</option>
									<option value="300">5 Minutes</option>
								{/* <option value="240">4 Minutes</option>
								<option value="300">5 Minutes</option>
								{(partnerName === 'Rosco Vision WCS' || companyType === 'WCS')
									&& (
										<option value="600">10 Minutes</option>
									)}
								{(partnerName === 'Rosco Vision WCS' || companyType === 'WCS')
									&& (
										<option value="900">15 Minutes</option>
									)} */}
							</select>
						</div>

						<div className="col-xs-12 col-sm-12 col-md-12 form-group" style={{ paddingTop: "15px" }}>
							<label className="control-label">Note: </label>
							<input type="text" name="note" placeholder="Note..." className="vehicle-search" value={note} onChange={fillForm} style={{ marginLeft: '10px', width: 'calc(100% - 50px)' }} />
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				<CustomButton
					onClick={sendRequest}
					variant="primary"
				>
					Request
				</CustomButton>
			</Modal.Footer>
		</Modal>
	);
}

ModalRequestContextVideo.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	event: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalRequestContextVideo);
