import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import DateTime from 'react-datetime'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import _ from 'lodash'

import { defaultTableOptions, paginationOptions } from '../../helpers/table'

import { getFleetAdminEventsLog } from '../../../action/FleetAdminActions'
import { getUserLogs, getCompanyLogs } from '../../../action/ClientActions'
import { formatTimestamp } from '../../helpers/tablesFuncHelpers'
import CustomButton from '../../CustomButton'

const ModalDeviceAuditLog = (props) => {

	const { type, device, user_role, loaderModal } = props;

	const [logs, setLogs] = useState([]);
	const [logs_sample, setLogs_sample] = useState([]);
	const [elastic_sample, setElastic_sample] = useState(0);
	const [elastic_total, setElastic_total] = useState(0);
	const [metadata_total, setMetadata_total] = useState(0);
	const [start_date, setStart_date] = useState(moment().subtract(4, 'days').format("YYYYMMDD"));
	const [end_date, setEnd_date] = useState(moment().format("YYYYMMDD"));
	const [page, setPage] = useState(1);
	const [sizePerPage, setSizePerPage] = useState(10);
	const [totalSize, setTotalSize] = useState(0);

	const onEnterModal = () => {
		setLogs([]);
		setLogs_sample([]);
		setElastic_sample(0);
		setElastic_total(0);
		setMetadata_total(0);
		setStart_date(moment().subtract(4, 'days').format("YYYYMMDD"));
		setEnd_date(moment().format("YYYYMMDD"));

		setPage(1);
		setSizePerPage(10);
		setTotalSize(0);

		changedEventsList();
	}

	const changedEventsList = () => {
		if (type && (type == 'user_logs' || type == 'company_logs')) {
			return getUserLogsFnc()
		}

		return getEventsLogs()
	}

	const getUserLogsFnc = () => {
		props.toggleLoaderModal(true)

		let data = {
			start_date: start_date,
			end_date: end_date,
			user_role: user_role
		}

		let request = null
		if (type && type == 'user_logs') {
			request = getUserLogs;
			data.user_id = device.id
		}
		else if (type && type == 'company_logs') {
			request = getCompanyLogs;
			data.company_id = device.id
		}

		request(data)
			.then((res, err) => {
				props.toggleLoaderModal(false)
				console.log('!getUserLogs res', res)

				const response = res.data.response

				setLogs_sample(response.logs);
			})
			.catch((error) => {
				props.toggleLoaderModal(false)
				console.log(error)

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getEventsLogs = () => {
		props.toggleLoaderModal(true)

		let data = {
			start_date: start_date,
			end_date: end_date,
			device_id: (device) ? device.device_id : '',
			page_number: elastic_sample,
			user_role: user_role
		}

		getFleetAdminEventsLog(data)
			.then((res, err) => {
				console.log('!getEventsLogs res', res)

				props.toggleLoaderModal(false)

				const response = res.data.response;
				const ulogs = _.union(logs, response.logs)

				setLogs(ulogs);
				setLogs_sample(ulogs.slice((page - 1) * sizePerPage, sizePerPage * page));
				setElastic_sample(elastic_sample + response.elastic_sample);
				setElastic_total(response.elastic_total);
				setMetadata_total(response.metadata_total);
				setTotalSize(response.elastic_total + response.metadata_total);
			})
			.catch((error) => {
				props.toggleLoaderModal(false)
				console.log(error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const reloadList = () => {
		if (start_date && end_date && (moment(end_date).diff(start_date) > 0)) {
			setLogs([]);
			setLogs_sample([]);
			setElastic_sample(0);
			setElastic_total(0);
			setMetadata_total(0);

			setPage(1);
			setTotalSize(0);

			changedEventsList();
		}
		else {
			toastr.error('End date should be bigger than Start date')
		}
	}

	const onDateChanged1 = (current) => {
		setStart_date(current.format('YYYYMMDD'));
	}

	const onDateChanged2 = (current) => {
		setEnd_date(current.format('YYYYMMDD'));
	}

	const handlePageChange = (page, sizePerPage) => {
		const logs_sample = logs.slice(sizePerPage * (page - 1), sizePerPage * page),
			logs_len = logs.length

		if (logs_sample.length < sizePerPage && logs_len < totalSize) {
			setPage(page);
			changedEventsList();
		}
		else {
			setPage(page);
			setLogs_sample(logs_sample);
		}
	}

	const getTitle = () => {
		let title = ''

		if (type && type == 'user_logs') {
			title = device ? device.first_name + ' ' + device.last_name : ''
		}
		else if (type && type == 'company_logs') {
			title = device ? device.company_name : ''
		}
		else {
			title = device ? device.device_name || device.device_id : ''
		}

		return title
	}

	const options = {
		...paginationOptions,
		withFirstAndLast: elastic_sample >= elastic_total, // Hide the going to First and Last page button
	}

	let opts = {}
	if (type && (type == 'user_logs' || type == 'company_logs')) {
	} else {
		options.onPageChange = handlePageChange
		opts['remote'] = true
		opts['fetchInfo'] = { dataTotalSize: totalSize }
	}

	const eventtypeFormatter = (cell, row) => {
		return cell.replace(/Fatal/, 'Critical')
	}

	return (
		<Modal
			size='xl'
			show
			onHide={props.onHide}
			onEnter={onEnterModal}
			aria-labelledby="contained-modal-title-lg"
			dialogClassName="modal-audit-lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Audit Log - {getTitle()}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-audit-log audit-view-modal">
					<div className="row reload-dates-mobile" style={{ display: 'flex' }}>
						<div className="col-md-4 date-container">
							<div className="date"><span>Start Date:</span></div>
							<div className="datetime">
								<DateTime
									timeFormat={false}
									defaultValue={moment(start_date).format('MM/DD/YYYY')}
									closeOnSelect
									onChange={onDateChanged1}
								/>
							</div>
						</div>
						<div className="col-md-4 date-container">
							<div className="date"><span>End Date:</span></div>
							<div className="datetime">
								<DateTime
									timeFormat={false}
									defaultValue={moment(end_date).format('MM/DD/YYYY')}
									closeOnSelect
									onChange={onDateChanged2}
								/>
							</div>
						</div>
						<div className="col-md-4" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
							<CustomButton
								onClick={reloadList}
								variant="primary-outline"
							>
								Reload
							</CustomButton>
						</div>
					</div>
				</div>

				<div className="manage-users-table audit-view-page-table" style={{ clear: 'both', marginTop: '20px' }}>
					<BootstrapTable
						data={logs_sample}
						{...defaultTableOptions}
					>
						<TableHeaderColumn
							isKey
							width="20%"
							dataField="timestamp"
							dataFormat={(cell) => formatTimestamp(cell)}
						>
							Timestamp
						</TableHeaderColumn>
						<TableHeaderColumn
							width="25%"
							dataField="type"
							dataFormat={eventtypeFormatter}
						>
							Type
						</TableHeaderColumn>
						<TableHeaderColumn
							width="55%"
							dataField="desc"
							tdStyle={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
						>
							Description
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</Modal.Body>
			{loaderModal}
		</Modal>
	)
}

export default connect(
	state => ({
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(ModalDeviceAuditLog);
