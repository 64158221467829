import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { checkEmail } from '../../component/helpers/tablesFuncHelpers'
import { sendDriverQRCode } from '../../action/DriverActions'
import CustomButton from '../../component/CustomButton'

const ModalEditDriver = (props) => {

	const { groups, onHide, deleteDriver, driver, handleDriverEdit } = props;

	const [data, setData] = useState({
		group_id: ''
	});
	const [errMessage, setErrMessage] = useState({
		errfirst_name: '',
		errlast_name: '',
		errcard_id: '',
		erremail: ''
	});
	const [upd, setUpd] = useState(false);

	useEffect(() => {
		const card = { ...data, ...driver };
		setData(card);
		checkForm(card);
	}, []);

	const fillForm = (e) => {
		const { name, value } = e.target;

		if (name === 'group_id') {
			setData({ ...data, group_id: value, group_name: (!value) ? '' : e.target.options[e.target.selectedIndex].text });
		} else {
			setData({ ...data, [name]: value });
		}

		checkForm({ [name]: value });
	};

	const sendQRCode = () => {
		if (!data.email) {
			setErrMessage({ ...errMessage, erremail: 'Enter a valid email to send the QR Code to.' });
		}
		else if (!data.card_id) {
			setErrMessage({ ...errMessage, errcard_id: 'The driver must have a valid Card ID.' });
		}
		else if (data.email === driver.email) {
			sendDriverQRCode(data.id)
				.then(res => {
					toastr.success("Successfully sent QR Code")
				})
				.catch(err => {
					toastr.error("Unable to send QR Code. Please try again later.")
				})
			onHide();
		}
		else {
			handleApply(true);
		}
	};

	const checkForm = (data) => {
		const regExp = /^[-+]?[0-9A-Fa-f]+\.?[0-9A-Fa-f]*?$/;

		let error = false;

		Object.keys(data).forEach((key) => {
			if (typeof data[key] === 'string' && data[key].trim() === '') {
				error = true;
				errMessage[`err${key}`] = `Enter your ${key} `;
			} else if ((key === "first_name" || key === "last_name") && !/^[a-zA-Z0-9 ]+$/.test(data[key].toString())) {
				error = true;
				errMessage[`err${key}`] = `Please enter a valid ${key}`;
			} else if (key === 'email' && data.email && !checkEmail(data.email)) {
				error = true;
				errMessage.erremail = 'Please enter a valid email address';
			} else if (key === 'card_id' && data.card_id && (data.card_id.length !== 8 || !regExp.test(data.card_id))) {
				error = true;
				errMessage.errcard_id = 'The Card ID must be either blank or exactly 8 hexadecimal digits';
			} else if (errMessage[`err${key}`]) {
				errMessage[`err${key}`] = null;
			}
		})

		setErrMessage(errMessage);
		setUpd(!upd);
		return error;
	};

	const handleApply = (sendQRCode = null) => {
		const error = checkForm(data);

		const changedData = _.reduce(_.keys(data), (result, key) => {
			if (!Object.prototype.hasOwnProperty.call(driver, key) || !_.isEqual(driver[key], data[key])) {
				result[key] = data[key]
			}
			return result;
		}, {});
		if (_.isEmpty(changedData)) {
			toastr.warning('', 'This driver has no changes')
			return
		}

		if (!error) {
			handleDriverEdit(changedData, sendQRCode)
		}
	};

	const { first_name, last_name, card_id, group_id, email } = data;
	const { errfirst_name, errlast_name, errcard_id, erremail } = errMessage;

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-batch-function modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>Edit Driver</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="edit-driver-modal">
					<input
						type="text"
						name="first_name"
						className="vehicle-search"
						placeholder="First Name..."
						value={first_name || ''}
						onChange={fillForm}
					/>
					{errfirst_name && <span className="error-label">{errfirst_name}</span>}
					<input
						type="text"
						name="last_name"
						className="vehicle-search"
						placeholder="Last Name..."
						value={last_name || ''}
						onChange={fillForm}
					/>
					{errlast_name && <span className="error-label">{errlast_name}</span>}
					<select
						placeholder="Select Group"
						className="rosco-select block"
						name="group_id"
						value={group_id || ''}
						onChange={fillForm}
					>
						<option value="">Select Group...</option>
						{groups.map(({ id, group_name }) => <option key={id} value={id}>{group_name}</option>)}
					</select>
					<input
						type="text"
						name="email"
						className="vehicle-search"
						placeholder="Email..."
						value={email || ''}
						onChange={fillForm}
					/>
					{erremail && <span className="error-label">{erremail}</span>}
					<input
						type="text"
						name="card_id"
						className="vehicle-search"
						placeholder="Card ID..."
						value={card_id || ''}
						onChange={fillForm}
					/>
					{errcard_id && <span className="error-label">{errcard_id}</span>}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="delete" onClick={() => deleteDriver(driver)}>Delete</CustomButton>
				<CustomButton variant="primary-outline" onClick={sendQRCode}>Send QR Code</CustomButton>
				<CustomButton variant="primary" onClick={handleApply}>Apply</CustomButton>
			</Modal.Footer>
		</Modal>
	)
};

ModalEditDriver.propTypes = {
	driver: PropTypes.objectOf(PropTypes.any).isRequired,
	groups: PropTypes.arrayOf(PropTypes.any).isRequired,
	handleDriverEdit: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired
};

export default ModalEditDriver;
