import React from 'react';

const LiveVideoErrorContainer = () => {

  return (
    <div className="main-content">
      <h3>Uh oh :(</h3>
      <p>
        There is a problem trying to get the camera live stream at this time.
        Please close this window and try again in a few minutes.
        If you continue to see this error message, please contact Rosco
        technical support.
      </p>
    </div>
  );
}

export default LiveVideoErrorContainer;
