import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import { CustomButtonToggle } from '../../component/DropdownComponents'
import CustomButton from '../../component/CustomButton';

const CompanyEditWebfleetSettings = (props) => {
	const { company, webfleetCredentials, modalConfirm } = props;

	const [showPassword, setShowPassword] = useState(false);

	// console.log('PROPS CompanyEditWebfleetSettings: ', props)
	// console.log('STATE CompanyEditWebfleetSettings: ', state)

	return (
		<div className="external-service-wrapper">
			{webfleetCredentials.account
				? (
					<Dropdown
						id="geotab-dropdown"
						role="menuitem"
					>
						<Dropdown.Toggle as={CustomButtonToggle} variant="primary-outline" size="lg">
							Webfleet Settings
						</Dropdown.Toggle>
						<Dropdown.Menu className="geotab-relations-menu">
							<div className="dropdown-body">
								<h5 className="category-title">Integration Info</h5>
								<div className="info-section">
									<b>Account:</b> {webfleetCredentials.account}
								</div>
								<div className="info-section">
									<b>Username:</b> {webfleetCredentials.username}
								</div>
								<div className="info-section">
									<b>Access Hash:</b> <span style={{ wordBreak: 'break-all' }}>{webfleetCredentials.access_hash}</span>
								</div>
								{/* <div className="info-section">
									{showPassword
										? <span><b>Password:</b> {webfleetCredentials.password}</span>
										: <CustomButton variant="link" onClick={() => setShowPassword(true)}>Show Password</CustomButton>
									}
								</div> */}
							</div>
							<div className="dropdown-footer">
								<CustomButton variant="primary-outline" onClick={(e) => modalConfirm(e, 'webfleet_email')}>Provision for Webfleet</CustomButton>
								{company.geotab_database && (
									<CustomButton variant="delete" onClick={(e) => modalConfirm(e, 'webfleet_relation')}>
										Delete Relation
									</CustomButton>
								)}
							</div>
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<CustomButton variant="primary-outline" onClick={(e) => modalConfirm(e, 'webfleet_email')}>
						Provision for Webfleet
					</CustomButton>
				)
			}
		</div>
	)
}

CompanyEditWebfleetSettings.propTypes = {
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	webfleetCredentials: PropTypes.objectOf(PropTypes.any).isRequired,
	modalConfirm: PropTypes.func.isRequired,
};

export default CompanyEditWebfleetSettings;
