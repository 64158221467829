// import moment from 'moment'

const defaultState = {
	showTripsHistory: false,
	tripsGPS: [], // list of GPS coordinates for the clicked trip route
	tripsEvents: [], // list of events for the clicked trip route
	tripsSpeedEvents: [], // list of the speed event subcategories for the clicked trip route LSA, MSA, HSA, SSA
	tripsSpeedEventsFilters: {},
	deviceTrips: [], // list of the device trips once user clicks on the Route History
	selectedTrip: null, // trip which was clicked on the right sidebar and getted from the deviceTrips list
	tripTimelineTimestamp: null, // timestamp clicked on the slider-horizontal of the TRIP REPLAY CONTROLS (like "2022-10-07 16:18:18")
	tripPlaybackTimestamp: null, // timestamp of playback trip on the slider-horizontal of the TRIP REPLAY CONTROLS (like "2022-10-07 16:18:18") once the PLAY clicking
	tripAlertsMarkers: [],
	tripAdvancedPlayerEvent: {}, // it is used to display advanced player on the trip history popup. If it is set then should display the advanced player.
	tripShowShareEvent: {}, // it is used to display share event on the trip history popup. If it is set then should display the share event popup.
	selectedDropdownEventId: null, // stores selected event id from the dropdown list of events on the trip card
	tripsGPSSnapRoad: [], // list of GPS coordinates for the clicked trip route are requested via Google Snap to Roads feature. Depends on the tripsGPS coordinates.
	showSnapRoad: false,
	dashboardRedirect: null, // stores needed params when redirecting to the Homepage (from the Unknown Driver Trips popup of Drivers page)
	selectedTripsEventIdToShowPopup: null, // stores selected event id of events for the clicked trip route to show MarkerAlertPopup -- FWSD-4482
	sideMenuOpen: JSON.parse(localStorage.getItem('openState')) === null ? false : JSON.parse(localStorage.getItem('openState')), // true/false should be collapsed/expanded the left sidebar menu
};

const dashboardData = (state = defaultState, action) => {
	switch (action.type) {	
	case 'SET_DASHBOARD_REDIRECT': {
		return { ...state, dashboardRedirect: action.payload };
	}
	case 'SET_DEVICE_TRIPS': {
		return { ...state, deviceTrips: action.payload };
	}
	case 'SET_SHOW_TRIP_HISTORY': {
		// debugger
		return { ...state, showTripsHistory: action.payload };
	}
	case 'SET_TRIPS_GPS': {
		// debugger
		const tripsEvents = []
		const tripsSpeedEvents = []
		const tripsSpeedEventsFilters = {}
		if (action.payload.length) {
			if (state.selectedTrip && state.selectedTrip.events && state.selectedTrip.events.length) {
				state.selectedTrip.events.forEach((event) => {
					// const pos = action.payload[0].polygon.find(item => item.timestamp >= moment(`${event.timestamp}+00`).format('YYYY-MM-DD HH:mm:ss'))
					const pos = action.payload[0].polygon.find(item => item.timestamp >= event.timestamp)
					if (pos) {
						tripsEvents.push(
							{ ...event, position: { lat: pos.lat, lng: pos.lng } },
						)
					}
				})
			}
			if (state.selectedTrip && state.selectedTrip.speeding_events && state.selectedTrip.speeding_events.length) {
				state.selectedTrip.speeding_events.forEach((speedEvent) => {
					const positions = action.payload[0].polygon.filter(item => item.timestamp >= speedEvent.start_ts && item.timestamp <= speedEvent.end_ts)
					if (positions.length) {
						tripsSpeedEvents.push(
							{ ...speedEvent, positions, active: true },
						)

						tripsSpeedEventsFilters[speedEvent.event_type] = true
					}
				})
			}
		}

		return { ...state, tripsGPS: action.payload, tripsEvents, tripsSpeedEvents, tripsSpeedEventsFilters, tripsGPSSnapRoad: [], showSnapRoad: false };
		// return { ...state, tripsGPS: action.payload, tripsEvents, /* tripsSpeedEvents, tripsSpeedEventsFilters, tripsGPSSnapRoad: [], showSnapRoad: false*/ };
	}
	case 'ADD_TRIPS_GPS_POLYGON_POINT': {
		// debugger
		const newTripsGPS = [...state.tripsGPS]
		newTripsGPS[0].polygon.push(action.payload)
		return { ...state, tripsGPS: newTripsGPS };
	}
	case 'SET_SELECTED_TRIP': {
		// // Hides/shows the devices tree --FWSD-4740
		// if (action.payload) {
		// 	$('#block-tree').css('width', 12).css('left', 0);
		// 	$('#map-block').css('left', 12);
		// } else {
		// 	if ($('#block-tree').width() < 13 ) {
		// 		$('#block-tree').css("width", 300).css("left", 0);
		// 		$('#map-block').css("left", 300);
		// 	}
		// }

		return { ...state, selectedTrip: action.payload };
	}
	case 'SET_TRIP_TIMELINE_TIMESTAMP': {
		return { ...state, tripTimelineTimestamp: action.payload };
	}
	case 'SET_TRIP_PLAYBACK_TIMESTAMP': {
		return { ...state, tripPlaybackTimestamp: action.payload };
	}
	case 'SET_TRIP_ADVANCED_PLAYER_EVENT': {
		return { ...state, tripAdvancedPlayerEvent: action.payload };
	}
	case 'SET_TRIP_SHOW_SHARE_EVENT': {
		return { ...state, tripShowShareEvent: action.payload };
	}
	case 'SET_SELECTED_DROPDOWN_EVENT_ID': {
		return { ...state, selectedDropdownEventId: action.payload };
	}
	case 'SET_SELECTED_TRIPS_EVENT_ID_TO_SHOW_POPUP': {
		return { ...state, selectedTripsEventIdToShowPopup: action.payload };
	}
	case 'SET_TRIPS_SPEED_EVENTS_FILTERS': {
		return { ...state, tripsSpeedEventsFilters: action.payload };
	}
	case 'SET_TRIPS_GPS_SNAP_ROAD': {
		return { ...state, tripsGPSSnapRoad: action.payload };
	}
	case 'SET_SHOW_SNAP_ROAD': {
		return { ...state, showSnapRoad: action.payload };
	}
	case 'SET_SIDE_MENU_OPEN': {
		return { ...state, sideMenuOpen: action.payload };
	}
	default:
		return state;
	}
};

export default dashboardData
