import {
	GET_ASSETS_REQUEST,
	GET_ASSETS_SUCCESS,
	GET_ASSETS_FAIL,
} from '../action/types';

// FWSD-3916
const defaultState = {
	// devices: [],
	lastViewedVehiclesPage: [], // last time when user viewed Vehicles page after site refreshing and getting fresh devices data [{companyId, timestamp}]
	isDevicesHaveProblems: false, // displays red bubble in the left sidebar
	// cameraHealthAlerts: [], // list of devices with alerts for the Camera Health tab of Vehicles page, FWSD-6457
	hasUnacknowledgedAlerts: false, // The Camera Health tab will have a red dot (bubble) indicator when there are unacknowledged errors on any camera, FWSD-6457
}

const assets = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_IS_DEVICES_HAVE_PROBLEMS': {
			return { ...state, isDevicesHaveProblems: action.payload }
		}
		// case 'SET_ASSETS': {
		// 	return { ...state, devices: action.payload }
		// }
		case 'SET_LAST_VIEWED_ASSETS': {
			return { ...state, lastViewedVehiclesPage: action.payload }
		}
		// case 'SET_CAMERA_HEALTH_ALERTS': {
		// 	return { ...state, cameraHealthAlerts: action.payload }
		// }
		case 'SET_HAS_UNACKNOWLEDGED_ALERTS': {
			return { ...state, hasUnacknowledgedAlerts: action.payload }
		}
		case GET_ASSETS_REQUEST:
			return { fetching: true };
		case GET_ASSETS_SUCCESS:
			return {
				devices: action.payload,
				fetching: false,
			};
		case GET_ASSETS_FAIL:
			return { fetching: false };
		default:
			return state
	}
}

export default assets
