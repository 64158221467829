import React from 'react'
import PropTypes from 'prop-types'

import CustomButton from '../../../component/CustomButton'
import momenttz from '../../../component/helpers/momenttz'

import './EventsTable.sass'

const EventsTable = (props) => {
	// console.log('PROPS EventsTable: ', props)
	const {
		events,
		showSnapshot,
		openClip,
		openEventSnapshot,
		sendRequestContextVideo,
	} = props

	const timestampFormatter = (cell, row) => {
		if (cell) {
			return momenttz(cell).format('h:mm a');
		}
		return 'Not reviewed';
	}

	const typeFormatter = (cell, row) => {
		// FWSD-4541
		if (row.event_display_type) {
			return row.event_display_type
		}

		// FWSD-4379
		if (row.uploading_status && row.uploading_status.toLowerCase() === 'video_non_exist' && row.type === 'Custom Clip') {
			return 'Custom Clip'
		}
		if (row.uploading_status && row.uploading_status.toLowerCase() === 'video_non_exist') {
			// return 'No video on device '
			return 'Video not found for this time'
		}
		// FWSD-3958
		const re = /External Driver|InputSensor2|InputSensor3|InputSensor5|InputSensor9/g;
		const typesArr = String(cell).replaceAll(re, 'Driver Event').replace('HighSpeed', 'Max Speed').split(',');
		const type = [...new Set(typesArr)].join(', ')

		return type
	}

	const videoOptionFormatter = (cell, row) => {
		if (row.video_option === 'alert_only') {
			return (
				<CustomButton variant="link" title="Request Video" onClick={() => sendRequestContextVideo(row)}>request</CustomButton>
			)
		}
		if (row.video_option === 'video_snapshot' || row.type === 'Custom Clip') {
			if (row.uploading_status === 'VIDEO_UPLOADED') {
				return (
					<CustomButton variant="link" title="Play Video" onClick={(e) => openClip(e, row)}>view</CustomButton>
				)
			} else {
				return <>pending</>
			}			
		}
		if (row.video_option === 'snapshot_only' || row.type === 'Custom Clip') {
			if (row.snapshot_status === 'SNAPSHOT_UPLOADED') {
				return (
					<CustomButton variant="link" title="View Snapshot" onClick={() => openEventSnapshot(row)}>view</CustomButton>
				)
			} else {
				return <>pending</>
			}
		}
		
	}

	return (
		<div className="vehicleSidebarTable">
		{
			events.map((item) => {
				// console.log('item: ', item)
				return (
					<div className="event-wrapper" key={item.id}>
						<div className="event-row">
							<div className="timestamp">{timestampFormatter(item.timestamp, item)}</div>
							<div className="event-type">{typeFormatter(item.type, item)}</div>
							<div className="video-option">{videoOptionFormatter(item.video_option, item)}</div>
						</div>
						{showSnapshot && (item.snapshot_1 || item.snapshot_2) &&
							<div className="event-snapshot">
								<img src={item.snapshot_1 || item.snapshot_2}/>
							</div>
						}
					</div>
				)
			})
		}
		</div>
	)
}

EventsTable.propTypes = {
	events: PropTypes.arrayOf(PropTypes.any).isRequired,
	showSnapshot: PropTypes.bool.isRequired,
	openClip: PropTypes.func.isRequired,
	openEventSnapshot: PropTypes.func.isRequired,
	sendRequestContextVideo: PropTypes.func.isRequired,
}

export default EventsTable
