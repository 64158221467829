import moment from 'moment';
import {
	formatChannelStatus,
	formatChannelStatusCSV,
	formatDataUsageOld,
	formatDataUsageCSVOld,
	formatEnableVehicle,
	formatFileSize,
	formatGroup,
	formatOnlineStatus,
	formatOnlineStatusCSV,
	formatRecordingStatus,
	formatRecordingStatusCSV,
	formatSpeedKPH,
	formatTimestamp,
	formatTimespanSeconds,
	formatVehicleName,
	formatViewEvent,
	timestampSortFunc,
	formatOverrideOverageLimitsCompany,
	formatCompanyName,
	formatVehicleNameLink,
	formatTotalDataUsed,
} from '../../helpers/tablesFuncHelpers';

export const standardReportData = [
	{
		name: 'Vehicle Activity',
		id: '1',
		type: 'Fleet Activity',
		dateEditable: false,
		searchTerm: ['device_name'],
		columns: [
			{
				name: 'Vehicle Name',
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				isKey: true,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '15%',
				dataField: 'groups',
				dataFormat: formatGroup,
				csvFormat: formatGroup,
				dataSort: true,
			},
			{
				name: 'Last Connected',
				width: '20%',
				dataField: 'last_connected',
				dataFormat: formatTimestamp,
				csvFormat: formatTimestamp,
				dataSort: true,
				sortFunc: timestampSortFunc('last_connected'),
			},
			{
				name: 'Last Event',
				width: '20%',
				dataField: 'last_event',
				dataFormat: formatTimestamp,
				csvFormat: formatTimestamp,
				dataSort: true,
				sortFunc: timestampSortFunc('last_event,timestamp'),
			},
			{
				name: 'Last Uploaded Event',
				width: '20%',
				dataField: 'last_uploaded_event',
				dataFormat: formatTimestamp,
				csvFormat: formatTimestamp,
				dataSort: true,
				sortFunc: timestampSortFunc('last_uploaded_event,uploaded_timestamp'),
			},
			{
				name: 'Event Count',
				width: '20%',
				dataField: 'count',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Total File Size',
				width: '20%',
				dataField: 'total_size',
				dataFormat: formatFileSize,
				csvFormat: formatFileSize,
				dataSort: true,
			},
		],
	},
	{
		name: 'Data Usage',
		id: '2',
		type: 'Fleet Activity',
		dateEditable: false,
		defaultDateRange: 'This Month',
		columns: [
			{
				name: 'Vehicle Name',
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				isKey: true,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '11%',
				dataField: 'groups',
				dataFormat: formatGroup,
				csvFormat: formatGroup,
				dataSort: true,
			},
			{
				name: 'Data Usage',
				width: '11%',
				dataField: 'data_usage',
				dataFormat: formatDataUsageOld,
				csvFormat: formatDataUsageCSVOld,
				dataSort: true,
			},
			{
				name: 'Device ID',
				width: '11%',
				dataField: 'device_id',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Online Status',
				width: '11%',
				dataField: 'ads_online',
				dataFormat: formatOnlineStatus,
				csvFormat: formatOnlineStatusCSV,
				dataSort: true,
			},
		],
	},
	{
		name: 'Feature Usage',
		id: '3',
		type: 'Fleet Activity',
		dateEditable: true,
		columns: [
			{
				name: 'Vehicle Name',
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				isKey: true,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '20%',
				dataField: 'vehicle_group',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				// dataFormat: formatGroup,
				// csvFormat: formatGroup,
				dataSort: true,
			},
			{
				name: 'Live Video (min)',
				width: '12%',
				dataField: 'live_video_min',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Event Clips',
				width: '12%',
				dataField: 'event_clips_number',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Custom Clips',
				width: '12%',
				dataField: 'custom_clips_number',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
		],
	},
	{
		name: 'Vehicle Event Breakdown',
		id: '4',
		type: 'Fleet Activity',
		containerClass: 'event-breakdown',
		dateEditable: true,
		columns: [
			{
				name: 'Vehicle Name',
				isKey: true,
				width: '150',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '150',
				dataField: 'vehicle_group',
				dataFormat: cell => cell || '[Unnamed]',
				csvFormat: cell => cell || '[Unnamed]',
				dataSort: true,
			},
			{
				name: 'High G-Force',
				width: '150',
				dataField: 'High G-Force',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Max Speed',
				width: '150',
				dataField: 'Max Speed',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Driver Event',
				width: '150',
				dataField: 'Driver Event',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Custom Clip',
				width: '150',
				dataField: 'Custom Clip',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Flood',
				width: '150',
				dataField: 'Flood',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Sensor',
				width: '150',
				dataField: 'Sensor',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Harsh Braking',
				width: '150',
				dataField: 'Harsh Braking',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Harsh Acceleration',
				width: '150',
				dataField: 'Harsh Acceleration',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Distraction',
				width: '150',
				dataField: 'Distraction',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Drowsiness/Head Down',
				width: '150',
				dataField: 'Drowsiness/Head Down',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Phone Use/Hand to Head',
				width: '150',
				dataField: 'Phone Use/Hand to Head',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Yawning',
				width: '150',
				dataField: 'Yawning',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Seatbelt',
				width: '150',
				dataField: 'Seatbelt',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Smoking',
				width: '150',
				dataField: 'Smoking',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Forward Collision',
				width: '150',
				dataField: 'Forward Collision',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Headway Warning',
				width: '150',
				dataField: 'Headway Warning',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Lane Departure',
				width: '150',
				dataField: 'Lane Departure',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Pedestrian Warning',
				width: '150',
				dataField: 'Pedestrian Warning',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Tailgating',
				width: '150',
				dataField: 'Tailgating',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
		],
	},
	{
		name: 'Group Event Breakdown',
		id: '5',
		type: 'Fleet Activity',
		searchTerm: ['vehicle_group'],
		containerClass: 'event-breakdown',
		dateEditable: true,
		columns: [
			{
				name: 'Group',
				width: '150',
				dataField: 'vehicle_group',
				isKey: true,
				dataFormat: cell => cell || '[Unnamed]',
				csvFormat: cell => cell || '[Unnamed]',
				dataSort: true,
			},
			{
				name: 'High G-Force',
				width: '150',
				dataField: 'High G-Force',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Max Speed',
				width: '150',
				dataField: 'Max Speed',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Driver Event',
				width: '150',
				dataField: 'Driver Event',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Custom Clip',
				width: '150',
				dataField: 'Custom Clip',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Flood',
				width: '150',
				dataField: 'Flood',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Sensor',
				width: '150',
				dataField: 'Sensor',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Harsh Braking',
				width: '150',
				dataField: 'Harsh Braking',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Harsh Acceleration',
				width: '150',
				dataField: 'Harsh Acceleration',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Distraction',
				width: '150',
				dataField: 'Distraction',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Drowsiness/Head Down',
				width: '150',
				dataField: 'Drowsiness/Head Down',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Phone Use/Hand to Head',
				width: '150',
				dataField: 'Phone Use/Hand to Head',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Yawning',
				width: '150',
				dataField: 'Yawning',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Seatbelt',
				width: '150',
				dataField: 'Seatbelt',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Smoking',
				width: '150',
				dataField: 'Smoking',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Forward Collision',
				width: '150',
				dataField: 'Forward Collision',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Headway Warning',
				width: '150',
				dataField: 'Headway Warning',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Lane Departure',
				width: '150',
				dataField: 'Lane Departure',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Pedestrian Warning',
				width: '150',
				dataField: 'Pedestrian Warning',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Tailgating',
				width: '150',
				dataField: 'Tailgating',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
		],
	},
	{
		name: 'DMS Events',
		id: '6',
		type: 'Fleet Activity',
		defaultSortName: 'timestamp',
		defaultSortOrder: 'desc',
		dateEditable: true,
		columns: [
			{
				name: 'Vehicle Name',
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				dataSort: true,
			},
			{
				name: 'Type',
				width: '20%',
				dataField: 'type',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Date/Time',
				isKey: true,
				width: '20%',
				dataField: 'timestamp',
				dataFormat: cell => cell ? formatTimestamp(`${cell}+00`) : '-',
				csvFormat: cell => cell ? formatTimestamp(`${cell}+00`) : '-',
				dataSort: true,
			},
			{
				name: 'View Event',
				width: '12%',
				dataField: 'event_id',
				dataFormat: formatViewEvent,
				csvFormat: cell => cell,
				dataSort: true,
			},
		],
	},
	// {
	// 	name: 'ADAS Events',
	// 	id: '7',
	// 	type: 'Fleet Activity',
	// 	defaultSortName: 'timestamp',
	// 	defaultSortOrder: 'desc',
	// 	dateEditable: true,
	// 	columns: [
	// 		{
	// 			name: 'Vehicle Name',
	// 			width: '20%',
	// 			dataField: 'device_id',
	// 			dataFormat: formatVehicleName,
	// 			csvFormat: formatVehicleName,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Type',
	// 			width: '20%',
	// 			dataField: 'type',
	// 			dataFormat: cell => cell,
	// 			csvFormat: cell => cell,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Date/Time',
	// 			isKey: true,
	// 			width: '20%',
	// 			dataField: 'timestamp',
	// 			dataFormat: cell => cell ? formatTimestamp(`${cell}+00`) : '-',
	// 			csvFormat: cell => cell ? formatTimestamp(`${cell}+00`) : '-',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'View Event',
	// 			width: '12%',
	// 			dataField: 'event_id',
	// 			dataFormat: formatViewEvent,
	// 			csvFormat: cell => cell,
	// 			dataSort: true,
	// 		},
	// 	],
	// },
	{
		name: 'Driver Event Breakdown',
		id: '7',
		type: 'Driver Activity',
		dateEditable: true,
		columns: [
			{
				name: 'First Name',
				width: '20%',
				dataField: 'first_name',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				isKey: true,
				dataSort: true,
			},
			{
				name: 'Last Name',
				width: '20%',
				dataField: 'last_name',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '15%',
				dataField: 'driver_group',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Speeding',
				width: '15%',
				dataField: 'HighSpeed',
				dataFormat: (cell) => cell || 0,
				csvFormat: (cell) => cell || 0,
				dataSort: true,
			},
			{
				name: 'High G-Force',
				width: '15%',
				dataField: 'GSensor',
				dataFormat: (cell) => cell || 0,
				csvFormat: (cell) => cell || 0,
				dataSort: true,
			},
		],
	},
	{
		name: 'Driver Event Performance',
		id: '8',
		type: 'Driver Activity',
		dateEditable: true,
		columns: [
			{
				name: 'First Name',
				width: '20%',
				dataField: 'first_name',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				isKey: true,
				dataSort: true,
			},
			{
				name: 'Last Name',
				width: '20%',
				dataField: 'last_name',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '15%',
				dataField: 'driver_group',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Miles Driven/Event',
				width: '20%',
				dataField: 'miles_driven_per_event',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Miles Driven',
				width: '20%',
				dataField: 'miles_driven',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
		],
	},
	{
		name: 'Firmware',
		id: '9',
		type: 'Camera Health',
		dateEditable: false,
		columns: [
			{
				name: 'Vehicle Name',
				width: '15%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				isKey: true,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '10%',
				dataField: 'vehicle_group',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Version',
				width: '10%',
				dataField: 'firmware',
				dataFormat: (cell) => (cell || '-'),
				csvFormat: (cell) => (cell || '-'),
				dataSort: true,
			},
			{
				name: 'Updated Date',
				width: '15%',
				dataField: 'firmware_updated_ts',
				dataFormat: formatTimestamp,
				csvFormat: formatTimestamp,
				dataSort: true,
			},
			{
				name: 'Firmware Status',
				width: '45%',
				dataField: 'firmware_status',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
		],
	},
	{
		name: 'Channel Status',
		id: '10',
		type: 'Camera Health',
		dateEditable: false,
		columns: [
			{
				name: 'Vehicle Name',
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				isKey: true,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '11%',
				dataField: 'groups',
				dataFormat: formatGroup,
				csvFormat: formatGroup,
				dataSort: true,
			},
			{
				name: 'Channel Status',
				width: '11%',
				dataField: 'channel_status',
				dataFormat: formatChannelStatus,
				csvFormat: formatChannelStatusCSV,
				dataSort: true,
			},
			{
				name: 'Device ID',
				width: '11%',
				dataField: 'device_id',
				dataFormat: (cell) => cell,
				csvFormat: (cell) => cell,
				dataSort: true,
			},
			{
				name: 'Online Status',
				width: '11%',
				dataField: 'ads_online',
				dataFormat: formatOnlineStatus,
				csvFormat: formatOnlineStatusCSV,
				dataSort: true,
			},
		],
	},
	// {
	// 	name: 'Recording Status (Under Improvement)',
	// 	id: '11',
	// 	type: 'Camera Health',
	// 	dateEditable: false,
	// 	columns: [
	// 		{
	// 			name: 'Vehicle Name',
	// 			width: '20%',
	// 			dataField: 'device_id',
	// 			dataFormat: formatVehicleName,
	// 			csvFormat: formatVehicleName,
	// 			isKey: true,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Group',
	// 			width: '11%',
	// 			dataField: 'groups',
	// 			dataFormat: formatGroup,
	// 			csvFormat: formatGroup,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Recording Status',
	// 			width: '11%',
	// 			dataField: 'sd_status',
	// 			dataFormat: formatRecordingStatus,
	// 			csvFormat: formatRecordingStatusCSV,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Device ID',
	// 			width: '11%',
	// 			dataField: 'device_id',
	// 			dataFormat: (cell) => cell,
	// 			csvFormat: (cell) => cell,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Online Status',
	// 			width: '11%',
	// 			dataField: 'ads_online',
	// 			dataFormat: formatOnlineStatus,
	// 			csvFormat: formatOnlineStatusCSV,
	// 			dataSort: true,
	// 		},
	// 	],
	// },
	{
		name: 'Event Detail',
		id: '12',
		type: 'Fleet Activity',
		defaultSortName: 'timestamp',
		defaultSortOrder: 'desc',
		dateEditable: true,
		columns: [
			{
				name: 'Vehicle Name',
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				dataSort: true,
			},
			{
				name: 'Type',
				width: '20%',
				dataField: 'type',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Date/Time',
				isKey: true,
				width: '20%',
				dataField: 'timestamp',
				dataFormat: cell => cell ? formatTimestamp(`${cell}+00`) : '-',
				csvFormat: cell => cell ? formatTimestamp(`${cell}+00`) : '-',
				dataSort: true,
			},
			{
				name: 'View Event',
				width: '12%',
				dataField: 'event_id',
				dataFormat: formatViewEvent,
				csvFormat: cell => cell,
				dataSort: true,
			},
		],
	},
	// {
	// 	name: 'Data Tracking1',
	// 	id: '33',
	// 	type: 'Admin Reports',
	// 	reportName: 'Data Usage (1 GB Limit)',
	// 	secondaryReportName: 'Data Usage (500 MB Limit)',
	// 	searchTerm: ['device_id', 'vehicle_name', 'partner_name', 'company_name', 'vehicle_group', 'iccid', 'imei'],
	// 	defaultSortName: 'data_usage',
	// 	defaultSortOrder: 'desc',
	// 	containerClass: 'event-breakdown',
	// 	defaultDateRange: 'This Month',
	// 	dateEditable: true,
	// 	columns: [
	// 		{
	// 			name: 'Vehicle Name',
	// 			width: '200',
	// 			dataField: 'device_id',
	// 			isKey: true,
	// 			dataFormat: formatVehicleName,
	// 			csvFormat: formatVehicleName,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'IMEI',
	// 			width: '135',
	// 			dataField: 'imei',
	// 			dataFormat: cell => cell || '-',
	// 			csvFormat: cell => cell || '-',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'ICCID',
	// 			width: '180',
	// 			dataField: 'iccid',
	// 			dataFormat: cell => cell || '-',
	// 			csvFormat: cell => cell || '-',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Fleet',
	// 			width: '175',
	// 			dataField: 'company_name',
	// 			dataFormat: cell => cell || '-',
	// 			csvFormat: cell => cell || '-',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Partner',
	// 			width: '175',
	// 			dataField: 'partner_name',
	// 			dataFormat: cell => cell || '-',
	// 			csvFormat: cell => cell || '-',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Group',
	// 			width: '150',
	// 			dataField: 'vehicle_group',
	// 			dataFormat: cell => cell || '-',
	// 			csvFormat: cell => cell || '-',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Fleet Allowance',
	// 			width: '150',
	// 			dataField: 'fleetLimit',
	// 			dataFormat: cell => (cell || 0) + ' GB',
	// 			csvFormat: cell => (cell || 0) + ' GB',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Data Usage',
	// 			width: '115',
	// 			dataField: 'data_usage',
	// 			dataFormat: cell => ((cell / 1024).toFixed(2) || 0) + ' GB',
	// 			csvFormat: cell => ((cell / 1024).toFixed(2) || 0) + ' GB',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Fleet Usage',
	// 			width: '125',
	// 			dataField: 'fleetUsage',
	// 			dataFormat: cell => ((cell / 1024).toFixed(2) || 0) + ' GB',
	// 			csvFormat: cell => ((cell / 1024).toFixed(2) || 0) + ' GB',
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Enable/Disable',
	// 			width: '125',
	// 			dataField: 'active',
	// 			dataFormat: formatEnableVehicle,
	// 			csvFormat: cell => cell ? 'Enabled' : 'Disabled',
	// 			dataSort: true,
	// 		},
	// 	],
	// },
	{
		name: 'Data Tracking',
		id: '13',
		type: 'Admin Reports',
		reportName: 'Companies',
		searchTerm: ['company_name'],
		defaultSortName: 'company_name',
		defaultSortOrder: 'asc',
		containerClass: 'event-breakdown',
		defaultDateRange: 'This Month',
		dateEditable: true,
		secondaryReportName: 'Devices',
		columns: [
			{
				name: 'Company ID',
				dataField: 'company_id',
				isKey: true,
				hidden: true,
			},
			{
				name: 'Company',
				width: '200',
				dataField: 'company_name',
				dataFormat: formatCompanyName,
				// csvFormat: formatVehicleName,
				dataSort: true,
			},
			{
				name: 'Partner',
				width: '135',
				dataField: 'partner_name',
				// dataFormat: cell => cell || '-',
				// csvFormat: cell => cell || '-',
				dataSort: true,
			},
			{
				name: 'Active Devices',
				width: '180',
				dataField: 'active_devices',
				dataSort: true,
			},
			{
				name: 'Total Data Used (GB)',
				width: '175',
				dataField: 'total_data_usage',
				dataFormat: formatTotalDataUsed,
				csvFormat: cell => (cell / 1024).toFixed(2),
				dataSort: true,
			},
			{
				name: 'Available Data Pool (GB)',
				width: '175',
				dataField: 'available_data_pool',
				dataFormat: cell => cell !== null ? (cell / 1024).toFixed(2) : 'Unlimited',
				csvFormat: cell => cell !== null ? (cell / 1024).toFixed(2) : 'Unlimited',
				dataSort: true,
			},
			{
				name: 'Overage Cost (USD)',
				width: '150',
				dataField: 'overage_cost',
				dataSort: true,
			},
			{
				name: 'Override Overage Limits',
				width: '150',
				dataField: 'override_overage_limits',
				dataFormat: formatOverrideOverageLimitsCompany,
				csvFormat: cell => cell ? 'Enabled' : 'Disabled',
				dataSort: true,
			},			
		],
		secondaryColumns: [
			{
				name: 'Device ID',
				dataField: 'device_id',
				isKey: true,
				hidden: true,
			},
			{
				name: 'Vehicle Name',
				width: '200',
				dataField: 'vehicle_name',
				dataFormat: formatVehicleNameLink,
				// csvFormat: formatVehicleName,
				dataSort: true,
			},
			{
				name: 'Company',
				width: '135',
				dataField: 'company_name',
				dataSort: true,
			},
			{
				name: 'Device Type',
				width: '180',
				dataField: 'device_type',
				dataSort: true,
			},
			{
				name: 'IMEI',
				width: '180',
				dataField: 'imei',
				dataSort: true,
			},
			{
				name: 'Total Data Used (GB)',
				width: '175',
				dataField: 'total_data_usage',
				dataFormat: cell => (cell / 1024).toFixed(2),
				csvFormat: cell => (cell / 1024).toFixed(2),
				dataSort: true,
			},
			{
				name: 'Data Pool Usage (%)',
				width: '150',
				dataField: 'data_usage',
				dataSort: true,
			},
			{
				name: 'Enable/Disable Device',
				width: '150',
				dataField: 'active',
				dataFormat: formatEnableVehicle,
				csvFormat: cell => cell ? 'Enabled' : 'Disabled',
				dataSort: true,
			},			
		],
	},
	{
		name: 'Idling Summary',
		id: '14',
		type: 'Fleet Activity',
		dateEditable: true,
		columns: [
			{
				name: 'Vehicle Name',
				isKey: true,
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '20%',
				dataField: 'group_name',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Idling Instances',
				width: '15%',
				dataField: 'idling_instances',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Total Time Idling',
				width: '15%',
				dataField: 'total_idling',
				dataFormat: formatTimespanSeconds,
				csvFormat: formatTimespanSeconds,
				dataSort: true,
			},
			{
				name: 'Average Idle Time',
				width: '15%',
				dataField: 'idling_average',
				dataFormat: formatTimespanSeconds,
				csvFormat: formatTimespanSeconds,
				dataSort: true,
			},
		],
	},
	{
		name: 'Idling Breakdown',
		id: '15',
		type: 'Fleet Activity',
		dateEditable: true,
		columns: [
			{
				name: 'Vehicle Name',
				width: '20%',
				dataField: 'device_id',
				dataFormat: formatVehicleName,
				csvFormat: formatVehicleName,
				dataSort: true,
			},
			{
				name: 'Group',
				width: '20%',
				dataField: 'group_name',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: true,
			},
			{
				name: 'Date/Time',
				isKey: true,
				width: '15%',
				dataField: 'idling_ts',
				dataFormat: formatTimestamp,
				csvFormat: formatTimestamp,
				dataSort: true,
			},
			{
				name: 'Duration',
				width: '15%',
				dataField: 'idling_duration',
				dataFormat: formatTimespanSeconds,
				csvFormat: formatTimespanSeconds,
				dataSort: true,
			},
		],
	},
	// {
	// 	name: 'Speeding Summary',
	// 	id: '16',
	// 	type: 'Fleet Activity',
	// 	dateEditable: true,
	// 	columns: [
	// 		{
	// 			name: 'Vehicle Name',
	// 			width: '20%',
	// 			dataField: 'device_id',
	// 			dataFormat: formatVehicleName,
	// 			csvFormat: formatVehicleName,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Group',
	// 			width: '20%',
	// 			dataField: 'group_name',
	// 			dataFormat: cell => cell,
	// 			csvFormat: cell => cell,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Speeding Instances',
	// 			isKey: true,
	// 			width: '15%',
	// 			dataField: 'speed_instances',
	// 			dataFormat: cell => cell,
	// 			csvFormat: cell => cell,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Low Speeding Time',
	// 			width: '15%',
	// 			dataField: 'low_speeding',
	// 			dataFormat: formatTimespanSeconds,
	// 			csvFormat: formatTimespanSeconds,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Moderate Speeding Time',
	// 			width: '15%',
	// 			dataField: 'moderate_speeding',
	// 			dataFormat: formatTimespanSeconds,
	// 			csvFormat: formatTimespanSeconds,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'High Speeding Time',
	// 			width: '15%',
	// 			dataField: 'high_speeding',
	// 			dataFormat: formatTimespanSeconds,
	// 			csvFormat: formatTimespanSeconds,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Severe Speeding Time',
	// 			width: '15%',
	// 			dataField: 'severe_speeding',
	// 			dataFormat: formatTimespanSeconds,
	// 			csvFormat: formatTimespanSeconds,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Max Speed',
	// 			width: '12%',
	// 			dataField: 'max_speed',
	// 			dataFormat: formatSpeedKPH,
	// 			csvFormat: formatSpeedKPH,
	// 			dataSort: true,
	// 		},
	// 	],
	// },
	// {
	// 	name: 'Speeding Breakdown',
	// 	id: '17',
	// 	type: 'Fleet Activity',
	// 	dateEditable: true,
	// 	columns: [
	// 		{
	// 			name: 'Vehicle Name',
	// 			width: '20%',
	// 			dataField: 'device_id',
	// 			dataFormat: formatVehicleName,
	// 			csvFormat: formatVehicleName,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Group',
	// 			width: '20%',
	// 			dataField: 'group_name',
	// 			dataFormat: cell => cell,
	// 			csvFormat: cell => cell,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Date/Time',
	// 			isKey: true,
	// 			width: '15%',
	// 			dataField: 'speeding_ts',
	// 			dataFormat: formatTimestamp,
	// 			csvFormat: formatTimestamp,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Type',
	// 			width: '15%',
	// 			dataField: 'speeding_type',
	// 			dataFormat: cell => cell,
	// 			csvFormat: cell => cell,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Time Speeding',
	// 			width: '15%',
	// 			dataField: 'duration',
	// 			dataFormat: formatTimespanSeconds,
	// 			csvFormat: formatTimespanSeconds,
	// 			dataSort: true,
	// 		},
	// 		{
	// 			name: 'Max Speed',
	// 			width: '12%',
	// 			dataField: 'max_speed',
	// 			dataFormat: formatSpeedKPH,
	// 			csvFormat: formatSpeedKPH,
	// 			dataSort: true,
	// 		},
	// 	],
	// },
	{
		name: 'Location History',
		id: '18',
		type: 'Fleet Activity',
		dateEditable: true,
		searchTerm: ['address'],
		defaultDateRange: 'Today',
		columns: [
			{
				name: 'Time',
				isKey: true,
				width: '15%',
				dataField: 'time',
				dataFormat: formatTimestamp,
				csvFormat: formatTimestamp,
				dataSort: true,
			},
			{
				name: 'Address',
				width: '15%',
				dataField: 'address',
				dataFormat: cell => cell,
				csvFormat: cell => cell,
				dataSort: false,
			}
		],
	},
]

export const getDateRangeFromTabKey = (tabKey, allowFutureDates = null) => {
	let new_start_date, new_end_date;
	let currentDate = moment().format('YYYYMMDD');
	switch (tabKey) {
		case 1:
			//today
			new_start_date = currentDate;
			new_end_date = currentDate;
			break;
		case 2:
			//yesterday
			let yesterday = moment().subtract(1, 'days').startOf(currentDate).format('YYYYMMDD');
			new_start_date = yesterday;
			new_end_date = yesterday;
			break;
		case 3:
			//this week
			new_start_date = moment().startOf('week').format('YYYYMMDD');
			new_end_date = allowFutureDates ? moment().endOf('week').format('YYYYMMDD') : currentDate;
			break;
		case 4:
			//last week
			new_start_date = moment().subtract(1, 'weeks').startOf('week').format('YYYYMMDD');
			new_end_date = moment().subtract(1, 'weeks').endOf('week').format('YYYYMMDD');
			break;
		case 5:
			//this month
			new_start_date = moment().startOf('month').format('YYYYMMDD');
			new_end_date = allowFutureDates ? moment().endOf('month').format('YYYYMMDD') : currentDate;
			break;
		case 6:
			//last month
			new_start_date = moment().subtract(1, 'months').startOf('month').format('YYYYMMDD');
			new_end_date = moment().subtract(1, 'months').endOf('month').format('YYYYMMDD');
			break;
	}
	return [new_start_date, new_end_date];
}

export const vehicleReportFields = [
	{
		name: 'Vehicle Info',
		fields: [
			{
				name: 'Vehicle Name',
				fieldName: 'vehicle_name',
				defaultSelected: true,
			},
			{
				name: 'VIN',
				fieldName: 'vin',
			},
		],
	},
	{
		name: 'Event Counts',
		fields: [
			{
				name: 'High G-Force',
				fieldName: 'critical_gsensor',
			},
			{
				name: 'Custom Video',
				fieldName: 'custom',
			},
			{
				name: 'Driver',
				fieldName: 'driver',
			},
			{
				name: 'Max Speed',
				fieldName: 'speeding',
			},
			{
				name: 'Input Sensor',
				fieldName: 'input_sensor',
			},
			{
				name: 'Harsh Braking',
				fieldName: 'harshbraking',
			},
			{
				name: 'Harsh Acceleration',
				fieldName: 'harshaccel',
			},
			{
				name: 'Potential Ch2 Obstruction',
				fieldName: 'camera_block',
			},
		],
	},
	{
		name: 'DMS Event Counts',
		fields: [
			{
				name: 'Distraction',
				fieldName: 'distraction',
			},
			{
				name: 'Drowsiness/Head Down',
				fieldName: 'drowsiness',
			},
			{
				name: 'Phone Use/Hand to Head',
				fieldName: 'phone_use',
			},
			{
				name: 'Yawning',
				fieldName: 'yawning',
			},
			{
				name: 'Seatbelt',
				fieldName: 'seatbelt',
			},
			{
				name: 'Smoking',
				fieldName: 'smoking',
			},
		],
	},
	{
		name: 'ADAS Event Counts',
		fields: [
			{
				name: 'Forward Collision',
				fieldName: 'adas_fcw',
			},
			{
				name: 'Headway Warning',
				fieldName: 'adas_hw',
			},
			{
				name: 'Lane Departure',
				fieldName: 'adas_ldw',
			},
			{
				name: 'Pedestrian Warning',
				fieldName: 'adas_pcw',
			},
			{
				name: 'Tailgating',
				fieldName: 'tailgating',
			},
		],
	},
	{
		name: 'Camera Health',
		fields: [
			{
				name: 'First Connected',
				fieldName: 'first_connected_ts',
			},
			{
				name: 'Last Connected',
				fieldName: 'last_connected_ts',
			},
			{
				name: 'No SD Card Alert',
				fieldName: 'no_sd_card',
			},
		],
	},
	{
		name: 'Modem / Router',
		fields: [
			{
				name: 'IMEI',
				fieldName: 'imei',
			},
			{
				name: 'Manufacturer',
				fieldName: 'manufacturer',
			},
			{
				name: 'Model',
				fieldName: 'model',
			},
			{
				name: 'Version',
				fieldName: 'version',
			},
		],
	},
]

export const driverReportFields = [
	{
		name: 'Driver Info',
		fields: [
			{
				name: 'Driver Name',
				fieldName: 'driver_name',
				defaultSelected: true,
			},
			{
				name: 'Group',
				fieldName: 'group',
				defaultSelected: true,
			},
			{
				name: 'Last Trip Date',
				fieldName: 'last_trip_ts',
			},
			{
				name: 'Last Vehicle Driven',
				fieldName: 'last_vehicle_driven',
			},
			{
				name: 'Miles Driven',
				fieldName: 'miles_driven',
			},
			{
				name: 'Miles Driven / Event',
				fieldName: 'miles_driven_per_event',
			},
		],
	},
	{
		name: 'Event Counts',
		fields: [
			{
				name: 'High G-Force',
				fieldName: 'critical_gsensor',
			},
			{
				name: 'Custom Video',
				fieldName: 'custom',
			},
			{
				name: 'Driver',
				fieldName: 'driver',
			},
			{
				name: 'Speeding',
				fieldName: 'speeding',
			},
			{
				name: 'Input Sensor',
				fieldName: 'input_sensor',
			},
			{
				name: 'Harsh Braking',
				fieldName: 'harshbraking',
			},
			{
				name: 'Harsh Acceleration',
				fieldName: 'harshaccel',
			},
		],
	},
	{
		name: 'DMS Event Counts',
		fields: [
			{
				name: 'Distraction',
				fieldName: 'distraction',
			},
			{
				name: 'Drowsiness/Head Down',
				fieldName: 'drowsiness',
			},
			{
				name: 'Phone Use/Hand to Head',
				fieldName: 'phone_use',
			},
			{
				name: 'Yawning',
				fieldName: 'yawning',
			},
			{
				name: 'Seatbelt',
				fieldName: 'seatbelt',
			},
			{
				name: 'Smoking',
				fieldName: 'smoking',
			},
		],
	},
	{
		name: 'ADAS Event Counts',
		fields: [
			{
				name: 'Forward Collision',
				fieldName: 'adas_fcw',
			},
			{
				name: 'Headway Warning',
				fieldName: 'adas_hw',
			},
			{
				name: 'Lane Departure',
				fieldName: 'adas_ldw',
			},
			{
				name: 'Pedestrian Warning',
				fieldName: 'adas_pcw',
			},
			{
				name: 'Tailgating',
				fieldName: 'tailgating',
			},
		],
	},
]