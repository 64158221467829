// FWSD-5329
import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'

import IconTooltip from '../../component/dump/menuComponents/IconTooltip'

const CameraEnableToggle = (props) => {
	const { isActive, onChange, addClass } = props
	return (
		<div className={`toggle-wrapper camera-toggle ${addClass}`}>
			<div className="device-label">
				Camera
				<IconTooltip
					description="When the device is disabled, it will not communicate with ROSCOLive. This will prevent any events being uploaded while disabled."
					id="disable-vehicle-tooltip"
				/>
			</div>
			<label>
				<span className="toggle-left-title">{isActive ? 'Enabled' : 'Disabled'}</span>
				<Toggle
					name="active"
					icons={false}
					checked={isActive || false}
					onChange={onChange}
				/>
			</label>
		</div>
	)
}

CameraEnableToggle.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	addClass: PropTypes.string,
}

CameraEnableToggle.defaultProps = {
	addClass: '',
}

export default CameraEnableToggle;
