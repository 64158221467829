import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function loginUserToken(data) {
	// console.log('!loginUserToken action data', data);

	const url = `${API_URL}/geotab/login`;

	const form = new FormData();
	form.append('token', data.token);
	form.append('database', data.database);
	form.append('role', data.role);

	const settings1 = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings1);
}

export function verifyUserPassword(data) {
	// console.log('!loginUserToken action data', data)
	const url = `${API_URL}/users/verify_password`
	const form = new FormData()
	form.append('password', data.password)

	const settings1 = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings1);
}

export function loginUser(data) {
	// console.log('!loginUser action:', data)

	//    return axios.post('/login', data);
	const url = `${API_URL}/login`

	//    var settings = {
	//        "async": true,
	//        "crossDomain": true,
	//        "url": "http://ttdevapi.roscocloud.com/api/v0.5/logout",
	//        "method": "GET",
	//        // "headers": {},
	//        "withCredentials": true
	//    }

	//    axios(settings)
	//        .then(function(response) {
	//            console.log('!logout');
	//            console.log(response);
	//
	const form = new FormData()
	form.append('email', data.email)
	form.append('password', data.password)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings)

	//        })
	//        .catch(function (error) {
	//            console.log('!error');
	//            console.log(error);
	//        });

	//    return axios.post(url, {"email":"maxim.kvaternyuk@gmail.com", "password":"password"});

}

export function logoutUser() {
	const url = `${API_URL}/logout`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": { Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}


export function restorePassword(data) {
	console.log('!restorePassword action email', data);

	const url = `${API_URL}/reset`;

	const form = new FormData();
	form.append('email', data.email);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function changePassword(data) {
	console.log('!changePassword action data', data);

	const url = `${API_URL}/reset/${data.token}`;

	const form = new FormData();
	form.append('password', data.password);
	form.append('password_confirm', data.password_confirm);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function updateUserAccount (state, user_role) {
	console.log('!updateUserAccount action state:', state);

	let url;

	switch (user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/users/${state.email}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/users/${state.email}`;
		break;
	case 'custom_partner':
	case 'partner':
		url = `${API_URL}/partners/users/${state.email}`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'storage_manager':
	case 'custom_user':
	case 'user':
		url = `${API_URL}/users/${state.email}`;
		break;
	case 'group_manager':
		url = `${API_URL}/group_manager/users/${state.email}`;
		break;
	default:
		break;
	}

	const form = new FormData();
	if (state.last_name) {form.append('last_name', state.last_name);}
	if (state.first_name) {form.append('first_name', state.first_name);}
	if (state.hasOwnProperty('phone_number')) {form.append('phone_number', state.phone_number);}
	if (state.hasOwnProperty('speed_unit')) {form.append('speed_unit', state.speed_unit);}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function changePasswordUserAccount (state) {
	console.log('!changePasswordUserAccount action state: ', state);

	const url = `${API_URL}/change`;

	const form = new FormData();
	form.append('password', state.password);
	form.append('new_password', state.new_password);
	form.append('new_password_confirm', state.new_password_confirm);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}


export function saveDashboardData(data) {
	// console.log('!action saveDashboardData', data);
	const url = `${API_URL}/users/settings`;
	const form = new FormData();
	form.append('dashboard_data', data);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
	// axios(settings).then((res, err)=>{
	//     // console.log('!dashboardDataSave add res');
	//     // console.log(res);
	//     if (typeof callback === 'function') {
	//         return callback(null, {res})
	//     }
	//
	//
	// })
	//     .catch((error)=>{
	//         if (typeof callback === 'function') {
	//             return callback(error, null);
	//         }
	//     });
}

export function getDashboardData() {
	const url = `${API_URL}/users/settings`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}


export function saveSettings (state) {
	console.log('!saveSettings action data:', state);

	const url = `${API_URL}/users/settings`;

	const form = new FormData();
	if (state.latitude) {form.append('latitude', state.latitude);}
	if (state.longitude) {form.append('longitude', state.longitude);}
	if (state['time_zone'] != undefined) {form.append('time_zone', state.time_zone);}
	if (state.zoom_level) {form.append('zoom_level', state.zoom_level);}

	if (state.inputsensor1_active || state.inputsensor2_active || state.inputsensor3_active || state.inputsensor4_active) {
		form.append('inputsensor1_active', state.inputsensor1_active || null);
		form.append('inputsensor1_name', state.inputsensor1_name || null);
		form.append('inputsensor1_description', state.inputsensor1_description || null);
		form.append('inputsensor2_active', state.inputsensor2_active || null);
		form.append('inputsensor2_name', state.inputsensor2_name || null);
		form.append('inputsensor2_description', state.inputsensor2_description || null);
		form.append('inputsensor3_active', state.inputsensor3_active || null);
		form.append('inputsensor3_name', state.inputsensor3_name || null);
		form.append('inputsensor3_description', state.inputsensor3_description || null);
		form.append('inputsensor4_active', state.inputsensor4_active || null);
		form.append('inputsensor4_name', state.inputsensor4_name || null);
		form.append('inputsensor4_description', state.inputsensor4_description || null);
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,

	}

	return axios(settings);
}
// *** CHARTS ***
export function getDynamicDataTop(user_role, start_date, end_date, events, alerts, company_name=null, partner_name=null) {
	// FWSD-6937, if we send alerts in this request then API returnds just data from event_stats_day table otherwise from event_tb table
	let url;
	switch (user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=bottom`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=bottom`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=bottom`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=bottom`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=bottom`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=bottom`;
		break;
	default:
		break;
	}
	
	if (alerts.length) {
		url += `&alerts=${alerts}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getDynamicDataBottom(user_role, start_date, end_date, events, alerts, company_name=null, partner_name=null) {
	// FWSD-6937, if we send alerts in this request then API returnds just data from event_stats_day table otherwise from event_tb table
	let url;
	switch (user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=top`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=top`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=top`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=top`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=top`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&type=top`;
		break;
	default:
		break;
	}

	if (alerts.length) {
		url += `&alerts=${alerts}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getDynamicDataDevices(user_role, start_date, end_date, events, alerts, devices, company_name=null, partner_name=null) {
	// FWSD-6937, if we send alerts in this request then API returnds just data from event_stats_day table otherwise from event_tb table
	let url;
	switch (user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&device_ids=${devices}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&device_ids=${devices}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&device_ids=${devices}`;
			break;
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&device_ids=${devices}`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&device_ids=${devices}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/reports/events?start_ts=${start_date}&end_ts=${end_date}&events=${events}&device_ids=${devices}`;
			break;
		default:
			break;
	}

	if (alerts.length) {
		url += `&alerts=${alerts}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getReportFirmware(user_role, company_name=null, partner_name=null) {
	let url;
	switch (user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/reports/firmware`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/reports/firmware`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company_name}/reports/firmware`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${company_name}/reports/firmware`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/reports/firmware`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/reports/firmware`;
		break;
	default:
		break;
	}
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getReportDataUsage(user_role, company_name=null, partner_name=null, data) {
	let url;
	switch (user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/reports/data_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/reports/data_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company_name}/reports/data_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${company_name}/reports/data_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/reports/data_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/reports/data_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	default:
		break;
	}
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getReportVehicleActivity(user_role, company_name=null, partner_name=null, data) {
	let url;
	switch (user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/reports/vehicle_activity?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/reports/vehicle_activity?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company_name}/reports/vehicle_activity?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${company_name}/reports/vehicle_activity?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/reports/vehicle_activity?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/reports/vehicle_activity?start_date=${data.start_date}&end_date=${data.end_date}`;
		break;
	default:
		break;
	}
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function signupImport(data) {
	// console.log('!logInRLOne action data', data);

	const url = `${API_URL}/import/signup`;

	const form = new FormData();
	form.append('user_login', data.user_login);
	form.append('user_password', data.user_password);
	form.append('fleet_token_r2', data.fleet_token_r2);
	form.append('fleet_token_r1', data.fleet_token_r1);


	const settings1 = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings1);
}

export function checkImportStatus(token) {
	const url = `${API_URL}/import/check?fleet_token_r2=${token}`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getUserPermissions(data) {
	console.log('getUserPermissions action data: ', data)

	//    let url;
	//    switch (data.user_role) {
	//        case 'system_admin':
	//        case 'customer_service':
	//            url = `${API_URL}/users/${data.user_id}/permissions`
	//            break
	//    }
	const url = `${API_URL}/users/${data.user_id}/permissions`

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}


export function updateUserPermissions(data) {
	console.log('!updateUserPermissions action data: ', data);

	let url;
	switch (data.user_role) {
	case 'system_admin':
	case 'customer_service':
	case 'fleet_manager':
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/users/${data.user_id}/permissions`
		break
	default:
		break
	}

	const form = new FormData();
	form.append('permissions', data.permissions);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function getUserNotifications(email, user_role = null) {
	let url;
	if (user_role === 'partner') {
		url = `${API_URL}/partners/users/${email}?show_notifications=true`
	}
	else {
		url = `${API_URL}/users/${email}?show_notifications=true`
	}
	
	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function updateUserNotifications(email, data) {
	const url = `${API_URL}/users/updatenotifications/${email}`

	let form = new FormData()

	for (let notif of Object.keys(data)) {
		form.append(notif, JSON.stringify(data[notif]))
	}
	
	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings)
}

export function addTutorialVideo(data) {
	let url = `${API_URL}/tutorials`
	
	const form = new FormData();
	form.append('url', data.url);
	form.append('name', data.name);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'PUT',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function deleteTutorialVideo(id) {
	let url = `${API_URL}/tutorials/${id}`;

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}