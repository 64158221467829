import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import { FaCheck, FaPlus } from 'react-icons/fa'
import { IoIosArrowForward } from "react-icons/io"


const TreeGroupNode = (props) => {
	// console.log('PROPS TreeGroupNode: ', props)
	// const { text, onClick, small, withArrow, rightLabel, className } = props
	const { group, onTreeGroupNodeClick } = props
	const { groupName, devices, collapsed, statuses } = group

	// const [state, setState] = useState({
	// 	driving: 0,
	// 	sleep: 0,
	// 	offline: 0,
	// 	parked: 0,
	// 	awake: 0,
	// 	disabled: 0,
	// })

	// useEffect(() => {
	// 	const newState = {
	// 		driving: 0,
	// 		sleep: 0,
	// 		offline: 0,
	// 		parked: 0,
	// 		awake: 0,
	// 		disabled: 0,
	// 	}
	// 	devices.forEach((item) => {
	// 		if (!item.active) {
	// 			newState.disabled ++
	// 		}
	// 		else if (!item.online) {
	// 			newState.offline ++
	// 		}
	// 		else {
	// 			const ignitionOffTS = item.ignition && item.ignition.ts ? item.ignition.ts : ''; // 2023-01-04 18:50:37
	// 			const duration = moment.duration(moment.utc().diff(moment.utc(ignitionOffTS), 'seconds'), 'seconds')
	// 			const durationInMins = duration.asMinutes()

	// 			// FWSD-4314 - If device does not receive GPS from device and ignition is Off more 24 hours (1440 mins) then display Online status.
	// 			// Device stopped receive GPS, broken antena or something, and FileServer does not know when ignition is On.
	// 			if ((item.ignition && item.ignition.state) || (!item.gps_online && durationInMins > 1440)) {
	// 				newState.driving ++
	// 			}
	// 			else (
	// 				newState.parked ++
	// 			)
	// 		}
	// 	})
	// 	setState((prevState) => ({ ...prevState, ...newState }))
	// }, [devices])

	return (
		<button 
			// className={classNames('category-item', { [className]: className, 'flex-item' : (withArrow || rightLabel), 'thin': small })}
			className={`category-item flex-item thin ${ !collapsed && 'expanded'}`}
			onClick={() => onTreeGroupNodeClick(group)}
		>
			<div>
				{`${groupName === 'null' ? 'NULL GROUP' : groupName} (${devices.length})`}
				<div className="group-statuses status-row">
					{statuses.driving > 0 && <><div className="icon driving" /><span>{statuses.driving}</span></>}
					{statuses.parked > 0 && <><div className="icon parked" /><span>{statuses.parked}</span></>}
					{statuses.offline > 0 && <><div className="icon offline" /><span>{statuses.offline}</span></>}
					{statuses.disabled > 0 && <><div className="icon disabled" /><span>{statuses.disabled}</span></>}
					{statuses.sleep > 0 && <><div className="icon sleep" /><span>{statuses.sleep}</span></>}
					{statuses.awake > 0 && <><div className="icon awake" /><span>{statuses.awake}</span></>}
					{statuses['pending sleep'] > 0 && <><div className="icon pending_sleep" /><span>{statuses['pending sleep']}</span></>}
					{statuses['pending awake'] > 0 && <><div className="icon pending_awake" /><span>{statuses['pending awake']}</span></>}
				</div>
			</div>
			<IoIosArrowForward/>
			{/* { text }
			{ withArrow && 
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right item-arrow" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			}
			{ rightLabel &&
				<label className='right-label'>{rightLabel}</label>
			} */}
		</button>
	)
}

// CategoryItem.propTypes = {
// 	text: PropTypes.string.isRequired,
// 	withArrow: PropTypes.bool,
// 	onClick: PropTypes.func,
// }

// CategoryItem.defaultProps = {
// 	withArrow: false,
// };

export default TreeGroupNode;
