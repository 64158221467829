const defaultState = {
	deviceId: '', // RL device id paired with passed Webfleet device serial number
}

const webfleetData = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_WEBFLEET_DEVICE_ID': {
			return { ...state, deviceId: action.payload || '' }
		}
		default:
			return state
	}
}

export default webfleetData
