import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router';
import Toggle from 'react-toggle';
import classnames from 'classnames';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';

import ModalDelete from '../../component/smart/modals/ModalDelete'
import RolloutBlacklistModal from './RolloutBlacklistModal'
import SearchBar from '../../component/dump/menuComponents/SearchBar'
import FleetAdminFirmwareBSTable from './FleetAdminFirmwareBSTable'
import GreyHeader from '../../component/dump/menuComponents/GreyHeader';

import { defaultTableOptions, expandTableOptions } from '../../component/helpers/table'
import { isDv6, isInternalUser } from '../../component/helpers/constants';
import { getAvailableFirmwareList, applyFirmwareAction, pushFirmwareFile, deleteFirmware, sendNotification, changeFirmwareViewingOptions } from '../../action/FleetAdminActions'
import { getDevicesList } from '../../action/DeviceActions'
import { apiCall, apiCallGet } from '../../action/RouterActions'

import '../../component/dump/menuComponents/menuComponents.sass'
import CustomButton from '../../component/CustomButton'

const Firmware = (props) => {
	const { user, company, toggleLoader } = props;
	const userRole = user.roles[0];

	const file_ref = useRef(null);
	const release_note_ref = useRef(null);
	const refs = { file: file_ref, release_note: release_note_ref };

	const [devices, setDevices] = useState([]);
	const [groups, setGroups] = useState([]);
	const [firmwares, setFirmwares] = useState([]);
	const [data, setData] = useState({
		file: '',
		release_note: ''
	});
	const [searchText, setSearchText] = useState('');
	const [errMessage, setErrMessage] = useState({
		file: '',
		release_note: ''
	});
	const [selectedFirmwareObj, setSelectedFirmwareObj] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showConfirmMandatoryModal, setShowConfirmMandatoryModal] = useState(false);
	const [showRolloutBlacklistModal, setShowRolloutBlacklistModal] = useState(false);
	const [blacklistCompaniesId, setBlacklistCompaniesId] = useState([])

	// console.log('STATE Firmware refs: ', refs)
	// console.log('STATE firmwares: ', firmwares)

	useEffect(() => {
		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
			case 'partner':
			case 'partner_view':
			case 'custom_partner':
				if (company.company_id) {
					getFleetDevices(company.company_id);
				}
				break;
			case 'fleet_maintainer':
			case 'fleet_manager':
			case 'storage_manager':
			case 'custom_user':
				if (company.company_id) {
					getFleetDevices();
				}
				break;
			default:
				break;
		}
	}, [company.company_id]);

	const getFleetDevices = (/*argCompanyName = null, argPartnerName = null,*/ argCompanyId = null) => {
		toggleLoader(true);
		const companyId = argCompanyId || company.company_id;
		// const companyName = argCompanyName || company.name || user.company_name;
		// const partnerName = argPartnerName || company.partner;
		const requestArray = []
		
		// requestArray.push(getAllDevices(userRole, companyName, partnerName, null, true));
		// requestArray.push(getDevicesList(companyId, 'latest_hdderror,data_usage,ignition,sd_status,channel_status,driver,firmware_version,firmware_status'));
		requestArray.push(getDevicesList(companyId, 'firmware_status'))
		requestArray.push(apiCallGet('/companies/firmware/blacklist'))

		if (!['partner', 'custom_partner', 'fleet_manager'].includes(userRole)) {
			requestArray.push(getAvailableFirmwareList(userRole))
		}
		Promise.all(requestArray)
			.then(
				(res) => {
					console.log('getFleetDevices PromiseAll res: ', res);
					const { devices } = res[0].data.response
					const blacklist = _.get(res[1], 'data.response', []).map(({company_id}) => company_id)

					const result = [];
					devices.map((item) => {
						if (!item.group_name) {
							item.group_name = 'Without group (total)';
						}

						if (!item.vehicle_name) {
							item.vehicle_name = item.device_id;
						}

						return item;
					})

					const groups = _.groupBy(devices, device => device.group_name);
					const keys = Object.keys(groups); // keys - groups name
					// keys = _.sortBy(keys, function(num){ return num.toLowerCase(); });

					for (let i = 0; i < keys.length; i++) {
						const k = keys[i];
						result.push({ id: i, group_name: k, expand: groups[k], userRole });
					}

					const all_devices_id = [];
					devices.forEach(device => all_devices_id.push(device.device_id));
					
					setDevices(devices);
					setGroups(result);
					setBlacklistCompaniesId(blacklist)

					// FWSD-7334, The /available_firmware API is available for ['partner', 'custom_partner', 'fleet_manager'] just if current company exists in Rollout Blacklist
					if (res[2]) {
						const newFirmwares = res[2].data.response.firmwares
						setFirmwares(newFirmwares.sort((a, b) => (a.id === 0) ? -1 : b.id - a.id))
					}
					else if (['partner', 'custom_partner', 'fleet_manager'].includes(userRole) && blacklist.includes(companyId)) {
						getAvailableFirmwareList(userRole)
							.then(res => {
								const newFirmwares = res.data.response.firmwares
								setFirmwares(newFirmwares.sort((a, b) => (a.id === 0) ? -1 : b.id - a.id))
							})
							.catch((errorAvFW) => {
								console.log('getAvailableFirmwareList error: ', errorAvFW)
								let errDescription = 'An unexpected error occurred. Please try again later'
								if (errorAvFW.response.data.response.error) {
									errDescription = errorAvFW.response.data.response.error
								}
								toastr.error(errDescription)
							})
					}

					toggleLoader(false);
				},
				(error) => {
					toggleLoader(false);
					console.log('getFleetDevices PromiseAll error: ', error.response, error)

					setFirmwares([]);
					setDevices([]);
					setGroups([]);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				},
			)
			.catch((error) => {
				toggleLoader(false);
				console.log('getFleetDevices PromiseAll catch error: ', error);

				setFirmwares([]);
				setDevices([]);
				setGroups([]);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	};

	const checkForm = (data, e) => {
		let error = false;

		if (!data.version) {
			error = true;
			toastr.error('You have to select a firmware');
		}

		if (!data.device_id) {
			error = true;
			toastr.error('No selected devices');
		}

		return error;
	}

	const applyFirmware = (e) => {
		e.preventDefault();
		const restart = data.restart || false;

		let selectedDevices = []
		groups.forEach((group) => {
			// if selected dv6 firmware then will update just dv6 devices,
			// else if selected dv4 firmware then will update just dv4 devices
			const groupDevices = group.expand.filter((item) => {
				if (data.selectedFirmware && data.selectedFirmware.includes('dv6') && isDv6(item)) {
					return item
				}
				if (data.selectedFirmware && !data.selectedFirmware.includes('dv6') && !isDv6(item)) {
					return item
				}
			});

			selectedDevices = [...selectedDevices, ...groupDevices]
		})

		const params = {
			version: data.selectedFirmware,
			device_id: selectedDevices.map(({ device_id }) => device_id).join(','),
			restart
		}

		const error = checkForm(params, e);
		if (!error) {
			toggleLoader(true);
			applyFirmwareAction(userRole, params)
				.then((res, err) => {
					console.log('!applyFirmware res: ', res.data.response);
					toggleLoader(false);
					if (res.status === 200) {
						getFleetDevices();
						toastr.success('Success', 'Firmware upgrade has been sent to selected devices.');
					}
					if (err) {
						console.log('!applyFirmware err', err);
					}
				})
				.catch((errorApply) => {
					toggleLoader(false);
					console.log('!applyFirmware error: ', errorApply.response, errorApply);
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				});
		}
	};

	const handleInputChange = (event) => {
		const { target } = event;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const { name } = target;
		setData({ ...data, [name]: value });
	};

	const onFileChange = (event) => {
		const { value } = event.target
		const file = event.target.files[0] || '';

		data.file = file

		setData(data);

		let i
		if (value.lastIndexOf('\\')) {
			i = value.lastIndexOf('\\') + 1;
		} else {
			i = value.lastIndexOf('/') + 1;
		}
		const filename = value.slice(i);
		uploadFileNameClear(filename);
	}

	const checkUploadFirmware = (data) => {
		let error = false;

		for (let i in data) {
			if ((data[i] == '' || data[i] == 'undefined') && refs[i]) {
				error = true
				errMessage[i] = 'Enter your ' + i.replaceAll('_', ' ');
			} else if (errMessage[i]) {
				errMessage[i] = null
			}
		}

		if (!errMessage.file && (!new Set(['fw', 'bin', 'mcu']).has(data.file.name.split('.').pop()))) {
			error = true
			errMessage.file = 'Select file with extension ".fw", ".bin" or ".mcu"'
		}

		setErrMessage(errMessage);
		return error;
	}

	const uploadFirmware = (e) => {
		e.preventDefault()
		const params = {
			file: data.file,
			release_note: data.release_note
		};
		const error = checkUploadFirmware(params, e)

		if (!error) {
			toggleLoader(true);
			pushFirmwareFile(userRole, params)
				.then((res, err) => {
					console.log('!downloadConfig uploadConfig res', res, err)
					toggleLoader(false);
					getFleetDevices();
					uploadFileNameClear();

					setData({ ...data, file:'' })
					toastr.success('Success', 'The firmware was successfully uploaded')
				})
				.catch((errorUpload) => {
					toggleLoader(false);
					console.log('!uploadConfig error: ', errorUpload.response, errorUpload)

					uploadFileNameClear()
					setData({ ...data, file:'' })

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (errorUpload.response.data.response.error) {
						errDescription = errorUpload.response.data.response.error
					}
					toastr.error(errDescription);
				})
		}
		else {
			toastr.error(errMessage.file || errMessage.release_note || 'An unexpected error occurred. Please try again later')
		}
	};

	const uploadFileNameClear = (filename) => {
		const upload_file_name = document.getElementById('upload-form-label');
		upload_file_name.innerHTML = filename || '';
	};

	const percentageFormatter = (cell) => {
		return cell ? cell.toFixed(2) : 0
	};

	const viewingFormatter = (cell, row) => {
		const { version, updated_devices, total_devices, total_updating } = row
		const percentage = total_devices ? (updated_devices / total_devices * 100) : 0
		if (version.includes('_v')) {
			return (
				<div>
					{/* <select
						placeholder="select"
						className="form-control"
						name={version}
						id="firmwareViewingOptions"
						style={{ height: '25px', padding: '2px 12px' }}
						value={cell}
						onChange={this.firmwareViewingOptions}
					>
						<option value="0">Test Firmware</option>
						<option value="1">Public Mandatory</option>
						<option value="2">Public Patch</option>
					</select> */}
					{isInternalUser(userRole) &&
						<CustomButton variant="primary" onClick={() => firmwareViewingOptions(version)}>Begin Rollout</CustomButton>
					}
					{total_updating !== 'no_updates' && (
						<div className="progress-wrapper">
							<ProgressBar now={percentage} variant={['active', 'stop'].includes(total_updating) ? 'info' : 'success'} />
							{['active', 'stop'].includes(total_updating)
								? <div className="progress-status">{`${updated_devices}/${total_devices} Vehicles Up to Date`}</div>
								: <div className="progress-status">Rollout Complete.<br /><div>{percentage}% of vehicles updated</div></div>
							}
						</div>
					)}
				</div>
			)
		}

		return ''
	};

	const deleteFormatter = (cell, row) => {
		const { total_updating } = row;

		// Does not allow to remove the No Firmware firmware
		if (row.id === 0) {
			return ''
		}

		return (
			<div className="actions-wrapper">
				<CustomButton variant="link" onClick={e => openDeleteModal(e, row)}>
					<i style={{ color: 'red' }} className="fas fa-times-circle" />
					Delete
				</CustomButton>
				{isInternalUser(userRole) && total_updating === 'active' && (
					<CustomButton variant="link" onClick={e => handleStopRolloutClick(e, row)}>
						<i style={{ color: 'red' }} className="far fa-stop-circle" />
						Stop Rollout
					</CustomButton>
				)}
				{isInternalUser(userRole) && total_updating === 'stop' && (
					<CustomButton variant="link" onClick={e => handleResumeRolloutClick(e, row)}>
						<i className="far fa-play-circle" />
						Resume Rollout
					</CustomButton>
				)}
			</div>
		)
	};

	const openDeleteModal = (e, row) => {
		e.preventDefault();
		setSelectedFirmwareObj(row);
		setShowDeleteModal(true);
	};

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
		setShowConfirmMandatoryModal(false);
		setSelectedFirmwareObj(null);
	};

	const deleteFirmwareModal = () => {
		toggleLoader(true);
		deleteFirmware(selectedFirmwareObj)
			.then((res) => {
				console.log('!deleteFirmwareModal res', res);
				toggleLoader(false);

				// if (selectedFirmwareObj.is_public === 1) {
				apiCall('POST', '/device/firmware/totalupdate/cancel?firmware={version}', { version: selectedFirmwareObj.version })
					.then((resCancel) => {
						console.log('!deleteFirmwareModal cancel res: ', resCancel)
					})
					.catch((error) => {
						console.log('!deleteFirmwareModal cancel error: ', error.response, error)
					})
				// }

				getFleetDevices();
				setSelectedFirmwareObj(null);
				setShowDeleteModal(false);

				toastr.success('', 'The firmware was successfully deleted');
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!deleteFirmwareModal error:', error);

				setSelectedFirmwareObj(null);
				setShowDeleteModal(false);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});
	};

	const handleStopRolloutClick = (e, row) => {
		e.preventDefault()
		const { version } = row

		apiCall('POST', '/device/firmware/totalupdate/stop?firmware={version}', { version })
			.then((res) => {
				console.log('!handleStopRolloutClick res: ', res)
				const { total_devices, updated_devices } = res.data.response
				const arr = firmwares.map((item) => {
					if (item.version === version) {
						item.total_updating = 'stop'
						item.total_devices = total_devices
						item.updated_devices = updated_devices
					}
					return item
				})
				setFirmwares(arr);
				toastr.success('', 'The firmware update was stopped successfully');
			})
			.catch((error) => {
				console.log('!handleStopRolloutClick error: ', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const handleResumeRolloutClick = (e, row) => {
		e.preventDefault()
		const { version } = row;

		apiCall('POST', '/device/firmware/totalupdate/resume?firmware={version}', { version })
			.then((res) => {
				console.log('!handleResumeRolloutClick res: ', res)
				const { total_devices, updated_devices } = res.data.response
				const arr = firmwares.map((item) => {
					if (item.version === version) {
						item.total_updating = 'active'
						item.total_devices = total_devices
						item.updated_devices = updated_devices
					}
					return item
				})
				setFirmwares(arr);
				toastr.success('', 'The firmware update continues successfully');
			})
			.catch((error) => {
				console.log('!handleResumeRolloutClick error: ', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const sendNotificationToUsers = () => {
		toggleLoader(true);
		sendNotification()
			.then((res) => {
				console.log('!sendNotificationToUsers res', res);
				toggleLoader(false);

				toastr.success('', 'The notification was sent successfully');
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!sendNotificationToUsers error', error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});
	}

	const expandComponent = (row) => (
		<FleetAdminFirmwareBSTable
			data={row}
			blacklistCompaniesId={blacklistCompaniesId}
			firmwares={firmwares}
		/>
	);

	const firmwareViewingOptions = (name) => {
		setSelectedFirmwareObj({ ...selectedFirmwareObj, version: name });
		setShowConfirmMandatoryModal(true);
	};

	const confirmPublicMandatory = () => {
		const { version } = selectedFirmwareObj;

		toggleLoader(true);
		changeFirmwareViewingOptions(version, '1')
			.then((res, err) => {
				console.log('!confirmPublicMandatory res: ', res, err);
				toggleLoader(false);

				// apiCall('POST', '/device/firmware/totalupdate/start?firmware={version}&company_id={companyId}', { version, companyId: company.company_id })
				apiCall('POST', '/device/firmware/totalupdate/start?firmware={version}', { version })
					.then((resStart, errStart) => {
						console.log('!confirmPublicMandatory start res: ', resStart)
						const { total_devices } = resStart.data.response
						const arr = firmwares.map((item) => {
							if (item.version === version) {
								// item.is_public = 1
								item.total_updating = 'active'
								item.total_devices = total_devices
								item.updated_devices = 0
							}
							return item
						})
						setFirmwares(arr);
					})
					.catch((error) => {
						console.log('!confirmPublicMandatory start error: ', error.response, error)
					})

				toastr.success('', 'Firmware view settings changed successfully');
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!confirmPublicMandatory error: ', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			});

		closeDeleteModal();
	};

	const dataSearch = (e) => {
		const result = [];
		const allDevicesId = [];
		const value = e.target.value.toLowerCase();

		const devicesFiltered = devices.filter((device) => {
			return (
				(device.vehicle_name && device.vehicle_name.toLowerCase().includes(value))
				|| (device.device_id && device.device_id.toString().toLowerCase().includes(value))
			)
		});

		const availableGroups = _.groupBy(devicesFiltered, device => device.group_name);
		const keys = Object.keys(availableGroups); // keys - groups name

		for (let i = 0; i < keys.length; i += 1) {
			const k = keys[i];
			result.push({ id: i, group_name: k, expand: availableGroups[k], userRole: user.roles[0] });
		}

		devicesFiltered.forEach(device => allDevicesId.push(device.device_id));

		setGroups(result);
		setSearchText(value);
	};

	const { file, release_note } = errMessage;
	const readOnly = (['sales_demo', 'partner', 'partner_view', 'custom_partner', 'fleet_manager'].includes(userRole))

	const firmwareList = () => {
		const option = [];
		if (firmwares) {
			firmwares.map((elem, index) => {
				if (elem.id !== 0) { // hides the No Firmware firmware version
					option[index] = (<option key={elem.id} value={elem.version}>{elem.version}</option>)
				}
			});
			return (
				<select placeholder="select" className="form-control" name="selectedFirmware" id="selectedFirmware" style={{ width: '250px', appearance: 'auto' }} onChange={handleInputChange}>
					<option />
					{option}
				</select>
			);
		}
	};

	const formatGroupName = (cell, row) => {
		const size = row.expand.length;
		let amount = 'vehicles';

		if (size === 1) {
			amount = 'vehicle';
		}

		return `${row.group_name} (${size} ${amount})`;
	};

	return (
		<div className="firmware-page">
			<GreyHeader title="Firmware" />
			<main className="main-content-block">
				<div className="fleet-admin-firmware">
					<div className="col-xs-12 main-content-background-border">
						<div className="firmware-container-mobile manage-users-table manage-firmware-page-table" style={{ clear: 'both' }}>
							{
								devices.length < 1
									? (
										<div className="col-xs-12">
											<h5>No vehicles in this Company</h5>
											{
												(['system_admin', 'customer_service'].indexOf(userRole) > -1)
												&& <p><Link to="/managevehicles">Please add vehicle to this group</Link></p>
											}
										</div>
									)
									: (
										<div className="firmware-wrapper">
											{/* The "Search vehicles" block */}
											<div className="col-md-6 col-xs-12 firmware-mobile-wrapper" style={{ marginBottom: '15px', padding: "0 25px" }}>
												<SearchBar
													term={searchText}
													onSearch={dataSearch}
													classSize="col-md-10"
													placeholder="Search vehicles..."
												/>
											</div>

											{/* The "Firmware Update for all devices in all groups" block */}
											{!readOnly
												&& (
													<div className="col-md-6 col-xs-12 firmware-mobile-wrapper" style={{ display: "flex", flexDirection: "column", padding: "0 25px" }}>
														<div className="firmware-inner col-xs-12"><h5>Firmware Update for all devices in all groups</h5></div>
														<div className="firmware-inner col-xs-12">
															<div className="fields-container" style={{ float: 'left', marginRight: '20px', marginTop: '4px' }}>{firmwareList()}</div>
															<div className="fields-container" style={{ float: 'left', marginTop: '4px' }}>
																<CustomButton variant="primary" onClick={applyFirmware}>Upgrade</CustomButton>
															</div>
														</div>
														<div className="firmware-inner col-xs-12" style={{ paddingTop: '15px' }}>
															<p><strong>The firmware will be updated on the device after rebooting.</strong></p>
															<p><strong>Reboot after uploading firmware?</strong></p>
															<label>
																<span style={{ paddingRight: '10px' }}>Don't reboot</span>
																<Toggle
																	name="restart"
																	onChange={e => handleInputChange(e)}
																/>
																<span style={{ paddingRight: '15px' }}>Reboot immediately</span>
															</label>
														</div>
													</div>
												)
											}
										</div>
									)
							}
							<div style={{ clear: 'both', margin: '10px 0 20px 0px' }}>
								<div className="row mobile-border" style={{ borderTop: '15px solid #F0F0F0' }} />
							</div>
							{/* Table of vehicles with installed firmwares */}
							<BootstrapTable
								data={groups}
								{...expandTableOptions}
								expandComponent={expandComponent}
							>
								<TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
								<TableHeaderColumn dataField="group_name" width="100%" dataFormat={formatGroupName}>Group Name</TableHeaderColumn>
							</BootstrapTable>


							{userRole === 'system_admin'
								&& (
									<div className="firmware-admin-table-wrapper">
										<div className="row mobile-border" style={{ borderTop: '15px solid #F0F0F0', margin: '20px -30px 5px' }} />
										<div className="firmware-portal-container">
											<h5>Firmware Portal</h5>
											<div className="firmware-upload-container">
												<div>
													<div className={classnames('upload-file-form', { 'has-error': file })}>
														<div id="upload-form-label" />
														<div className="selectbutton">Select a file</div>
														<input type="file" ref={file_ref} name="file" id="upload-input" onChange={onFileChange} />
														{file && <span className="help-block">{file}</span>}
													</div>
													<CustomButton variant="secondary" onClick={uploadFirmware}>Upload</CustomButton>
												</div>
												<CustomButton variant="primary-outline" onClick={() => setShowRolloutBlacklistModal(true)}>Rollout Blacklist </CustomButton>
											</div>
										</div>
										<div className="firmware-note-container">
											<label htmlFor="release_note" className="control-label">Release note:</label>
											<div className={classnames('form-group', { 'has-error': errMessage.release_note })}>
												<textarea name="release_note" ref={release_note_ref} className="release-note" onChange={handleInputChange} />
												{errMessage.release_note &&
													<span className="help-block">{errMessage.release_note}</span>
												}
											</div>
										</div>

										<BootstrapTable
											data={firmwares}
											{...defaultTableOptions}
										>
											<TableHeaderColumn dataField="id" isKey hidden>ID</TableHeaderColumn>
											<TableHeaderColumn width="15%" dataField="version">Firmware Version</TableHeaderColumn>
											<TableHeaderColumn width="35%" dataField="release_note" tdStyle={{ whiteSpace: 'normal' }}>Release note</TableHeaderColumn>
											<TableHeaderColumn width="10%" dataField="devices_percentage" dataFormat={percentageFormatter} dataSort>% Devices</TableHeaderColumn>
											<TableHeaderColumn width="20%" dataField="is_public" dataFormat={viewingFormatter}>Rollout Firmware</TableHeaderColumn>
											<TableHeaderColumn width="20%" dataField="url" dataFormat={deleteFormatter}>Actions</TableHeaderColumn>
										</BootstrapTable>

										<div className="row mobile-border" style={{ borderTop: '15px solid #F0F0F0', margin: '20px -30px 5px' }} />

										<div className="firmware-send-container">
											<h5>Send notification to users about a new firmware:</h5>

											<CustomButton variant="primary" onClick={sendNotificationToUsers}>Send Notification</CustomButton>
										</div>
									</div>
								)
							}
						</div>
					</div>
				</div>

				{showDeleteModal
					&& (
						<ModalDelete
							content={`Are you sure you want to delete "${selectedFirmwareObj.version}" ?`}
							closeModal={closeDeleteModal}
							deleteModal={deleteFirmwareModal}
						/>
					)
				}

				{showConfirmMandatoryModal
					&& (
						<ModalDelete
							title="Caution"
							content="Selecting Public Mandatory will cause this firmware to be pushed to every vehicle in Production. Are you sure you wish to proceed?"
							deleteBtnName="Update Firmware Status and Push"
							closeModal={closeDeleteModal}
							deleteModal={confirmPublicMandatory}
						/>
					)
				}

				{showRolloutBlacklistModal
					&& (
						<RolloutBlacklistModal
							onHide={() => setShowRolloutBlacklistModal(false)}
							userRole={userRole}
							blacklistCompaniesId={blacklistCompaniesId}
							loadFirmwareBlacklist={getFleetDevices}
						/>
					)
				}
			</main>
		</div>
	)
}

Firmware.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
};

const mapStateToProps = ({ user, company }) => ({
	user: user.user,
	company: company.company,
});

const mapDispatchToProps = (dispatch) => {
	return {
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Firmware);
