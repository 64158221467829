const constants = {
	alertcheckboxes: [
		{ id: 'hdderror', title: 'SD Error' },
		{ id: 'uncleanshutdown', title: 'Improper shutdown' },
		{ id: 'videoloss', title: 'Camera failure' },
	],
	eventcheckboxes: [
		{ id: 'fatalgsensor', title: 'High G-Force Event' },
		{ id: '%_clip,%_alert,%_event', title: 'Custom Video' },
		{ id: 'highspeed', title: 'Max Speed Event' },
		{ id: 'inputsensor5,inputsensor9', title: 'Driver Event' },
		{ id: 'inputsensor', title: 'Input Sensor Event' },
		{ id: 'harshbraking', title: 'Harsh Braking Event'},
		{ id: 'harshaccel', title: 'Harsh Acceleration Event'},
	],
	dmscheckboxes: [
		{ id: 'Drowsiness', title: 'Drowsiness/Head Down' },
		{ id: 'Phoneuse', title: 'Phone Use/Hand to Head' },
		{ id: 'Yawning', title: 'Yawning' },
		{ id: 'Distraction', title: 'Distraction' },
		{ id: 'Seatbelt', title: 'Seatbelt' },
		{ id: 'Smoking', title: 'Smoking' },
	],
	adascheckboxes: [
		{ id: 'headwaywarning', title: 'Headway Warning' },
		{ id: 'forwardcollisionwarning', title: 'Forward Collision' },
		{ id: 'pedestrianwarning', title: 'Pedestrian Warning' },
		{ id: 'lanedeparture', title: 'Lane Departure' },
		{ id: 'followingdistancewarning', title: 'Tailgating' },
	],
	eventcheckboxestitle: [
		{ id: 'fatalgsensor', title: 'High G-Force Event' },
		{ id: 'highspeed', title: 'Max Speed Event' },
		{ id: 'inputsensor5,inputsensor9', title: 'Driver Event' },
		{ id: 'inputsensor', title: 'Input Sensor Event' },
		{ id: 'pedestrianwarning', title: 'Pedestrian Warning' },
		{ id: 'headwaywarning', title: 'Headway Warning' },
		{ id: 'forwardcollisionwarning', title: 'Forward Collision' },
		{ id: 'lanedeparture', title: 'Lane Departure' },
		{ id: 'followingdistancewarning', title: 'Tailgating' },
		{ id: 'drowsiness', title: 'Drowsiness/Head Down' },
		{ id: 'distraction', title: 'Distraction' },
		{ id: 'phone_use', title: 'Phone Use/Hand to Head' },
		{ id: 'yawning', title: 'Yawning' },
		{ id: 'seatbelt', title: 'Seatbelt' },
		{ id: 'smoking', title: 'Smoking' },
	],
}

export default constants
