const initialState = {
	partner_list: [],
	company_list: [],
	company_collection: [],
	fetching: false,
};

export default function partnerList(state = initialState, action) {
	switch (action.type) {
	case 'GET_COMPANY_LIST_SUCCESS':
		return { ...state, ...{ company_list: action.payload, fetching: false } };
	case 'GET_COMPANY_COLLECTION_SUCCESS':
		return { ...state, ...{ company_collection: action.payload, fetching: false } };
	case 'EDIT_COMPANY_COLLECTION': {
		return {
			...state,
			company_collection: action.payload ? state.company_collection.map((item) => {
				if (item.id === action.payload.id) {
					return { ...item, ...action.payload }
				}
				return item;
			}) : state.company_collection };
	}
	case 'GET_PARTNER_LIST_SUCCESS':
		return { ...state, ...{ partner_list: action.payload, fetching: false } };
	case 'ADD_PARTNER_SUCCESS':
		return { ...state, partner_list: [...state.partner_list, action.payload] }
	case 'DEL_PARTNER_SUCCESS':
		return {
			...state,
			partner_list: [
				...state.partner_list.filter(item => item !== action.payload),
			],
		}
	case 'ADD_COMPANY_SUCCESS':
		return { ...state, company_list: [...state.company_list, action.payload.company] }
	case 'DEL_COMPANY_SUCCESS':
		return (
			{
				...state,
				company_list: [
					...state.company_list.filter(item => item !== action.payload.company),
				],
			}
		)
	default:
		return state
	}
}
