import React from 'react'
import Toggle from 'react-toggle'
import PropTypes from 'prop-types'

const ToggleCellNode = (props) => {
	const { name, checked, perm_category_id, onChange, disabled, readOnly, isEmpty } = props

	return (
		<td className="col-md-2">
			{!isEmpty && (
				<Toggle
					name={name}
					checked={checked}
					onChange={(e) => {onChange(e, perm_category_id)}}
					disabled={disabled}
					readOnly={readOnly}
					// value={true}
				/>
			)}
		</td>
	)
}

ToggleCellNode.propTypes = {
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	perm_category_id: PropTypes.number.isRequired,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	isEmpty: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
}

export default ToggleCellNode;
