import React from 'react';

const SearchSelect = ({
  placeholder,
  value,
  onChangeCb,
  optionsList,
  children
}) => {

  const renderOptions = () => {
    return optionsList.map((opt) => <option key={opt.value} value={opt.value}>{opt.name}</option>);
  }

  return (
    <div className="event-search">
      <select
        placeholder={placeholder}
        className="event-search-select"
        name="search-category"
        id="search-category"
        value={value}
        onChange={onChangeCb}
      >
        { renderOptions() }
      </select>
      { children }
    </div>
  )
};

export default SearchSelect;
