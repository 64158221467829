import React from "react"
import GreyHeader from '../../GreyHeader'

const LegalNotice = (props) => {
    return(
    <div>
        <GreyHeader title='Legal Notice' hideSubtitle />
        
        <main className="main-content-block">
            <p>www.roscomirrors.com<br/>Copyright 2004 Rosco, Inc.<br/>All Rights Reserved.<br/><br/>The following legal notices apply to the use of this Web site.<br/></p>
            <h2>Privacy Statement</h2>
            <p>Rosco, Inc., along with its affiliates and business partners, may collect certain personal information about you either automatically, such as information used to generate WebTrends-type reports, or via Web-based forms which you may choose to complete during your visit. Such information may be stored for the use of Rosco, Inc., and its affiliates and business partners, and contractors, to help it improve its Web site, provide you with certain goods and services, or respond to your inquiries.<br/><br/>Rosco, Inc., will not sell or provide any of the information about you that we have collected or stored to other companies, marketers, magazines or any other third parties who are not our contractors or affiliates. We will provide such information to governmental authorities to the extent that it is requested.</p>
            <h2>Copyrights</h2>
            <p>Copyright 2004, Rosco, Inc., All Rights Reserved. The text, images, graphics, sound files, animation files, video files and their arrangement on this Web site, and the programming code, are subject to copyright and other intellectual property protection and may not be copied, distributed or otherwise used without the express written consent of the owner.</p>
            <h2>Statement Regarding Warranties</h2>
            <p>The information on this Web site is provided "as is" without warranty of any kind, either express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement. In no event will Rosco, Inc., its affiliates, contractors, or business partners, be held liable to any party for any direct or indirect, incidental, punitive, special or other consequential damages for any use of this Web site, or any other hyperlinked Web site or data files, including without limitation, any lost profits, business interruption, loss of data or otherwise, even if the company is expressly advised of the possibility of such damages.<br/><br/>Rosco, Inc., has not reviewed all of the sites linked to this Web site, and makes no warranties or representations whatsoever about any other site which you may access through this one. Such hyperlinks are provided only as a convenience and providing these links does not mean that the company endorses or accepts any responsibility for the contents or the use of such web site. You access such other sites at your own risk.<br/><br/>While we seek to provide accurate and up-to-date information on our site, the information may contain factual or typographical errors, and the company makes no warranties or representations as to the accuracy of such information. The information contained on our site may be changed at any time without prior notification or obligation.</p>
        </main>
    </div>
    )
}

export default LegalNotice
