import store from '../../store/store'
import { isInternalUser } from './constants'

export const hasSleepStatusesAccess = () => {	
	const env = location.hostname
	const userRole = store.getState().user?.user?.roles[0]
	const companyId = store.getState().company?.company?.company_id
	
	// Hide Sleep Mode statuses from all companies and users 
	// EXCEPT for PROD company “TransAction Corporate Shuttles, Inc”, STAGING company "Rosco-Geotab1" and Internal users
	if (isInternalUser(userRole) 
		|| (env === 'fleet.roscolive.com' && companyId === 253)
		|| (env === 'fleet.roscolive.com' && companyId === 253)
		|| (env !== 'fleet.roscolive.com' && companyId === 825)
		|| (env !== 'fleet.roscolive.com' && companyId === 825)
	) {
		return true
	}

	return false
}

export const getAvailableFleetStatuses = (argStatuses, argType) => {
	const env = location.hostname
	const availableStatuses = ['driving', 'parked', 'offline', 'disabled']	
	const userRole = store.getState().user?.user?.roles[0]
	const companyId = store.getState().company?.company?.company_id
	// console.log('argStatuses, argType, env, userRole, companyId: ', argStatuses, argType, env, userRole, companyId)

	// Hide Sleep Mode statuses from all companies and users 
	// EXCEPT for PROD company “TransAction Corporate Shuttles, Inc”, STAGING company "Rosco-Geotab1" and Internal users
	if (isInternalUser(userRole) 
		|| (env === 'fleet.roscolive.com' && companyId === 253)
		|| (env === 'fleet.roscolive.com' && companyId === 253)
		|| (env !== 'fleet.roscolive.com' && companyId === 825)
		|| (env !== 'fleet.roscolive.com' && companyId === 825)
	) {
		return argStatuses
	}

	/* Returns availble statuses for the Fleet Activity Status of At a Glance  */
	if (argType === 'glance') {
		const newFilterStatuses = Object.keys(argStatuses).reduce((result, key) => {
			if (availableStatuses.includes(key)) {
				result[key] = argStatuses[key]
			}
			return result
		}, {})

		return newFilterStatuses
	}
	/* Returns availble statuses for Dashboard filters */
	if (argType === 'filters') {
		const newFilterStatuses = Object.keys(argStatuses.statuses).reduce((result, key) => {
			if (availableStatuses.includes(key)) {
				result[key] = argStatuses.statuses[key]
			}
			return result
		}, {})

		return {...argStatuses, statuses: newFilterStatuses}
	}
	/* Returns availble statuses for Dashboard FLEET ACTIVITY STATUS */
	return argStatuses.filter((item) => availableStatuses.includes(item))
}

export default hasSleepStatusesAccess
