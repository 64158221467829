import moment from 'moment'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import DateTime from 'react-datetime'

import CustomButton from '../../../CustomButton'
import { formatDate } from '../../../helpers/tablesFuncHelpers'

const SelectReportDateModal = (props) => {
	const { currentDate, onHide, allowFutureDates, singleDate } = props;

	const [start_date, setStart_date] = useState(currentDate.start_date)
	const [end_date, setEnd_date] = useState(currentDate.end_date)

	// console.log('STATE SelectReportDateModal start_date:', start_date, moment(start_date).format('YYYYMMDDHHmmss'))
	// console.log('STATE SelectReportDateModal end_date:', end_date, moment(end_date).format('YYYYMMDDHHmmss'))

	const changeDate = () => {
		props.changeDate(0, start_date, end_date)
		onHide()
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="reports-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>Change Date Range</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='text-center'>
					<span className='date-label'>Start Date:</span>
					<DateTime
						timeFormat={false}
						closeOnSelect
						inputProps={{ readOnly: true }}
						value={formatDate(start_date)}
						isValidDate={date => {
							return (!singleDate ? moment(date).isBefore(moment(end_date).add(1, 'days')) : moment(date).isBefore(moment()))
						}}
						onChange={date => {
							if (!singleDate) {
								setStart_date(date);
							} else {
								setStart_date(date);
								setEnd_date(date)
							}
						}}
					/>
				</div>
				{!singleDate &&
					<div className='text-center'>
						<span className='date-label'>End Date:</span>
						<DateTime
							timeFormat={false}
							closeOnSelect
							value={formatDate(end_date)}
							inputProps={{ readOnly: true }}
							isValidDate={date => {
								return (allowFutureDates || (moment(date).isBefore(moment()))
									&& moment(date).isAfter(moment(start_date).subtract(1, 'days')))
							}}
							onChange={date => setEnd_date(date)}
						/>
					</div>
				}
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={changeDate}>Update</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

export default SelectReportDateModal;
