import React from 'react';
import { connect } from 'react-redux';
import './Footer.sass'

//global
var year = 2024

const Footer = ({ version }) => (
	<footer>
		{`RoscoLive v${version} ${year} \u00A9 Rosco`}
	</footer>
)

export default connect(
	state => ({
		version: state.user.version,
	}),
)(Footer)
