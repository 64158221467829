import React from 'react';
import Autocomplete from 'react-autocomplete';

const AutocompleteComponent = (props) => {
	const { placeholder, clear, label, partner, company } = props
	const matchStateToTerm = (item, value) => {
		return item.toLowerCase().indexOf(value.toLowerCase()) !== -1
	}

	return (
		<div className={partner ? 'partner-select' : (company ? 'company-select' : 'select-company')}>
			{ label && <label className="control-label">{label}</label>}
			<Autocomplete
				autoHighlight
				value={props.value}
				wrapperProps={{ className: 'search-autocomplete-header' }}
				getItemValue={item => item}
				menuStyle={{
					borderRadius: '3px',
					boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
					background: 'rgba(255, 255, 255, 1)',
					padding: '2px 0',
					fontSize: '90%',
					position: 'absolute',
					maxHeight: '320px',
					zIndex: '999999',
					top: '34px',
					left: '0px',
					width: '100%',
					overflowX: 'hidden',
					overflowY: 'auto',
				}}
				onChange={props.onChange}
				onSelect={props.onSelect}
				renderMenu={function(items, value, style) {
					return (
						<div className="items-list" style={{ ...style, ...this.menuStyle }}
							children={
								items.length ?
								items :
								<div className='item has-error'>
									No Companies
								</div>
							}
						/>
					)
				}}
				renderItem={(item, isHighlighted) => (
					<div key={item} className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
						{item}
					</div>
				)}
				renderInput={(props) => {
					return (
						<div>
							{clear && <i className="fa fa-times" aria-hidden="true" onClick={clear} /> }
							<input {...props} placeholder={placeholder} className="form-control" onFocus={(event) => event.target.select()}/>
						</div>
					)
				}}
				shouldItemRender={matchStateToTerm}
				items={props.items}
				wrapperProps={{ className: 'search-autocomplete-header' }}
			/>
		</div>
	);
}

export default AutocompleteComponent;
