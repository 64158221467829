import React, { useState, useEffect } from 'react';
import { Link, browserHistory } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';

import { getDeviceById } from '../../../action/DeviceActions';

const PatternsDeviceContainer = (props) => {
	const { toggleLoader } = props;

	const [error, setError] = useState('');

	useEffect(() => {
		getDevice();
	}, []);

	const getDevice = () => {
		console.log('PROPS PatternsDeviceContainer: ', props)
		const { params, user, updateCompany, setDashboardRedirect } = props
		const deviceId = params.device_id
		const patternType = params.type
		const userRole = user.roles[0]
		
		getDeviceById({ deviceId, userRole })
			.then((res, err) => {
				toggleLoader(true);
				console.log('!getDevice res: ', res, err)
				
				const { device } = res.data.response
				
				// updateCompany({
				// 	name: device.company_name,
				// 	partner: device.partner_name,
				// 	company_id: device.company_id,
				// });
				updateCompany({
					"advanced_profile_settings": false,
					"company_data": {
						"data_limit": 3,
						"live_active": true,
						"override_overage_limits": false,
						"storage_active": true,
						"trace_active": true,
						"trial_status": false,
						"using_rosco_sim": true
					},
					"driver_recognition": "cardreader",
					"id": 1526,
					"name": "Muffin Shop",
					"token": "71825424",
					"type": "CUSTOMER"
				})

				if (patternType === 'unknowndriver') {
					browserHistory.push({
						pathname: '/',
						state: {
							unknownDriver: {
								device_id: params.device_id,
								timestamp: moment(params.timestamp, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'),
							},
							type: 'redirectFromUnknownDrivers',
						},
					})
				}
				if (patternType === 'livestream') {
					setDashboardRedirect({
						type: 'redirectFromBellNotificationsOpenLS',
						device_id: deviceId,
					})

					browserHistory.push({
						pathname: '/',
						state: {
							device_id: params.device_id,
							type: 'redirectFromBellNotificationsOpenLS',
						},
					})
				}
			})
			.catch((error) => {
				console.log('!getDevice error: ', error.response, error)

				let _error = JSON.stringify(error);
				if (error.response && error.response.data.response.error) {
					_error = error.response.data.response.error
				}
				setError(_error);
			})
	}

	return (
		<article>
			{error
				&& (
					<div>
						<h3>Error 404!</h3>
						<div>{error}</div>
						<div>
							Go back to
							{' '}
							<Link to="/" className="btn">Home</Link>
						</div>
					</div>
				)}
		</article>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		partnerList: state.partnerList
	}),
	dispatch => ({
		updateCompany: (company) => {
			dispatch({ type: 'GET_COMPANY_SUCCESS', payload: company });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		},
		setDashboardRedirect: (state) => {
			dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state });
		},
	})
)(PatternsDeviceContainer);
