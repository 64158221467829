import React, { useState, useEffect } from 'react'
import { Button, Modal, Tab, Tabs } from 'react-bootstrap'
import { CheckboxGroup, Checkbox } from 'react-checkbox-group'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import moment from 'moment'
import DateTime from 'react-datetime'
import Toggle from 'react-toggle'
import FileSaver from 'file-saver'
import _, { set } from 'lodash'

import LiveRecordingLogs from './LiveRecordingLogs'

import {
	getConfigFile,
	pushConfigFile,
	configurationRequestLog,
	getConfigurationLogs,
	getLiveStatusLogs,
} from '../../../action/FleetAdminActions';
import { apiCall } from '../../../action/RouterActions'

import '../../dump/menuComponents/menuComponents.sass'
import { formatTimestamp } from '../../helpers/tablesFuncHelpers'
import { isDv6 } from '../../helpers/constants'
import CustomButton from '../../CustomButton';

const ConfigModal = (props) => {
	const { device, user_role, loaderModal, toggleLoaderModal, clearSelection } = props
	const device_id = device.device_id;

	const [interval, setIntervalState] = useState(null);

	const [events, setEvents] = useState([]);
	const [file, setFile] = useState('');
	const [fileDM100, setFileDM100] = useState('');
	const [restart, setRestart] = useState(false);
	const [restartDM100, setRestartDM100] = useState(false);
	const [start_date, setStart_date] = useState(moment().format('YYYYMMDD'));
	const [end_date, setEnd_date] = useState(moment().format('YYYYMMDD'));
	const [showPendingLogs, setShowPendingLogs] = useState(false);
	const [showRequestLogs, setShowRequestLogs] = useState(false);
	const [showWaitingLogs, setShowWaitingLogs] = useState(false);
	const [noLogs, setNoLogs] = useState(false);
	const [data_logs, setData_logs] = useState({
		start_date: null,
		end_date: null,
		file_url: ''
	});
	const [liveLogs, setLiveLogs] = useState([]);
	const [liveLogsDate, setLiveLogsDate] = useState(moment().format('YYYYMMDD'));
	const [selectedLogTypes, setSelectedLogTypes] = useState(['audit', 'version']);
	const [selectedFirmware, setSelectedFirmware] = useState('');
	const [tabKey, setTabKey] = useState(1);

	useEffect(() => {
		return () => {
			if (interval) {
				clearInterval(interval);
				setIntervalState(null);
			}
		}
	}, [interval]);

	const onEnterModal = () => {
		setFile('');

		setSelectedFirmware('');
		setFile('');
		setFileDM100('');
		refreshLogsData();
		getStatusLogs();
	};

	const getStatusLogs = (date = null) => {
		toggleLoaderModal(true);
		const start_ts = moment.utc((date || moment()).startOf('day')).format('YYYYMMDDHHmmss')
		const end_ts = moment.utc((date || moment()).endOf('day')).format('YYYYMMDDHHmmss')
		getLiveStatusLogs(device_id, start_ts, end_ts)
			.then(res => {
				toggleLoaderModal(false)
				setLiveLogs(res.data.response.logs)
			})
			.catch(err => {
				toggleLoaderModal(false)
				console.log(err);
				toastr.error('Unable to get live status logs. Please try again later');
			})
	}

	const getNextLogs = () => {
		const selectedDate = moment(liveLogsDate).add(1, 'days')
		if (selectedDate.isAfter(moment())) {
			toastr.info('No future logs available')
		}
		else {
			setLiveLogsDate(selectedDate.format('YYYYMMDD'))
			getStatusLogs(selectedDate)
		}
	}

	const getPrevLogs = () => {
		const selectedDate = moment(liveLogsDate).subtract(1, 'days')
		setLiveLogsDate(selectedDate.format('YYYYMMDD'))
		getStatusLogs(selectedDate)
	}

	const changeLiveDate = (selectedDate) => {
		if (selectedDate.isAfter(moment())) {
			toastr.info('No future logs available')
		}
		else {
			setLiveLogsDate(selectedDate.format('YYYYMMDD'))
			getStatusLogs(selectedDate)
		}
	}

	const onHideModal = () => {
		if (interval) {
			clearInterval(interval);
			setIntervalState(null);
		}

		props.onHide();
	};

	const handleInputChange = (event) => {
		const { target } = event
		const { name } = target
		const value = target.type === 'checkbox' ? target.checked : target.value
		if (name === "restart") setRestart(value);
		if (name === "restartDM100") setRestartDM100(value);
	};

	const downloadConfig = () => {
		toggleLoaderModal(true);
		getConfigFile(user_role, device_id)
			.then((res, err) => {
				toggleLoaderModal(false)
				let file = res.data;
				let blob = new Blob([file], { type: 'text/plain;charset=utf-8' });
				FileSaver.saveAs(blob, 'config.txt');
			})
			.catch((error) => {
				toggleLoaderModal(false)
				console.log(error);
				toastr.error('Unable to download config file. Please try again later');
			});
	};

	const uploadConfig = (e) => {
		e.preventDefault()
		const data = {
			events,
			file,
			fileDM100,
			restart,
			restartDM100,
			start_date,
			end_date,
			showPendingLogs,
			showRequestLogs,
			showWaitingLogs,
			noLogs,
			data_logs,
			liveLogs,
			liveLogsDate,
			selectedLogTypes,
			tabKey,
		}
		const error = checkUploadConfig(data, e)

		if (!error) {
			toggleLoaderModal(true);
			pushConfigFile({
				userRole: user_role,
				deviceId: device_id,
				...data,
			})
				.then(res => {
					console.log('!uploadConfig pushConfigFile res', res);
					clearSelection()
					toggleLoaderModal(false)
					toastr.success('Successfully pushed config file.');
				})
				.catch(error => {
					toggleLoaderModal(false)
					console.log(error);
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	const uploadConfigDM100 = (e) => {
		e.preventDefault()
		const error = checkUploadConfig({ file: fileDM100 }, e, 'confDM100')

		if (!error) {
			toggleLoaderModal(true);
			const params = {
				device_id: device.device_id,
				restart: restartDM100,
				file: fileDM100,
			}
			apiCall('PUT', '/devices/{device_id}/config/binfile', params)
				.then((res, err) => {
					console.log('!uploadConfigDM100 res:', res, err)
					toggleLoaderModal(false)
					toastr.success('Success', res.data.response);
				})
				.catch((err) => {
					toggleLoaderModal(false)
					console.log('!uploadConfigDM100 error: ', err.response, err)

					if (err.response && (err.response.data.body || err.response.data.response.error)) {
						toastr.error(err.response.data.body || err.response.data.response.error)
					} else {
						toastr.error('An unexpected error occurred. Please try again later')
					}
				})
		}
	}

	const checkUploadConfig = (data, e, type = 'conf') => {
		let error = false;
		if (!data.file) {
			error = true;
			toastr.error('You have to select a config file');
		}

		const filename = type === 'conf' ? 'config.txt' : 'vehicleprofile.dat'
		if (data.file) {
			if (data.file.name !== filename) {
				error = true
				toastr.error(`Select file with name ${filename}`)
			}
			else if (data.file.size < 100) {
				error = true
				toastr.error('Selected file is empty')
			}
			else if (data.file.size < 4000) {
				error = true
				toastr.error('Selected file size is too small')
			}
		}

		return error;
	}

	const onFileChange = (event) => {
		const { name, value } = event.target
		const file = event.target.files[0]

		let uploadId = 'upload-form-label'
		if (name === 'uploadDM100') {
			uploadId = 'upload-form-label-dm100'
			setFileDM100(file);
		} else {
			setFile(file);
		}

		let i
		if (value.lastIndexOf('\\')) {
			i = value.lastIndexOf('\\') + 1;
		} else {
			i = value.lastIndexOf('/') + 1;
		}
		const filename = value.slice(i);
		const uploaded = document.getElementById(uploadId);
		uploaded.innerHTML = filename;
	};

	const onDateChanged1 = (current) => {
		setStart_date(current.format('YYYYMMDD'));
	};

	const onDateChanged2 = (current) => {
		setEnd_date(current.format('YYYYMMDD'));
	};

	const downloadLogs = () => {
		if (data_logs.file_url && data_logs.file_url.length) {
			window.location.href = data_logs.file_url;
		}
	}

	const refreshLogsData = () => {
		if (interval) {
			clearInterval(interval);
			setIntervalState(null);
		}

		getConfigurationLogs({ user_role: user_role, device_id: device_id })
			.then((res, err) => {
				console.log('getConfigurationLogs1 res', res);

				const data = { start_date: res.data.response.start_date, end_date: res.data.response.end_date, file_url: res.data.response.file_url }
				setData_logs(data);

				if (res.data.response.file_url || (res.data.response && res.data.response === 'Log request')) {
					if (res.data.response.file_url === "pending") {
						setShowPendingLogs(true);
					}
					// added Request Logs
					else if (res.data.response.file_url === 'requested' || (res.data.response && res.data.response === 'Log request')) {
						setShowRequestLogs(true);
					}
					else if (res.data.response.file_url === 'waiting') {
						setShowWaitingLogs(true);
					}
					setNoLogs(false);

					setIntervalState(setInterval(() => {
						console.log('INTERVAL');

						getConfigurationLogs({ user_role: user_role, device_id: device_id })
							.then((res, err) => {
								console.log('getConfigurationLogs2 res', res);

								const data = { start_date: res.data.response.start_date, end_date: res.data.response.end_date, file_url: res.data.response.file_url }
								setData_logs(data);

								if (res.data.response.file_url !== 'pending' && res.data.response.file_url !== 'requested' && res.data.response.file_url !== 'waiting' && res.data.response !== 'Log request') {
									console.log('STOP INTERVAL');
									setShowPendingLogs(false);
									setShowRequestLogs(false);
									setShowWaitingLogs(false);

									if (interval) {
										console.log('STOP INTERVAL2');
										clearInterval(interval);
										setIntervalState(null);
									}
								}
							})
							.catch((error) => {
								console.log(error);

								let errDescription = 'An unexpected error occurred. Please try again later'
								if (error.response.data.response.error) {
									errDescription = error.response.data.response.error
								}
								toastr.error(errDescription)
							});

					}, 5000));
				}

				else {
					setNoLogs(true)
				}
			})
			.catch((error) => {
				console.log(error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const requestLogs = () => {
		if (!start_date || !end_date || (moment(end_date).diff(start_date) < 0)) {
			toastr.error('End date should be bigger than Start date');
		} else if (moment(moment()).diff(end_date) < 0) {
			toastr.error('End date cannot be large than current date');
		} else {
			toggleLoaderModal(true);
			const data = {
				device_id: device.device_id,
				start_date,
				end_date,
				user_role,
				action: 'request',
				type: selectedLogTypes.join(','),
			}
			configurationRequestLog(data)
				.then((res, error) => {
					// console.log('!configurationRequestLog1 res', res)
					if (res.data.response.error && !['device is offline, request is pending', 'device is sleep, request is pending'].includes(res.data.response.error)) {
						toastr.error('An unexpected error occurred. Please try again later')
					} else {
						if (res.data.response.error && (res.data.response.error === 'device is sleep, request is pending')) {
							toastr.info('', 'Device is sleep, request is pending')
						}
						else if (res.data.response.error && (res.data.response.error === 'device is offline, request is pending')) {
							toastr.info('', 'Device is offline, request is pending')
						}
						refreshLogsData();
					}

					clearSelection()
					toggleLoaderModal(false)
				})
				.catch(error => {
					toggleLoaderModal(false)
					console.log('Error configurationRequestLog: ', error)
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription)
				});
		}
	}

	const requestCancel = () => {
		toggleLoaderModal(true);

		const data = {
			device_id: device.device_id,
			start_date: data_logs.start_date,
			end_date: data_logs.end_date,
			user_role: user_role,
			action: 'cancel',
			type: selectedLogTypes.join(','),
		}
		configurationRequestLog(data)
			.then(res => {
				console.log('!configurationRequestLog res', res);

				if (res.data.response === 'Log deleted') {
					setShowPendingLogs(false);
					setShowRequestLogs(false);
					setShowWaitingLogs(false);
					refreshLogsData();
				}

				toggleLoaderModal(false)
			})
			.catch(error => {
				toggleLoaderModal(false)
				console.log(error);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const { dms_enabled } = device;
	const vehicle_name = (device) ? (device.vehicle_name || device.device_id) : '';

	return (
		<Modal
			show
			onHide={onHideModal}
			onEnter={onEnterModal}
			size="lg"
			aria-labelledby="contained-modal-title-lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Remote Management - {vehicle_name}</Modal.Title>
			</Modal.Header>
			<Modal.Body className="modal-body configuration-modal-body">
				<div className="config-modal">
					<Tabs activeKey={tabKey} onSelect={(key) => setTabKey(key)} id="controlled-tab-example" className="rosco-tabs">
						<Tab eventKey={1} title="Recording Logs">
							<LiveRecordingLogs
								deviceId={device_id}
							/>
						</Tab>

						<Tab eventKey={2} title="Status Logs">
							<div>
								<h4 className="category-title text-center">Live Status Logs</h4>
								<div className="logs-body">
									<div className="live-logs-date-container">
										<div className="datetime">
											<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16" onClick={getPrevLogs}>
												<path d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
											</svg>
											<DateTime
												timeFormat={false}
												value={moment(liveLogsDate).format('MM/DD/YYYY')}
												closeOnSelect
												isValidDate={date => {
													if (moment(date).isBefore(moment())) {
														return true
													}
													return false;
												}}
												onChange={changeLiveDate}
											/>
											<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" onClick={getNextLogs}>
												<path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
											</svg>
										</div>
									</div>
								</div>

								<div className="live-logs-container">
									{liveLogs.length > 0 ? liveLogs.map((log, index) =>
										<div className="log-wrapper" key={index}>
											<span className='log-timestamp'>
												{formatTimestamp(log.timestamp)}:
											</span>
											<span className='log-code'>
												{log.code}
											</span>
											<span className='log-desc'>
												{log.desc}
											</span>
										</div>) :
										<div className='text-center'>
											No Logs Available for the Selected Date
										</div>
									}
								</div>
							</div>

							<hr />

							<h4 className="category-title text-center">Request Logs</h4>
							{(showPendingLogs || showRequestLogs || showWaitingLogs) ? (
								<div className="item-logs">
									<label className="control-label">Logs Requested: </label>

									{showRequestLogs && <span>Requested</span>}
									{showPendingLogs && <span>Request Pending</span>}
									{showWaitingLogs && <span>Waiting... </span>}

									<div className="logs-body">
										<div className="date-container">
											Dates: {moment(data_logs.start_date).format('M/D/YYYY')} - {moment(data_logs.end_date).format('M/D/YYYY')}
										</div>
									</div>
									<div className="configuration-modal-footer">
										<CustomButton variant='primary-outline' size="sm" onClick={requestCancel}>Cancel</CustomButton>
									</div>
								</div>
							) : (
								<div>
									{!noLogs
										? <div className="log-status">Logs Available: {moment(data_logs.start_date).format('MM/DD/YYYY')} - {moment(data_logs.end_date).format('MM/DD/YYYY')}</div>
										: <div className="log-status">No Logs Available</div>
									}
									<div className="logs-body three-column-grid">
										<div className="date-container">
											<div className="date"><span>Start Date:</span></div>
											<div className="datetime">
												<DateTime
													timeFormat={false}
													defaultValue={moment(start_date).format('MM/DD/YYYY')}
													closeOnSelect
													onChange={onDateChanged1}
												/>
											</div>
										</div>
										<div className="date-container">
											<div className="date"><span>End Date:</span></div>
											<div className="datetime">
												<DateTime
													timeFormat={false}
													defaultValue={moment(end_date).format('MM/DD/YYYY')}
													closeOnSelect
													onChange={onDateChanged2}
												/>
											</div>
										</div>
										<div className="log-options">
											<strong>Include debug logs: </strong>
											<div className="form-group">
												<CheckboxGroup
													name="type_logs"
													onChange={types => setSelectedLogTypes(types)}
													value={selectedLogTypes}
												>
													<label className='no-margin'>
														<Checkbox className="logs-checkbox" value="gps" />
														<span className="log-type">GPS logs</span>
													</label>
													<label className='no-margin'>
														<Checkbox className="logs-checkbox" value="modem" />
														<span className="log-type">Extra modem logs</span>
													</label>
												</CheckboxGroup>
											</div>
										</div>
									</div>
									<div className="configuration-modal-footer">
										{!noLogs
											&& <CustomButton variant='primary-outline' size="sm" onClick={downloadLogs}>Download</CustomButton>
										}
										<CustomButton variant='primary' size="sm" onClick={requestLogs}>Request</CustomButton>
									</div>
								</div>
							)}
						</Tab>

						<Tab eventKey={3} title="Configuration">
							<h4 className="category-title text-center">Upload Configuration</h4>
							<div className="upload-form">
								<div className="upload-file-form">
									<div id="upload-form-label" className="upload-form-label" />
									<div className="selectbutton">Select a file</div>
									<input type="file" name="upload" className="upload-input" onChange={onFileChange} />
								</div>

								<div className="reboot-label">
									<label>
										<Toggle
											name="restart"
											checked={restart}
											onChange={handleInputChange}
										/>
										<span>Reboot</span>
									</label>
								</div>
							</div>
							<div className="configuration-modal-footer">
								<CustomButton variant='primary-outline' size="sm" onClick={downloadConfig}>Download Config</CustomButton>
								<CustomButton variant='primary' size="sm" onClick={uploadConfig}>Upload</CustomButton>
							</div>
						</Tab>

						{!isDv6(device) && dms_enabled && (
							<Tab eventKey={3} title="Upload DM100 Config">
								<h4 className="category-title text-center">Upload DM100 Configuration</h4>
								<div className="upload-file-form">
									<div id="upload-form-label-dm100" className="upload-form-label" />
									<div className="selectbutton">Select a file</div>
									<input type="file" name="uploadDM100" className="upload-input" onChange={onFileChange} />
								</div>

								<div className="reboot-label">
									<label>
										<Toggle
											name="restartDM100"
											checked={restartDM100}
											onChange={handleInputChange}
										/>
										<span>Reboot</span>
									</label>
								</div>
								<div className="configuration-modal-footer">
									<CustomButton variant='primary' size="sm" onClick={uploadConfigDM100}>Upload</CustomButton>
								</div>
							</Tab>
						)}
					</Tabs>
				</div>
			</Modal.Body>
			{loaderModal}
		</Modal>
	);
}

export default connect(
	state => ({
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(ConfigModal);
