import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import CustomButton from '../../CustomButton'

const SMSAgreementModal = (props) => {

    const { hideSMSAgreementModal, agreeToSMS } = props;

    return (
        <Modal
            show={true}
            onHide={hideSMSAgreementModal}
            className="notifications-modal modal-lg-size"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">SMS Notification Agreement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='agreement-modal-header'>
                    <img src='/images/rosco-logo-horizontal.png' />
                    <p>
                        Mobile Message Service Terms and Conditions
                    </p>
                    <p>
                        Last updated: 4/20/21
                    </p>
                </div>
                <div className='agreement-modal-body'>
                    <p>
                        The RoscoLive mobile message service (the "Service") is operated by Rosco Inc. (“Rosco Inc.”, “we”, or “us”). Your use of the Service constitutes
                        your agreement to these terms and conditions (“Mobile Terms”). We may modify or cancel the Service or any of its features without notice.
                        We may also modify these Mobile Terms at any time and your continued use of the Service following the effective date of any such changes shall
                        constitute your acceptance of such changes.
                    </p>
                    <p>
                        We do not charge for the Service, but you are responsible for all charges and fees associated with text messaging imposed by your wireless provider.
                        Message and data rates may apply.
                    </p>
                    <p>
                        Text messages may be sent using an automatic telephone dialing system or other technology. Your consent to receive autodialed marketing text messages is
                        not required as a condition of purchasing any goods or services. If you have opted in, the Service provides updates, alerts, and information
                        (e.g., speeding events) from Rosco Inc. via text messages through your wireless provider to the mobile number you provided. Message frequency varies.
                        Text the single keyword command CUTOFF to the automated number to cancel at any time. You'll receive a one-time opt-out confirmation text message.
                        For Service support or assistance, text HELP to the automated number or email <a href='mailto:techsupport@roscovision.com'>techsupport@roscovision.com</a>.
                    </p>
                    <p>
                        We may change any telephone number we use to operate the Service at any time and will notify you of these changes. You acknowledge that any messages,
                        including any CUTOFF or HELP requests, you send to a short code or telephone number we have changed may not be received and we will not be responsible
                        for honoring requests made in such messages.
                    </p>
                    <p>
                        The wireless carriers supported by the Service are not liable for delayed or undelivered messages. You agree to provide us with a valid mobile number.
                        You agree to maintain accurate, complete, and up-to-date information with us related to your receipt of messages, including, without limitation,
                        notifying us immediately if you change your mobile number.
                    </p>
                    <p>
                        You agree to indemnify, defend, and hold us harmless from any third-party claims, liability, damages or costs arising from your use of the Service
                        or from you providing us with a phone number that is not your own.
                    </p>
                    <p>
                        You agree that we will not be liable for failed, delayed, or misdirected delivery of any information sent through the Service, any errors in such
                        information, and/or any action you may or may not take in reliance on the information or Service.
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant="delete" onClick={hideSMSAgreementModal}>I Don't Want SMS Notifications</CustomButton>
                <CustomButton variant="primary" onClick={agreeToSMS}>Agree</CustomButton>
            </Modal.Footer>
        </Modal>
    )
}

export default SMSAgreementModal;
