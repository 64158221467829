import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import classnames from 'classnames'
import { toastr } from 'react-redux-toastr'
import { browserHistory } from 'react-router'
import { isMobile } from 'react-device-detect'
import { connect } from 'react-redux';

import Auth from '../../Auth'
import CustomButton from '../../CustomButton';
import { getDeviceById, getDevicesLive, stopDevicesLive } from '../../../action/DeviceActions'
import { getUserInfo } from '../../../action/ClientActions'
import { apiCall } from '../../../action/RouterActions'
import useInterval from '../../common/UseInterval'
import { detectRSSIQuality } from '../../helpers/helperVehicle'

const LiveVideoContainer = (props) => {
	const { params, loaderModal, toggleLoaderModal } = props

	const live_player = useRef(null)
	const player = useRef(null)
	const timerPleaseContinueWaiting = useRef(null) // timer, updates message to 'Please continue waiting...' in 7 secs after start getting m3u8
	const timerStartPlayback = useRef(null) // timer, in 45secs after the stream starting will display "The stream will be stopped in 15 secs" 

	const [user, setUser] = useState(null);
	const [userRole, setUserRole] = useState('');
	const [device, setDevice] = useState('');
	const [channel, setChannel] = useState('');
	const [showLiveOut, setShowLiveOut] = useState(false);
	const [showLiveOutStopped, setShowLiveOutStopped] = useState(false);
	const [url, setUrl] = useState('');
	const [modem_rssi, setModem_rssi] = useState(0);
	const [rssi_object, setRssi_object] = useState('');
	const [channels_available, setChannels_available] = useState(null);
	const [showCalibrationBox, setShowCalibrationBox] = useState(false);
	const [m3u8Requests, setM3u8Requests] = useState(0);
	const [message, setMessage] = useState('');
	const [switchingIsAvailable, setSwitchingIsAvailable] = useState(false);

	const [delayDeviceStatusAndRSSIOnline, setDelayDeviceStatusAndRSSIOnline] = useState(null) // interval to request RSSIQuality each 10 secs
	const [delayStreamStopped, setDelayStreamStopped] = useState(null) // interval of counting 15 secs to stream stopping (without Continue Watching clicking), is called each 1 sec
	const [player_out, setPlayer_out] = useState(15) // stores mount of seconds to stream stopping (from 15 to 5)

	const [forceStopPlayer, setForceStopPlayer] = useState(false)

	// console.log('channel: ', channel)
	useLayoutEffect(() => {
		console.log('!LiveVideoContainer componentWillMount');
		document.body.classList.add('live-video-page');

		// if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)){
		if (isMobile) {
			if (window.innerHeight < window.innerWidth) {
				document.body.classList.remove('portrait');
				document.body.classList.add('landscape');
			}
			else {
				document.body.classList.remove('landscape');
				document.body.classList.add('portrait');
			}

			$(window).on('orientationchange', () => {
				// console.log(window.orientation+' - h: '+window.innerHeight+' - w: '+window.innerWidth);

				if ((window.orientation && Math.abs(window.orientation) === 90) || (window.innerHeight > window.innerWidth)) {
					document.body.classList.remove('portrait');
					document.body.classList.add('landscape');
				}
				else {
					document.body.classList.remove('landscape');
					document.body.classList.add('portrait');
				}
			});
		}
	}, []);

	
	// FWSD-6899
	//* Sends stop video request once close live video window.
	// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
	// useEffect(() => {
	// 	if (!channel) return

	// 	const handleBeforeUnload = (event) => {
	// 		event.preventDefault();
	// 		event.returnValue = '';
	// 		stopLiveVideo(() => {
	// 			destroyPlayer()
	// 		})
	// 	}
		
	// 	window.addEventListener('beforeunload', handleBeforeUnload, { capture: true })

	// 	return () => {
	// 		window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true })
	// 	}
	// }, [channel]);

	useEffect(() => {
		// debugger
		// flag that LiveStream was called from Webfleet page with JWT authorization
		const wfAuthToken = localStorage.getItem('wfauth_token')
		if (location.pathname.includes('live/stream') && wfAuthToken) {
			// set token to axios common header
			new Auth().setAxiosAuthToken(wfAuthToken)
		}

	   	// document.title = `${props.params.deviceId} - Live`;

		setChannel('')
		setShowLiveOut(false)
		setShowLiveOutStopped(false)
		setUrl('')
		setChannels_available(null) // '1,2'

		getUserInfo()
			.then((res, err) => {
				// debugger
				console.log('!LiveVideoLayout getUserInfo componentDidMount res: ', res, err)

				const user = res.data.response.user;
				const userRole = user.roles[0];
				const userPermission = user.company_data
				if (!userPermission.live_active && ['fleet_manager', 'fleet_maintainer', 'group_manager', 'user'].indexOf(userRole) > -1) {
					browserHistory.push('/not-found')
				}
				setUserRole(userRole);
				setUser(user);

				const params = {
					deviceId: props.params.deviceId,
					userRole: res.data.response.user.roles[0],
					includeData: 'modem_rssi',
				}
				// getDeviceById(props.params.deviceId, res.data.response.user.roles[0], false, false, false, false, true)
				getDeviceById(params)
					.then((res1, err1) => {
						console.log('!LiveVideoLayout getDeviceById res: ', res1, err1);
						if (res1.status === 200) {
							const { device } = res1.data.response
							const vehicleName = device.vehicle_name || device.device_id;
							document.title = `${vehicleName} - Live`;

							setDevice(device);
							setModem_rssi(+device.modem_rssi)
							setRssi_object(detectRSSIQuality(+device.modem_rssi))
							// getDeviceStatusAndRSSIOnline() // starts to get device data (online and modem_rssi) in realtime
							setDelayDeviceStatusAndRSSIOnline(10000)

							if (device.channel_available) {
								const defaultChannel = device.channel_available.split(',')[0]
								// debugger
								setChannel(defaultChannel);
								setChannels_available(device.channel_available);
								getLiveToken(defaultChannel);
							} else {
								setChannels_available('');
							}

							//* Sends stop video request once close live video window.
							// window.addEventListener("beforeunload", (ev) =>
							// {
							//    console.log('!!!beforeunload', ev)
							// //    ev.preventDefault()
							// //    ev.returnValue = 'Are you sure you want to close?';
							//     stopLiveVideo(function(){
							//         destroyPlayer();
							//     })
							// })

							// window.onbeforeunload = () => {
							// 	stopLiveVideo(() => {
							// 		destroyPlayer()
							// 	})
							// }
							//********************************************************
							// let secondObject = {};
							// for(var k in res.data.response.device)
							// 	secondObject[k]=res.data.response.device[k];

							// if (res.data.response.device.users){
							// 	let devices = res.data.response.device.users;
							// 	let option = [];
							// 	let devicesEmailList = () => {
							// 		let map = devices.map((device, index) =>{
							// 			option[index] = device.email;
							// 		})
							// 		return option;
							// 	}
							// 	setDevicesUsers(devicesEmailList());
							// }
							// setData(res.data.response.device);
							// setDevices(secondObject);
						}
					})
					.catch((error) => {
						// console.log('!changedDevicesList error');
						// console.log(error.response);
					});

			})
			.catch((error) => {
				console.log('!LiveVideoLayout getUserInfo componentDidMount error: ', error.response, error);

				new Auth().deauthenticateUser();
				location.href = '/';
			});

		return () => {
			// debugger
			document.body.classList.remove('live-video-page');
			// clearInterval(intervalStatus);
			// intervalStatus = null;
			setDelayDeviceStatusAndRSSIOnline(null)
			clearTimer();
			stopLiveVideo(() => {
				destroyPlayer();
			})
		}
	}, []);


	useEffect(() => {
		if (forceStopPlayer) {
			// console.log('forceStopPlayer!!!')
			// console.log('forceStopPlayer channel: ', channel)
			stopLiveVideo(
				() => {
					destroyPlayer()
					clearTimer()
					window.close()
				},
				null,
				true
			)
		}
	}, [forceStopPlayer])

	useEffect(() => {
        const handleUnload = (event) => {
            // // Call your API here
            // navigator.sendBeacon('/your-api-endpoint', JSON.stringify({ action: 'window closed' }));

            // // If you want to show a confirmation dialog
            // event.returnValue = 'Are you sure you want to leave?'; // Optional
			setForceStopPlayer(true)

        };

        window.addEventListener('beforeunload', handleUnload);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [])

	useInterval(() => {
		const params = {
			deviceId: device.device_id,
			userRole,
			includeData: 'modem_rssi',
		}
		getDeviceById(params)
			.then((res, err) => {
				const { device } = res.data.response
				setDevice(device);
				setModem_rssi(+device.modem_rssi);
				setRssi_object(detectRSSIQuality(+device.modem_rssi))

				if (!device.ads_online) {
					setMessage('Camera is offline, try please again later.')
					toastr.error('Camera is offline, try please again later.');
				}
			})
			.catch((error) => {
				console.log('useInterval getDeviceById error: ', error)
			});
	}, delayDeviceStatusAndRSSIOnline);

	useInterval(() => {
		setPlayer_out((prevState) => prevState - 1)
		if (player_out === 1) {
			setDelayStreamStopped(null)
			stopLiveVideo(() => {
				setUrl('');
				setShowLiveOut(false);
				setShowLiveOutStopped(true);
			},
				null,
				true
			)
		}
	}, delayStreamStopped);

	const continueWatching = () => {
		clearTimer();
		getLiveToken(channel);
	}

	const clearTimer = () => {
		setDelayStreamStopped(null)
		setPlayer_out(15)

		clearTimeout(timerStartPlayback.current)
		timerStartPlayback.current = null

		clearTimeout(timerPleaseContinueWaiting.current)
		timerPleaseContinueWaiting.current = null

		setShowLiveOut(false);
		setShowLiveOutStopped(false);
	}

	const stopTimer = () => {
		clearTimer();
		startTimer();
	}

	const startTimer = () => {
		console.log('!!!!START TIMER!!!!');

		if (timerStartPlayback.current) {
			clearTimeout(timerStartPlayback.current)
			timerStartPlayback.current = null
		}
		timerStartPlayback.current = setTimeout(() => {
			setShowLiveOut(true);
			setPlayer_out(15)
			setDelayStreamStopped(1000)

		}, 45000);
	}

	const changeChannel = (e, argChannel) => {
		// debugger
		console.log('changeChannel clicked: ', argChannel)
		e.preventDefault()
		// if (channel == channel) return false;
		const channel_prev = channel;
		// debugger
		setChannel(argChannel);
		setUrl('');
		setSwitchingIsAvailable(false);

		clearTimer();
		if (player.current) {
			console.log('player pause')
			player.current.pause()
		}
		// debugger
		stopLiveVideo(
			() => getLiveToken(argChannel),
			channel_prev,
		)
	}

	const stopLiveVideo = (callback, channelData = null, stop = false) => {
		const active_device_id = params.deviceId
		const _channel = channelData || channel;
		// console.log('stopLiveVideo _channel: ', _channel)

		if (player.current) {
			if (stop) {
				$('.player-poster').addClass('stopped');
				player.current.stop();
			} else {
				player.current.pause();
			}
		}

		let data = { device_id: active_device_id, channel: _channel };
		stopDevicesLive(data)
			.then((res, err) => {
				console.log('!stopLiveVideo stopDevicesLive res: ', res);
				// setUrl(config.liveURL+res.data.response.url);
				// startTimer();
				if (typeof callback === 'function') {
					return callback();
				}

			})
			.catch((error) => {
				// props.onHide();
				console.log('!stopLiveVideo stopDevicesLive error: ', error.response, error);

				toastr.error('Error stopping video streaming. Please try again later.')
			});
	}

	const getLiveToken = (channelData = null, errorMessage = null) => {
		console.log('!getLiveToken channel: ', channel);
		const active_device_id = params.deviceId;
		const _channel = channelData || channel;

		toggleLoaderModal(true);
		setM3u8Requests(m3u8Requests + 1);
		setMessage('Loading livestream, please wait');

		if (timerPleaseContinueWaiting.current) {
			clearTimeout(timerPleaseContinueWaiting.current)
			timerPleaseContinueWaiting.current = null
		}
		timerPleaseContinueWaiting.current = setTimeout(() => {
			setMessage('Please continue waiting, the livestream is still loading');
		}, 7000)

		const data = { device_id: active_device_id, channel: _channel };
		getDevicesLive(data)
			.then((res, err) => {
				console.log('!getLiveToken getDevicesLive res: ', res, err);

				// setUrl(config.liveURL+res.data.response.url);
				setUrl(res.data.response.url);
				setModem_rssi(+res.data.response.modem_rssi);
				setM3u8Requests(0);
				detectRSSIQuality(+res.data.response.modem_rssi)
				// if (player) {
				// 	// destroyPlayer();
				// 	player.load(res.data.response.url)
				// }
				if (player.current) {
					player.current.load(res.data.response.url)
				}
				else {
					console.log('Player Creeation')

					$('.player-poster').removeClass('stopped');
					// player = new Clappr.Player({
					player.current = new Clappr.Player({
						parent: live_player.current,
						// poster: '/images/background.jpg',
						source: res.data.response.url,
						// source: 'https://videoservice.roscolive.com:1234/live/407018739/1a83/0/livestream.m3u8',
						mimeType: 'application/x-mpegURL',
						autoPlay: true,
						disableErrorScreen: true,
						exitFullscreenOnEnd: false,
						playInline: true,
						hideMediaControl: true,
						width: '100%',
						height: '100%',
						playback: {
							hlsjsConfig: {
								// debug: true,
								levelLoadingTimeOut: 30000,
								maxLoadingDelay: 10,
							},
							triggerFatalErrorOnResourceDenied: true,
						},
						events: {
							onError: (e) => {
								console.log('onError Clappr Error: ', e);
								// console.log('onError Clappr Error e.raw.details: ', e.raw.details);

								if (e.raw.details === 'manifestLoadError') {
									setMessage('Could not play video');
									// debugger
									// clearTimeout(timeoutChangeMsg);
									// timeoutChangeMsg = null;
									if (timerPleaseContinueWaiting.current) {
										clearTimeout(timerPleaseContinueWaiting.current)
										timerPleaseContinueWaiting.current = null
									}
								}

								// self.player.configure({
								// 	autoPlay: true,
								// 	source: 'playback.error',
								// 	playbackNotSupportedMessage: 'Retrying to return device connectionin ...'
								// });

								const params = {
									log_type: 'error',
									message: JSON.stringify({ title: 'LiveVideo Error, PLAYER_ERROR', device: active_device_id, channel: _channel, user: user.id, code: e.code, description: e.description })
								}
								apiCall('POST', '/elasticsearch/log', params)

								// When Player gets onError make request video again
								// self.stopLiveVideo(
								// 	() => self.getLiveToken(channel, 'onError player handler'),
								// 	channel,
								// )
							},
							onReady: (e) => {
								console.log('onReady Clappr Error: ', e);
								setMessage('')
								// debugger
								// clearTimeout(timeoutChangeMsg);
								// timeoutChangeMsg = null;
								if (timerPleaseContinueWaiting.current) {
									clearTimeout(timerPleaseContinueWaiting.current)
									timerPleaseContinueWaiting.current = null
								}
							}, //Fired when the player is ready on startup
							onPlay: (e) => {
								console.log('onPlay Clappr Error: ', e);

								setTimeout(() => {
									setSwitchingIsAvailable(true)
								}, 1000);
							}, //Fired when player starts to play
							onPause: (e) => {
								console.log('onPause Clappr Error: ', e);
							}, //Fired when player pauses
							onStop: (e) => {
								console.log('onStop Clappr Error: ', e);
								// onStop event does not see updated/current useState values, in particular the channel value
								setForceStopPlayer(true)
							}, //Fired when player stops
							onEnded: (e) => {
								console.log('onEnded Clappr Error: ', e);
							}, //Fired when player ends the video
							// onSeek: (e) => {
							// 	console.log('onSeek Clappr Error: ', e);
							// }, //Fired when player seeks the video
							// onTimeUpdate: (e) => {
							// 	console.log('onTimeUpdate Clappr Error: ', e);
							// }, //Fired when the time is updated on player
						}

						// hlsjsConfig: {
						// 	debug: true,
						// 	levelLoadingTimeOut: 1000,
						// }

						// width: '400px',
						// height: '300px',
						// hlsjsConfig: {
						// 	enableWorker: true
						// },
						// plugins: [Clappr.FlasHLS],
						// events: {
						// 	onTimeUpdate: function(obj) {
						// 		// this event is fired several time per seconds
						// 		// obj properties are : {current: FLOAT_VALUE, total: FLOAT_VALUE}
						// 		console.log('onTimeUpdate', obj)
						// 	}
						// }
						// events: {
						// 	onError:  function(err, p, t) {
						// 		console.log('Clappr.onError', err, p , t);
						// 	}
						// },
					});
				}

				// player.on(Clappr.Events.PLAYBACK_PLAY_INTENT, function(e) {
				// 	console.log("Clappr Error initiated1")
				// });
				// player.listenTo(player, Clappr.Events.PLAYBACK_PLAY_INTENT, (e) => {
				// 	console.log("Clappr Error initiated2")
				// });

				// player.on(Clappr.Events.PLAYER_PLAY, (e) => { console.log("Clappr Error PLAYER_PLAY1 e: ", e) })
				// player.listenTo(player, Clappr.Events.PLAYER_PLAY, (e) => { console.log("Clappr Error PLAYER_PLAY2 e: ", e) })

				// player.listenTo(player, Clappr.Events.PLAYER_PLAY, function() { console.log("Clappr playing!") });
				// console.log('Clappr Error: player: ', player)
				// player.listenTo(player, Clappr.Events.PLAYER_ERROR, (e) => {
				// 	console.error('Clappr Error: PLAYER_ERROR! ', e);
				// 	// browserHistory.push(`/live/video/${params.deviceId}/error`);
				// });
				// player.listenTo(player, Clappr.Events.PLAYBACK_ERROR, (e) => {
				// 	console.error('Clappr Error: PLAYBACK_ERROR! ', e);
				// });
				console.log('!!! player: ', player)
				startTimer();

				toggleLoaderModal(false);

				// setUrl('//192.168.2.46:8080'+res.data.response.url);
			})
			.catch((error) => {
				console.log('Error getDevicesLive error: ', error)
				if (m3u8Requests < 1) {
					// debugger
					stopLiveVideo(
						() => getLiveToken(channel),
						channel,
					)
				}
				else {
					// debugger
					setM3u8Requests(0)
					toggleLoaderModal(false);
					// props.onHide();
					console.error('getDevicesLive error: ', error);
					toastr.error('Please check if camera is online, or try again later.');
					setMessage('Could not play video')

					const params = {
						log_type: 'error',
						message: JSON.stringify({ title: `LiveVideo Error, ${errorMessage || 'get m3u8 link error'}`, device: active_device_id, channel: _channel, user: user.id })
					}
					apiCall('POST', '/elasticsearch/log', params)
				}
			});
	}

	const destroyPlayer = () => {
		// if (player) {
		// 	player.destroy();
		// }
		// player = null;
		if (player.current) {
			player.current.destroy();
		}
		player.current = null;
	}

	const calibrationAccess = ['system_admin', 'customer_service', 'partner'].includes(userRole);

	const activeChannel = (channelData) => {
		if (channel === channelData) {
			return true;
		}
	}

	const channelsList = () => {
		if (channels_available && channels_available.length) {
			const option = []
			channels_available.split(',').map((elem, index) => {
				option[index] = (
					<li key={index} className={classnames('list-inline-item', { active: activeChannel(elem) })}>
						<a href="/" onClick={(e) => changeChannel(e, elem)} className={`${activeChannel(elem) || !switchingIsAvailable ? 'disabled' : ''}`}>{elem}</a>
					</li>
				)
			})
			return (
				<ul className="list-inline chanels-list">
					{option}
				</ul>
			)
		}

		if (channels_available !== null) {
			return (
				<div>No enabled channels</div>
			)
		}
	}

	// console.log('PROPS LiveVideoContainer props: ', props)
	// console.log('STATE LiveVideoContainer channel, url, modem_rssi, m3u8Requests: ', channel, url, modem_rssi, m3u8Requests)
	// console.log('player LiveVideoContainer: player', player)
	// console.log('live_player LiveVideoContainer: live_player', live_player)

	return (
		<div>
			<div className="live-video-player new-window-video" style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '35px' }}>
				<div className="live-video-container" ref={live_player} style={{ width: '100%', height: '100%' }} />
				{/*
						<ReactPlayer
							url={url}
							playing={true}
							controls={false}
							width='100%'
							height='100%'
							fileConfig={{ attributes: { style: {
								width: '100%',
								height: '100%',
								transform:'scaleX(1.45)',
								msTransform:'scaleX(1.46)',
								OTransform:'scaleX(1.45)',
								WwebkitTransform:'scaleX(1.45)',
								MozTransform:'scaleX(1.45)'
							} } }}
						/>
					*/}
				{/*
						<ReactHLS
							url={url}
							autoplay={true}
							constrols={true}
						/>
					*/}

				{/* FWSD-4497 */}
				{
					showCalibrationBox && (
						<div className="calibration-box">
							<div className="cross-shape" />
						</div>
					)
				}

				{(() => {
					if (showLiveOut) {
						return (
							<div className="continue-watching">
								{/* The stream will be stopped in <span id="timer-out">15</span> seconds */}
								The stream will be stopped in <span id="timer-out">{player_out}</span> seconds <CustomButton
									variant="primary-outline"
									style={{ padding: '2px' }}
									onClick={stopTimer}
								>
									Continue Watching
								</CustomButton>
							</div>
						)
					}
					if (showLiveOutStopped) {
						return (
							<div className="continue-watching">
								Stream stopped <CustomButton
									variant="primary-outline"
									style={{ padding: '2px' }}
									onClick={continueWatching}
								>
									Continue Watching
								</CustomButton>
							</div>
						)
					}
					
				})()}

				{!!message && <div className="message">{message}</div>}

				{loaderModal}
			</div>

			<div className="live-video-channels">
				{!!modem_rssi && !!rssi_object
					? (
						<OverlayTrigger
							// trigger="click"
							placement="top"
							delayHide={100}
							overlay={
								<Tooltip id="sidemenu-tooltip" className="tooltip custom-tooltips rssi-tooltips">
									<p>Network Signal Strength: {rssi_object.title}</p>
									{rssi_object.description}
								</Tooltip>
							}
						>
							<div className="rssi-signal">
								<img src={`/images/${rssi_object.image}`} alt="Network Signal Strength" />
								Signal Strength
							</div>
						</OverlayTrigger>
					)
					: <div style={{ width: '150px' }} />
				}

				{channelsList()}

				{calibrationAccess
					? (
						<CustomButton
							variant="primary-outline"
							size="sm"
							prefix="calibration-btn"
							onClick={() => setShowCalibrationBox(!showCalibrationBox)}
						>
							Toggle Calibration Box
						</CustomButton>
					)
					: <div style={{ width: '150px' }} />
				}
			</div>
		</div>
	);
}

export default connect(
	state => ({
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(LiveVideoContainer);
