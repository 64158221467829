const initialState =  {
    tripreplay: {},
    fetching: false,
};

export default function tripreplay(state = initialState, action) {
    switch (action.type) {
        case 'GET_TRIPREPLAY_SUCCESS':
            return {...state, ...{tripreplay: action.payload, fetching: false}};
//            return Object.assign({}, state, {user: action.payload, fetching: false});
//            return {
//                user: action.payload,
//                fetching: false,
//            };
        default:
            return state
    }
}