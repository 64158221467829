import React from 'react'
import PropTypes from 'prop-types'

import './LabelTag.sass'

/* Tag Types
*  internal - For features that are only available to internal users
*  beta - For features that are released in a beta state
*/

const LabelTag = ({text, tag_type}) => <span className={`label-tag ${tag_type}`}>{text}</span>

LabelTag.propTypes = {
    text: PropTypes.string,
    tag_type: PropTypes.string
}

export default LabelTag