import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import Autocomplete from 'react-autocomplete'
import classnames from 'classnames'

import ModalDelete from './ModalDelete'

import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton';

const DriverIdentifierModal = (props) => {

    const { drivers, selectedDriverTrip, toggleDriverIdentifier, toggleLoader } = props;

    const [driverName, setDriverName] = useState('');
    const [driverId, setDriverId] = useState('');
    const [errMessage, setErrMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const Save = () => {
        if (!driverName) {
            setErrMessage('Enter the driver name')
        }
        else {
            setErrMessage('')
            const obj = drivers.find(({ name }) => name === driverName)

            if (obj) {
                setDriverId(obj.id);
                createDriver();
            }
            else {
                setShowConfirmModal(true)
            }
        }
    }

    const createDriver = () => {
        const { toggleDriverIdentifier, selectedDriverTrip, onSave } = props;

        toggleLoader(true)

        const data = {
            trip_photos_ids: _.map(selectedDriverTrip.photos, 'id').join(',')
        }

        if (driverId) {
            data['driver_id'] = driverId
        }
        else {
            data['driver_name'] = driverName
        }

        apiCall('POST', '/associate/driver/withtrip', { ...data }).then((res, err) => {
            toggleLoader(false)

            toastr.success('Success', 'Driver has been created')
            toggleDriverIdentifier(false)
            onSave()
        })
            .catch((error) => {
                toggleLoader(false)

                let errDescription = 'An unexpected error occurred. Please try again later'
                if (error.response.data.response.error) {
                    errDescription = error.response.data.response.error
                }
                toastr.error(errDescription)
            })
    }

    const closeConfirmModal = () => {
        setShowConfirmModal(false)
    }

    return (
        <Modal
            show={true}
            onHide={() => toggleDriverIdentifier(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-lg">Driver Identification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={classnames({ 'has-error': errMessage })}>
                    <label>Available drivers:</label>
                    <Autocomplete
                        value={driverName}
                        items={drivers}
                        getItemValue={item => item.name}
                        autoHighlight={true}
                        wrapperStyle={{ position: 'relative' }}
                        wrapperProps={{ className: 'driver-autocomplete' }}
                        // open={true}
                        menuStyle={{
                            borderRadius: '3px',
                            boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                            background: 'rgba(255, 255, 255, 1)',
                            fontSize: '95%',
                            position: 'absolute',
                            maxHeight: '320px',
                            zIndex: '999999',
                            top: '34px',
                            left: '0px',
                            width: '100%',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            padding: '2px 0 2px 4px',
                        }}
                        onChange={(event, value) => {
                            setDriverName(value);
                            setDriverId('');
                        }}
                        onSelect={(value, item) => {
                            setDriverName(value);
                            setDriverId(item.id);
                        }}
                        renderMenu={function (items, value, style) {
                            return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
                        }
                        }
                        renderItem={(item, isHighlighted) =>
                            <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`}>
                                {item.name}
                            </div>
                        }
                        renderInput={(props) => {
                            return (
                                <div>
                                    <input {...props} className="form-control" />
                                </div>
                            )
                        }}
                        shouldItemRender={(item, value) => {
                            return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
                        }}
                    />
                    {errMessage && <span className="help-block">{errMessage}</span>}
                </div>
                <div className="photos-list">
                    {
                        selectedDriverTrip.photos &&
                        selectedDriverTrip.photos.map((item, index) => {
                            return <img className="driver-photo" key={item.id} src={item.url} alt="" />
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant="secondary" onClick={Save}>Save</CustomButton>
            </Modal.Footer>

            {showConfirmModal &&
                <ModalDelete
                    content={`Are you sure you want to add a new driver "${driverName}" ?`}
                    deleteBtnName={'CONFIRM'}
                    closeModal={closeConfirmModal}
                    deleteModal={createDriver}
                />
            }
        </Modal>
    )
}

const mapStateToProps = (state) => {
    const { driver } = state
    return {
        drivers: driver.drivers,
        selectedDriverTrip: driver.selectedDriverTrip,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        toggleDriverIdentifier: (state) => {
            dispatch({ type: 'SHOW_DRIVER_IDENTIFIER', payload: state })
        },
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverIdentifierModal);
