import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dropdown } from 'react-bootstrap'
import _ from 'lodash'
import CustomButton from '../../component/CustomButton'
import { CustomButtonToggle } from '../../component/DropdownComponents'

const CompanyEditGeotabSettings = (props) => {

	const [showGeotabPassword, setShowGeotabPassword] = useState(false);

	// console.log('PROPS CompanyEditGeotabSettings: ', props)
	// console.log('STATE CompanyEditGeotabSettings: ', state)
	const { company, geotabCredentials, modalConfirm } = props;

	return (
		<div className='subheader-section company-info'>
			{(company.geotab_database || company.geotab_email)
				? (
					<Dropdown
						drop
						id="geotab-dropdown"
						role="menuitem"
					>
						<Dropdown.Toggle as={CustomButtonToggle} variant='primary-outline' size="lg">
							Geotab Settings
						</Dropdown.Toggle>
						<Dropdown.Menu className="geotab-relations-menu">
							<div className='dropdown-body'>
								<h5 className='category-title'>Integration Info</h5>
								<div className='info-section'>
									<b>POC Email:</b> {company.geotab_email}
								</div>
								<div className='info-section'>
									{company.geotab_database && <div><b>Database:</b> {company.geotab_database}</div>}
								</div>
								<div className='info-section'>
									{company.geotab_path && <div><b>URL:</b> {company.geotab_path}</div>}
								</div>
								{company.geotab_database && <div>
									<h5 className='category-title'>Integration Credentials</h5>
									<div className='info-section'>
										<b>Email:</b> {geotabCredentials.email}
									</div>
									{/* <div className='info-section'>
										{showGeotabPassword ?
											<span><b>Password:</b> {geotabCredentials.password}</span> :
											<CustomButton variant="link" onClick={() => setShowGeotabPassword(true)}>Show Password</CustomButton>
										}
									</div> */}
								</div>}
							</div>
							<div className='dropdown-footer'>
								<CustomButton variant="primary-outline" onClick={(e) => modalConfirm(e, 'geotab_email')}>Provision for Geotab</CustomButton>
								{company.geotab_database && (
									<CustomButton variant="delete" onClick={(e) => modalConfirm(e, 'geotab_relation')}>
										Delete Relation
									</CustomButton>
								)}
							</div>
						</Dropdown.Menu>
					</Dropdown>
				) : (
					<CustomButton
						variant="primary-outline"
						size="sm"
						id="geotab-dropdown"
						onClick={(e) => modalConfirm(e, 'geotab_email')}
					>
						Provision for Geotab
					</CustomButton>
				)
			}
		</div>
	)
}

CompanyEditGeotabSettings.propTypes = {
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	geotabCredentials: PropTypes.objectOf(PropTypes.any).isRequired,
	modalConfirm: PropTypes.func.isRequired,
};

export default CompanyEditGeotabSettings;
