import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { getCompanyGroups } from '../../../action/GroupsActions';
import { apiCallGet } from '../../../action/RouterActions';

import ManageAssetsGroupsContainer from '../../smart/menuComponentsContainer/ManageAssetsGroupsContainer';
import AddGroupContainer from '../../smart/menuComponentsContainer/AddGroupContainer';
import SearchBar from './SearchBar';
import GreyHeader from './GreyHeader';

const ManageAssetsGroups = (props) => {
	const { user, company, toggleLoader } = props;
	const userRole = user.roles[0];

	const [groups, setGroups] = useState([]);
	const [data, setData] = useState({});
	const [modal, setModal] = useState({
		show: false,
		content: '',
		error: false
	});
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		switch (userRole) {
			case 'fleet_maintainer':
			case 'fleet_manager':
			case 'storage_manager':
			case 'custom_user':
				changedGroupsList();
				break;
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
				if (company.name && company.partner) {
					changedGroupsList(company.name, company.partner);
				}
				break;
			case 'partner':
			case 'custom_partner':
				if (company.name) {
					changedGroupsList(company.name);
				}
				break;
			default:
				break;
		}
	}, [company.name, company.partner]);

	const changedGroupsList = (argCompanyName, argPartnerName) => {
		const companyName = argCompanyName || company.name
		if (companyName || argPartnerName) {
			toggleLoader(true);
			const params = {
				name: companyName,
				partner: argPartnerName,
			}
			const getids = '?getids=&include_data=active_devices'
			getCompanyGroups(userRole, params, getids)
				.then((res, err) => {
					console.log('!changedGroupsList res', res);

					if (res.status === 200 || res.status === 201) {
						toggleLoader(false);
						// FWSD-4523
						const getGroups = res.data.response.groups.filter(item => item.group_name.toLowerCase() !== 'installed devices' || item.active_devices !== 0);
						setGroups(getGroups);
					}
					if (err) {
						toggleLoader(false);
						console.log('!changedGroupsList err');
						console.log(err);
					}
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!changedDevicesList error', error);
					toastr.error('There was an issue getting the available groups. Please try again later.')
					setGroups([]);
				});
		}
	}

	const user_permissions = user.permissions;
	const filteredGroups = searchText ? groups.filter(group => group.group_name.toLowerCase().includes(searchText.toLowerCase())) : groups;

	return (
		<div>
			<GreyHeader title='Groups' />

			<div className='page-subheader'>
				<div className='subheader-section search'>
					<SearchBar term={searchText} onSearch={(e) => setSearchText(e.target.value)} />
				</div>
			</div>

			{(() => {
				switch (userRole) {
					case 'fleet_manager':
					case 'fleet_maintainer':
					case 'storage_manager':
					case 'system_admin':
					case 'customer_service':
					case 'partner':
						return (
							<main className='manage-users main-content-block'>
								<h3 className='category-title'>Groups</h3>
								<AddGroupContainer changedGroupsList={changedGroupsList} />
								<ManageAssetsGroupsContainer groups={filteredGroups} company={company} />
							</main>
						)
					case 'custom_partner':
					case 'custom_user':
						return (
							<main className='manage-users main-content-block'>
								<h3 className='category-title'>Groups</h3>
								{_.find(user_permissions, { 'perm_category_id': 6, 'a_add': true }) &&
									<AddGroupContainer changedGroupsList={changedGroupsList} />
								}
								<ManageAssetsGroupsContainer groups={filteredGroups} company={company} />
							</main>
						)
					case 'sales_demo':
						return (
							<main className='manage-users main-content-block'>
								<h3 className='category-title'>Groups</h3>
								<ManageAssetsGroupsContainer groups={filteredGroups} company={company} />
							</main>
						)
				}
			})()}
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ManageAssetsGroups);
