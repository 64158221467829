import ambulance from '../../../../assets/vehicle_icon/ambulance.png';
import fire_marshal from '../../../../assets/vehicle_icon/fire_marshal.png';
import ambulancel from '../../../../assets/vehicle_icon/ambulancel.png';
import arrow1 from '../../../../assets/vehicle_icon/1.png';
import arrow2 from '../../../../assets/vehicle_icon/arrow_2.png';
import arrow3 from '../../../../assets/vehicle_icon/arrow.png';
import charter_bus from '../../../../assets/vehicle_icon/charter_bus.png';
import transit_bus from '../../../../assets/vehicle_icon/transit_bus.png';
import transitl_bus from '../../../../assets/vehicle_icon/transitl_bus.png';
import chopper from '../../../../assets/vehicle_icon/chopper.png';
import fire_truck from '../../../../assets/vehicle_icon/fire_truck.png';
import firetruck from '../../../../assets/vehicle_icon/firetruck.png';
import trash_truck_ram from '../../../../assets/vehicle_icon/trash_truck_ram.png';
import humvee from '../../../../assets/vehicle_icon/humvee.png';
import suv from '../../../../assets/vehicle_icon/suv.png';
import limo from '../../../../assets/vehicle_icon/limo.png';
import pick_up_truck from '../../../../assets/vehicle_icon/pick_up_truck.png';
import picup from '../../../../assets/vehicle_icon/picup.png';
import pic_up_ram from '../../../../assets/vehicle_icon/pic_up_ram.png';
import police_car from '../../../../assets/vehicle_icon/police_car.png';
import schoolbus from '../../../../assets/vehicle_icon/schoolbus.png';
import red_sedan from '../../../../assets/vehicle_icon/red_sedan.png';
import truck from '../../../../assets/vehicle_icon/truck.png';
import semi_truck from '../../../../assets/vehicle_icon/semi_truck.png';
import sports_car_1 from '../../../../assets/vehicle_icon/sports_car_1.png';
import sports_car_2 from '../../../../assets/vehicle_icon/sports_car_2.png';
import green_sport from '../../../../assets/vehicle_icon/green_sport.png';
import taxi from '../../../../assets/vehicle_icon/taxi.png';
import taxi_3d from '../../../../assets/vehicle_icon/taxi_3d.png';
import red_bus from '../../../../assets/vehicle_icon/red_bus.png';
import van from '../../../../assets/vehicle_icon/van.png';
import step_van from '../../../../assets/vehicle_icon/step_van.png';
import * as _ from "lodash";
import { sendGA } from './helperUsers'

export const vehicleIcons = [
	{ value: "ambulance", name: "Ambulance 1", src: ambulance },
    { value: "fire_marshal", name: "Ambulance 2", src: fire_marshal },
    { value: "ambulancel", name: "Ambulance 3", src: ambulancel },
    { value: "1", name: "Arrow 1", src: arrow1 },
    { value: "arrow_2", name: "Arrow 2", src: arrow2 },
    { value: "arrow", name: "Arrow 3", src: arrow3 },
    { value: "charter_bus", name: "Bus 1", src: charter_bus },
	{ value: "transit_bus", name: "Bus 2", src: transit_bus },
	{ value: "transitl_bus", name: "Bus 3", src: transitl_bus },
    { value: "chopper", name: "Chopper", src: chopper },
    { value: "fire_truck", name: "Fire Truck 1", src: fire_truck },
    { value: "firetruck", name: "Fire Truck 2", src: firetruck },
    { value: "trash_truck_ram", name: "Garbage Truck", src: trash_truck_ram },
    { value: "humvee", name: "Humvee", src: humvee },
    { value: "suv", name: "Jeep", src: suv },
    { value: "limo", name: "Limousine", src: limo },
    { value: "pick_up_truck", name: "Pickup Truck 1", src: pick_up_truck },
    { value: "picup", name: "Pickup Truck 2", src: picup },
    { value: "pic_up_ram", name: "Pickup Truck 3", src: pic_up_ram },
    { value: "police_car", name: "Police Car", src: police_car },
    { value: "schoolbus", name: "School Bus", src: schoolbus },
    { value: "red_sedan", name: "Sedan", src: red_sedan },
    { value: "truck", name: "Semi Truck 1", src: truck },
    { value: "semi_truck", name: "Semi Truck 2", src: semi_truck },
    { value: "sports_car_1", name: "Sports Car 1", src: sports_car_1 },
    { value: "sports_car_2", name: "Sports Car 2", src: sports_car_2 },
    { value: "green_sport", name: "Sports Car 3", src: green_sport },
    { value: "taxi", name: "Taxi 1", src: taxi },
    { value: "taxi_3d", name: "Taxi 2", src: taxi_3d },
    { value: "red_bus", name: "Van 1", src: red_bus },
    { value: "van", name: "Van 2", src: van },
    { value: "step_van", name: "Van 3", src: step_van },
];


export function	findVehicleImg(imgArr, searchParams) {
    const img = _.find(imgArr, {'value': searchParams});
    return img?.src;
}

export const parkingDurations = [
	{ value: '0', name: 'Disabled' },
	{ value: '30', name: '1/2 Hour' },
	{ value: '60', name: '1 Hour' },
	{ value: '90', name: '1 1/2 Hour' },
	{ value: '120', name: '2 Hour' },
	{ value: '180', name: '3 Hour' },
	{ value: '240', name: '4 Hour' },
	{ value: '360', name: '6 Hour' },
	{ value: '480', name: '8 Hour' },
	{ value: '600', name: '10 Hour' },
	{ value: '720', name: '12 Hour' },
	{ value: '1440', name: '24 Hour' },
	{ value: '2880', name: '48 Hour' },
	{ value: '4320', name: '72 Hour' },
]

export const getDeviceDataLimit = (deviceDataLimit, companyData) => {
	// let dataLimit = 1;
	// switch (deviceDataLimit) {
	// case 3:
	// 	dataLimit = 1;
	// 	break;
	// case 2:
	// 	dataLimit = 0.5;
	// 	break;
	// default:
	// 	dataLimit = companyData.data_limit === 2 ? 0.5 : 1;
	// 	break;
	// }
	// return dataLimit

	return 1
}

//FWSD-4623 - remove VIN validation
export const vinValidation = (argVin) => {
	const isNumeric = (mixedVar) => {
		return (typeof (mixedVar) === 'number' || typeof (mixedVar) === 'string') && mixedVar !== '' && !isNaN(mixedVar);
	}
	// var pattern = /^[^\Wioq]{17}$/;
	const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
	const transliterations = {
		a: 1, b: 2, c: 3, d: 4,
		e: 5, f: 6, g: 7, h: 8,
		j: 1, k: 2, l: 3, m: 4,
		n: 5, p: 7, r: 9, s: 2,
		t: 3, u: 4, v: 5, w: 6,
		x: 7, y: 8, z: 9,
	};
	const vin = argVin.toLowerCase();
	// if(!vin.match(pattern)) { return false; }

	let sum = 0;
	for (let i = 0; i < vin.length; i++) {
		if (!isNumeric(vin.charAt(i))) {
			sum += transliterations[vin.charAt(i)] * weights[i];
		}
		else {
			sum += parseInt(vin.charAt(i)) * weights[i];
		}
	}

	let checkdigit = sum % 11;
	const p = vin.charAt(8)
	if (checkdigit === 10) { // check digit of 10 represented by X
		checkdigit = 'x';
	}

	return (checkdigit.toString() === vin.charAt(8));
}

//FWSD-4624
//source: https://sndeep.info/en/tools/checksum_calculator
export const imeiValidation = (imei) => {
	// console.debug('imeiValidation',imei);
	var odd_sum = 0;
	var even_sum = 0;
	var result = 0;
	var check_digit = 0;
	const numbers_map_list = [0,2,4,6,8,1,3,5,7,9];

	//sum odd numbers, map even numbers to list and sum mapped numbers
	//do not include the check digit in these calculations
	for (var i = 0; i < imei.length - 1; i++){
		var char = imei[i];

		if (isNaN(char)){
			return false;
		}

		if ((i + 1) % 2 !== 0){
			odd_sum += Number(char);
		}else {
			even_sum += numbers_map_list[Number(char)];
		}
	}
	//sum results
	result = odd_sum + even_sum

	//check digit is number needed to sum with result to get nearest 10s
	for (var i = 0;i < 10;i++){
		if ((result + i) % 10 === 0){
			check_digit = i
			break;
		}
	}

	console.debug('check_digit',check_digit);
	return check_digit === Number(imei.charAt(imei.length - 1))? true : false;
}

// FWSD-6275, the dot in the resolutions field is a required separator for list items
export const errorStatusCodes = [
	{ code: "C100", name: "CAN Bus Failure", type: "warn", definitiona: "Camera is unable to properly communicate with the CAN bus.", resolutions: "Check that the camera CAN bus settings are correctly configured. Check the connections between the camera and the vehicle CAN bus connector to ensure they are properly seated and secured."},
	{ code: "E005", name: "Channel 3/4 Video Loss", type: "warn", definitiona: "There is no video stream detected for channels 3 and/or 4 from the camera.", resolutions: "Check the connections between the camera and auxiliary cameras and ensure they are still properly seated and secure. Check and confirm that the auxiliary cameras and equipment are functioning properly and do not have any faults. If camera does not have a camera for channels 3 or 4, check the camera’s settings profile to ensure only the proper channels are enabled."},
	{ code: "E008", name: "High Temperature", type: "warn", definitiona: "The camera has experienced temperatures outside the safe operating temperature threshold. Continued operation in high temperatures can lead to shutdowns and potential damage to hardware.", resolutions: "Relocate camera to a position such that it is protected from heat sources and/or any other factor that could cause high temperatures in the camera. Remove anything around the camera that could prevent the camera from receiving proper ventilation."},
	{ code: "PW23", name: "Shutdown: high temperature", type: "warn", definitiona: "The camera has experienced temperatures outside the safe operating range for an extended amount of time and has shutdown. Camera will power on when it has cooled down to a safe operating temperature.", resolutions: "Relocate camera to a position such that it is protected from heat sources and/or any other factor that could cause high temperatures in the camera. Remove anything around the camera that could prevent the camera from receiving proper ventilation."},
	{ code: "SD20", name: "SD Card Missing", type: "warn", definitiona: "There is no SD card detected in the camera. Camera is unable to capture and store video without an SD card present.", resolutions: "Insert SD card into camera. If SD card is already present in camera and this error still persists, please contact RoscoLive technical support."},
	{ code: "SD21", name: "SD Card Mount Error", type: "warn", definitiona: "A problem has occurred while trying to mount the SD card in the camera. Camera is unable to capture and store video until the SD card is mounted correctly.", resolutions: "Reboot the camera. Ensure SD card is seated properly in camera by taking it out and re-inserting into camera. If steps above do not resolve this issue, please contact RoscoLive technical support."},
	{ code: "SD22", name: "SD Card Read-Only", type: "warn", definitiona: "Camera is unable to write to SD card due to the SD card being read-only. Camera is unable to capture and store video until SD card is writable.", resolutions: "Switch SD card from read-only mode. Insert new SD card into camera that is able to be written to."},
	{ code: "SD23", name: "SD Card Initialization Error", type: "warn", definitiona: "A problem has occurred while trying to initialize the SD card in the camera. Camera is unable to capture and store video until this issue is resolved.", resolutions: "Reboot camera. Ensure SD card is properly seated in camera by removing and re-inserting the SD card. Replace the current SD card with a new one. If issue persists after performing all the above, please contact RoscoLive technical support."},
	{ code: "SD24", name: "Incorrect SD Card", type: "warn", definitiona: "An SD card from another camera has been detected in this camera. Camera is unable to capture and store video until this issue is resolved.", resolutions: "Insert correct SD card into camera. Reformat SD card. Replace SD card with a new SD card."},
	{ code: "SD26", name: "SD Card Recording Error", type: "warn", definitiona: "A problem has been detected with the SD card in this camera. Camera is unable to capture and store video until this issue is resolved.", resolutions: "Reboot camera. Reformat SD card. Replace SD card with a new SD card."},
	{ code: "SD28", name: "SD Card Partition Error", type: "warn", definitiona: "A problem has been detected with a partition on the SD card in this camera. Camera is unable to capture and store video until this issue is resolved.", resolutions: "Reformat SD card. Replace SD card with a new SD card."},
	{ code: "E001,E002,E003,E006,G002,SD27", name: "Critical Error", type: "crit", definitiona: "A critical issue has been detected for this camera. Please contact RoscoLive technical support at your soonest opportunity for further guidance. Be advised that this issue could potentially affect the camera’s ability to record event video and it is recommended to discontinue operation of the camera until the issue is addressed.", resolutions: "Contact RoscoLive technical support."},
];

export const rssiList = {
	good: { title: 'GOOD', image: 'rssi_good.png', description: 'Your camera has ideal network signal strength for live streaming.' },
	fair: { title: 'FAIR', image: 'rssi_fair.png', description: 'Live stream may take longer than usual to load.' },
	weak: { title: 'WEAK', image: 'rssi_weak.png', description: 'Live stream may take longer than usual to load or may not load at all.' },
}

export const detectRSSIQuality = (rssi) => {
	let obj = ''

	if (rssi === 0) {
		obj = ''
	}
	else if (rssi >= -60) {
		obj = rssiList.good
	}
	else if ((-75 <= rssi) && (rssi < -60)) {
		obj = rssiList.fair
	}
	else if (rssi < -75) {
		obj = rssiList.weak
	}

	return obj
}

export const detectDeviceStatus = (device) => {
	const { active, sleep, online, ignition, general_status, ads_online } = device
	let status = ''

	if (!active) {
		status = 'disabled'
	}
	else if (general_status === 'pending sleep') {
		status = 'sleep'
	}
	else if (general_status === 'pending awake') {
		status = 'awake'
	}
	else if (sleep && sleep > 0) {
		status = general_status || 'sleep'
	}	
	else if (!general_status) {
		status = 'offline'
	}
	else {
		status = general_status
	}

	// if (!device.active) {
	// 	status = 'disabled'
	// }
	// else if (sleep && sleep > 0) {
	// 	status = general_status || 'sleep'
	// }
	// else if (!online) {
	// 	status = 'offline'
	// }
	// else if (online && ignition && ignition.state) {
	// 	status = 'driving'
	// }
	// else {
	// 	status = 'parked'
	// }

	// if (!device.active) {
	// 	status = 'disabled'
	// }
	// else if (!device.online) {
	// 	status = 'offline'
	// }
	// else {
	// 	const ignitionOffTS = device.ignition && device.ignition.ts ? device.ignition.ts : ''; // 2023-01-04 18:50:37
	// 	const duration = moment.duration(moment.utc().diff(moment.utc(ignitionOffTS), 'seconds'), 'seconds')
	// 	const durationInMins = duration.asMinutes()

	// 	// FWSD-4314 - If device does not receive GPS from device and ignition is Off more 24 hours (1440 mins) then display Online status.
	// 	// Device stopped receive GPS, broken antena or something, and FileServer does not know when ignition is On.
	// 	if ((device.ignition && device.ignition.state) || (!device.gps_online && durationInMins > 1440)) {
	// 		status = 'driving'
	// 	}
	// 	else (
	// 		status = 'parked'
	// 	)
	// }

	return status
}

export const getDeviceStatusName = (statusName) => {
	let res = statusName
	switch (statusName) {
		case 'pending sleep':
			// res = 'Going To Sleep'
			res = 'Sleep'
			break;
		case 'pending awake':
			// res = 'Waking Up'
			res = 'Awake'
			break;
		default:
			break;
		}
	
	return res
}

export const openLiveStream = (userRole, device) => {
	const deviceId = device.device_id

	sendGA({category: userRole, action: 'Custom Video Request', label: 'Open Live Stream'})

	const width = 660
	const height = 400
	const w = window
	const d = document
	const e = d.documentElement
	const g = d.getElementsByTagName('body')[0]
	const winWidth = w.innerWidth || e.clientWidth || g.clientWidth
	const winHeight = w.innerHeight || e.clientHeight || g.clientHeight
	const winX = (document.all) ? window.screenLeft : window.screenX
	const winY = (document.all) ? window.screenTop : window.screenY
	const left = ((winWidth - width) / 2) + winX
	const top = ((winHeight - height) / 2) + winY
	const windowSpecs = `toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=1,width=${width},height=${height},left=${left},top=${top}`
	const target = deviceId
	const win = window.open(`/live/video/${target}`, `${target}1`, windowSpecs);

	return win
}

export const deviceStatusPopups = {
	'driving': 'The camera and vehicle ignition are on.',
	'parked': 'The camera is on and in parking mode. Vehicle ignition is off. Camera will go to sleep after set time on parking mode timer has expired.',
	'awake': 'The camera is on and fulfilling a request made from RoscoLive to upload data. Vehicle ignition is off. After completion, the camera will return back to Sleep.',
	'sleep': 'Camera is inactive and vehicle ignition is off. Requests can be made to the camera to wake it up and receive video, live stream, or send updates to the camera.',
	'offline': 'The camera is currently not connected to the server and is unavailable for live streaming, event upload, or settings changes. Camera will still record to local storage if powered on and upload events on next reconnect.',
	'disabled': 'Camera has been disabled and is prohibited from connecting to the server. Typically done on request if device no longer in use.',
	// 'pending awake': 'The camera is currently in the process of waking up to perform a task requested from RoscoLive. Vehicle ignition is off.',
	// 'pending sleep': 'The camera is currently in the process of going into Sleep mode. Once in a sleep state, the camera can be activated by sending an update, requesting video, or live streaming. Otherwise it will awake by vehicle ignition.',
}


export default vehicleIcons;
