// FWSD-5138
export const VehicleDataUsageAllowance = [
	{ value: 75, label: '75%' },
	{ value: 85, label: '85%' },
	{ value: 95, label: '95%' },
	{ value: 100, label: '100%' },
]

export const VehicleMaxDataOverage = [
	{ value: 10, label: '10%' },
	{ value: 20, label: '20%' },
	{ value: 40, label: '40%' },
	{ value: 50, label: '50%' },
	{ value: 75, label: '75%' },
	{ value: 85, label: '85%' },
	{ value: 95, label: '95%' },
	{ value: 100, label: '100%' },
]
// FWSD-6823
export const DataPoolUsageOptions = [
	{ value: 'daily', label: 'Daily' },
	{ value: 'weekly', label: 'Weekly' },
	{ value: 'bi-monthly', label: 'Bi-Monthly' },
	{ value: 'billing_cycle_end', label: 'Billing Cycle End' },
]

export const DataPoolWarningOptions = [
	{ value: '50', label: '50%' },
	{ value: '75', label: '75%' },
	{ value: '90', label: '90%' },
	{ value: '100', label: '100%' },
]
