import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { browserHistory, Link } from 'react-router'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import classnames from 'classnames'
import _ from 'lodash'
import { CSVLink } from 'react-csv'

import EventClipsDateRange from './EventClipsDateRange'
import EventClipsSearchType from './EventClipsSearchType'
import EventNoteModal from '../modals/EventNoteModal'
import ModalDelete from '../modals/ModalDelete'

import { renderCustomHeader, paginationExportOptions, tableAdjustableColExportOptions } from '../../helpers/table'
import momenttz from '../../helpers/momenttz'
import { eventStatusFormatter } from '../../helpers/helperEvents'
import { isInternalUser } from '../../helpers/constants'
import { getDevicesList } from '../../../action/DeviceActions'
import { generalIcons } from '../../helpers/iconHelpers'
import { getCompanyGroups } from '../../../action/GroupsActions'
import { getAvailableDatesOfCompany, getEventsOfCompanyAndRange, getEventVideo, restoreFromArchiveWCSEvent, requestFloodDownload } from '../../../action/AlertActions'
import { apiCallGet, apiCall } from '../../../action/RouterActions'

import { exportIcon } from '../../helpers/iconHelpers'
import CustomButton from '../../CustomButton'
import FilterMenu from '../../FilterMenu'

const CSV_HEADERS =[
	{ label: "Time", key: "timestamp" },
	{ label: "Event Type", key: "type" },
	// { label: "Status", key: "uploading_status" },
	{ label: "Vehicle Name", key: "vehicle_name" },
	{ label: "Groups", key: "groups" },
	// { label: "Reviewed By", key: "last_viewed" },
	{ label: "ID", key: "id" },
	// { label: "Duration", key: "duration" },
	{ label: "Requested At", key: "createtime" },
	{ label: "Driver Name", key: "driver_name" },
	{ label: "Note", key: "custom_event_type" },
]

const EventClipsQuarantine = (props) => {
	const INITIAL_STATE = {
		event_date: momenttz(),
		searchCategory: 'vehicle',
		autoValue: '',
		tabKey: 0,
		start_date: null,
		end_date: null,
		location: null,
		page: 1,
		sizePerPage: 20,
		totalSize: 0,
		sortName: 'status',
		sortOrder: 'desc',
		groups: [],
		drivers: [],
		devices: [],
		events: [],
		// allEvents: [],
		availableDates: [],
		requestedEventIDs: [],
		selectedEvent: null,
		showEventNote: false,
		showRequestVideo: false,
		showModalDelete: false,
		deleteEventId: '',
		showModalRequestVideo: false,
		filters: {
			critical_gsensor: true,
			speed: true,
			driver: true,
			custom: true,
			pending: true,
			harshaccel: true,
			harshbraking: true,
			adas_fcw: true,
			adas_ldw: true,
			adas_pcw: true,
			adas_hw: true,
			tailgating: true,
			distraction: true,
			drowsiness: true,
			phone_use: true,
			yawning: true,
			seatbelt: true,
			smoking: true,
			camerablock: true,
		}
	}
	const { company, location, user, toggleLoader } = props;
	const userRole = user.roles[0];
	const userPermissions = user.permissions;

	const prevAutoValue = useRef('')
	const prevStateRef = useRef(_.cloneDeep(INITIAL_STATE))

	const [state, setState] = useState(_.cloneDeep(INITIAL_STATE))

	// Export button
	const refExportBtn = useRef()
	const [csvData, setCsvData] = useState([])
	useEffect(() => {
		if (csvData.length > 0) {
		  	refExportBtn.current.link.click()
		}
	}, [csvData])

	useEffect(() => {
		// debugger
		if (!!state.start_date && !!state.end_date) {
			// setState({ ...state, tabKey: 0, start_date: null, end_date: null });
			setState((prevState) => { return { ...prevState, tabKey: 0, start_date: null, end_date: null } })
			// clearTimer();
		}
		// setState({ ...state, autoValue: '', page: 1, totalSize: 0 });
		setState((prevState) => { return { ...prevState, autoValue: '', page: 1, totalSize: 0, groups: [] } })

		if (company && company.company_id) {
			getFleetGroups(company);
			getFleetDrivers(company.company_id);
			getFleetDevices(null, company.name, company.partner)
			// getFleetDevices(() => {
			// 	if (state.devices) {
			// 		getAvailableDates(() => {
			// 			if (state.availableDates) {
			// 				setState({ ...state, event_date: state.availableDates[state.availableDates.length - 1] });
			// 				getFleetEvents()
			// 			} else {
			// 				toggleLoader(false)
			// 				toastr.info('', 'No events available for this company')
			// 			}
			// 		})
			// 	}
			// },
			// 	company.name,
			// 	company.partner,
			// )
		}
	}, [company.company_id]);

	useEffect(() => {
		// debugger
		if (state.devices.length) {
			getAvailableDates()
		}
	}, [state.devices])

	useEffect(() => {
		// debugger
		if (state.availableDates.length) {
			const new_event_date = state.availableDates[state.availableDates.length - 1]
			setState((prevState) => { return { ...prevState, event_date: new_event_date } })
			getFleetEvents(state.page, state.sizePerPage, true, new_event_date)
		}
		// else {
		// 	toggleLoader(false)
		// 	toastr.info('', 'No events available for this company')
		// }
	}, [state.availableDates])

	useEffect(() => {
		if (!_.isEqual(state.filters, prevStateRef.current.filters)
			|| !_.isEqual(state.videostatuses, prevStateRef.current.videostatuses)
			|| (prevStateRef.current.groups.length && state.groups.length && !_.isEqual(state.groups, prevStateRef.current.groups))
		) {
			// debugger
			getFleetEvents(1, state.sizePerPage, true)
		}
		prevStateRef.current = _.cloneDeep(state)
		// }, [state.filters, state.videostatuses])
	}, [state])

	// useEffect(() => {
	// 	debugger
	// 	if (prevAutoValue.current !== state.autoValue) {
	// 		searchVehicle()
	// 		prevAutoValue.current = state.autoValue
	// 	}		

	// }, [state.autoValue])

	const getFleetEventsByRole = (location) => {
		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
			case 'sales_demo':
				if (company.name) {
					getFleetGroups()
					getFleetDrivers()
					getFleetDevices(
						() => {
							if (state.devices.length) {
								getAvailableDates(() => {
									if (state.availableDates.length) {
										setState({ ...state, event_date: state.availableDates[state.availableDates.length - 1] });
										getFleetEvents();
									} else {
										toggleLoader(false);
										toastr.info('', 'Device does not have events');
									}

								})
							}
						},
						company.name,
						company.partner
					);
				}
				break;
			case 'partner':
			case 'partner_view':
				if (company.name) {
					getFleetGroups()
					getFleetDrivers()
					getFleetDevices(
						() => {
							if (state.devices.length) {
								getAvailableDates(() => {
									if (state.availableDates.length) {
										setState({ ...state, event_date: state.availableDates[state.availableDates.length - 1] });
										getFleetEvents();
									} else {
										toggleLoader(false);
										toastr.info('', 'Device does not have events');
									}
								})
							}
						},
						company.name
					)
				}
				break;
			default:
				getFleetGroups()
				getFleetDrivers()
				getFleetDevices(
					() => {
						if (state.devices.length) {
							getAvailableDates(() => {
								if (state.availableDates.length) {
									setState({ ...state, event_date: state.availableDates[state.availableDates.length - 1] });
									getFleetEvents();
								} else {
									toggleLoader(false);
									toastr.info('', 'Device does not have events');
								}
							})
						}
					},
					company.name
				);
				break;
		}
	}

	const getFleetDevices = (callback) => {
		toggleLoader(true);

		getDevicesList(company.company_id)
			.then(res => {
				// setState({ ...state, devices: res.data.response.devices, events: [], event_date: momenttz() });
				setState((prevState) => { return { ...prevState, devices: res.data.response.devices, events: [], event_date: momenttz() } })

				if (!res.data.response.devices.length) {
					toggleLoader(false);
				}

				if (callback && typeof callback === "function") {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const getFleetDrivers = (companyId) => {
		if (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'fleet_maintainer', 'partner_view', 'sales_demo', 'group_manager', 'custom_user', 'user'].indexOf(userRole) === -1) {
			return
		}

		const company_id = companyId || company.company_id
		apiCallGet('/company/{company_id}/driver', { company_id })
			.then((res, err) => {
				console.log('!getFleetDrivers res:', res, err)

				const { drivers } = res.data.response;
				// setState({ ...state, drivers: drivers });
				setState((prevState) => { return { ...prevState, drivers } })
			})
			.catch((error) => {
				console.log('!getFleetDrivers error:', error.response, error)
				// setState({ ...state, drivers: [] });
				setState((prevState) => { return { ...prevState, drivers: [] } })

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const getFleetGroups = (argСompany = null) => {
		const paramCompany = argСompany || company

		getCompanyGroups(userRole, paramCompany, '?getids')
			.then(res => {
				setState((prevState) => {
					return {
						...prevState,
						groups: res.data.response.groups.map((item, i) => {
							if (i < 10) {
								return { ...item, selected: true, visible: true }
							}
							return { ...item, selected: true, visible: false }
						})
					}
				})
			})
			.catch((error) => {
				console.log('!getCompanyGroups error:', error.response, error);
				// setState({ ...state, groups: [] });
				setState((prevState) => { return { ...prevState, groups: [] } })
			})
	}

	const getAvailableDates = (callback, company_name = null) => {
		const data = {
			user_role: user.roles[0],
			company_name: company_name || company.name || user.company_name,
			partner_name: company.partner,
			start_ts: '20150101000000',
			search_type: state.searchCategory,
			search: state.autoValue,
			is_flood: true,
			is_datausage_locked: true,
		};
		getAvailableDatesOfCompany(data)
			.then((res, err) => {
				console.log('!getAvailableDates getAvailableDatesOfCompany res: ', res, err);
				// setState({ ...state, availableDates: res.data.response.events_dates });
				setState((prevState) => { return { ...prevState, availableDates: res.data.response.events_dates } });

				if (!res.data.response.events_dates.length) {
					toggleLoader(false);
					toastr.info('', 'No events available for this company')
				}

				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getAvailableDates getAvailableDatesOfCompany error:', error.response, error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const getFleetEvents = (page = state.page, sizePerPage = state.sizePerPage, showLoader = true, event_date = null, company_name = null, callback = null, eventIds = null, start_date = null /*state.start_date*/, end_date = null /*state.start_date*/) => {
		// debugger
		event_date = event_date || state.event_date
		start_date = start_date || state.start_date
		end_date = end_date || state.end_date
		if (!start_date && !end_date) {
			if (moment(event_date).format('MM/DD/YY') === moment().format('MM/DD/YY')) {
				// setState({ ...state, tabKey: 1 }) /* clearTimer()*/
				setState((prevState) => { return { ...prevState, tabKey: 1 } })
			}
			else {
				// setState({ ...state, tabKey: 0 }) /* clearTimer()*/
				setState((prevState) => { return { ...prevState, tabKey: 0 } })
			}
		}
		!!start_date ? start_date : start_date = event_date;
		!!end_date ? end_date : end_date = event_date;

		if (showLoader) {
			toggleLoader(true);
		}

		const data = {
			user_role: userRole,
			company_name: company_name || company.name || user.company_name,
			partner_name: company.partner,
			start_ts: momenttz(moment(start_date).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(moment(end_date).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
			page_number: page,
			page_size: sizePerPage,
			filters: getFiltersEventTypes(),
			groups: getGroups(),
			search_type: state.searchCategory,
			search: state.autoValue,
			order: `&order=${state.sortName}&direction=${state.sortOrder}`,
			search_by_id: (state.location && state.location.search_by_id) ? state.location.search_by_id : '',
			event_ids: eventIds,
			is_flood: true,
			is_datausage_locked: true,
		}

		if (eventIds) {
			delete data.page_number
			delete data.page_size
		}

		// let allEventsData = _.cloneDeep(data);
		// delete allEventsData.page_number
		// delete allEventsData.page_size

		// getEventsOfCompanyAndRange(allEventsData)
		// 	.then(res => {
		// 		setState((prevState) => {
		// 			return {
		// 				...prevState,
		// 				allEvents: res.data.response.events
		// 			}
		// 		})
		// 	});

		getEventsOfCompanyAndRange(data)
			.then((res, err) => {
				// console.log('!getFleetEvents getEventsOfCompanyAndRange res', res)
				const { response } = res.data

				if (data.search_type === 'event' && data.search && response.events.length) {
					const newEventDate = momenttz(response.events[0].timestamp).format('YYYY-MM-DD')
					// setState({ ...state, event_date: newEventDate, events: response.events, totalSize: response.events.length, page: 1 });
					setState((prevState) => { return { ...prevState, event_date: newEventDate, events: response.events, totalSize: response.events.length, page: 1 } })

				}
				else if (eventIds && eventIds.length) {
					if (response.events.length) {
						const filteredEvents = state.events.map(event => {
							const index = eventIds.indexOf(event.id);
							if (index > -1) {
								event = _.find(response.events, ['id', event.id]);
								if (event
									&& ((event.uploading_status === 'VIDEO_UPLOADED' && event.snapshot_status === 'SNAPSHOT_UPLOADED')
										|| event.uploading_status === 'VIDEO_NON_EXIST')
								) {
									let requestedEventIDs = state.requestedEventIDs;
									requestedEventIDs.splice(index, 1);
									// setState({ ...state, requestedEventIDs: requestedEventIDs });
									setState((prevState) => { return { ...prevState, requestedEventIDs } })
								}
							}

							return event
						})

						// setState({ ...state, setEvents: filteredEvents });
						setState((prevState) => { return { ...prevState, setEvents: filteredEvents } })
					}
				} else {
					const location1 = state.location;
					// setState({ ...state, events: response.events, totalSize: response.total_number, page: (response.page_number) ? response.page_number * 1 : page, location: (location1 && location1.search_by_id) ? { ...location1, search_by_id: null } : location1, sizePerPage: sizePerPage });
					setState((prevState) => { return { ...prevState, events: response.events, totalSize: response.total_number, page: (response.page_number) ? response.page_number * 1 : page, location: (location1 && location1.search_by_id) ? { ...location1, search_by_id: null } : location1, sizePerPage: sizePerPage } })
				}

				if (!response.events.length) {
					toastr.info('', 'Device does not have events');
				}

				if (callback && typeof callback === 'function') {
					callback();
				}

				if (showLoader) {
					toggleLoader(false);
				}
			})
			.catch(error => {
				toggleLoader(false);
				console.log('!EventClipsQuarantine getFleetEvents getEventsOfCompanyAndRange error: ', error.response, error);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	// Selects event types filters which should be send in /events request
	const getFiltersEventTypes = () => {
		const res = []

		_.forOwn(state.filters, (value, key) => {
			if (!value) {
				res.push(key)
			}
		})

		return res.join(',');
	}

	const getGroups = () => {
		const res = _.reduce(state.groups, (result, value, index, arr) => {
			if (!value.selected) {
				result.push(value.id);
			}
			return result;
		}, []);

		return res.join(',');
	}

	const editVehicle = (device_id) => {
		browserHistory.push({ pathname: '/managevehicles/edit', state: { id: device_id, company: company, type: '/event' } });
	}

	const handleSortChange = (sortName, sortOrder) => {
		setState({ ...state, sortName: sortName, sortOrder: sortOrder });
		getFleetEvents(state.page, state.sizePerPage, true);
	}

	const onSelectDate = (argTabKey, argStartDate, argEndDate) => {
		// setState({ ...state, tabKey: argTabKey, start_date: argStartDate, end_date: argEndDate });
		setState((prevState) => { return { ...prevState, tabKey: argTabKey, start_date: argStartDate, end_date: argEndDate } })
		getFleetEvents(state.page, state.sizePerPage, true, state.event_date, null, null, null, argStartDate, argEndDate);
	}

	const onDateChanged = (current) => {
		if (!moment(current, 'MM/DD/YYYY', true).isValid()) {
			toastr.error('', 'Please enter valid date value.')
			return
		}

		toastr.removeByType('error')
		// setState({ ...state, event_date: current });
		setState((prevState) => { return { ...prevState, event_date: current } })
		getFleetEvents(1, state.sizePerPage, true, current)
	}

	const getPreviousEvents = () => {
		let event_date = state.event_date;
		if (!!state.start_date) {
			event_date = momenttz(state.start_date).format('YYYY-MM-DD')
		}
		let availableDates = state.availableDates;
		const pos = _.indexOf(availableDates, event_date)
		if (pos === -1 && availableDates.length) {
			const arr = availableDates.filter(date => {
				return (momenttz(date).format('YYYY-MM-DD') < momenttz(event_date).format('YYYY-MM-DD'))
			})

			if (arr.length) {
				const elm = arr[arr.length - 1]
				// setState({ ...state, tabKey: 0, event_date: elm, start_date: elm, end_date: elm });
				setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
				getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm)
			} else {
				toastr.info('', 'No previous events are available.')
			}
		} else if (pos > 0) {
			const elm = availableDates[pos - 1]
			// setState({ ...state, tabKey: 0, event_date: elm, start_date: elm, end_date: elm });
			setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
			getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm)
		} else {
			toastr.info('', 'No previous events are available.')
		}
	}

	const getNextEvents = () => {
		let event_date_Update = state.event_date;
		let availableDatesUpdate = state.availableDates;
		if (!!state.end_date) {
			event_date_Update = momenttz(state.end_date).format('YYYY-MM-DD')
		}
		const pos = _.indexOf(availableDatesUpdate, event_date_Update)
		if (pos === -1 && availableDatesUpdate.length) {
			const arr = availableDatesUpdate.filter(date => {
				return (momenttz(date).format('YYYY-MM-DD') > momenttz(event_date_Update).format('YYYY-MM-DD'))
			})

			if (arr.length) {
				const elm = arr[0];
				// setState({ ...state, tabKey: 0, event_date: elm, start_date: elm, end_date: elm });
				setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
				getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm)
			} else {
				toastr.info('', 'No future events are available.');
			}
		} else if (pos > -1 && pos < (availableDatesUpdate.length - 1)) {
			const elm = availableDatesUpdate[pos + 1];
			// setState({ ...state, tabKey: 0, event_date: elm, start_date: elm, end_date: elm });
			setState((prevState) => { return { ...prevState, tabKey: 0, event_date: elm, start_date: elm, end_date: elm } })
			getFleetEvents(1, state.sizePerPage, true, elm, null, null, null, elm, elm)
		} else {
			toastr.info('', 'No future events are available.')
		}
	}

	const onSearchCategoryChange = (e) => {
		// setState({ ...state, searchCategory: e.target.value });
		// setState((prevState) => { return { ...prevState, searchCategory: e.target.value } })
		if (state.autoValue) {
			searchVehicle()
		}
	}

	const searchVehicle = () => {
		// getAvailableDates()
		getFleetEvents(state.page, state.sizePerPage, true);
	}

	const fillFilters = (name) => {
		let filters = state.filters;
		filters[name] = !filters[name]
		// setState({ ...state, filters: filters });
		setState((prevState) => { return { ...prevState, filters } })
		// getFleetEvents(1, state.sizePerPage, true);
	}

	const fillGroups = (argGroupId) => {
		let groupsData = state.groups;
		const result = groupsData.map((item) => {
			if (item.id === argGroupId) {
				return Object.assign({}, item, { selected: !item.selected })
			}
			return item;
		})

		// setState({ ...state, groups: result });
		setState((prevState) => { return { ...prevState, groups: result } })
		// getFleetEvents(1, state.sizePerPage, true);
	}

	const selectAllGroups = () => {
		let groupsData = state.groups;
		const countSelectedGroups = _.reduce(groupsData, (sum, value) => { if (value.selected) { return sum + 1 } return sum }, 0)
		if (countSelectedGroups === groupsData.length) {
			// setState({ ...state, groups: groupsData.map(item => ({ ...item, selected: false })) })
			setState((prevState) => { return { ...prevState, groups: groupsData.map(item => ({ ...item, selected: false })) } })
			// getFleetEvents(1, state.sizePerPage, true)
		} else {
			// setState({ ...state, groups: groupsData.map(item => ({ ...item, selected: true })) })
			setState((prevState) => { return { ...prevState, groups: groupsData.map(item => ({ ...item, selected: true })) } })
			// getFleetEvents(1, state.sizePerPage, true)
		}
	}

	const selectAllFilters = () => {
		const countSelectedFilters = _.reduce(state.filters, (sum, value) => { return sum + value }, 0)
		if (countSelectedFilters === Object.keys(state.filters).length) {
			// setState({ ...state, filters: _.reduce(state.filters, (res, value, key) => { res[key] = false; return res; }, {}) });
			setState((prevState) => { return { ...prevState, filters: _.reduce(state.filters, (res, value, key) => { res[key] = false; return res; }, {}) } })
			// getFleetEvents(1, state.sizePerPage, true)
		} else {
			// setState({ ...state, filters: _.reduce(state.filters, (res, value, key) => { res[key] = true; return res; }, {}) });
			setState((prevState) => { return { ...prevState, filters: _.reduce(state.filters, (res, value, key) => { res[key] = true; return res; }, {}) } })
			// getFleetEvents(1, state.sizePerPage, true)
		}
	}

	const onMoreGroupsClick = (e) => {
		e.preventDefault();

		let counter = 0
		const result = state.groups.map((item) => {
			if (!item.visible && (counter < 10)) {
				counter += 1
				return { ...item, visible: true }
			}
			return item
		})

		// setState({ ...state, groups: result });
		setState((prevState) => { return { ...prevState, groups: result } })
	}

	const handlePageChange = (page, sizePerPage) => {
		getFleetEvents(page, sizePerPage);
	}

	const handleSizePerPageChange = (sizePerPage) => {
		// setState({ ...state, events: [] });
		setState((prevState) => { return { ...prevState, events: [] } })
		getFleetEvents(state.page, sizePerPage);
	}

	const setPropStates = (states, callback = null) => {
		// setState({ ...state, ...states });
		setState((prevState) => { return { ...prevState, ...states } })
		if (typeof callback === 'function') {
			callback()
		}
	}

	const timestampFormatter = (cell) => {
		if (cell) {
			return momenttz(cell).format('M/DD/YYYY hh:mm:ss A');
		}
		return 'Not reviewed';
	}

	// const csvTimestampFormatter = (cell) => {
	// 	if (cell) {
	// 		return momenttz(cell).format('hh:mm:ss A')
	// 	}
	// 	return 'Not available'
	// }

	const durationFormatter = (cell, row) => {
		// FWSD-4819
		if (eventStatusFormatter(row.uploading_status) !== 'Uploaded') {
			return '0'
		}

		let clipDuration = row.duration
		// FWSD-4558
		if (row.accurate_duration) {
			clipDuration = row.accurate_duration
		}

		return moment.duration(clipDuration, 'seconds').format('h [hrs] m [min] s [sec]', { trim: 'both' })
	}

	const driverFormatter = (cell, row) => row.driver_name || row.geotab_driver;

	const vehicleFormatter = (cell, row) => {
		return isInternalUser(userRole) ?
			<CustomButton variant="link" onClick={() => editVehicle(row.device_id)}>{cell || row.device_id}</CustomButton> :
			(cell || row.device_id);
	}

	const vehicleCSVFormatter = (cell, row) => row.vehicle_name || row.device_id;

	// const videostatusFormatter = (cell, row) => {
	// 	if (row.size) {
	// 		return `${(row.size).toFixed(2)} MB `
	// 	}
	// 	if (row.uploading_status && row.uploading_status.toLowerCase() === 'event_reported') {
	// 		return 'pending'
	// 	}
	// 	if (!row.uploading_status || row.uploading_status.toLowerCase() === 'video_non_exist') {
	// 		return 'Unavailable'
	// 	}

	// 	const re = /_/gi;
	// 	return row.uploading_status.replace(re, ' ')
	// }

	const eventTypeDetection = (event) => {
		if (!event) {
			return 'No Event Type'
		}

		// FWSD-3958
		const re = /External Driver|InputSensor2|InputSensor3|InputSensor5|InputSensor9/g;
		let typesArr = String(event.type).replaceAll(re, 'Driver Event').replace('HighSpeed', 'Max Speed').split(',')

		// FWSD-4506
		if (typesArr.length > 1) {
			if (typesArr.includes('High G-Force')) {
				typesArr = ['High G-Force']
			}
			else {
				typesArr = typesArr.filter(item => item !== 'Critical G-Sensor')
			}
		}

		const type = [...new Set(typesArr)].join(', ')

		// FWSD-4541
		if (event.event_display_type) {
			return event.event_display_type
		}

		return type
	}

	const typeFormatter = (cell, row) => eventTypeDetection(row);

	const reviewedByFormatter = (cell, row) => {
		const res = []
		if (row.last_viewed.viewer.first_name) {
			res.push(row.last_viewed.viewer.first_name)
		}
		if (row.last_viewed.viewer.last_name) {
			res.push(row.last_viewed.viewer.last_name)
		}
		if (res.length > 0 && row.last_viewed.viewed_ts) {
			res.push('on')
		}
		if (row.last_viewed.viewed_ts) {
			res.push(momenttz(row.last_viewed.viewed_ts).format('M-D-YYYY'))
		}

		if (!res.length) {
			return 'Not Reviewed'
		}

		return res.join(' ')
	}

	const tripFormatter = (cell, row) => {
		return <Link to="/reports" onClick={(e) => {
			e.preventDefault();
			redirectToTripReplay(row);
		}}>View Trip</Link>
	}

	const redirectToTripReplay = (eventData) => {
		const { setDashboardRedirect } = props;
		setDashboardRedirect({
			type: 'redirectFromDailyEvents',
			device_id: eventData.device_id,
			timestamp: moment(eventData.timestamp).utc().format('YYYY-MM-DD HH:mm:ss')
		})
		browserHistory.push({ pathname: '/' });
	}

	const driverNameSort = name => (a, b, order) => {
		const first = a.driver_name || a.geotab_driver || '';
		const second = b.driver_name || b.geotab_driver || '';

		return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)
	}

	const actionsFormatter = (cell, row) => {
		let deleteButton, requestVideoButton, noteButton;

		if (!(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_edit': false }))) {
			noteButton = (
				<CustomButton
					size="icon"
					variant="tertiary"
					prefix={classnames("note-button", { 'with-note': row.custom_event_type })}
					active={row.custom_event_type}
					// onClick={() => setState({ ...state, selectedEvent: row, showEventNote: true })}
					onClick={() => setState((prevState) => { return { ...prevState, selectedEvent: row, showEventNote: true } })}
				>
					Note
				</CustomButton>
			)
		}

		// requestVideoButton = <CustomButton prefix="imageButton floodRestoreButton" title="Request Video" onClick={() => setState({ ...state, selectedEvent: row, showModalRequestVideo: true })} />
		requestVideoButton = <CustomButton prefix="imageButton floodRestoreButton" title="Request Video" onClick={() => setState((prevState) => { return { ...prevState, selectedEvent: row, showModalRequestVideo: true } })} />

		if (['system_admin', 'customer_service', 'partner', 'fleet_manager'].indexOf(userRole) > -1) {
			// deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Event" onClick={() => setState({ ...state, showModalDelete: true, deleteEventId: row.id })}>
			deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Event" onClick={() => setState((prevState) => { return { ...prevState, showModalDelete: true, deleteEventId: row.id } })}>
				{generalIcons.crossDelete}
			</CustomButton>
		}

		return (
			<div className="event-actions">
				{noteButton}
				{requestVideoButton}
				{deleteButton}
			</div>
		)
	}

	const onCloseEventNote = (note) => {
		// setState({ ...state, showEventNote: false })
		setState((prevState) => { return { ...prevState, showEventNote: false } })
		let label = 'Close Event Note';
		if ((note && note.length) || note === '') {
			label = 'Submit Event Note';
			let selectedEvent = state.selectedEvent;
			if (selectedEvent) {
				selectedEvent.custom_event_type = note
				// setState({ ...state, selectedEvent: selectedEvent })
				setState((prevState) => { return { ...prevState, selectedEvent } })
			}
		}
	}

	const deleteEventVideo = () => {
		// setState({ ...state, showModalDelete: false })
		setState((prevState) => { return { ...prevState, showModalDelete: false } })
		toggleLoader(true);
		const params = {
			event_id: state.deleteEventId,
			wcs: company.type.toLowerCase() === 'wcs',
		}
		apiCall('DELETE', '/events/{event_id}', params)
			.then((res, err) => {
				props.toggleLoader(false);
				toastr.success('', `Video of the "${state.deleteEventId}" event has been removed`);
				getFleetEvents()

				//* Removes the events update (interval) if removed the monitored event
				const requestedEventIDs = state.requestedEventIDs
				if (state.interval && requestedEventIDs.length) {
					const index = requestedEventIDs.indexOf(state.deleteEventId)
					if (index > -1) {
						requestedEventIDs.splice(index, 1)
						// setState({ ...state, requestedEventIDs: requestedEventIDs })
						setState((prevState) => { return { ...prevState, requestedEventIDs } })
					}
					if (!state.requestedEventIDs.length) {
						clearInterval(state.interval)
						// setState({ ...state, interval: null });
						setState((prevState) => { return { ...prevState, interval: null } })
					}
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const requestEventVideo = () => {
		// setState({ ...state, showModalRequestVideo: false })
		setState((prevState) => { return { ...prevState, showModalRequestVideo: false } })

		toggleLoader(true);
		const params = {
			event_id: state.selectedEvent.id,
		}
		apiCall('POST', '/events/flood_download', params)
			.then((res, err) => {
				console.log('requestEventVideo res, err: ', res, err);
				toggleLoader(false);
				toastr.success('', `Video of the "${state.selectedEvent.id}" event has been requested`);
				getFleetEvents()
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('requestEventVideo error: ', error.response, error);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const options = {
		...paginationExportOptions,
		onPageChange: handlePageChange,
		onSizePerPageList: handleSizePerPageChange,
		page: state.page,
		sizePerPage: state.sizePerPage,
		sortName: state.sortName,
		sortOrder: state.sortOrder,
		onSortChange: handleSortChange,
	}
	const selectedRow = {
		mode: 'radio',
		bgColor: '#f3f6c1', // you should give a bgcolor, otherwise, you can't regonize which row has been selected
		hideSelectColumn: true, // enable hide selection column.
		clickToSelect: true,
	}

	const getAllFleetEvents = async () => {
		// debugger
		let { event_date, start_date, end_date } = state
		!!start_date ? start_date : start_date = event_date;
		!!end_date ? end_date : end_date = event_date;

		toggleLoader(true)

		const data = {
			user_role: userRole,
			company_name: company.name || user.company_name,
			partner_name: company.partner,
			start_ts: momenttz(moment(start_date).format('YYYY-MM-DD 00:00:00')).utc().format('YYYYMMDDHHmmss'),
			end_ts: momenttz(moment(end_date).format('YYYY-MM-DD 23:59:59')).utc().format('YYYYMMDDHHmmss'),
			filters: getFiltersEventTypes(),
			groups: getGroups(),
			search_type: state.searchCategory,
			search: state.autoValue,
			order: `&order=${state.sortName}&direction=${state.sortOrder}`,
			search_by_id: (state.location && state.location.search_by_id) ? state.location.search_by_id : '',
			is_flood: true,
			is_datausage_locked: true,
		}

		try {
			const request = await getEventsOfCompanyAndRange(data)
			const { events } = request.data.response
			return events
		} catch (error) {
			console.log('!EventClipsQuarantine getAllFleetEvents getEventsOfCompanyAndRange error: ', error.response, error);
			if (!error.response.data.body) {
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			}
		}
		finally {
			toggleLoader(false)
		}
	} 

	const onExportClick = async() => {
		// debugger
		const events = await getAllFleetEvents()
		const newCsvData = []
		events.forEach((item) => {
			const obj = {
				id: item.id,
				device_id: item.device_id,
				vehicle_name: vehicleCSVFormatter(item.vehicle_name, item),
				timestamp: timestampFormatter(item.timestamp),
				type: eventTypeDetection(item),
				// uploading_status: eventStatusFormatter(item.uploading_status),
				groups: item.groups,
				// last_viewed: reviewedByFormatter(item.last_viewed, item),
				// duration: durationFormatter(item.duration, item),
				createtime: timestampFormatter(item.createtime),
				driver_name: driverFormatter(item.driver_name, item),
				custom_event_type: item.custom_event_type || '',
			}

			newCsvData.push(obj)
		})

		setCsvData(newCsvData)
	}

	if (props.selectedEvent) selectedRow['selected'] = [props.selectedEvent.id * 1];

	// console.log('STATE EventClipsQuarantine state: ', state)	

	return (
		<div className="cloud-storage">
			<div className="page-subheader event-subheader-grid">
				{/* Date range selection */}
				<EventClipsDateRange
					tabKey={state.tabKey}
					eventDate={state.event_date}
					startDate={state.start_date}
					endDate={state.end_date}
					onSelectDate={onSelectDate}
					onDateChanged={onDateChanged}
					getPreviousEvents={getPreviousEvents}
					getNextEvents={getNextEvents}
				/>

				{/* Search type and input */}
				<EventClipsSearchType
					devices={state.devices}
					drivers={state.drivers}
					searchCategory={state.searchCategory}
					autoValue={state.autoValue}
					onSearchCategoryChange={onSearchCategoryChange}
					searchVehicle={searchVehicle}
					setPropStates={setPropStates}
				/>

				{/* Events filter */}
				<FilterMenu
					userRole={userRole}
					filters={state.filters}
					user={user}
					fillFilters={fillFilters}
					selectAllFilters={selectAllFilters}
					groups={state.groups}
					fillGroups={fillGroups}
					selectAllGroups={selectAllGroups}
					onMoreGroupsClick={onMoreGroupsClick}
					twoColumns
				/>
			</div>

			{/* Events table */}
			<main className="main-content-block">
				<div className="event-page-table">
					{/* Export button */}
					<div className="react-bs-table-tool-bar btn-group">
						<CustomButton onClick={onExportClick} variant='primary-outline' size='sm' prefix='export-button'>
							{exportIcon}
							<span className='export-label'>Export</span>
						</CustomButton>
					</div>
					<CSVLink
						ref={refExportBtn} 
						filename={`daily_events_${moment(state.event_date).format('M-D-YY')}.csv`} 
						headers={CSV_HEADERS}
						data={csvData} 
					/>

					<BootstrapTable
						data={state.events}
						{...tableAdjustableColExportOptions}
						remote
						fetchInfo={{ dataTotalSize: state.totalSize }}
						selectRow={selectedRow}
						// options={{ ...options, onExportToCSV: () => state.allEvents }}
						options={{ ...options }}
						// csvFileName={`daily_events_${moment(state.event_date).format('M-D-YY')}.csv`}
						exportCSV={false}
					>
						<TableHeaderColumn
							width="12%"
							dataField="timestamp"
							dataSort
							dataFormat={timestampFormatter}
							csvFormat={timestampFormatter}
							csvHeader="Date/Time"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(0, 'Date / Time')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="12%"
							dataField="type"
							dataSort
							dataFormat={typeFormatter}
							csvFormat={typeFormatter}
							csvHeader="Event Type"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(1, 'Type')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="15%"
							dataField="vehicle_name"
							dataSort
							dataFormat={vehicleFormatter}
							csvFormat={vehicleCSVFormatter}
							csvHeader="Vehicle Name"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(2, 'Vehicle')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="14%"
							dataField="groups"
							csvHeader="Groups"
							dataSort
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(3, 'Groups')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="8%"
							dataField="id"
							csvHeader="ID"
							dataSort
							isKey
						>
							{renderCustomHeader(4, 'ID')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="12%"
							dataField="createtime"
							dataSort
							dataFormat={timestampFormatter}
							csvFormat={timestampFormatter}
							csvHeader="Requested At"
						>
							{renderCustomHeader(5, 'Requested At')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="12%"
							dataField="driver_name"
							dataSort
							dataFormat={driverFormatter}
							csvHeader="Driver Name"
							tdStyle={{ whiteSpace: 'normal' }}
							sortFunc={driverNameSort()}
						>
							{renderCustomHeader(6, 'Driver')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="7%"
							dataFormat={tripFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
							export={false}
						>
							{renderCustomHeader(7, 'Trip')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="15%"
							dataFormat={actionsFormatter}
							dataField="custom_event_type"
							csvFormat={(cell) => cell || ''}
							csvHeader="Note"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							<div className="custom-table-header-row">
								<div className="custom-table-header-title">Actions</div>
							</div>
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</main>

			<EventNoteModal
				show={state.showEventNote}
				event={state.selectedEvent}
				company_name={company.name || user.company_name}
				partner_name={company.partner || user.partner_company_name}
				onHide={onCloseEventNote}
				onSave={(note) => { getFleetEvents(); onCloseEventNote(note); }}
			/>

			{state.showModalRequestVideo
				&& (
					<ModalDelete
						title={'Request Video'}
						content={`Clicking the Request button will remove event from quarantine and request video for the event. The event will now show up in the Daily Events page.`}
						closeModal={() => setState({ ...state, showModalRequestVideo: false })}
						deleteModal={requestEventVideo}
						deleteBtnName="Request"
						styleView="primary"
					/>
				)}

			{state.showModalDelete
				&& (
					<ModalDelete
						title={'Delete Video'}
						content={`Are you sure you want to delete this event video?`}
						closeModal={() => setState({ ...state, showModalDelete: false })}
						deleteModal={deleteEventVideo}
					/>
				)}

		</div>
	)
}

EventClipsQuarantine.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = ({ user, company }) => ({
	user: user.user,
	company: company.company,
})

const mapDispatchToProps = dispatch => ({
	setDashboardRedirect: (state) => {
		dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state })
	},
	toggleLoader: (show) => {
		dispatch({ type: 'TOGGLE_LOADER', payload: show });
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(EventClipsQuarantine);
