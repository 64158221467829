import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import LiveStreams from '../../dump/menuComponents/LiveStreams';

const LiveStreamsContainer = () => {
    return;
}

export default connect()(LiveStreams);
