import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'

import ModalDelete from './ModalDelete'
import { vinValidation, imeiValidation } from '../../helpers/helperVehicle'
import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'

const ModalDeviceReplace = (props) => {
	const { onHide, device, updateDeviceData, toggleLoader } = props;

	const [replace_type, setReplace_type] = useState('camera');
	const [imei, setImei] = useState('');
	const [vin, setVin] = useState('');
	const [tag_number, setTag_number] = useState('');
	const [vehicle_name, setVehicle_name] = useState('');
	const [errMessage, setErrMessage] = useState({
		err_imei: '',
		err_vin: '',
		err_tag_number: '',
		err_vehicle_name: '',
	});
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [upd, setUpd] = useState(false);

	const onChangeField = (e) => {
		const { target } = e
		const { name } = target
		const value = target.type === 'checkbox' ? target.checked : target.value

		if (name === "replace_type") setReplace_type(value);
		if (name === "imei") setImei(value);
		if (name === "vin") setVin(value);
		if (name === "tag_number") setTag_number(value);
		if (name === "vehicle_name") setVehicle_name(value);
	}

	const checkForm = (params) => {
		let error = false
		let errMessageUpdate = errMessage;
		for (let i in params) {
			if (!params[i]) {
				error = true
				errMessageUpdate['err_' + i] = 'Enter a field value'
			}
			else if (errMessageUpdate['err_' + i]) {
				errMessageUpdate['err_' + i] = '';
			}
		}

		if (params.imei !== undefined && !errMessageUpdate.err_imei) {
			if (!imeiValidation(imei)) {
				error = true
				errMessageUpdate.err_imei = 'Incorrect IMEI entered, please check and try again.'
			}

			if (!(/^(XC4RECO|DV401)/.test(imei) || /^\D*(?:\d\D*){15}$/.test(imei))) {
				error = true
				errMessageUpdate.err_imei = 'The IMEI must exactly 15 numbers. Please try again.'
			}
		}

		if (params.vin !== undefined && !errMessageUpdate.err_vin) {
			if (vin && !vinValidation(vin)) {
				error = true
				errMessageUpdate.err_vin = 'Incorrect VIN entered, please check and try again.'
			}
		}

		setErrMessage(errMessageUpdate);
		return error
	}

	const onReplaceClick = () => {
		const params = {
			replace_type,
		}
		if (replace_type === 'camera') {
			params.imei = imei
		}
		else {
			params.vin = vin
			params.tag_number = tag_number
			params.vehicle_name = vehicle_name
		}

		if (!checkForm(params)) {
			setShowConfirmModal(true)
		}
		setUpd(!upd);
	}

	const onConfirmReplaceClick = () => {
		const params = {
			device_id: device.device_id,
			replace_type,
		}
		if (replace_type === 'camera') {
			params.serial_number = imei
		}
		else {
			params.vin = vin
			params.tag_number = tag_number
			params.vehicle_name = vehicle_name
		}

		toggleLoader(true);
		apiCall('POST', '/devices/{device_id}/replace', params)
			.then((res, err) => {
				// console.log('onUploadVehicles res: ', res, err)
				setShowConfirmModal(false)
				updateDeviceData()
				toggleLoader(false);
				toastr.success('', 'Successfully updated vehicle')
				onHide()
			})
			.catch((error) => {
				console.log('onUploadVehicles error: ', error.response, error)
				toggleLoader(false);
				setShowConfirmModal(false)
				const { status, data } = error.response
				if (status >= 400 && status < 500) {
					toastr.error(data.response.error);
				} else {
					toastr.error('Unable to upload vehicles. Please try again later.');
				}
			})
	}

	const { err_imei, err_vin, err_tag_number, err_vehicle_name } = errMessage;

	return (
		<Modal
			show
			onHide={onHide}
			aria-labelledby="contained-modal-title-lg"
			className="modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{`Replace for ${device.vehicle_name || device.device_id}`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<p className="modal-text">Select the type of replace.</p>
					<div>
						<div className="form-check" style={{ paddingLeft: '0px' }}>
							<input
								type="radio"
								id="radios1"
								key="Same Vehicle, Different Camera"
								name="replace_type"
								value="camera"
								checked={replace_type === "camera"}
								onChange={onChangeField}
								style={{ marginRight: '5px' }}
							/>
							<label className="form-check-label" for="radios1">
								Same Vehicle, Different Camera
							</label>
						</div>
						<div className="form-check" style={{ paddingLeft: '0px' }}>
							<input
								type="radio"
								id="radios2"
								key="Same Camera, Different Vehicle"
								name="replace_type"
								value="vehicle"
								checked={replace_type === 'vehicle'}
								onChange={onChangeField}
								style={{ marginRight: '5px' }}
							/>
							<label className="form-check-label" for="radios2">
								Same Camera, Different Vehicle
							</label>
						</div>
					</div>

					<hr />

					{replace_type === "camera"
						? (
							<div className={`${err_imei && 'has-error'}`}>
								<label>Enter IMEI of new camera</label>
								<input
									type="text"
									name="imei"
									className="vehicle-search"
									placeholder="IMEI..."
									value={imei || ''}
									onChange={onChangeField}
								/>
								{err_imei && <span className="help-block">{err_imei}</span>}
							</div>
						) : (
							<div>
								<div className={`form-group${err_vin && ' has-error'}`}>
									<label>Enter new vehicle VIN</label>
									<input
										type="text"
										name="vin"
										className="vehicle-search"
										placeholder="VIN..."
										value={vin || ''}
										onChange={onChangeField}
									/>
									{err_vin && <span className="help-block">{err_vin}</span>}
								</div>
								<div className={`form-group${err_tag_number && ' has-error'}`}>
									<label>Enter new vehicle tag/license plate number</label>
									<input
										type="text"
										name="tag_number"
										className="vehicle-search"
										placeholder="Tag/Plate Number..."
										value={tag_number || ''}
										onChange={onChangeField}
									/>
									{err_tag_number && <span className="help-block">{err_tag_number}</span>}
								</div>
								<div className={`${err_vehicle_name && 'has-error'}`}>
									<label>Enter new vehicle name</label>
									<input
										type="text"
										name="vehicle_name"
										className="vehicle-search"
										placeholder="Name..."
										value={vehicle_name || ''}
										onChange={onChangeField}
									/>
									{err_vehicle_name && <span className="help-block">{err_vehicle_name}</span>}
								</div>
							</div>
						)}
				</div>
			</Modal.Body>
			<Modal.Footer className="default-modal-footer modal-footer">
				<CustomButton
					variant="primary"
					onClick={onReplaceClick}
				>
					Replace
				</CustomButton>
			</Modal.Footer>

			{showConfirmModal
				&& (
					<ModalDelete
						title="Are you sure ?"
						content={`${replace_type === 'camera' ? 'By selecting APPLY, the old camera will no longer connect to RoscoLive.' : 'By selecting APPLY, these changes will be made.'}`}
						deleteBtnName="Apply"
						backdropClassName="upper"
						showClose
						closeModal={() => setShowConfirmModal(false)}
						deleteModal={onConfirmReplaceClick}
					/>
				)}
		</Modal>
	);
}

ModalDeviceReplace.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	updateDeviceData: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalDeviceReplace);
