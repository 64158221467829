import React from 'react'
import PropTypes from 'prop-types'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import './CustomOverlayTrigger.sass'

const CustomOverlayTrigger = (props) => {
    const { children, title, body, closeBtn, trigger, placement, prefix, bodyPrefix } = props

	const closePopover = (e) => {
		e.preventDefault();
        document.body.click()
		// document.getElementById("advanced-player-mp4videos").style.display = "none";
	}

    const popover = (
		<Popover id="popover-trigger-status-definitions" bsPrefix={`popover custom-overlay-trigger ${prefix ? prefix : ''}`}>
			<Popover.Header as="h3">
                {title}
                {closeBtn && 
                    <button type="button" variant="close" className="close-btn" onClick={closePopover}>
                        <span aria-hidden="true">×</span>
                    </button>
                }
            </Popover.Header>
			<Popover.Body className={`${bodyPrefix ? bodyPrefix : ''}`}>
                <span dangerouslySetInnerHTML={{ __html: body }} />
			</Popover.Body>
		</Popover>
	)

	// We have to use the ["hover", "hover"] to fix displaying the following bootstrap warning in console:
	// "warning.js:34 Warning: [react-bootstrap] Specifying only the `"hover"` trigger limits the visibility of the overlay to just mouse users. Consider also including the `"focus"` trigger so that touch and keyboard only users can see the overlay as well."
	// This problem described here: https://github.com/react-bootstrap/react-bootstrap/issues/5027
	// https://stackoverflow.com/questions/73169897/bootstrap-5-how-can-i-prevent-tooltip-from-displaying-after-a-modal-closes
	return (
		<OverlayTrigger
            // show={true}
			// trigger={trigger || "hover"}
			trigger={trigger || ["hover", "hover"]}			
			rootClose
			placement={placement || 'top'}
			overlay={popover}
		>
			{children}
		</OverlayTrigger>
	)
};

CustomOverlayTrigger.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
}

export default CustomOverlayTrigger
