const defaultState = {
	notifications: [], // list of notifications from user_notifications table for current user we receiver via /user/notifications API
	sessionNotifications: [], // FWSD-7345, global/session notifications generated for current user while exist his session: when he start wakeup for sleeped user FWSD-7345
	sessionDevicesIds: [], // FWSD-7345, list of devices ids used for /devices/general_status API to check if they woke up and can display LiveStream
	selectedObj: {},
	showGlobalPopup: false,
	globalPopupData: {}, // some data required for global/session notifications, {vehicle_name: 'Truck A'},
}

const notification = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_USER_NOTIFICATIONS': {
			// return { ...state, ...{ notifications: action.payload.concat(state.sessionNotifications) } }
			return { ...state, ...{ notifications: action.payload } }
		}
		case 'SET_OBJECT_NOTIFICATIONS': {
			return { ...state, ...{ selectedObj: action.payload } }
		}
		case 'SET_SESSION_NOTIFICATIONS': {
			return { ...state, ...{ sessionNotifications: action.payload } }
		}
		case 'SET_SESSION_DEVICES_IDS': {
			return { ...state, ...{ sessionDevicesIds: action.payload } }
		}
		case 'TOGGLE_GLOBAL_POPUP': {
			return { ...state, ...{ showGlobalPopup: action.payload } }
		}
		case 'SET_GLOBAL_POPUP_DATA': {
			return { ...state, ...{ globalPopupData: action.payload } }
		}
		default:
			return state
	}
}

export default notification
