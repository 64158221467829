import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'

import ModalInstallReport from '../modals/ModalInstallReport'
import ModalDeviceReplace from '../modals/ModalDeviceReplace'
import DeviceArchiveHistory from '../../../pages/vehicles/DeviceArchiveHistory'

import { isDv6, isInternalUser, isInternalUserWithPartner } from '../../helpers/constants'
import { formatTimestamp, formatChannelStatusCSV, formatDataUsage } from '../../helpers/tablesFuncHelpers'
import { apiCallGet } from '../../../action/RouterActions'

import CustomButton from '../../CustomButton'

const EditAssetDeviceInfo = (props) => {

	const { device, user, company, showModalInstallReport, setPropStates, webfleetDeviceId, toggleLoader } = props;
	const userRole = user.roles[0];

	const [showModalDeviceArchiveHistory, setShowModalDeviceArchiveHistory] = useState(false);
	const [showModalDeviceReplace, setShowModalDeviceReplace] = useState(false);

	const onRebootDeviceClick = () => {
		toggleLoader(true);
		apiCallGet('/device/{device_id}/restart', { device_id: device.device_id })
			.then((res, err) => {
				toggleLoader(false);
				console.log('!onRebootDeviceClick res, err: ', res, err)

				setPropStates({ data: { ...device, ads_online: false } })
				toastr.success('Success', 'Reboot has been sent')
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!onRebootDeviceClick error:', error.response, error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			})
	}

	const { device_id, vin_number, serial_number, modem, activated_timestamp, ads_online, last_disconnected_ts, installed_id, firmware, channel_status, data_usage } = device;

	return (
		<section>
			<h3 className="category-title">
				<span className="mr-30">Device Info</span>
				{/* <CustomButton
					variant="link"
					onClick={() => setShowModalDeviceArchiveHistory(true)}
				>
					View History
				</CustomButton> */}
			</h3>
			<div className="two-column-grid with-margin device-grid param-grid">
				<div className="param-wrapper">
					<div className="device-param">
						<strong>Device ID:</strong> {device_id}
					</div>
					{vin_number && (
						<div className="device-param">
							<strong>VIN:</strong> {vin_number}
						</div>
					)}
					{serial_number && (
						<div className="device-param">
							<strong>{isDv6(device) ? 'IMEI: ' : 'Serial Number: '}</strong> {serial_number}
						</div>
					)}
					{modem && modem.simcard_iccid && (
						<div className="device-param">
							<strong>ICCID:</strong> {modem.simcard_iccid}
						</div>
					)}
					<div className="device-param">
						<strong>First Connection:</strong> {activated_timestamp ? formatTimestamp(activated_timestamp, true) : 'Not Available'}
					</div>
					<div className="device-param">
						<strong>Status:</strong> {ads_online ? <span className="online-label">Online</span> : `Offline since ${formatTimestamp(last_disconnected_ts, true)}`}
					</div>
					{!!webfleetDeviceId &&
						<div className="device-param">
							<strong>Channel Status:</strong> {formatChannelStatusCSV(channel_status, device)}
						</div>
					}
					{!!webfleetDeviceId &&
						<div className="device-param">
							<strong>Data Usage:</strong> {formatDataUsage(data_usage, device, user.company_data)}
						</div>
					}
					{(isInternalUser(userRole) && firmware) &&
						<div className="device-param">
							<strong>Firmware:</strong> {firmware}
						</div>
					}
					{isInternalUser(userRole) &&
						<div className="device-param">
							<strong>Fleet Token:</strong> {company.token || 'Not Available'}
						</div>
					}
					{installed_id && (
						<div className="device-param">
							<CustomButton
								variant="primary-outline" onClick={() => setPropStates({ showModalInstallReport: true })}>
								Install Report
							</CustomButton>
						</div>
					)}
					{/* {isInternalUserWithPartner(userRole) &&
						<div className="button-wrapper">
							<CustomButton variant="primary-outline" prefix={`${!ads_online && 'disabled'}`} disabled={!ads_online} onClick={onRebootDeviceClick}>
								Reboot Device
							</CustomButton>

							<CustomButton variant="primary-outline" onClick={() => setShowModalDeviceReplace(true)}>
								Replace
							</CustomButton>
						</div>
					} */}
				</div>

				{device_id &&
					<div className={`device-type ${isDv6(device) ? 'dv6' : 'dv4'}`} />
				}
			</div>

			{showModalInstallReport
				&& (
					<ModalInstallReport
						device={device}
						onHide={() => setPropStates({ showModalInstallReport: false })}
					/>
				)}

			{showModalDeviceReplace
				&& (
					<ModalDeviceReplace
						device={device}
						onHide={() => setShowModalDeviceReplace(false)}
					/>
				)}

			{showModalDeviceArchiveHistory
				&& (
					<DeviceArchiveHistory
						device={device}
						onHide={() => setShowModalDeviceArchiveHistory(false)}
					/>
				)}
		</section>
	)
}

EditAssetDeviceInfo.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	showModalInstallReport: PropTypes.bool.isRequired,
	setPropStates: PropTypes.func.isRequired,
}

const mapStateToProps = ({ user, company, webfleetData }) => ({
	user: user.user,
	company: company.company,
	webfleetDeviceId: webfleetData.deviceId,
})

export default connect(
	mapStateToProps,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(EditAssetDeviceInfo);
