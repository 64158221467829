import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

export function getDriverScoresByTimeframe(data) {
	const { start_ts, end_ts, company_id } = data;
	const url = `${API_URL}/driver/scoring?start_ts=${start_ts}&end_ts=${end_ts}&company_id=${company_id}`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getDriverEventWeights(company_id) {
	const url = `${API_URL}/driver/scoring/weights?company_id=${company_id}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function updateEventWeights(data) {
	const url = `${API_URL}/driver/scoring/weights`

	const form = new FormData();
	form.append('company_id', data.company_id)
	form.append('weights', data.weights)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function getDriverCoachingData(data) {
	const url = `${API_URL}/driver/coaching?start_date=${data.start_date}&end_date=${data.end_date}&company_id=${data.company_id}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function createCoachingSession(data) {
	let url = `${API_URL}/driver/coaching`;

	const form = new FormData();
	for (let session of Object.entries(data)) {
		session[1] && form.append(session[0], session[1])
	}

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	};
	
	return axios(settings);
}

export function completeCoachingSesion(id) {
	const url = `${API_URL}/driver/coaching/${id}`

	let form = new FormData()

	form.append('status', 'complete')
	
	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings)
}

export function deleteCoachingSession(id) {
	let url = `${API_URL}/driver/coaching/${id}`;

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function sendDriverQRCode(driver_id) {
	const url = `${API_URL}/driver/${driver_id}/qrcode`
	
	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings)
}