import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL


export function getCompanyWifipoints (data) {
    console.log('!getCompanyWifipoints action data:', data);

    let url;
    switch(data.user_role) {
        case 'system_admin':
            url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/wifipoints`;
            break;
        case 'customer_service':
            url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/wifipoints`;
            break;
    }

    let settings = {
        "async": true,
        "crossDomain": true,
        "url": url,
        "method": "GET",
        // "headers": {Pragma: 'no-cache'},
        "dataType": 'jsonp',
        "withCredentials": true
    }

    return axios(settings);
}

export function saveCompanyWifipoints(data){
    console.log('!saveCompanyWifipoints action data:', data);

    let url;
    switch(data.user_role) {
        case 'system_admin':
            url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/wifipoints`;
            break;  
        case 'customer_service':
            url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/wifipoints`;
            break;
    }
    
    let form = new FormData();
    form.append("points", JSON.stringify(data.points));

    let settings = {
        "async": true,
        "crossDomain": true,
        "url": url,
        "method": "PUT",
        // "headers": {: {},
        "processData": false,
        "contentType": false,
        "mimeType": "multipart/form-data",
        "data": form,
        "dataType": 'jsonp',
        "withCredentials": true
    };   

    return axios(settings);
}

export function deleteCompanyWifiPoint (data) {
    console.log('!deleteCompanyWifiPoint action data:', data);

    let url;
    switch(data.user_role) {
        case 'system_admin':
            url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/wifipoints/${data.wifipoint_id}`;
            break;
        case 'customer_service':
            url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/wifipoints/${data.wifipoint_id}`;
            break;
    }

    let settings = {
        "async": true,
        "crossDomain": true,
        "url": url,
        "method": "DELETE",
        // "headers": {},
        "withCredentials": true
    }

    return axios(settings);
}