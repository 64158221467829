// FWSD-3520
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Polyline } from 'react-google-maps'
import { connect } from 'react-redux'

import TripSpeedEventPopup from '../TrackTrace/TripSpeedEventPopup'
import { getSpeedEventsFiltersColors } from '../../../component/helpers/helperTripsGrid'

const TripSpeedEvents = (props) => {

	const { tripsSpeedEvents, tripsSpeedEventsFilters, device_id } = props;

	const [showSpeedEventPopup, setShowSpeedEventPopup] = useState(false);
	const [hoveredEvent, setHoveredEvent] = useState(null);
	const [hoveredLatLng, setHoveredLatLng] = useState(null);

	const handleMouseOver = (e, speedEvent) => {
		// console.log('handleMouseOver e.latLng: ', e.latLng)
		setHoveredEvent(speedEvent);
		setHoveredLatLng(e.latLng);
		setShowSpeedEventPopup(true);
	}

	const lineSymbol = {
		path: 'M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z',
		fillColor: '#fff',
		fillOpacity: 0.6,
		strokeWeight: 0.5,
		scale: 0.4,
		anchor: new google.maps.Point(12, 0),
	}

	const polylineOptions = {
		zIndex: 10,
		strokeColor: '#4268A8',
		strokeOpacity: 1.0,
		strokeWeight: 5,
		icons: [
			{
				icon: lineSymbol,
				repeat: '30px',
			},
		],
	}

	return (
		<div>
			{tripsSpeedEvents && tripsSpeedEvents.length > 0 && (
				tripsSpeedEvents.map((item, index) => {
					// console.log('tripsSpeedEvents item: ', item)
					if (tripsSpeedEventsFilters[item.event_type]) {
						return (
							<Polyline
								key={item.event_type + item.start_ts + item.end_ts}
								options={{ ...polylineOptions, strokeColor: getSpeedEventsFiltersColors[item.event_type] }}
								path={item.positions}
								onMouseOver={e => handleMouseOver(e, item)}
								onMouseMove={e => handleMouseOver(e, item)}
								onMouseOut={() => setShowSpeedEventPopup(false)}
							// show={props.showroute}
							// onRightClick={() => console.log("you clicked")}
							/>
						)
					}
				})
			)}

			{showSpeedEventPopup && (
				<TripSpeedEventPopup
					event={hoveredEvent}
					device_id={device_id}
					position={hoveredLatLng}
				/>
			)}
		</div>
	)
}

TripSpeedEvents.propTypes = {
	device_id: PropTypes.string,
	tripsSpeedEvents: PropTypes.arrayOf(PropTypes.any).isRequired,
	tripsSpeedEventsFilters: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = ({ dashboardData }) => ({
	tripsSpeedEvents: dashboardData.tripsSpeedEvents,
	tripsSpeedEventsFilters: dashboardData.tripsSpeedEventsFilters
});

export default connect(mapStateToProps)(TripSpeedEvents);
