import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';

const LiveStreams = (props) => {
    return (
        <div>LiveStreams</div>
    )
};

export default LiveStreams;
