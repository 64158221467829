import React, { Component, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'

import { renderCustomHeader, tableAdjustableColOptions } from '../../component/helpers/table'

import { upgradeModemFirmware, upgradeModemFirmwareCancel } from '../../action/FleetAdminActions'

import '../../component/dump/menuComponents/menuComponents.sass'
import CustomButton from '../../component/CustomButton'

const FleetAdminModemContainerBSTable = (props) => {

	const { user_role, data, toggleLoader } = props;
	const [expandDevices, setExpandDevices] = useState([]);

	useEffect(() => {
		// console.log('!componentDidMount FleetAdminModemContainerBSTable', this.props.data.expand);

		const expandDevices = data.expand.map((device) => {
			let modem = {}
			if (!_.isEmpty(device.modem)) {
				modem = device.modem
				device.modem_type = 'modem'
			} else if (!_.isEmpty(device.router)) {
				modem = device.router
				device.modem_type = 'router'
			}

			for (var key in modem) {
				device[key] = modem[key]
			}

			return device;
		})

		setExpandDevices(expandDevices);
	}, []);

	useEffect(() => {
		const expandDevices = data.expand.map((device) => {
			let modem = {}
			if (!_.isEmpty(device.modem)) {
				modem = device.modem
				device.modem_type = 'modem'
			} else if (!_.isEmpty(device.router)) {
				modem = device.router
				device.modem_type = 'router'
			}

			for (var key in modem) {
				device[key] = modem[key]
			}

			return device;
		})

		setExpandDevices(expandDevices);

		return () => {
			setExpandDevices([]);
		}
	}, [data]);

	const activeFormatter = cell => (
		<div style={{ textAlign: 'center' }}>
			{cell ? <p className="active-dots" /> : <p className="non-active-dots" />}
		</div>
	);

	const vehicleFormatter = (cell, row) => row.vehicle_name || row.device_id;

	const upgradeFirmwareCancel = (deviceId) => {
		toggleLoader(true);
		upgradeModemFirmwareCancel(deviceId)
			.then((res, err) => {
				console.log('!upgradeModemFirmwareCancel res: ', res.data.response, err)
				const { response } = res.data
				let expand

				for (let key in response) {
					expand = expandDevices.map((item) => {
						if (item.device_id == key) {
							item.modem.firmware_status = response[key]
							item.firmware_status = response[key]
						}
						return item
					})
				}
				setExpandDevices(expand);

				toggleLoader(false);
				toastr.success('Success', 'Modem Firmware upgrade has been canceled.')
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!upgradeModemFirmwareCancel error', error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	const applyFirmware = (deviceId) => {
		toggleLoader(true);

		upgradeModemFirmware(deviceId)
			.then((res, err) => {
				console.log('!upgradeModemFirmware res: ', res.data.response, err)
				const { response } = res.data
				let expand

				for (let key in response) {
					expand = expandDevices.map((item) => {
						if (item.device_id == key) {
							item.modem.firmware_status = response[key];
							item.firmware_status = response[key];
						}

						return item
					})
				}
				setExpandDevices(expand);

				toggleLoader(false);
				if (res.status === 200) {
					toastr.success('Success', 'Firmware upgrade has been sent to selected devices.');
				}
				if (err) {
					console.log('!applyFirmware err', err);
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!upgradeModemFirmware error', error.response)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	};

	const cancelFirmware = (row) => {
		upgradeFirmwareCancel(row.device_id)
	};

	const upgradeFirmware = (row) => {
		applyFirmware(row.device_id)
	};

	const upgradeFormatter = (cell, row, extra) => {
		let search = -1
		if (cell) {
			search = cell.search(/pending/i)
		}
		const getAction = () => {
			if (row.modem_type) {
				if (row.modem_type === 'router') {
					return (
						<a href="javascript:void(0)"
							onClick={() => props.setConfigParams({ routerManagementShow: true, activeDevice: row })}
							className="remote-management"
						>
							<img src="/images/remote_management.png" />
							Remote Management
						</a>
					)
				}
				if (row.modem_type === 'modem' && search > -1) {
					return (
						<div className="upgrade-column">
							<CustomButton
								size="xs"
								variant="delete"
								onClick={() => cancelFirmware(row)}
							>
								Cancel
							</CustomButton>
						</div>
					)
				}
				if (row.modem_type === 'modem' && search === -1) {
					return (
						<div className="upgrade-column">
							<CustomButton
								size="xs"
								variant="primary"
								onClick={() => upgradeFirmware(row)}
							>
								Upgrade
							</CustomButton>
						</div>
					)
				}
			}
			return '';
		}

		return (
			<div style={{ textAlign: 'center' }}>
				{getAction()}
			</div>
		)
	};

	if (data) {
		return (
			<div className="firmware-expanded-wrapper">
				<BootstrapTable
					data={expandDevices}
					{...tableAdjustableColOptions}
				>
					{/* //we can not use hidden column and renderCustomHeader function in the same time
							<TableHeaderColumn dataField="device_id" isKey hidden>ID</TableHeaderColumn>
						*/}

					<TableHeaderColumn
						isKey
						width="15%"
						dataField="vehicle_name"
						dataSort
						dataFormat={vehicleFormatter}
					>
						{renderCustomHeader(0, 'Vehicle Name')}
					</TableHeaderColumn>

					<TableHeaderColumn
						width="5%"
						dataField="active"
						dataSort
						dataFormat={activeFormatter}
						editable={false}
					>
						{renderCustomHeader(1, 'Enable')}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%" dataField="firmware" dataSort>
						{renderCustomHeader(2, 'Current Firmware')}
					</TableHeaderColumn>

					{/* <TableHeaderColumn
							width="15%"
							dataField='imei'
							formatExtraData="modem.imei"
							dataFormat={ trafficFormatter.bind(this)}
							dataSort={true}
						>
							{renderCustomHeader(3, 'IMEI')}
						</TableHeaderColumn>     */}
					{/* <TableHeaderColumn
							width="15%"
							dataField="imei"
							formatExtraData="imei"
							dataSort={true}
							dataFormat={ modemFormatter }
						>
							{renderCustomHeader(3, 'IMEI')}
						</TableHeaderColumn> */}
					<TableHeaderColumn
						width="15%"
						dataField="imei"
						dataSort
					>
						{renderCustomHeader(3, 'IMEI')}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%" dataField="manufacturer" dataSort>
						{renderCustomHeader(4, 'Manufacturer')}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%" dataField="model" dataSort>
						{renderCustomHeader(5, 'Model')}
					</TableHeaderColumn>

					<TableHeaderColumn width="10%" dataField="version" dataSort>
						{renderCustomHeader(6, 'Version')}
					</TableHeaderColumn>

					<TableHeaderColumn
						width="10%"
						dataField="firmware_status"
						dataSort={false}
						dataFormat={upgradeFormatter}
					>
						{renderCustomHeader(7, 'Actions')}
					</TableHeaderColumn>

					<TableHeaderColumn width="15%" dataField="firmware_status" dataSort>
						Status
					</TableHeaderColumn>
				</BootstrapTable>
			</div>
		)
	}

	return (<p>?</p>)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(FleetAdminModemContainerBSTable);
