import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import Toggle from 'react-toggle'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { browserHistory } from 'react-router'

import { deleteDeviceCustomer } from '../../../action/DeviceActions'
import { getCompanyById } from '../../../action/CompaniesActions'
import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'

const VehicleDeleteModal = (props) => {

	const { onHide, device, userRole, toggleLoader } = props;

	const [databaseDelete, setDatabaseDelete] = useState(false);
	const [company, setCompany] = useState(null);
	const [showDeleteModal, setShowDeleteModal] = useState();

	useEffect(() => {
		if (userRole === 'system_admin') {
			getCompany()
		}
	}, [])

	const getCompany = () => {
		toggleLoader(true);
		getCompanyById(userRole, device.company_id)
			.then((res, err) => {
				console.log('!getCompany res: ', res)
				toggleLoader(false);
				const { company } = res.data.response
				setCompany(company)
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getCompany error: ', error.response, error)

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const handleDeleteClick = () => {
		const params = {
			device_id: device.device_id,
			user_role: userRole
		};

		let funcName = null
		if (databaseDelete) {
			funcName = apiCall('DELETE', '/devices/database/{device_id}', params)
		} else {
			funcName = deleteDeviceCustomer(params)
		}

		toggleLoader(true);
		funcName
			.then((res) => {
				toggleLoader(false);
				browserHistory.push('/managevehicles')
				toastr.success('Vehicle deleted successfully.');
			})
			.catch((error) => {
				toggleLoader(false);
				console.log(error);

				setShowDeleteModal(false)

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	console.log('PROPS VehicleDeleteModal: ', props)

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="has-error"
			backdropClassName="custom-modal-backdrop"
			className="modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title className="delete-title">Delete Vehicle</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal-text">
					{`Are you sure you want to delete the vehicle ${device.vehicle_name || device.device_id} ?`}
				</div>
			</Modal.Body>
			<Modal.Footer className="default-modal-footer modal-footer">
				{userRole === 'system_admin' && company && !company.asset_deletion_protection
					&& (
						<label>
							<span className="toggle-left-title">Delete from DB</span>
							<Toggle
								name="databaseDelete"
								checked={databaseDelete}
								onChange={e => setDatabaseDelete(e.target.checked)}
							/>
						</label>
					)}
				<CustomButton variant="delete" onClick={handleDeleteClick}>DELETE</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

VehicleDeleteModal.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	userRole: PropTypes.string.isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(VehicleDeleteModal);
