import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CheckboxGroup, Checkbox } from 'react-checkbox-group';
import Select from 'react-select';
import { connect } from 'react-redux';

import constants from '../../helpers/helperEventAlertsSearch';
import { isInternalUser } from '../../helpers/constants';

const DeviceNode = (props) => {
	const { deviceId, vehicleName } = props;

	return (
		<span className="checkbox" key={deviceId}>
			<label>
				<Checkbox value={deviceId} />
				{vehicleName || deviceId}
			</label>
		</span>
	)
};

const FleetSearch = (props) => {

	const { user, devices, getSelectedDevices } = props;

	let selectAllInitial, selectedDevicesIDInitial, selectedAlertTypeInitial, selectedEventTypeInitial;

	if (props.alertsearch && (['tt_link', 'storage_link'].indexOf(props.type) > -1)) {
		selectAllInitial = false;
		selectedDevicesIDInitial = props.alertsearch.selectedDevices || [];
		selectedAlertTypeInitial = props.alertsearch.selectedAlertType || [];
		selectedEventTypeInitial = props.alertsearch.selectedEventType || [];
	} else {
		selectAllInitial = false;
		selectedDevicesIDInitial = [];
		selectedAlertTypeInitial = [];
		selectedEventTypeInitial = [];
	};

	const [selectAllState, setSelectAll] = useState(selectAllInitial);
	const [selectedDevices, setSelectedDevices] = useState(selectedDevicesIDInitial);
	const [selectedAlertType, setSelectedAlertType] = useState(selectedAlertTypeInitial);
	const [selectedEventType, setSelectedEventType] = useState(selectedEventTypeInitial);

	const selectCheckboxes = () => {
		if (selectAllState) {
			unselectAll();
		} else {
			selectAll();
		}

		setSelectAll(!selectAllState);
	};

	const selectAll = () => {
		const selectedDevices = [];
		devices.forEach(device => selectedDevices.push(device.device_id));

		setSelectedDevices(selectedDevices);

		getSelectedDevices({
			selectedDevices,
			selectedAlertType: selectedAlertType,
			selectedEventType: selectedEventType
		});
	};

	const unselectAll = () => {
		setSelectedDevices([]);

		getSelectedDevices({
			selectedDevices: [],
			selectedAlertType: selectedAlertType,
			selectedEventType: selectedEventType
		});
	};

	const selectedChange = (devicesId) => {
		setSelectedDevices(devicesId);

		getSelectedDevices({
			selectedDevices: devicesId,
			selectedAlertType: selectedAlertType,
			selectedEventType: selectedEventType
		});
	};

	const selectedAlerts = (alert) => {
		setSelectedAlertType(alert);

		getSelectedDevices({
			selectedDevices: selectedDevices,
			selectedEventType: selectedEventType,
			selectedAlertType: alert
		});
	};

	const selectedEvents = (event) => {
		setSelectedEventType(event);

		getSelectedDevices({
			selectedDevices: selectedDevices,
			selectedEventType: event,
			selectedAlertType: selectedAlertType
		});
	};

	const styleBottom = {
		marginBottom: '0',
	};
	const styleBottom6 = {
		marginBottom: '6px',
	};

	const alertsList = [];
	// FWSD-4420
	const userRole = user.roles[0];
	let alertcheckboxesFiltered = constants.alertcheckboxes;
	if (!isInternalUser(userRole)) {
		alertcheckboxesFiltered = constants.alertcheckboxes.filter(({ id }) => id !== 'hdderror')
	};
	if (alertcheckboxesFiltered) {
		alertcheckboxesFiltered.forEach(alert => alertsList.push({ value: alert.id, label: alert.title }));
	};

	const eventsList = [...constants.eventcheckboxes, ...constants.dmscheckboxes, ...constants.adascheckboxes].map(event => ({ value: event.id, label: event.title }))

	return (
		<div>
			<div className="asset-alerts-list fleet-search">
				<label className='alerts-label'>Vehicles</label>
				<label onChange={selectCheckboxes}>
					<input type="checkbox" name="select_all" value="on" style={{ margin: '0px 5px', height: '15px', width: '15px', verticalAlign: 'text-top' }} />
					Select All
				</label>
				<CheckboxGroup
					name="alerts"
					onChange={selectedChange}
					value={selectedDevices}
				>
					{
						devices.map((item, index) => (
							<DeviceNode
								deviceId={item.device_id}
								vehicleName={item.vehicle_name}
								key={item.device_id}
							/>
						))
					}
				</CheckboxGroup>
			</div>
			<div className="row" style={styleBottom6}>
				<div className="form-group col-md-6">
					<label htmlFor="user-groups" className="control-label" style={styleBottom}>
						Alert Type:
					</label>
					<Select
						// multi
						isMulti
						name="group"
						options={alertsList}
						id="user-groups"
						onChange={selectedAlerts}
						placeholder="Select alert"
						value={selectedAlertType}
						noOptionsMessage={() => null} 
					/>
				</div>
				<div className="form-group col-md-6">
					<label htmlFor="user-groups" className="control-label" style={styleBottom}>
						Event Type:
					</label>
					<Select
						// multi
						isMulti
						name="group"
						options={eventsList}
						id="user-groups"
						onChange={selectedEvents}
						placeholder="Select event"
						value={selectedEventType}
						noOptionsMessage={() => null} 
					/>
				</div>
			</div>
		</div>
	)
};

// const DeviceNode = (props) => {
//        const {device_id, vehicle_name} = props;
//        return (
//            <div className="checkbox" key={device_id}>
//                <label>
//                    <Checkbox value={ device_id } />
//                    { vehicle_name || device_id }
//                </label>
//            </div>
//        )
// }

FleetSearch.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	devices: PropTypes.arrayOf(PropTypes.any).isRequired,
	getSelectedDevices: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired
};

const mapStateToProps = ({ user }) => ({
	user: user.user
});

export default connect(mapStateToProps)(FleetSearch);
