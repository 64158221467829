import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

//* You can intercept requests or responses before they are handled by then or catch.
axios.interceptors.response.use(undefined, function(error) {
	// debugger
	// console.log('axios.interceptors1 error: ', error);
	// console.log('axios.interceptors2', error.status);
	// console.log('axios.interceptors3', error.response);
	// console.log('axios.interceptors4', error.message);
	// console.log('axios.interceptors5 location', location);

	if (error.response && error.response.status === 401 && !location.pathname.includes('webfleet/device')) {
		// ipcRenderer.send('response-unauthenticated');
		// return Promise.reject(error);
		location.href = '/login';
	}
	else if (error.message == 'Network Error') {
		error['response'] = {data: {response: {error: 'Check your Internet connection and try again'}}};        
		return Promise.reject(error);
	}
	else {
		// Do something with response error
		return Promise.reject(error);
	}
});

export function getCompanyGroups(userRole, company, getids) {
	console.log('!getCompanyGroups action: ', userRole, company, getids)

	const id = getids || '';

	let url;
	switch (userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${company.partner}/companies/${company.name}/groups${id}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${company.partner}/companies/${company.name}/groups${id}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company.name}/groups${id}`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${company.partner}/companies/${company.name}/groups${id}`;
		break;
	default:
		url = `${API_URL}/groups${id}`;
		break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}


export function addGroup(data, user_role) {
	console.log('!addGroup data: ', data);

	let url;
	switch (user_role) {
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'storage_manager':
	case 'custom_user':
		url = `${API_URL}/groups/${data.group_name}`;
		break;
	case 'system_admin':
		// url = `${API_URL}/system_admin/companies/${state.data.company_name}/groups/${state.data.group_name}`;
		url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}`;
		break;
	default:
		break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
//        "headers": {},
		withCredentials: true
	}

	return axios(settings);
}

export function deleteGroup (data) {
	// console.log('!deleteGroup data', data);
	// console.log('deleteGroup group_name', group_name);
	// console.log('deleteGroup user_role', user_role);
	// console.log('deleteGroup company_name', company_name);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/groups/${group_name}`;
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/groups/${data.group_name}`;
			break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
//        "headers": {},
		withCredentials: true
	}

	return axios(settings);
}

//***DEVICES***
export function getDevicesOfGroup(data) {
	console.log('action getDevicesOfGroup group_name', data);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/groups/${group_name}/devices`;
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/devices`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/devices`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}/devices`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/groups/${data.group_name}/devices`;
			break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true
	}

	return axios(settings);
}



export function addDeviceToGroup(data , device_id){
	// console.log('addDeviceToGroup DATA', data);
	// console.log('addDeviceToGroup group_name', group_name);
	// console.log('addDeviceToGroup device_id', device_id);
	// console.log('addDeviceToGroup user_role', user_role);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/groups/${group_name}/devices/${device_id}`;
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/devices/${device_id}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/devices/${device_id}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}/devices/${device_id}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/groups/${data.group_name}/devices/${device_id}`;
			break;
	}
	var settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
//        "headers": {},
		withCredentials: true
	}

	return axios(settings);
}

export function removeDeviceFromGroup(data , device_id){
	// console.log('removeDeviceFromGroup company_name', company_name);
	// console.log('removeDeviceFromGroup group_name', group_name);
	// console.log('removeDeviceFromGroup device_id', device_id);
	// console.log('removeDeviceFromGroup user_role', user_role);

	let url;

	switch(data.user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/groups/${group_name}/devices/${device_id}`;
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/devices/${device_id}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/devices/${device_id}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}/devices/${device_id}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/groups/${data.group_name}/devices/${device_id}`;
			break;
	}
	var settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
//        "headers": {},
		withCredentials: true
	}

	return axios(settings);
}

//***USERS***
export function getUsersOfGroup(data) {
	// console.log('getUsersOfGroup data', data);
	let url;
	switch(data.user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/groups/${group_name}/users`;
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/users`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/users`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}/users`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/groups/${data.group_name}/users`;
			break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true
	}

	return axios(settings);
}


export function addUserToGroup(data, user_email){
	console.log('addUserToGroup action', data, user_email)

	let url
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/users/${user_email}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/users/${user_email}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}/users/${user_email}`;
			break;
		default:
			url = `${API_URL}/groups/${data.group_name}/users/${user_email}`;
			break;
	}
	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
//        "headers": {},
		withCredentials: true
	}

	return axios(settings);
}


export function removeUserFromGroup(data, user_email){
	console.log('removeUserFromGroup action:', data, user_email)

	let url
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/users/${user_email}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.group_name}/users/${user_email}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.group_name}/users/${user_email}`;
			break;
		default:
			url = `${API_URL}/groups/${data.group_name}/users/${user_email}`;
			break;
	}
	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
//        "headers": {},
		withCredentials: true

	}

	return axios(settings);
}

export function editGroup(data) {
	console.log('editGroup data', data);

	let url;
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.id}`;
			// /system_admin/partner/<string:partnerName>/companies/<string:companyName>/groups/<int:groupID>'
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.id}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.id}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/groups/${data.id}`;
			break;
	}

	const form = new FormData();
	form.append('group_name', data.group_name);

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
//        "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true
	}
	return axios(settings);
	// const request = axios(settings);
	//
	// return request.then(
	//     response => {
	//         console.log('response', response)
	//
	//
	//     }
	// ).catch(
	//     error => console.log('error', error.response)
	// );
}


export function getGroupData(data) {
	// console.log('!getGroupData action', data)

	let url;
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.id}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/groups/${data.id}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/groups/${data.id}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/groups/${data.id}`;
			break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}