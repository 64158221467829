import React, { useState } from 'react'
import { browserHistory, Link } from 'react-router'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'

import { tableAdjustableColExportOptions, renderCustomHeader } from '../../helpers/table'
import ModalDeviceAuditLog from '../modals/ModalDeviceAuditLog'

import '../../dump/menuComponents/menuComponents.sass'
import { constants } from '../../helpers/constants'
import { formatName, formatTimestampUTC, formatTimestamp } from '../../helpers/tablesFuncHelpers'
import changePasswordImg from "../../../../../assets/password_user.png"
import CustomButton from '../../CustomButton'
import moment from 'moment'

const ManageAssetsUsersContainer = (props) => {

	const { user, users, allUsers, company } = props;
	const user_role = user.roles[0];

	const [showModalDeviceAuditLog, setShowModalDeviceAuditLog] = useState(false)
	const [activeUser, setActiveUser] = useState(null)

	const usernameFormatter = (cell, row) => {
		if (row.roles) {
			const rowRole = row.roles[0];
			const userRole = user.roles[0];
			const rowUserName = row.email;
			const userName = user.email;

			if (
				(user_role === 'storage_manager'
					&& (
						(rowRole === 'storage_manager' && rowUserName !== userName)
						|| rowRole === 'customer_service'
						|| rowRole === 'fleet_maintainer'
						|| rowRole === 'system_admin'
						|| rowRole === 'sales_demo'
						|| rowRole === 'provisioning'
						|| rowRole === 'installer'
						|| rowRole === 'fleet_manager'
						|| rowRole === 'custom_user'
					)
				)
				||
				(user_role === 'fleet_manager' &&
					(
						(rowRole === 'fleet_manager' && rowUserName !== userName)
						|| rowRole === 'customer_service'
						|| rowRole === 'fleet_maintainer'
						|| rowRole === 'system_admin'
						|| rowRole === 'sales_demo'
						|| rowRole === 'provisioning'
						|| rowRole === 'installer'
						|| rowRole === 'partner_view'
						|| rowRole === 'partner'
					)
				)
				||
				(user_role === 'partner' &&
					(
						(rowRole === 'partner' && rowUserName !== userName)
						|| rowRole === 'customer_service'
						|| rowRole === 'system_admin'
						|| rowRole === 'sales_demo'
						|| rowRole === 'installer'
						|| rowRole === 'partner_view'
					)
				)
				||
				(
					(userRole === 'customer_service' && rowUserName !== userName)
					&& (rowRole === 'customer_service' || rowRole === 'system_admin')
				)
				|| (
					(rowUserName !== userName) &&
					(rowRole === 'system_admin' || rowRole === 'customer_service')
				)
				||
				userRole === 'sales_demo'
			) {
				return <CustomButton variant="link" onClick={() => editUser(row, true)}>{row.first_name + ' ' + row.last_name}</CustomButton>
			}
			else if (rowUserName === userName) {
				return <Link to='/account/settings'>{row.first_name + ' ' + row.last_name}</Link>
			}
			return <CustomButton variant="link" onClick={() => editUser(row)}>{row.first_name + ' ' + row.last_name}</CustomButton>
		}

		return <span>No Role</span>
	}


	const editUser = (row, readOnly = false) => {
		browserHistory.push({ pathname: "/manageusers/edit", state: { email: row.email, userId: row.id, readOnly } });
	}

	const passwordFormatter = (cell, row) => {
		if (row.roles) {
			const rowRole = row.roles[0];
			const rowUserName = row.email;
			const userName = props.user.email;

			if (
				(user_role === 'fleet_manager' && (rowRole === 'fleet_manager' || rowRole === 'customer_service' || rowRole === 'fleet_maintainer' || rowRole === 'system_admin'))

				|| (user_role === 'customer_service' && (rowRole === 'customer_service' || rowRole === 'system_admin') && rowUserName !== userName)
				|| user_role === 'group_manager'
				|| (user_role === 'partner' &&
					(
						rowRole === 'partner'
						|| rowRole === 'customer_service'
						|| rowRole === 'system_admin'
						|| rowRole === 'sales_demo'
						|| rowRole === 'provisioning'
						|| rowRole === 'installer'
						|| rowRole === 'partner_view'
					)
				)
			) {
				return <p><img src={changePasswordImg} alt='change password' /><label className='change-password-label'>Change</label></p>
			}
			else {
				return <CustomButton variant="link" onClick={() => changePassword(row)}>
					<img src={changePasswordImg} alt='change password' />
					<label className='change-password-label'>Change</label>
				</CustomButton>
			}
		}
		else {
			return <span>No Role</span>
		}
	}

	const changePassword = (row) => {
		browserHistory.push({ pathname: "/manageusers/change-password", state: { user: row } });
	}

	const viewEventsFormatter = (cell, row) => {
		return <CustomButton variant="link" onClick={() => lgOpen(row)}>View</CustomButton>
	}

	const formatRecentActivity = (cell) => {
		if (allUsers) {
			return cell !== 'None' ? formatTimestampUTC(cell) : 'No activity'
		}
		return cell ? formatTimestamp(cell) : 'No activity'
	}

	const lgOpen = (row) => {
		setShowModalDeviceAuditLog(true)
		setActiveUser(row);
	}

	const lgClose = () => {
		setShowModalDeviceAuditLog(false)
	}

	const rolesFormatter = (cell, row) => {
		let role = ''

		if (row.roles && cell.length)
			role = cell.reduce(function (value, index) { return value.concat(', ') })

		return constants.roleNames[role];
	}

	const showAdditionalCols = ['system_admin', 'customer_service', 'partner'].includes(user_role)

	return (
		<div className="manage-users-page-table">
			<BootstrapTable
				data={users}
				{...tableAdjustableColExportOptions}
			>
				<TableHeaderColumn width="18%" dataField="first_name" dataFormat={usernameFormatter} csvHeader="Name" csvFormat={formatName} dataSort>
					{renderCustomHeader(0, 'Users')}
				</TableHeaderColumn>
				<TableHeaderColumn width="18%" dataField="email" csvHeader='Email' isKey dataSort>
					{renderCustomHeader(1, 'E-mail')}
				</TableHeaderColumn>
				<TableHeaderColumn width="12%" dataField="roles" csvHeader='Role' csvFormat={rolesFormatter} dataFormat={rolesFormatter} dataSort>
					{renderCustomHeader(2, 'Role')}
				</TableHeaderColumn>
				<TableHeaderColumn width="16%" dataField="recent_activity" dataFormat={formatRecentActivity} csvHeader="Recent Activity" csvFormat={formatRecentActivity} dataSort>
					{showAdditionalCols ? renderCustomHeader(3, 'Activity') : 'Activity'}
				</TableHeaderColumn>
				<TableHeaderColumn hidden={!showAdditionalCols} width="10%" dataFormat={passwordFormatter} export={false}>
					{renderCustomHeader(4, 'Change password')}
				</TableHeaderColumn>
				<TableHeaderColumn hidden={!showAdditionalCols} width="8%" dataFormat={viewEventsFormatter} export={false}>
					Audit Log
				</TableHeaderColumn>
			</BootstrapTable>

			{showModalDeviceAuditLog
				&& (
					<ModalDeviceAuditLog
						device={activeUser}
						onHide={lgClose}
						user_role={user_role}
						type="user_logs"
					/>
				)}

		</div>

	);
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
)(ManageAssetsUsersContainer);
