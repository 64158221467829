// FWSD-3520
import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'

import CustomCheckbox from '../../../component/common/CustomCheckbox'
import { getSpeedEventsFiltersColors, getDurationFormat } from '../../../component/helpers/helperTripsGrid'
import './TripsGrid.sass'

const SpeedEventsDropdown = (posts) => {
	// console.log('SpeedEventsDropdown posts: ', posts)
	const { speedingEvents, tripsSpeedEventsFilters, setTripsSpeedEventsFilters } = posts
	const ITEMS_ORDER = ['Low Speeding', 'Moderate Speeding', 'High Speeding', 'Severe Speeding']

	const totalDuration = speedingEvents.reduce((previousValue, currentValue) => {
		return previousValue + currentValue.duration
	}, 0)

	const handleSpeedEventsFiltersClick = (e, menuItem) => {
		e.preventDefault()
		// console.log('handleSpeedEventsFiltersClick menuItem: ', menuItem)
		setTripsSpeedEventsFilters({ ...tripsSpeedEventsFilters, [menuItem]: !tripsSpeedEventsFilters[menuItem] })
	}

	const speedEventsSorted = Object.keys(tripsSpeedEventsFilters).sort((a, b) => ITEMS_ORDER.indexOf(a) - ITEMS_ORDER.indexOf(b))

	return (
		<div>
			{speedingEvents && speedingEvents.length > 0
				&& (
					<Dropdown
						className="alertDropdown"
						id="trip-speed-events-dropdown"
					>
						<Dropdown.Toggle
							// nocaret
							className="alertDropdownToggle no-caret"
						>
							<div className="alertBlock">
								<span className="glyphicon glyphicon-dashboard" />
								<span className="value">{getDurationFormat(totalDuration)}</span>
							</div>
						</Dropdown.Toggle>
						<Dropdown.Menu className="alertDropdownMenu">
							{speedEventsSorted.map((item) => {
								return (
									<li key={item}>
										<a
											onClick={(e) => handleSpeedEventsFiltersClick(e, item)}
											role="menuitem"
											tabIndex="-1"
											href="#"
										>
											<CustomCheckbox
												checked={tripsSpeedEventsFilters[item]}
												color={getSpeedEventsFiltersColors[item]}
											// onChange={() => handleSpeedEventsFiltersClick(item)}
											/>
											<span style={{ marginLeft: 8 }}>{`${item} Alert`}</span>
										</a>
									</li>
								)
							})}
						</Dropdown.Menu>
					</Dropdown>
				)}
		</div>
	)
}

SpeedEventsDropdown.propTypes = {
	speedingEvents: PropTypes.arrayOf(PropTypes.any).isRequired,
	tripsSpeedEventsFilters: PropTypes.objectOf(PropTypes.any).isRequired,
	setTripsSpeedEventsFilters: PropTypes.func.isRequired,
};

const mapStateToProps = ({ dashboardData }) => ({
	tripsSpeedEventsFilters: dashboardData.tripsSpeedEventsFilters,
});

const mapDispatchToProps = dispatch => ({
	setTripsSpeedEventsFilters: (state) => {
		dispatch({ type: 'SET_TRIPS_SPEED_EVENTS_FILTERS', payload: state })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(SpeedEventsDropdown);
