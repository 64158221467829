const getDomain = () => {
	const env = document.location.hostname;
	let domain = '';

	switch (env) {
		case 'localhost':
			domain = 'http://localhost:3001'
			break
		case 'test.roscolive.com':
			domain = 'https://partner-test.roscolive.com'
			break
		case 'dev.roscolive.com':
			domain = 'https://partner-dev.roscolive.com'
			break
		case 'staging.roscolive.com':
			domain = 'https://partner-stage.roscolive.com'
			break
		case 'canada.roscolive.com':
			domain = 'https://partner-canada.roscolive.com'
			break
		case 'roscolive.com':
		case 'fleet.roscolive.com':
			domain = 'https://partner.roscolive.com'
			break
		default:
			break
	}

	return domain;
}

export default getDomain;