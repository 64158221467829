import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import IconTooltip from '../../component/dump/menuComponents/IconTooltip'
import { constants } from '../../component/helpers/constants'

import { tableExportOptions } from '../../component/helpers/table'
import { formatViewEvent, formatTimestamp, formatDate } from '../../component/helpers/tablesFuncHelpers'

const DriversScoreTable = (props) => {
	console.log('DriversScoreTable PROPS: ', props)

	const formatEventTimestamp = (cell, row) => {
		if (['Idling', 'Total Events Score', 'Total Speeding Score'].includes(row.type)) {
			return `Since: ${formatDate(cell, row)}`
		}
		return formatTimestamp(cell, false)
	}

	const formatWeightLabel = (cell) => {
		const eventVariable = constants.eventVars[cell];
		const eventName = props.weights.find(event => event.name === eventVariable)
		return eventName ? eventName.weight : '-'
	}

	const formatViewEventColumn = (cell, row) => {
		if (row.type !== 'Max Speed' && row.type.includes('Speed')) {
			return '-'
		}
		if (['Idling', 'Total Events Score', 'Total Speeding Score'].includes(row.type) && row.event_id > 200) {
			return <div>
				<strong className="error-label">High Impact</strong>
				<IconTooltip
					id={`idling-${row.event_id}-tooltip`}
					description="High Impact means this category has an exceedingly high effect on the Driver's score.
					This is likely due to the driver registering an excessive amount of events or idling instances while also
					not traveling long distances."
				/>
			</div>
		}
		if (row.type === 'Idling') {
			return <div>
				<strong>Impact on Score: +{Math.round(row.event_id)}</strong>
				<IconTooltip
					id={`idling-${row.event_id}-tooltip`}
					description="This is the impact that time spent idling per mile driven has on the driver's overall score.
					You can adjust the impact idling has on the score by clicking Adjust Event Weights in the header."
				/>
			</div>
		}
		if (row.type === 'Total Events Score') {
			return <div>
				<strong>Impact on Score: +{Math.round(row.event_id)}</strong>
				<IconTooltip
					id={`total-events-${row.event_id}-tooltip`}
					description={`This is the impact that the total number of events per mile driven has on the driver's overall score.
						This also includes events set to "Alert Only", without video, that don't appear below.`}
				/>
			</div>
		}
		if (row.type === 'Total Speeding Score') {
			return <div>
				<strong>Impact on Score: +{Math.round(row.event_id)}</strong>
				<IconTooltip
					id={`speeding-${row.event_id}-tooltip`}
					description="This is the impact that time spent speeding per mile driven has on the driver's overall score.
					You can adjust the impact that each speeding category has on the score by clicking Adjust Event Weights in the header."
				/>
			</div>
		}
		return formatViewEvent(cell, row)
	}

	const formatViewEventColumnCSV = (cell, row) => {
		if (row.type !== 'Max Speed' && row.type.includes('Speed')) {
			return '-'
		}
		if (['Idling', 'Total Events Score', 'Total Speeding Score'].includes(row.type)) {
			return `Impact on Score: ${Math.round(row.event_id)}`
		}
		return `Event ID ${row.event_id}`
	}

	return (
		<BootstrapTable
			data={props.data}
			// data={expandedData}
			{...tableExportOptions}
			csvFileName="driver_events.csv"
		>
			<TableHeaderColumn width="10%" dataField="timestamp" dataSort dataFormat={formatEventTimestamp} csvFormat={formatEventTimestamp} csvHeader="Date / Time">
				Date / Time
			</TableHeaderColumn>
			<TableHeaderColumn width="10%" dataField="type" dataSort csvHeader="Event Type">
				Type
			</TableHeaderColumn>
			<TableHeaderColumn width="10%" dataField="id" isKey dataFormat={formatViewEventColumn} csvHeader="Action" csvFormat={formatViewEventColumnCSV}>
				Action
			</TableHeaderColumn>
			<TableHeaderColumn width="10%" dataField="type" dataFormat={formatWeightLabel} csvHeader="Weight" csvFormat={formatWeightLabel}>
				Event Weight
				<IconTooltip
					id="weight-tooltip"
					description="This is the weight assigned to the given event type. To adjust these values, click Adjust Event Weights in the header."
				/>
			</TableHeaderColumn>
		</BootstrapTable>
	)
}

export default DriversScoreTable;
