import React, { useState, useEffect } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import CustomButton from '../../component/CustomButton'
import { tableNoPaginationOptions } from '../../component/helpers/table'

const CustomAPN = (props) => {
    // console.log('PROPS CustomAPN: ', props)
    const { custom_apn, onUpdateApn } = props
    const apns = [{id: 'Default', flag: 0}] 
    if (custom_apn.length) {
        custom_apn.split(',').forEach(item => {
            apns.push({id: item, flag: 1})
        })
    }

    // const [apns, setApns] = [{id: 'Default', flag: 0}]
    const [apn_name, setApn_name] = useState('')
    const [errapn_name, setErrapn_name] = useState('')

    // useEffect(() => {

	// }, [custom_apn])

    const checkApn = () => {
		let error = false
        let errapn = ''

        if (apn_name === '') {
            error = true
            errapn = 'Please enter value'
        }
        else if (custom_apn.split(',').includes(apn_name)) {
            error = true
            errapn = 'This APN exists in your list'
        }
        else if (custom_apn.split(',').length >= 5) {
            error = true
            errapn = 'You should remove one APN before adding another'
        }
        else if ((custom_apn + apn_name).length > 256) {
            error = true
            errapn = 'Your APN values are too long. Available just 256 symbols.'
        }

		// if (!updatedErrMessage['email']) {
		// 	let re = /^([a-zA-Z0-9_\-\.\+]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		// 	if (!re.test(data['email'])) {
		// 		error = true;
		// 		updatedErrMessage['email'] = 'Email is not valid.';
		// 	}
		// }

		setErrapn_name(errapn)
		return error
	}

    const onAddClick = (e) => {
        e.preventDefault()

        const error = checkApn()
		if (!error) {
            const newApns =  [...apns, {id: apn_name, flag: 1}].filter(({flag}) => flag === 1).map(({id}) => id).join(',')
            onUpdateApn(e, newApns)
        }
    }

    const onRemoveClick = (e, apnArg) => {
        e.preventDefault()
        const newApns =  apns.filter(({id, flag}) => id !== apnArg.id && flag === 1).map(({id}) => id).join(',')
        onUpdateApn(e, newApns)
    }


    const formatActions = (cell, row) => {
        if (row.flag) {
            return (
                <CustomButton
                    variant="link"
                    prefix="error-label"
                    onClick={(e) => onRemoveClick(e, row)}
                >
                    Remove
                </CustomButton>
            )
        }
    }

    return (
        <div className="company-custom-apn">
            <div className={`subheader-section search-flex mb-10 ${errapn_name && 'has-error'}`}>
                <label className="search-label">Custom APN</label>
                <div>
                    <input
                        type="text"
                        name="apn_name"
                        className="vehicle-search apn-name"
                        placeholder="Type custom apn, add, and save"
                        onChange={(e) => setApn_name(e.target.value)}
                        value={apn_name}
                    />
                    <CustomButton
                        variant="link"
                        onClick={onAddClick}
                    >
                        Add
                    </CustomButton>
                </div>
                {errapn_name && <span className="help-block">{errapn_name}</span>}
            </div>

            <BootstrapTable
                data={apns}
                {...tableNoPaginationOptions}
                // tableContainerClass="rosco-table manage-vehicles-table"
                // headerContainerClass="rosco-table-header custom-table-header-container manage-vehicles-table-header"
                // bodyContainerClass="rosco-table-body manage-vehicles-table-body"
                // csvFileName="companies_report.csv"
            >
                <TableHeaderColumn dataField="id" dataSort isKey>
                    APN
                </TableHeaderColumn>
                <TableHeaderColumn width="30%" dataFormat={formatActions}>
                    Actions
                </TableHeaderColumn>


            </BootstrapTable>
        </div>
    )
}

export default CustomAPN
