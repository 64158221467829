import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import SearchBar from '../../../SearchBar'
import { getUsers } from '../../../../action/ClientActions'
import { constants } from '../../../helpers/constants'
import _ from 'lodash'
import { updateReport } from '../../../../action/ReportsActions'
import { toastr } from 'react-redux-toastr'
import CustomButton from '../../../CustomButton'

const ShareReportModal = (props) => {

	const { user, company, reportInfo, onHide, loaderModal, toggleLoaderModal } = props;
	const userRole = user.roles[0];
	const newUsers = reportInfo.users || [];

	const [otherUsers, setOtherUsers] = useState([])
	const [sharedUsers, setSharedUsers] = useState([])
	const [emails, setEmails] = useState([])
	const [otherUserSearch, setOtherUserSearch] = useState('')
	const [sharedUserSearch, setSharedUserSearch] = useState('')

	useEffect(() => {
		const email = user.email;
		const sharedUserEmails = newUsers;
		toggleLoaderModal(true);
		getUsers(userRole, company)
			.then(res => {
				const totalUsers = res.data.response.users;
				setOtherUsers(totalUsers.filter(user => email !== user.email && sharedUserEmails.indexOf(user.email) === -1));
				setSharedUsers(totalUsers.filter(user => sharedUserEmails.indexOf(user.email) > -1));
				setEmails(sharedUserEmails)
				toggleLoaderModal(false);
			})
			.catch(err => {
				toastr.error('Error getting share options. Please try again later.')
				console.log(err)
			})
	}, [])

	const shareWithUser = (user, index) => {
		let otherUsersUpdate = otherUsers;
		let sharedUsersUpdate = sharedUsers;
		otherUsersUpdate.splice(index, 1)
		sharedUsersUpdate.unshift(user);
		setOtherUsers(otherUsersUpdate)
		setSharedUsers(sharedUsersUpdate)
		setEmails([...emails, user.email])
	}

	const removeSharedUser = (user, index) => {
		let otherUsersUpdate = otherUsers;
		let sharedUsersUpdate = sharedUsers;
		sharedUsersUpdate.splice(index, 1)
		otherUsersUpdate.unshift(user);
		setOtherUsers(otherUsersUpdate)
		setSharedUsers(sharedUsersUpdate)
		setEmails(emails.filter(email => email !== user.email))
	}

	const shareReport = () => {
		if (!_.isEqual(emails, newUsers)) {
			toggleLoaderModal(true);
			updateReport(reportInfo.id, { users: emails })
				.then(res => {
					toggleLoaderModal(false);
					toastr.success('Successfully shared report')
					onHide(res.data);
				})
				.catch(err => {
					toastr.error('Unable to share report. Please try again later')
					toggleLoaderModal(false);
				})
		}
		else {
			onHide();
		}
	}

	return (
		<Modal
			size='lg'
			show
			onHide={onHide}
			className="reports-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>Share Report</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="share-report-body">
					<div className='modal-subtitle'>Select the users you would like to share this report with. Sharing a report makes it available in a user's Custom Reports.</div>
					<div className="user-select-box-wrapper">
						<div className='modal-column select-box'>
							<div className='select-box-header'>
								<div className='column-title'>Users</div>
							</div>
							<SearchBar term={otherUserSearch} onSearch={(e) => setOtherUserSearch(e.target.value)} />
							<div className='select-box-body no-checkbox'>
								{otherUsers && otherUsers.map((user, index) => {
									let name = user.first_name + ' ' + user.last_name;
									if (name && (!otherUserSearch || name.toLowerCase().includes(otherUserSearch.toLowerCase()))) {
										return (
											<div className='asset-select-wrapper' key={user.email} onClick={() => shareWithUser(user, index)}>
												<span className='asset-name'>{name + ' - ' + constants.roleNames[user.roles[0]]}</span>
											</div>
										)
									}
								})}
							</div>
						</div>
						<div className='modal-column select-box'>
							<div className='select-box-header'>
								<div className='column-title'>Currently Shared With</div>
							</div>
							<SearchBar term={sharedUserSearch} onSearch={(e) => setSharedUserSearch(e.target.value)} />
							<div className='select-box-body no-checkbox'>
								{sharedUsers && sharedUsers.map((user, index) => {
									const name = user.first_name + ' ' + user.last_name;
									if (name && (!sharedUserSearch || name.toLowerCase().includes(sharedUserSearch.toLowerCase()))) {
										return (
											<div className='asset-select-wrapper' key={user.email} onClick={() => removeSharedUser(user, index)}>
												<span className='asset-name'>{name + ' - ' + constants.roleNames[user.roles[0]]}</span>
											</div>
										)
									}
								})}
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={shareReport}>Share</CustomButton>
			</Modal.Footer>
			{loaderModal}
		</Modal>
	)

}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company,
		loaderModal: state.loader.loaderModal,
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(ShareReportModal);
