import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import momenttz from '../../helpers/momenttz'
import { apiCallGet } from '../../../action/RouterActions';
import { tableNoPaginationOptions } from '../../helpers/table'
import CustomButton from '../../CustomButton';

const ModalSeeHistory = (props) => {
	const { profile, onHide, onClickCompareHistory, loaderModal, toggleLoaderModal } = props
	const [history, setHistory] = useState([]);

	useEffect(() => {
		toggleLoaderModal(true);
		apiCallGet(`/profiles/${profile.id}/history`)
			.then(res => {
				let response = res.data.response.history;
				if (response.length) {
					response[response.length - 1].profile_version = "Latest";
					response = response.reverse();
				}
				setHistory(response);
				toggleLoaderModal(false);
			})
			.catch(err => {
				toggleLoaderModal(false);
				console.log(err)
			})
	}, [])

	const timestampFormatter = (timestamp) => {
		return momenttz(`${timestamp}`).format('M/D/YY h:mm:ss a')
	}

	const compareFormatter = (version) => {
		return (<CustomButton variant="link" onClick={() => onClickCompareHistory(version, history)}>
			Compare
		</CustomButton>)
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="config-profile-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg" className="action-title">
					{`${profile.name} History`}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<main className='main-content-seeHistory'>
						<BootstrapTable
							data={history}
							{...tableNoPaginationOptions}
						>
							<TableHeaderColumn
								width="15%"
								dataField="profile_version"
								dataAlign="center"
								isKey={true}
							>
								Version
							</TableHeaderColumn>
							<TableHeaderColumn
								width="30%"
								dataField="user_name"
								dataAlign="center"
							>
								Change Made By
							</TableHeaderColumn>
							<TableHeaderColumn
								width="35%"
								dataField="created_at"
								dataFormat={timestampFormatter}
								dataAlign="center"
							>
								Date Of Change
							</TableHeaderColumn>
							<TableHeaderColumn
								width="20%"
								dataField="profile_version"
								dataFormat={(version) => compareFormatter(version)}
								dataAlign="center"
							>
								Actions
							</TableHeaderColumn>
						</BootstrapTable>
					</main>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={onHide}>
					Close
				</CustomButton>
			</Modal.Footer>
			{loaderModal}
		</Modal>
	)
}

ModalSeeHistory.propTypes = {
	profile: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired,
}

export default connect(
	state => ({
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show })
		},
	})
)(ModalSeeHistory)
