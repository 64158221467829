import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { apiCall } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton';

const AddInstallReportModal = (props) => {

	const { user, device, onHide, showInstallReport, toggleLoader } = props;

	const [data, setData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone_number: "",
		installer_company_name: "",
		mecp_cert: false,
		windshield_img: "",
		obd_mount: "",
		obd_zip_tie: "",
		antenna_img: "",
		power_img: "",
		pdc_img: "",
		modem_img: ""
	});
	const [showModalImageView, setShowModalImageView] = useState(false);
	const [imageViewSRC, setImageViewSRC] = useState("");
	const [imgValue, setImgValue] = useState({
		value_1: "",
		value_2: "",
		value_3: "",
		value_4: "",
		value_5: "",
		value_6: "",
		value_7: ""
	});

	const sendData = () => {
		toggleLoader(true);
		const dataSend = data;
		for (let item in dataSend) {
			if (!dataSend[item]) dataSend[item] = "";
		}
		apiCall('POST', `/devices/${device.device_id}/install_report`, dataSend)
			.then(res => {
				toggleLoader(false);
				if (res.data.response.message === "success") {
					onHide();
					showInstallReport();
					toastr.success('', 'The install report was successfully uploaded');
				} else {
					toastr.error('An unexpected error occurred. Please try again later');
				}
			})
			.catch((error) => {
				console.log('Error: ', error.response, error)
				toggleLoader(false);
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
					if (errDescription.includes("jpeg|jpg|png")) errDescription = errDescription.replaceAll('|', '/');
				}
				toastr.error(errDescription)
			})
	}

	const hideModalImageView = () => {
		setShowModalImageView(false);
		setImageViewSRC("");
	}

	const viewUploadFile = (name) => {
		setImageViewSRC(window.URL.createObjectURL(data[name]));
		setShowModalImageView(true);
	}

	const deleteUploadFile = (name, value) => {
		setData({ ...data, [name]: "" });
		setImgValue({ ...imgValue, [value]: "" });
	}

	const onFileChange = (e, name) => {
		setData({ ...data, [name]: e.target.files[0] });
		setImgValue({ [e.target.id]: e.target.value });
	}

	const file_1 = "windshield_img";
	const file_2 = "obd_mount";
	const file_3 = "obd_zip_tie";
	const file_4 = "antenna_img";
	const file_5 = "power_img";
	const file_6 = "pdc_img";
	const file_7 = "modem_img";
	let dataSendAccess = Object.values(data).reduce((prev, current) => !!prev || !!current, false);

	return (
		<Modal dialogClassName="addInstallReportModal"
			show
			onHide={onHide}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					Add Install Report
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h5>Installer Info</h5>
				<div className="formReportInstall">
					<div className="itemRow">
						<input
							type="text"
							value={data.first_name}
							placeholder="First Name"
							className="firstNameInput"
							onChange={(e) => setData({ ...data, first_name: e.target.value })}
						/>
						<input
							type="text"
							value={data.last_name}
							placeholder="Last Name"
							className="lastNameInput"
							onChange={(e) => setData({ ...data, last_name: e.target.value })}
						/>
					</div>
					<div className="itemInput">
						<input
							type="text"
							value={data.email}
							placeholder="Email"
							className="fullWidthInput"
							onChange={(e) => setData({ ...data, email: e.target.value })}
						/>
					</div>
					<div className="itemInput">
						<input
							type="text"
							value={data.phone_number}
							placeholder="Phone Number"
							className="fullWidthInput"
							onChange={(e) => setData({ ...data, phone_number: e.target.value })}
						/>
					</div>
					<div>
						<input
							type="text"
							value={data.installer_company_name}
							placeholder="Installer Company Name"
							className="fullWidthInput"
							onChange={(e) => setData({ ...data, installer_company_name: e.target.value })}
						/>
					</div>
					<div className="itemInput">
						<label className="control-label lbl-certified">MECP Certified:</label>
						<div className="itemRow-checkbox">
							<input
								type="checkbox"
								checked={data.mecp_cert}
								onChange={(e) => setData({ ...data, mecp_cert: e.target.checked })}
							/>
							<label>Yes</label>
						</div>
						<hr />
					</div>
					<div className="upload-files">
						<div className="itemRow">
							<label className="control-label load-image">Camera on Windshield</label>
							<CustomButton><label className="lbl">Upload<input type="file" value={imgValue.value_1} className="upload-input" id="value_1" onChange={e => onFileChange(e, file_1)} /></label></CustomButton>
							<label className="control-label item-right">{!!data[file_1] ? <div><a className="btn-view-image" onClick={() => viewUploadFile(file_1)}>Preview</a><a className="btn-delete-image" onClick={() => deleteUploadFile(file_1, "value_1")}>Delete</a></div> : "No Snapshot"}</label>
						</div>
						<div className="itemRow">
							<label className="control-label load-image">OBD Connection</label>
							<CustomButton><label className="lbl">Upload<input type="file" value={imgValue.value_2} className="upload-input" id="value_2" onChange={e => onFileChange(e, file_2)} /></label></CustomButton>
							<label className="control-label item-right">{!!data[file_2] ? <div><a className="btn-view-image" onClick={() => viewUploadFile(file_2)}>Preview</a><a className="btn-delete-image" onClick={() => deleteUploadFile(file_2, "value_2")}>Delete</a></div> : "No Snapshot"}</label>
						</div>
						<div className="itemRow">
							<label className="control-label load-image">OBD Zip Tie</label>
							<CustomButton><label className="lbl">Upload<input type="file" value={imgValue.value_3} className="upload-input" id="value_3" onChange={e => onFileChange(e, file_3)} /></label></CustomButton>
							<label className="control-label item-right">{!!data[file_3] ? <div><a className="btn-view-image" onClick={() => viewUploadFile(file_3)}>Preview</a><a className="btn-delete-image" onClick={() => deleteUploadFile(file_3, "value_3")}>Delete</a></div> : "No Snapshot"}</label>
						</div>
						<div className="itemRow">
							<label className="control-label load-image">Antenna</label>
							<CustomButton><label className="lbl">Upload<input type="file" value={imgValue.value_4} className="upload-input" id="value_4" onChange={e => onFileChange(e, file_4)} /></label></CustomButton>
							<label className="control-label item-right">{!!data[file_4] ? <div><a className="btn-view-image" onClick={() => viewUploadFile(file_4)}>Preview</a><a className="btn-delete-image" onClick={() => deleteUploadFile(file_4, "value_4")}>Delete</a></div> : "No Snapshot"}</label>
						</div>
						<div className="itemRow">
							<label className="control-label load-image">Power Connection</label>
							<CustomButton><label className="lbl">Upload<input type="file" value={imgValue.value_5} className="upload-input" id="value_5" onChange={e => onFileChange(e, file_5)} /></label></CustomButton>
							<label className="control-label item-right">{!!data[file_5] ? <div><a className="btn-view-image" onClick={() => viewUploadFile(file_5)}>Preview</a><a className="btn-delete-image" onClick={() => deleteUploadFile(file_5, "value_5")}>Delete</a></div> : "No Snapshot"}</label>
						</div>
						<div className="itemRow">
							<label className="control-label load-image">PDC</label>
							<CustomButton><label className="lbl">Upload<input type="file" value={imgValue.value_6} className="upload-input" id="value_6" onChange={e => onFileChange(e, file_6)} /></label></CustomButton>
							<label className="control-label item-right">{!!data[file_6] ? <div><a className="btn-view-image" onClick={() => viewUploadFile(file_6)}>Preview</a><a className="btn-delete-image" onClick={() => deleteUploadFile(file_6, "value_6")}>Delete</a></div> : "No Snapshot"}</label>
						</div>
						<div className="itemRow">
							<label className="control-label load-image">Modem/Router</label>
							<CustomButton><label className="lbl">Upload<input type="file" value={imgValue.value_7} className="upload-input" id="value_7" onChange={e => onFileChange(e, file_7)} /></label></CustomButton>
							<label className="control-label item-right">{!!data[file_7] ? <div><a className="btn-view-image" onClick={() => viewUploadFile(file_7)}>Preview</a><a className="btn-delete-image" onClick={() => deleteUploadFile(file_7, "value_7")}>Delete</a></div> : "No Snapshot"}</label>
						</div>
					</div>
					<hr />
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={() => onHide()}>Cancel</CustomButton>
				<CustomButton variant="primary" disabled={!dataSendAccess} onClick={() => sendData()}>Add</CustomButton>
			</Modal.Footer>
			{showModalImageView
				&& (
					<ModalImageView
						src={imageViewSRC}
						onHide={hideModalImageView}
					/>
				)}
		</Modal>
	)
}

AddInstallReportModal.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

const ModalImageView = (props) => {
	const { src, onHide } = props;
	return (
		<Modal
			show
			onHide={onHide}
			size="large"
			backdropClassName="upper"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Image View</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<img src={src} width="100%" id="event-snapshot" alt="" />
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(AddInstallReportModal);
