import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

import '../../component/dump/menuComponents/menuComponents.sass'
import CustomButton from '../../component/CustomButton';

const ModalDeleteDriver = ({ driver, handleDriverDelete, onHide }) => {
	const { first_name, last_name, card_id, group_name } = driver;

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-batch-function modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title className='delete-title'>Delete Driver</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="manage-users text-center row">
					<div className="col-md-6 form-group">
						<label className="control-label">
							First Name
						</label>
						<div>
							{first_name}
						</div>
					</div>

					<div className="col-md-6 form-group">
						<label className="control-label">
							Last Name
						</label>
						<div>
							{last_name}
						</div>
					</div>

					<div className="col-md-6 form-group">
						<label className="control-label">
							Group
						</label>
						<div>
							{group_name}
						</div>
					</div>

					<div className="col-md-6 form-group">
						<label className="control-label">
							Card ID
						</label>
						<div>
							{card_id}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="delete" onClick={handleDriverDelete}>
					Delete Driver
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
};

ModalDeleteDriver.propTypes = {
	driver: PropTypes.objectOf(PropTypes.any).isRequired,
	handleDriverDelete: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired
};

export default ModalDeleteDriver;
