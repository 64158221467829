import { ActionTypes } from '../action/types'
import roscoLogo from '../../../assets/roscovision-icon.png'

const initialState = {
    loader: <></>,
    loaderModal: <></>,
    customLoader: false,
};

export default function loader(state = initialState, action) {
    switch (action.type) {
        // case ActionTypes.TOGGLE_LOADER:
        case 'TOGGLE_LOADER':
            return {
                ...state,
                ...{
                    loader: action.payload ? (
                        !state.customLoader ?
                            (<div className="loader">
                                <i className="fas fa-circle-notch fa-spin rosco-spinner" />
                                <img src={roscoLogo} alt="logo loader" className="rosco-icon" />
                            </div>) :
                            (<div className="loader customLoader"></div>)
                    ) : (
                        <></>
                    )
                }
            };
        // case ActionTypes.TOGGLE_LOADER_MODAL:
        case 'TOGGLE_LOADER_MODAL':
            return {
                ...state,
                ...{
                    loaderModal: action.payload ? (
                        !state.customLoader ?
                            (<div className="loaderModal">
                                <i className="fas fa-circle-notch fa-spin rosco-spinner" />
                                <img src={roscoLogo} alt="logo loader" className="rosco-icon" />
                            </div>) :
                            (<div className="loaderModal customLoader"></div>)
                    ) : (
                        <></>
                    )
                }
            };
        case 'TOGGLE_CUSTOMLOADER':
            return {
                ...state,
                ...{
                    customLoader: action.payload
                }
            };
        default:
            return state
    }
}
