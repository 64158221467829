import React from 'react';
import { Line } from 'react-chartjs-2';
import _ from 'lodash'
import PropTypes from 'prop-types';

const GsensorGraph = (props) => {

	const { acceleration, timestamp } = props;

	const optionsLine = {
		elements: {
			lines: {
				borderWidth: 4,
				borderColor: 'rgb(255, 255, 0)',
			},
		},
		scales: {
			xAxes: [{
				// type: 'linear',
				// position: 'bottom',
				display: true,
				gridLines: {
					// color: "#464f56",
					lineWidth: 0,
					showTickMarks: false,
					drawBorder: true,
				},
				ticks: {
					beginAtZero: true,
				},
				borderWidth: 4,
			}],
			yAxes: [{
				gridLines: {
					lineWidth: 1,
					// color: "#fff",
					drawBorder: true,
				},
				ticks: {
					min: -2,
					max: 2,
					stepSize: 1,
					beginAtZero: true,
					showLabelBackdrop: true,
				},
			}],
		},
		// scaleStartValue: 0,
		// maintainAspectRatio: true,

		title: {
			display: false,
		},
		legend: {
			display: false,
		},
		maintainAspectRatio: false,
		// layout: chart_padding,
		responsive: true,
		showXLabels: 4,
	}

	const data = {
		labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
		datasets: [{
			label: 'Z = ',
			fill: false,
			pointRadius: 0,
			// pointHitRadius: 10,
			backgroundColor: '#11cfd6',
			borderColor: '#11cfd6',
			borderWidth: 1.5,
			// hoverBackgroundColor: '#20C80C',
			// hoverBorderColor: 'red',
			// data: [1.75, 0.65, 1, 1.2, 0.8, 0.6, 1.2, 1.75, 0.65, 1, 1.2, 0.8, 0.6, 1.2, 1.75, 0.65, 1, 1.2, 0.8, 0.6,
			// 		1.2, 1.75, 0.65, 1, 1.2, 0.8, 0.6, 1.2, 1.75, 0.65, 1, 1.2, 0.8, 0.6, 1.2, 1.75, 0.65, 1, 1.2, 0.8,
			// 		0.6, 1.2, 1.75, 0.65, 1, 1.2, 0.8, 0.6, 1.2, 1.75, 0.65, 1, 1.2, 0.8, 0.6,   -2, 2, 0.5],
			// data: _.dropRight(this.state.acc.filter(acc => {
			// 			if (acc.t <= self.state.timestamp) {
			// 				console.log('acc.z', acc.z.toFixed(2));
			// 				return acc.z.toFixed(2);
			// 			}
			// 		}), 25)
			// data:_.map(acc.filter(a =>
			// 				a.t < 1522237763291
			// 		).slice(-2), 'x')
			data: acceleration.filter(acc => acc.t <= timestamp).slice(-55).map(item => item.z),
		},
		{
			label: 'Y = ',
			fill: false,
			pointRadius: 0,
			backgroundColor: '#04eb0d',
			borderColor: '#04eb0d',
			borderWidth: 1.5,
			// data: [0.2, -0.3, 0.5, 0.6, -0.1, -0.5, 0.6],
			data: acceleration.filter(acc => acc.t <= timestamp).slice(-55).map(item => item.y),
		},
		{
			label: 'X = ',
			fill: false,
			pointRadius: 0,
			backgroundColor: 'red',
			borderColor: 'red',
			borderWidth: 1.5,
			// data: [0.3, -0.2, 0.4, 0.5, -0.31, -0.9, 0.3],
			data: acceleration.filter(acc => acc.t <= timestamp).slice(-55).map(item => item.x),
		}]
	}

	let accObj = { x: 0, y: 0, z: 0 }
	let obj = null;
	obj = _.findLast(acceleration, (o) => { return o.t <= timestamp; });
	if (obj) {
		accObj = obj;
	}

	return (
		<div className="gsensor-data">
			<div id="captureGsensor" style={{ width: '285px', height: '200px', position: 'absolute', bottom: '-100%', paddingBottom: "20px" }}>
				<div style={{ color: 'pink', fontSize: `16px`, fontWeight: 'bold', textAlign: 'center' }}>G-Force Data</div>
				<Line
					data={data}
					options={optionsLine}
				// data = {
				//    datasets: [{
				//        showLine: false, // disable for a single dataset
				//        data: data
				//    }]
				// },
				// legend={{display:true, position: 'bottom'}}
				/>
				<div className="acceleration-values" style={{display: "flex", paddingLeft: "10px"}}>
					<div className="col-md-4 col-xs-4 col-sm-4 x-value" style={{ fontWeight: 'bold', padding: 0 }}>{`X = ${accObj.x.toFixed(2)}`}</div>
					<div className="col-md-4 col-xs-4 col-sm-4 y-value" style={{ fontWeight: 'bold', padding: 0 }}>{`Y = ${accObj.y.toFixed(2)}`}</div>
					<div className="col-md-4 col-xs-4 col-sm-4 z-value" style={{ fontWeight: 'bold', padding: 0 }}>{`Z = ${accObj.z.toFixed(2)}`}</div>
				</div>
			</div>
		</div>
	)
}

GsensorGraph.propTypes = {
	acceleration: PropTypes.arrayOf(PropTypes.any).isRequired,
	timestamp: PropTypes.string.isRequired
}

export default GsensorGraph;
