import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import SearchBar from '../../component/SearchBar'
import { tableExportOptions } from '../../component/helpers/table'
import { formatDate, formatViewDriverEvent } from '../../component/helpers/tablesFuncHelpers'
import { getDriverCoachingData } from '../../action/DriverActions'
import { toastr } from 'react-redux-toastr'
import DateDropdown from '../../component/dump/menuComponents/DateDropdown'
import { getDateRangeFromTabKey } from '../../component/smart/reportsComponents/ReportsHelpers'
import moment from 'moment'
import ModalCoachEvent from './ModalCoachEvent'
import CustomButton from '../../component/CustomButton'
import { questionIcon } from '../../component/helpers/iconHelpers'

const DriversCoaching = (props) => {

    const { company, toggleLoader } = props;

    const [searchText, setSearchText] = useState('')
    const [coachingSessions, setCoachingSessions] = useState([])
    const [tabKey, setTabKey] = useState(5)
    const [start_date, setStart_date] = useState(moment().startOf('month').format('YYYYMMDD'))
    const [end_date, setEnd_date] = useState(moment().endOf('month').format('YYYYMMDD'))

    const [selectedItem, setSelectedItem] = useState(null)
    const [showCoachModal, setShowCoachModal] = useState(false)
    const [hideDescription, setHideDescription] = useState(JSON.parse(localStorage.getItem('hideDriverCoachingDescription')) || false)

    useEffect(() => {
        getCoachingEvents();
        setSearchText('');
    }, [company.company_id])

    const getCoachingEvents = (new_start_date = null, new_end_date = null) => {
        const data = {
            start_date: new_start_date || start_date,
            end_date: new_end_date || end_date,
            company_id: company.company_id
        }
        toggleLoader(true);
        getDriverCoachingData(data)
            .then(res => {
                setCoachingSessions(res.data.response)
                toggleLoader(false);
            })
            .catch(err => {
                console.log(err)
                toastr.error('There was an issue getting coaching sessions. Please try again later.')
                toggleLoader(false);
            })
    }

    const selectDate = (tabKey, start_date, end_date) => {
        let new_start_date, new_end_date;
        if (tabKey) {
            // TRUE IS SECOND PARAMETER BECAUSE WE WANT COACHING EVENTS IN THE FUTURE
            const dateRange = getDateRangeFromTabKey(tabKey, true)
            new_start_date = dateRange[0];
            new_end_date = dateRange[1];
        }
        else {
            //manually selected date
            new_start_date = start_date ? moment(start_date).format('YYYYMMDD') : state.start_date;
            new_end_date = end_date ? moment(end_date).format('YYYYMMDD') : state.end_date;
        }
        setTabKey(tabKey)
        setStart_date(new_start_date)
        setEnd_date(new_end_date)
        getCoachingEvents(new_start_date, new_end_date)
    }

    const formatType = (cell, row) => {
        return cell === 'score' ?
            <CustomButton variant="link" onClick={() => showCoachEventModal(row)}>Coach on Score</CustomButton> :
            <CustomButton variant="link" onClick={() => showCoachEventModal(row)}>Coach on Event</CustomButton>
    }

    const showCoachEventModal = (row) => {
        setSelectedItem(row)
        setShowCoachModal(true)
    }

    const formatActionItem = (cell, row) => {
        return row.coaching_type === 'score' ?
            `Score: ${Math.round(cell)}` :
            formatViewDriverEvent(cell, row)
    }

    const formatStatus = (cell) => {
        const status = cell.charAt(0).toUpperCase() + cell.slice(1);
        switch (status) {
            case 'Complete':
                return <label className='online-label'>{status}</label>
            case 'Overdue':
                return <label className='error-label'>{status}</label>
            default:
                return <label>{status}</label>
        }
    }

    const updateCoachingSessions = () => {
        setShowCoachModal(false)
        getCoachingEvents();
    }

    const toggleShowDescription = () => {
        localStorage.setItem('hideDriverCoachingDescription', JSON.stringify(!hideDescription))
        setHideDescription(!hideDescription)
    }

    const filteredSessions = coachingSessions.filter(driver => {
        let name = driver.driver_first_name + ' ' + driver.driver_last_name;
        if (!searchText || name.toLowerCase().includes(searchText.toLowerCase())) {
            return true;
        }
        return false;
    })

    return (
        <>
            <div className='page-subheader'>
                <div className='subheader-section search-flex'>
                    <SearchBar
                        term={searchText}
                        placeholder="Search Driver..."
                        onSearch={(e) => setSearchText(e.target.value)}
                    />
                    <DateDropdown
                        start_date={start_date}
                        end_date={end_date}
                        tabKey={tabKey}
                        onSelectDate={selectDate}
                        allowFutureDates
                    />
                </div>
                {hideDescription &&
                    <div className='subheader-section'>
                        <CustomButton variant='secondary' size='icon' title='More...' onClick={toggleShowDescription} style={{ border: "none" }}>
                            {questionIcon}
                        </CustomButton>
                    </div>
                }
            </div>

            <main className="main-content-block driver-page-table">

                {!hideDescription &&
                    <section className='asset-description'>
                        <h3 className='category-title text-center'>Driver Coaching</h3>
                        <div className="asset-paragraph">
                            <p>
                                There are two possible ways to set up a coaching session; you can coach a driver either on their score, or
                                on a particular event that occurred.
                            </p>
                            <ul>
                                <li>
                                    To coach a driver based on their score, go to the <strong>Scoring</strong> tab
                                    and click on the score you would like to coach the driver on.
                                </li>
                                <li>
                                    To coach on an event, when viewing the event in the player,
                                    select <strong>Coach Event</strong> in the dropdown found in the <strong>Event Info</strong> section.
                                </li>
                            </ul>
                        </div>
                        <div className='text-center'>
                            <CustomButton variant='link' onClick={toggleShowDescription}>Hide</CustomButton>
                        </div>
                    </section>
                }

                <BootstrapTable
                    data={filteredSessions}
                    {...tableExportOptions}
                    csvFileName='driver_coaching_events.csv'
                >
                    <TableHeaderColumn dataField='id' isKey hidden />
                    <TableHeaderColumn
                        width='20'
                        dataField='driver_first_name'
                        dataFormat={(cell, row) => row.driver_first_name + ' ' + row.driver_last_name}
                        dataSort
                        csvFormat={(cell, row) => row.driver_first_name + ' ' + row.driver_last_name}
                        csvHeader='Driver Name'
                    >
                        Driver Name
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='20'
                        dataField='coach_email'
                        dataFormat={cell => cell}
                        dataSort
                        csvFormat={cell => cell}
                        csvHeader='Score'
                    >
                        Coach Email
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='20'
                        dataField='coaching_type'
                        dataFormat={formatType}
                        dataSort
                        csvFormat={cell => cell}
                        csvHeader='Type'
                    >
                        Type
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='20'
                        dataField='action_item'
                        dataFormat={formatActionItem}
                        dataSort
                        csvFormat={formatActionItem}
                        csvHeader='Score / Event'
                    >
                        Score / Event
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='20'
                        dataField='due_date'
                        dataFormat={formatDate}
                        dataSort
                        csvFormat={formatDate}
                        csvHeader='Due Date'
                    >
                        Due Date
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        width='20'
                        dataField='status'
                        dataFormat={formatStatus}
                        dataSort
                        csvFormat={cell => cell}
                        csvHeader='Status'
                    >
                        Status
                    </TableHeaderColumn>
                </BootstrapTable>
            </main>

            {showCoachModal &&
                <ModalCoachEvent
                    onHide={() => setShowCoachModal(false)}
                    coachingItem={selectedItem}
                    type={selectedItem.coaching_type}
                    updateCoachingSessions={updateCoachingSessions}
                />
            }
        </>
    );
}

DriversCoaching.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    company: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
    }),
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(DriversCoaching);
