import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import CustomButton from '../../CustomButton';

const DisableSMSModal = (props) => {

    return (
        <Modal
            show={true}
            onHide={props.hideDisableSMSModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Disable SMS Notifications</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='notifications-modal-body modal-text'>
                    Are you sure you wish to disable ALL SMS notifications for this user?
                    The user will have to manually subscribe again to re-enable them.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant="delete" type='submit' onClick={props.disableAllSMS}>Disable</CustomButton>
            </Modal.Footer>
            {props.loaderModal}
        </Modal>
    )
}

export default connect(
    state => ({
        loaderModal: state.loader.loaderModal
    })
)(DisableSMSModal);
