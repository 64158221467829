import React from 'react';
import classnames from 'classnames';

const SnapshotChannelNode = ({ activechannel, index, changeChannel }) => {
	const handleClick = (e) => {
		e.preventDefault()
		changeChannel(index)
	}

	return (
		<li className={classnames('list-inline-item', { active: (activechannel === index) })}>
			<a href="/" onClick={handleClick}>
				{index}
			</a>
		</li>
	)
}

export default SnapshotChannelNode;
