import axios from 'axios'
import _ from 'lodash'

const API_URL = process.env.REACT_APP_API_URL

export function getDevicesLastgpsOfDevice(data) {
	console.log('!getDevicesLastgpsOfDevice action data', data)

	const url = `${API_URL}/devices/${data.device_id}/lastevent`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getDevicesEventUpdates(user_role, company_name=null, partner_name=null) {
	// console.log('!getDevicesEventUpdates action data:', user_role, company_name, partner_name)

	let url
	switch(user_role) {
	case 'system_admin':
		// url = `${API_URL}/system_admin/companies/${company_name}/devices/lastevent`;
		url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/devices/eventupdates`
		break
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/devices/eventupdates`
		break
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${company_name}/devices/eventupdates`
		break
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${company_name}/devices/eventupdates`
		break
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/devices/eventupdates`
		break
	default:
		url = `${API_URL}/devices/eventupdates`
		break
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings)
}

export function getDevicesLastgps(data) {
	const { user_role, company_name, partner_name, inactive } = data
	let url

	switch(user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/devices/lastevent`;
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/devices/lastevent`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/devices/lastevent`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${company_name}/devices/lastevent`;
			break;
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${company_name}/devices/lastevent`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/devices/lastevent`;
			break;
		default:
			url = `${API_URL}/devices/lastevent`;
			break;
	}

	if (inactive) {
		url += '?inactive=' + inactive
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getDevicesLive(data) {
	console.log('!gettDevicesLive data: ', data)

	const url = `${API_URL}/live/${data.device_id}/${data.channel}?timestamp=${new Date().getTime()+ Math.random()}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function stopDevicesLive(data) {
	console.log('!stopDevicesLive', data)

	let url;
	switch(data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/live/stop/${data.device_id}/${data.channel}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/live/stop/${data.device_id}/${data.channel}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/live/stop/${data.device_id}/${data.channel}`;
		break;
	default:
		url = `${API_URL}/live/stop/${data.device_id}/${data.channel}`;
		break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getDevicesMetadata(data) {
	console.log('!getDevicesMetadata action data', data)

	const url = `${API_URL}/devices/metadata?start_ts=${data.start_ts}&end_ts=${data.end_ts}&ids=${data.device_ids}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

/* Get last known GPS or Alert location */
export function getDevicesMetadataLastKnown(data) {
	console.log('!getDevicesMetadataLastKnown', data)

	const url = `${API_URL}/devices/metadata/last24?ids=${data.device_ids}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getDeviceMetadata(data) {
	console.log('!getDeviceMetadata', data)

	const url = `${API_URL}/devices/${data.device_id}/metadata?start_ts=${data.start_ts}&end_ts=${data.end_ts}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function createDevicesCustomer(data) {
	console.log('!createDevicesCustomer action data: ', data);
	let url;
	switch (data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/devices`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/devices`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${data.company_name}/devices`;
			break;
		default:
			break;
	}

	const form = new FormData();
	if (data.snlist) {
		form.append('snlist', data.snlist);
	}
	if (data.number) {
		form.append('number', data.number);
	}
	// form.append("assigned_device_ids", ids);

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	};
	return axios(settings);
}

export function getAllDevices(userRole, companyName = null, partnerName = null, fields = null, version = null, assetFields = null) {
	// console.log('getAllDevices action userRole, companyName, partnerName, fields, version, assetFields: ', userRole, companyName, partnerName, fields, version, assetFields)
	let url;
	switch (userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${partnerName}/companies/${companyName}/devices`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${partnerName}/companies/${companyName}/devices`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${companyName}/devices`;
		break;
	case 'partner_view':
		url = `${API_URL}/partner_view/companies/${companyName}/devices`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/devices`;
		break;
	case 'installer':
		url = `${API_URL}/installer/companies/${companyName}/devices`;
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/partner/${partnerName}/companies/${companyName}/devices`;
		break;
	default:
		break;
	}

	const attr = []
	if (assetFields) {
		attr.push(`include_data=${assetFields}`)
	}

	if (fields) {
		attr.push(`fields=${fields}`)
	}

	if (version) {
		attr.push('version=true')
	}

	if (attr.length > 0) {
		url += `?${attr.join('&')}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}
	return axios(settings);
}

export function getDevicesList(company_id, params = null) {
	// console.log('getDevicesList action company_id: ', company_id)
	let url = `${API_URL}/companies/${company_id}/devices/list`

	if (params) {
		url += '?include_data='+params
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getDevicesDriverID(company_id) {
	const url = `${API_URL}/driver/devices/cardreader?company_id=${company_id}`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function toggleDevicesDriverID(data, company_id) {
	const url = `${API_URL}/driver/devices/cardreader`

	let form = new FormData()
	form.append('enabled', data.enabled)
	form.append('devices', data.devices)
	form.append('company_id', company_id)

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getFleetDevices() {
	console.log('!getFleetDevices')
	const url = `${API_URL}/devices`;
	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

// export function getCustomerDevices(company_name) {
//     console.log('!getCustomerDevices', company_name)
//
//
//
//     let settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": url,
//         "method": "GET",
//         // //"headers": {},
//         "withCredentials": true
//     }
//
//     return axios(settings);
// }

// export function searchDevicesCustomer(state) {
//     console.log('!searchDevicesCustomer', state.data.search)
//
//     const url = `${API_URL}/customer_service/devices/search?keyword=${state.data.search}`;
//
//     let settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": url,
//         "method": "GET",
//         // //"headers": {},
//         "withCredentials": true
//     }
//
//     return axios(settings);
// }

export function getDeviceById(data
	// deviceId,
	// userRole,
	// receiveGeotabData = false,
	// receiveModemData = false,
	// receiveReplace = false,
	// receiveRemove = false,
	// receiveModemRSSI = false,
) {
	console.log('getDeviceById action data:', data);

	let url;
	switch (data.userRole) {
		case 'system_admin':
			url = `${API_URL}/system_admin/devices/${data.deviceId}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/devices/${data.deviceId}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/devices/${data.deviceId}`;
			break;
		case 'installer':
			url = `${API_URL}/provisioning/devices/${data.deviceId}`;
			break;
		default:
			url = `${API_URL}/devices/${data.deviceId}`;
			break;
	}

	const attr = []
	const includeData = []

	if (_.has(data, 'geotab')) {
		attr.push(`geotab=${data.geotab}`)
	}

	if (_.has(data, 'modem')) {
		attr.push(`modem=${data.modem}`)
	}

	if (_.has(data, 'externalSrv')) {
		attr.push(`external_srv=${data.externalSrv}`)
	}

	if (_.has(data, 'includeData')) {
		attr.push(`include_data=${data.includeData}`)
	}

	// if (receiveGeotabData) {
	// 	attr.push('geotab=true')
	// }

	// if (receiveModemData) {
	// 	attr.push('modem=true')
	// }

	//* filling of the include_data
	// if (receiveReplace) {
	// 	includeData.push('replace')
	// }
	// if (receiveRemove) {
	// 	includeData.push('remove')
	// }
	// if (receiveModemRSSI) {
	// 	includeData.push('modem_rssi')
	// }
	// if (includeData.length > 0) {
	// 	attr.push(`include_data=${includeData.join(',')}`)
	// }

	if (attr.length > 0) {
		url += `?${attr.join('&')}`
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		// "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}


export function updateDevice(data, user_role, company, callback) {
	// console.log('!updateDevice action data', data, user_role, company);
	let user_email = data.devicesUsers ? (data.devicesUsers.length < 1 ? 'none' :  data.devicesUsers ) : ''
	let url

	switch (user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/devices/${data.deviceId}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/devices/${data.deviceId}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/devices/${data.deviceId}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'installer':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/devices/${data.deviceId}`;
			break;
		default:
			break;
	}

	let form = new FormData()
	data.data.vehicle_icon ? form.append('vehicle_icon', data.data.vehicle_icon) : ''
	data.data.vehicle_name ? form.append('vehicle_name', data.data.vehicle_name) : ''
	data.data.contract_id ? form.append('contract_id', data.data.contract_id) : ''
	data.data.data_limit ? form.append('data_limit', data.data.data_limit) : ''
	data.data.timezone ? form.append('timezone', data.data.timezone) : ''
	data.data.parking_duration ? form.append('parking_duration', data.data.parking_duration) : ''

	_.has(data.data, 'user_email') ? form.append('user_email', data.data.user_email) : false
	user_email ? form.append('user_email', user_email) : false
	_.has(data.data, 'driver_name') ? form.append('driver_name', data.data.driver_name) : false
	_.has(data.data, 'driver_id') ? form.append('driver_id', data.data.driver_id) : false
	_.has(data.data, 'active') ? form.append('active', data.data.active) : false
	_.has(data.data, 'flood_blocked') ? form.append('flood_blocked', data.data.flood_blocked) : false
	_.has(data.data, 'driver_recognition') ? form.append('driver_recognition', data.data.driver_recognition) : false
	_.has(data.data, 'cardreader') ? form.append('cardreader', data.data.cardreader) : false
	_.has(data.data, 'canbus') ? form.append('canbus', data.data.canbus) : false

	_.has(data.data, 'adas_units') ? form.append('adas_units', data.data.adas_units) : ''
	_.has(data.data, 'adas_vehicle_hood_len') ? form.append('adas_vehicle_hood_len', data.data.adas_vehicle_hood_len) : ''
	_.has(data.data, 'adas_camera_height') ? form.append('adas_camera_height', data.data.adas_camera_height) : ''
	_.has(data.data, 'adas_vehicle_width') ? form.append('adas_vehicle_width', data.data.adas_vehicle_width) : ''
	_.has(data.data, 'adas_camera_offset') ? form.append('adas_camera_offset', data.data.adas_camera_offset) : ''
	// _.has(data.data, 'data_threshold_pct') ? form.append('data_threshold_pct', data.data.data_threshold_pct) : ''
	// _.has(data.data, 'data_threshold_type') ? form.append('data_threshold_type', data.data.data_threshold_type) : ''
	_.has(data.data, 'disable_live_stream') ? form.append('disable_live_stream', data.data.disable_live_stream) : ''
	// _.has(data.data, 'sleep_mode_enabled') ? form.append('sleep_mode_enabled', data.data.sleep_mode_enabled) : ''


	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		// //"headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	}

	axios(settings)
		.then((res, err)=>{
			console.log('!updateDevice updated user res', res, err)
			if (data.data.groups) {
				if (!_.isEqual(_.sortBy(data.data.groups), _.sortBy(data.initialData.groups))) {
					console.log('Adds groups')

					//* Was removed groups
					const arr_remove = _.difference(data.initialData.groups, data.data.groups)
					let settings_remove = {
						async: true,
						crossDomain: true,
						method: 'DELETE',
						// //"headers": {},
						dataType: 'jsonp',
						withCredentials: true
					}

					//* Was added groups
					const arr_add = _.difference(data.data.groups, data.initialData.groups)
					let settings_add = {
						async: true,
						crossDomain: true,
						method: 'PUT',
						// //"headers": {},
						dataType: 'jsonp',
						withCredentials: true
					}

					const count = arr_remove.length + arr_add.length

					console.log('count', count)
					let i = 0

					console.log('arr_remove', arr_remove)
					arr_remove.forEach(function(item) {

						switch(user_role) {
						case 'system_admin':
							settings_remove.url = `${API_URL}/system_admin/partner/${company.partner}/companies/${company.name}/groups/${item}/devices/${data.deviceId}`;
							break;
						case 'customer_service':
							settings_remove.url = `${API_URL}/customer_service/partner/${company.partner}/companies/${company.name}/groups/${item}/devices/${data.deviceId}`;
							break;
						case 'partner':
						case 'custom_partner':
							// console.log('partner API settings_remove');
							settings_remove.url = `${API_URL}/partners/companies/${company.name}/groups/${item}/devices/${data.deviceId}`;
							break;
						case 'fleet_maintainer':
						case 'fleet_manager':
						case 'storage_manager':
						case 'custom_user':
							settings_remove.url = `${API_URL}/groups/${item}/devices/${data.deviceId}`;
							break;
						}

						axios(settings_remove).then((res, err)=>{
							console.log('!updateDevice remove devices group res', res)

							i++;
							if (i >= count) {
								if (typeof callback === 'function') {
									return callback(null, {result:'ok'})
								}
							}

						})
							.catch((error)=>{
								if (typeof callback === 'function') {
									return callback(error, null)
								}
							})
					})

					console.log('arr_add', arr_add)
					arr_add.forEach(function(item) {
						switch(user_role) {
						case 'system_admin':
							settings_add.url = `${API_URL}/system_admin/partner/${company.partner}/companies/${company.name}/groups/${item}/devices/${data.deviceId}`
							break
						case 'customer_service':
							settings_add.url = `${API_URL}/customer_service/partner/${company.partner}/companies/${company.name}/groups/${item}/devices/${data.deviceId}`
							break
						case 'partner':
						case 'custom_partner':
							settings_add.url = `${API_URL}/partners/companies/${company.name}/groups/${item}/devices/${data.deviceId}`
							break
						case 'fleet_maintainer':
						case 'fleet_manager':
						case 'storage_manager':
						case 'custom_user':
							settings_add.url = `${API_URL}/groups/${item}/devices/${data.deviceId}`
							break
						}


						axios(settings_add).then((res, err)=>{
							console.log('!updateDevice add devices group res')
							console.log(res)

							i++
							if (i >= count) {
								if (typeof callback === 'function') {
									return callback(null, {result:'ok'})
								}
							}
						})
							.catch((error)=>{
								if (typeof callback === 'function') {
									return callback(error, null)
								}
							})
					});

				}
				else {
					if (typeof callback === 'function') {
						return callback(null, {result:'ok'})
					}
				}
			}
			else {
				if (typeof callback === 'function') {
					return callback(null, {result:'ok'})
				}
			}

		})
		.catch((error)=>{
			if (typeof callback === 'function') {
				return callback(error, null)
			}
		})
}

export function deleteDeviceCustomer(data) {
	console.log('!deleteDeviceCustomer action data:', data)

	let url;
	switch(data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/devices/${data.device_id}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/devices/${data.device_id}`;
		break;
	}

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
		// //"headers": {},
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function importGroupData(data){
	console.log('!importGroupData action data:', data);

	const url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/import/move/group/${data.group_name}`

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		// //"headers": {},
		dataType: 'jsonp',
		withCredentials: true
	};

	return axios(settings);
}

export function importDeviceData(deviceId, action){
	console.log('!importDeviceData action data:', deviceId, action);

	let url = `${API_URL}/import/${action}/${deviceId}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		// //"headers": {},
		dataType: 'jsonp',
		withCredentials: true
	};

	return axios(settings);
}


export function cancelImport(deviceId){
	let url = `${API_URL}/import/cancel/${deviceId}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		// //"headers": {},
		dataType: 'jsonp',
		withCredentials: true
	};

	return axios(settings);
}

export function getDevicesModems(data){
	console.log('!getDevicesModems action data:', data);

	let url;
	switch(data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/partner/${data.partner_name}/companies/${data.company_name}/devices/modems`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/partner/${data.partner_name}/companies/${data.company_name}/devices/modems`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/companies/${data.company_name}/devices/modems`;
		break;
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		//"headers": {Pragma: 'no-cache'},
		withCredentials: true
	}

	return axios(settings);
}

export function getDeviceAddress(data){
	let url =`${API_URL}/address?lat=${data.lat}&lng=${data.lng}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		withCredentials: true
	}

	return axios(settings);
}