import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import AccountUserContainer from './AccountUserContainer'
import AccountChangePasswordContainer from './AccountChangePasswordContainer'
import ModalDelete from '../modals/ModalDelete'


import '../../dump/menuComponents/menuComponents.sass'
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';

const AccountSettings = (props) => {
	// console.log('PROPS AccountSettings: ', props)
	const { user, location } = props;
	
	const [tabKey, setTabKey] = useState(1)
	const [userStatus, setUserStatus] = useState(null)
	const [showWelcomeNotifications, setShowWelcomeNotifications] = useState(false)

	useEffect(() => {
		const userRole = user.roles[0];

		if (location && location.state) {
			if (location.state.status === 'firstly' && ['provisioning', 'installer', 'partner_view', 'sales_demo', 'storage_user', 'custom_user'].indexOf(userRole) === -1) {
				//* The user first login
				// setTabKey(2);
				setShowWelcomeNotifications(true)
				setTabKey(1);
			} else {
				setTabKey(1)
			}
			setUserStatus('firstly');

			browserHistory.replace({ pathname: '/account/settings', state: {} });
		}
	}, [])

	const handleSelect = (key) => {
		setTabKey(key)
	}

	return (
		<div className="account-settings">
			<GreyHeader
				title='Account'
				renderSubtitle={
					<nav className="page-subtitle">
						<CustomButton variant='subtitle' active={tabKey === 1} onClick={() => handleSelect(1)}>
							Settings
						</CustomButton>
						<CustomButton variant='subtitle' active={tabKey === 2} onClick={() => handleSelect(2)}>
							Password
						</CustomButton>
					</nav>
				}
			/>

			{tabKey === 1 && <AccountUserContainer />}
			{tabKey === 2 && <AccountChangePasswordContainer user_status={userStatus} />}

			{showWelcomeNotifications && (
				<ModalDelete
					title="Email & SMS Notifications"
					content={`Welcome to RoscoLive! \n\n This is your User Settings page. Here you can update some info about yourself, change some preferences, change your password, and subscribe to email and SMS notifications! \n\n We’ve taken the liberty of enabling some email notifications for you to get you started, these are denoted by the blue toggles. You can edit or toggle these off by clicking the toggle to open the settings menu for that notification.`}
					styleView="primary"
					deleteBtnName="OK"
					deleteModal={() => setShowWelcomeNotifications(false)}
				/>
			)}
		</div>
	);
}

export default connect(
	state => ({
		user: state.user.user
	})
)(AccountSettings);
