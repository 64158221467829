import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';

const LiveVideoLayout = (props) => {

	useEffect(() => {
		if (isMobile) {
			document.body.classList.add('mobile');
		}
	}, [])

	return (
		<div className="container">
			{props.children}
		</div>
	);
}

export default LiveVideoLayout;
