import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { withGoogleMap, GoogleMap, Rectangle, Marker } from 'react-google-maps';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux'

import { default as DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager';
import { default as SearchBox } from 'react-google-maps/lib/components/places/SearchBox';

import GreyHeader from '../../component/dump/menuComponents/GreyHeader'
import ModalDelete from '../../component/smart/modals/ModalDelete';
import { getCompanyWifipoints, saveCompanyWifipoints, deleteCompanyWifiPoint } from '../../action/WCSActions';
import { getDeviceAddress } from '../../action/DeviceActions'
import { googleMapStylesList } from '../../component/helpers/constants'
import CustomButton from '../../component/CustomButton';

const INPUT_STYLE = {
	boxSizing: 'border-box',
	MozBoxSizing: 'border-box',
	border: '1px solid transparent',
	width: '240px',
	height: '32px',
	marginTop: '10px',
	padding: '0 12px',
	borderRadius: '1px',
	boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
	fontSize: '14px',
	outline: 'none',
	textOverflow: 'ellipses',
};

const SimpleMapExampleGoogleMap = withGoogleMap((props) => {
	return (
		<GoogleMap
			ref={props.onMapMounted}
			zoom={props.zoom}
			center={props.center}
			onZoomChanged={props.onZoomChanged}
			options={{
				styles: googleMapStylesList.default_map, // FWSD-3757, FWSD-4457
			}}
		>
			{props.bounds.map((bound, index) => {

				// let rectangle = new google.maps.Rectangle({
				// 	bounds: bound,
				// 	options:{
				// 		fillColor: '#ff0000',
				// 		fillOpacity: 0.5,
				// 		strokeWeight: 2,
				// 		zIndex: 1
				// 	},
				// 	editable: true,
				// 	draggable: true,
				// 	clickable: true
				// });
				// // rectangle.setMap(props._map);
				// google.maps.event.addListener(rectangle, 'bounds_changed', function() {
				// 		var bounds =  rectangle.getBounds();
				// 		var ne = bounds.getNorthEast();
				// 		var sw = bounds.getSouthWest();
				// 	console.log('bounds_changed', ne, sw);
				// 		//do whatever you want with those bounds
				// });

				// const address = JSON.parse(bound.address)
				const area = bound.area;
				const latlng = new google.maps.LatLngBounds(
					new google.maps.LatLng(area.swlat, area.swlng),
					new google.maps.LatLng(area.nelat, area.nelng),
				)

				const center_map = latlng.getCenter();
				const location = { lat: center_map.lat(), lng: center_map.lng() };

				return (
					<span key={index}>
						<Rectangle
							key={'rec' + index}
							bounds={latlng}
							options={{
								fillColor: '#ff0000',
								fillOpacity: 0.5,
								strokeWeight: 2,
								zIndex: 1
							}}
							editable={false}
							draggable={false}
							clickable={false}
						/>

						{props.zoom < 18
							&& (
								<Marker
									key={'marker' + index}
									position={location}
									visible
									opacity={1}
									onClick={() => props.onSelectMarker(bound)}
								/>
							)}
					</span>
				)
			})}

			<SearchBox
				ref={props.onSearchBoxMounted}
				// bounds={props.bounds}
				controlPosition={google.maps.ControlPosition.TOP_LEFT}
				onPlacesChanged={props.onPlacesChanged}
				inputPlaceholder="Customized your placeholder"
				inputStyle={INPUT_STYLE}
			// inputProps={{
			// 	onBlur: () => { alert(':)') }
			// }}
			/>

			<DrawingManager
				// defaultDrawingMode={google.maps.drawing.OverlayType.RECTANGLE}
				defaultOptions={{
					drawingControl: true,
					drawingControlOptions: {
						position: google.maps.ControlPosition.TOP_CENTER,
						drawingModes: [
							// google.maps.drawing.OverlayType.CIRCLE,
							// google.maps.drawing.OverlayType.POLYGON,
							// google.maps.drawing.OverlayType.POLYLINE,
							google.maps.drawing.OverlayType.RECTANGLE,
						],
					},
					rectangleOptions: {
						editable: true,
						draggable: true,
						clickable: true,
						fillColor: '#ff0000',
						fillOpacity: 0.5,
						strokeWeight: 2,
						zIndex: 1
					},
					// circleOptions: {
					// 	fillColor: `#ffff00`,
					// 	fillOpacity: 1,
					// 	strokeWeight: 5,
					// 	clickable: false,
					// 	editable: true,
					// 	zIndex: 1,
					// },
				}}

				onOverlayComplete={(e) => {
					console.log('onOverlayComplete', e)
					const self = this;
					const rectangle = e.overlay;
					const id = new Date().getTime();
					props.handleRectangleComplete(rectangle, id);

					google.maps.event.addListener(rectangle, 'bounds_changed', function () {
						props.handleBoundsChanged(rectangle, id);
					});
				}}
			// onRectanglecomplete={(e) => {console.log('send', e); }}
			// onRectangleComplete ={(e,p,t) => {alert('onRectangleComplete'); console.log(e,p,t)}}
			// onMarkerComplete    ={() => alert('onMarkerComplete')}
			// onCircleComplete    ={() => alert('onCircleComplete')}
			// onPolygonComplete   ={() => alert('onPolygonComplete')}
			// onPolylineComplete  ={() => alert('onPolylineComplete')}
			/>
		</GoogleMap>
	)
});

const CompanyEditWCSPoints = (props) => {
	const { user, location } = props;
	const user_role = user.roles[0];
	const _company_name = location.state.company.company_name;
	const _partner_name = location.state.company.partner_company;

	const [center, setCenter] = useState({ lat: 37.09024, lng: -95.712891 });
	const [zoom, setZoom] = useState(6);
	const [bounds, setBounds] = useState([]);
	const [newbounds, setNewbounds] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deletePoint, setDeletePoint] = useState(null);
	const [_map, setMap] = useState(null);
	const [_searchBox, setSearchBox] = useState(null);

	useEffect(() => {
		// console.log('CompanyEditWCSPoints')
		props.onRef(CompanyEditWCSPoints)

		toggleLoader(true);

		getWifiPoints(() => {
			centeringWifiPoints()
		})

		return () => {
			props.onRef(undefined)
		}
	}, []);

	const handleMapMounted = (map) => {
		setMap(map);
	}

	const onZoomChangedManually = (e, p) => {
		const zoom = _map.getZoom();
		setZoom(zoom);
	}

	const getWifiPoints = (callback) => {
		getCompanyWifipoints({
			user_role,
			company_name: _company_name,
			partner_name: _partner_name
		})
			.then((res, err) => {
				toggleLoader(false);
				console.log('!getCompanyWifipoints res: ', res, err);

				setBounds(res.data.response.wifipoints)

				if (callback && typeof callback === 'function') {
					callback();
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getCompanyWifipoints error:', error);
			});
	}

	const centeringWifiPoints = () => {
		let bounds = new google.maps.LatLngBounds();
		let boundsArr = [];

		bounds.map((item, i) => {
			if (item.area) {
				const { area } = item;

				const latlng = new google.maps.LatLngBounds(
					new google.maps.LatLng(area.swlat, area.swlng),
					new google.maps.LatLng(area.nelat, area.nelng),
				)
				const center = latlng.getCenter();
				boundsArr.push(latlng);
			}
		})

		if (boundsArr.length) {
			if (boundsArr.length === 1) {
				if (_map) {
					_map.fitBounds(boundsArr[0]);
					_map.panToBounds(boundsArr[0]);
					setCenter(boundsArr[0].getCenter());
				}
			} else {
				boundsArr.forEach(function (element, index) {
					bounds.extend(element.getCenter());
				});

				if (_map) {
					_map.fitBounds(bounds);
					_map.panToBounds(bounds);
					const center_map = _map.getCenter();
					setCenter({ lat: center_map.lat(), lng: center_map.lng() });
				}
			}
		}
	}

	const handleRectangleComplete = (rectangle, id) => {
		toggleLoader(true);
		const bounds = rectangle.getBounds();
		const center = bounds.getCenter();
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest()

		const data = { lat: center.lat(), lng: center.lng() };
		getDeviceAddress(data)
			.then(res => {
				let address = formatAddress(res.data.response.address)
				address = prompt('Enter the wifi pointer name or change address', address)

				let newboundsUpdate = newbounds;
				newboundsUpdate.push({
					id,
					area: { nelat: ne.lat(), nelng: ne.lng(), swlat: sw.lat(), swlng: sw.lng() },
					rectangle,
					name: address,
				})
				setNewbounds(newboundsUpdate);
				toggleLoader(false);
			})
			.catch(err => {
				console.log(err);
			})
	}

	const handleBoundsChanged = (rectangle, id) => {
		const bounds = rectangle.getBounds();
		const ne = bounds.getNorthEast();
		const sw = bounds.getSouthWest();

		let newboundsUpdate = newbounds;
		let point = _.find(newboundsUpdate, ['id', id]);
		if (point) {
			// point.area = JSON.stringify({nelat: ne.lat(), nelng: ne.lng(), swlat: sw.lat(), swlng: sw.lng()});
			point.area = { nelat: ne.lat(), nelng: ne.lng(), swlat: sw.lat(), swlng: sw.lng() };
			setNewbounds(newboundsUpdate);
		}

		let contentString = '' + ne.lat() + ', ' + ne.lng() + '-' + sw.lat() + ', ' + sw.lng();
	}

	const onTreeItemClick = (rectangle) => {
		// const address = JSON.parse(rectangle.address);
		const { area } = rectangle;

		const latlng = new google.maps.LatLngBounds(
			new google.maps.LatLng(area.swlat, area.swlng),
			new google.maps.LatLng(area.nelat, area.nelng),
		)
		const center = latlng.getCenter();

		if (_map) {
			_map.fitBounds(latlng);
			_map.panToBounds(latlng);
			setCenter(center);
		}
	}

	const handleSearchBoxMounted = (searchBox) => {
		setSearchBox(searchBox);
	}

	const handlePlacesChanged = () => {
		const places = _searchBox.getPlaces();

		if (places.length) {
			setZoom(18);
			setCenter(places[0].geometry.location);
		}
	}

	const cancel = () => {
		browserHistory.push('/company');
	}

	const saveWifipoints = () => {
		if (newbounds.length) {
			toggleLoader(true);

			// wifipoints   = _.map(this.state.newbounds, 'area');
			const wifipoints = _.map(newbounds, item => ({ area: item.area, name: item.name }))

			saveCompanyWifipoints({
				user_role,
				company_name: _company_name,
				partner_name: _partner_company,
				points: wifipoints,
			})
				.then((res, err) => {
					console.log('saveWifipoints res', res, err);
					getWifiPoints(() => {
						newbounds.map((point, i) => {
							point.rectangle.setMap(null);
						})

						setNewbounds([]);
						toastr.success('', 'Wifi points were saved');
					});
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('saveWifipoints error', error);
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				});
		} else {
			toastr.warning('Warning', 'Company does not have new wifi points');
		}
	}

	const deleteWifiPoint = () => {
		if (deletePoint.type === 'exist') {
			toggleLoader(true);

			const wifipoint_id = deletePoint.id;
			deleteCompanyWifiPoint({
				user_role,
				company_name: _company_name,
				partner_name: _partner_name,
				wifipoint_id,
			})
				.then((res, err) => {
					toggleLoader(false);
					console.log('!deleteWifiPoint res:', res, err);

					const boundsUpdate = bounds.filter(bound => bound.id != wifipoint_id)
					setBounds(boundsUpdate);
				})
				.catch((error) => {
					toggleLoader(false);

					console.log('!deleteWifiPoint error', error);

					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				});
		} else if (deletePoint.type === 'new') {
			let delRectangle = null;
			const newboundsUpdate = newbounds.filter((bound) => {
				if (bound.id === deletePoint.id) {
					delRectangle = bound.rectangle;
				} else {
					return true;
				}
			})

			if (delRectangle) {
				delRectangle.setMap(null);
			}

			setNewbounds(newboundsUpdate);
		}

		setShowDeleteModal(false);
		setDeletePoint(null);
	}

	const openDeleteModal = (type, id = null) => {
		setShowDeleteModal(true);
		setDeletePoint({ type, id });
	}

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
	}

	return (
		<div>
			<GreyHeader
				{...props.headerOptions}
			/>
			<div className='page-subheader'>
				<div className='subheader-text-container'>
					<div className='subheader-text'>
						Wifi Points: {_company_name}
					</div>
				</div>
				<div className="subheader-section">
					<CustomButton type="button" variant="secondary" onClick={saveWifipoints}>SAVE</CustomButton>
					<CustomButton type="button" variant="secondary-outline" onClick={cancel}>BACK</CustomButton>
				</div>
			</div>

			<main className="track-container-inner" style={{ position: 'relative' }}>
				<div id="block-tree" style={{ float: 'left', position: 'relative', height: '800px', width: '340px', padding: '15px' }}>
					<div className="wifipoints-tree">
						<ol>
							{newbounds.map((rectangle, index) => {
								const { area } = rectangle;
								const { id } = rectangle;
								return (
									<li
										key={index}
										style={{ color: '#52e258' }}
										onClick={() => onTreeItemClick(rectangle)}
									>
										{rectangle.name ? rectangle.name : `${area.nelat}, ${area.nelng}`}
										<span className="delete-wifipointer-icon">
											<i className="fa fa-remove" onClick={() => { openDeleteModal('new', id) }} />
										</span>
									</li>
								)
							})}

							{bounds.map((rectangle, index) => {
								const { area } = rectangle;
								const { id } = rectangle;
								return (
									<li
										key={index}
										onClick={() => onTreeItemClick(rectangle)}
									>
										{rectangle.name ? rectangle.name : `${area.nelat}, ${area.nelng}`}
										<span className="delete-wifipointer-icon">
											<i className="fa fa-remove" onClick={() => { openDeleteModal('exist', id) }} />
										</span>
									</li>
								)
							})}
						</ol>
					</div>
				</div>

				<div id="map-block" style={{ top: 'auto', bottom: 'auto', left: '340px' }}>
					<SimpleMapExampleGoogleMap
						containerElement={
							(isMobileOnly)
								? <div style={{ height: 'calc(100vh - 50px)' }} className="container-element" />
								: <div style={{ height: '800px' }} className="container-element" />
						}
						mapElement={
							<div style={{ height: '100%' }} className="map-element" />
						}
						center={center}
						zoom={zoom}
						bounds={bounds}
						_map={_map}
						onMapMounted={handleMapMounted}
						onZoomChanged={onZoomChangedManually}
						handleRectangleComplete={handleRectangleComplete}
						handleBoundsChanged={handleBoundsChanged}
						onSelectMarker={onTreeItemClick}
						onSearchBoxMounted={handleSearchBoxMounted}
						onPlacesChanged={handlePlacesChanged}
					/>

					{showDeleteModal
						&& (
							<ModalDelete
								content="Are you sure you want to delete this wifi point ?"
								closeModal={closeDeleteModal}
								deleteModal={deleteWifiPoint}
							/>
						)}
				</div>
			</main>
		</div >
	)
}

CompanyEditWCSPoints.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(
	state => ({
		user: state.user.user,
	}),
)(CompanyEditWCSPoints);
