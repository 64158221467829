const initialState = {
	company: {},
	fetching: false,
};

export default function company(state = initialState, action) {
	switch (action.type) {
		case 'GET_COMPANY_SUCCESS':
			return { ...state, ...{ company: action.payload, fetching: false } };
		case 'EDIT_COMPANY':
			return { ...state, company: { ...state.company, ...action.payload } };
		default:
			return state
	}
}
