import React from 'react'
import { connect } from 'react-redux'
import { AuthorizedComponent } from 'react-router-role-authorization'

const RestrictedContainer = (props) => {
        // console.log('!!!notAuthorizedPath', props)
        const userRoles = props.user.roles;
        const notAuthorizedPath = '/not-found';

        return (
            <div>
                {props.children}
            </div>
        )
    }

export default connect(
    state =>({
        user: state.user.user
    })
)(RestrictedContainer);
