import React, { useState, useEffect } from 'react'
import { SplitButton, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import classnames from 'classnames'
import { apiCallGet } from '../../../action/RouterActions'
import DropdownItem from "react-bootstrap/DropdownItem";
import GreyHeader from '../../GreyHeader'
import _ from 'lodash'
import imgRefresh from '../../../../../assets/imgRefresh.svg'

const DATERANGES = [
	{ title: '1 Hour', value: '1h', range: 1, group_range: 1, unit: 'minute', unit_step: 2, duration_unit: 'hour', duration_unit_step: 1 },
	{ title: '3 Hours', value: '3h', range: 3, group_range: 1, unit: 'minute', unit_step: 10, duration_unit: 'hour', duration_unit_step: 1 },
	{ title: '12 Hours', value: '12h', range: 12, group_range: 5, unit: 'hour', unit_step: 1, duration_unit: 'hour', duration_unit_step: 1 },
	{ title: '1 Day', value: '1d', range: 24, group_range: 10, unit: 'hour', unit_step: 1, duration_unit: 'hour', duration_unit_step: 1 },
	{ title: '1 Week', value: '1w', range: 168, group_range: 60, unit: 'day', unit_step: 1, duration_unit: 'day', duration_unit_step: 1 },
	{ title: '1 Month', value: '1m', range: 744, group_range: 200, unit: 'day', unit_step: 2, duration_unit: 'day', duration_unit_step: 1 },
],
	REFRESH_INTERVALS = [
		{ title: '10 Seconds', value: '10' },
		{ title: '1 Minute', value: '60' },
		{ title: '5 Minutes', value: '300' },
		{ title: '15 Minutes', value: '900' },
		{ title: '30 Minutes', value: '1800' },
		{ title: '1 Hour', value: '3600' },
	]

const generateColor = () => {
	return '#' + Math.random().toString(16).substr(-6)
}

const FleetAdminServerStatsContainer = (props) => {

	const { user, toggleLoader } = props;

	const [interval, setIntervalState] = useState(null);
	const [historyState, setHistoryState] = useState({});
	const [durationState, setDurationState] = useState({});
	const [selectedRange, setSelectedRange] = useState(1);
	const [refreshAuto, setRefreshAuto] = useState(false);
	const [refreshInterval, setRefreshInterval] = useState(60);

	useEffect(() => {
		switch (user.user.roles[0]) {
			case 'system_admin':
				getServerStatsHistory()
				break
			default:
				break;
		}
	}, []);

	useEffect(() => {
		return () => {
			if (interval) {
				clearInterval(interval)
				setIntervalState(null)
			}
		}
	}, [interval]);

	const getServerStatsHistory = async () => {
		toggleLoader(true)

		const obj = _.find(DATERANGES, { 'range': selectedRange })
		const start_ts = moment().utc().subtract(selectedRange, 'hours').format('YYYYMMDDHHmmss')
		const end_ts = moment().utc().format('YYYYMMDDHHmmss')
		const group_range = obj.group_range

		let requestArray = []
		requestArray.push(apiCallGet('/system_admin/stats/history', { start_ts, end_ts, group_range }))
		requestArray.push(apiCallGet('/system_admin/stats/socket/duration', { start_ts, end_ts }))

		await Promise.all(requestArray).then(
			res => {
				toggleLoader(false)
				console.log('Promise.all res', res)

				const history = res[0].data.response,
					duration = res[1].data.response;

				setHistoryState(history);
				setDurationState(duration);
			},
			error => {
				toggleLoader(false)

				let errcont = _.get(error, 'response.data.response.error')
				if (!errcont) {
					errcont = JSON.stringify(error)
				}
				toastr.error("", errcont)
			}
		)
	}

	const daterangeClick = (range) => {
		setSelectedRange(range);
		getServerStatsHistory();
	}

	const autorefreshClick = (auto = refreshAuto, intervalFnc = refreshInterval) => {
		console.log('autorefreshClick', auto, intervalFnc)

		setRefreshAuto(auto);
		setRefreshInterval(intervalFnc);

		if (interval) {
			clearInterval(interval)
			setIntervalState(null)
		}

		if (auto) {
			getServerStatsHistory()
			setIntervalState(setInterval(function () {
				getServerStatsHistory()
			}, intervalFnc * 1000))
		}
	}

	const history = historyState.history || {},
		timestamps = (history.timestamp) ? history.timestamp.map((item) => moment.utc(item).local()) : [],
		duration = durationState.history || {},
		patternColors = ['rgba(75,192,192,1)', '#ff7f0e', '#b3dc99', '#7d5cff', '#e92c6c'],
		patternDataset = {
			fill: false,
			lineTension: 0,
			borderWidth: 2,
			pointRadius: 0,

			borderCapStyle: 'butt',
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: 'miter',
			pointBackgroundColor: '#fff',
			pointBorderWidth: 2,
			pointHoverRadius: 5,
			pointHoverBorderColor: 'rgba(220,220,220,1)',
			pointHoverBorderWidth: 2,
			pointHitRadius: 10,
		},
		obj = _.find(DATERANGES, { 'range': selectedRange }),
		optionsLine = {
			responsive: true,
			maintainAspectRatio: false, //* убирает соблюдение пропорций высоты и ширины графика и так можно выставлять высоту и ширину графика
			legend: {
				display: true,
				position: 'top',
				align: 'start',
				textAlign: 'center',
				labels: {
					boxWidth: 20, //* Width of coloured box near with label
					fontColor: '#000', //* Color of label text
					align: 'start',
				}
			},
			scales: {
				yAxes: [{
					ticks: {
					}
				}],
				xAxes: [
					{
						title: {
							text: 'title_x_axis',
							style: {
								fontWeight: 'bold'
							},
							formatter: function () {
								return "<h3><b>" + this.value + "</b></h3>";
							}
						},
						type: "time",
						time: {
							unit: obj.unit,
							unitStepSize: obj.unit_step,
							tooltipFormat: "YYYY-MM-DD HH:mm:ss",
							displayFormats: {
								millisecond: 'mm:ss',
								second: 'mm:ss',
								hour: 'HH:mm',
								minute: 'HH:mm',
								day: 'MM/DD',
								week: 'MM/DD',
								month: 'YYYY MMM',
								quarter: 'YYYY MMM',
							},
						},

						gridLines: {
							display: false,
						},

						ticks: {
							fontSize: 12,
							lineHeight: 1.2,
							autoSkip: false,
							maxRotation: 0,
							minRotation: 0,
						}
					}
				]
			}
		},
		dataQuantityСonnects = {
			labels: timestamps,
			datasets: [
				{ ...patternDataset, ...{ label: 'Connects', data: history.ads_connections, backgroundColor: patternColors[0], borderColor: patternColors[0], pointBorderColor: patternColors[0], pointHoverBackgroundColor: patternColors[0] } },
				{ ...patternDataset, ...{ label: 'Disconnects', data: history.ads_disconnects, backgroundColor: patternColors[1], borderColor: patternColors[1], pointBorderColor: patternColors[1], pointHoverBackgroundColor: patternColors[1] } }
			]
		},
		dataEventsInQueue = {
			labels: timestamps,
			datasets: [
				{ ...patternDataset, ...{ label: 'Event Clips', data: history.clips_in_queue, backgroundColor: patternColors[0], borderColor: patternColors[0], pointBorderColor: patternColors[0], pointHoverBackgroundColor: patternColors[0] } }
			]
		},
		dataDownloadedClips = {
			labels: timestamps,
			datasets: [
				{ ...patternDataset, ...{ label: 'Downloaded Clips', data: history.clips_last_hour, backgroundColor: patternColors[0], borderColor: patternColors[0], pointBorderColor: patternColors[0], pointHoverBackgroundColor: patternColors[0] } }
			]
		},
		dataOnOffDevices = {
			labels: timestamps,
			datasets: [
				{ ...patternDataset, ...{ label: 'Online', data: history.devices_online, backgroundColor: patternColors[0], borderColor: patternColors[0], pointBorderColor: patternColors[0], pointHoverBackgroundColor: patternColors[0] } },
				{ ...patternDataset, ...{ label: 'Offline', data: history.devices_offline, backgroundColor: patternColors[1], borderColor: patternColors[1], pointBorderColor: patternColors[1], pointHoverBackgroundColor: patternColors[1] } }
			]
		},
		dataConDiscLive = {
			labels: timestamps,
			datasets: [
				{ ...patternDataset, ...{ label: 'Connects', data: history.live_connects, backgroundColor: patternColors[0], borderColor: patternColors[0], pointBorderColor: patternColors[0], pointHoverBackgroundColor: patternColors[0] } },
				{ ...patternDataset, ...{ label: 'Disconnects', data: history.live_disconnects, backgroundColor: patternColors[1], borderColor: patternColors[1], pointBorderColor: patternColors[1], pointHoverBackgroundColor: patternColors[1] } }
			]
		},
		dataLiveStreams = {
			labels: timestamps,
			datasets: [
				{ ...patternDataset, ...{ label: 'Live Streams', data: history.live_video_now, backgroundColor: patternColors[0], borderColor: patternColors[0], pointBorderColor: patternColors[0], pointHoverBackgroundColor: patternColors[0] } }
			]
		}


	let optionsLineDuration = _.cloneDeep(optionsLine)
	optionsLineDuration.scales.xAxes[0].time.unit = obj.duration_unit
	optionsLineDuration.scales.xAxes[0].time.unitStepSize = obj.duration_unit_step

	let file_server_avg_duration = [], //(duration.servers_duration) ? duration.servers_duration.data.file_server_avg_duration : [],
		live_server_avg_duration = [], //(duration.servers_duration) ? duration.servers_duration.data.live_server_avg_duration : [],
		timestamp_servers_duration = [] //(duration.servers_duration) ? duration.servers_duration.data.timestamp : [],
	if (duration.servers_duration) {
		file_server_avg_duration = duration.servers_duration.data.file_server_avg_duration || []
		live_server_avg_duration = duration.servers_duration.data.live_server_avg_duration || []
		timestamp_servers_duration = (duration.servers_duration.data.timestamp) ? duration.servers_duration.data.timestamp.map((item) => moment.utc(item).local().format('YYYY-MM-DD HH:mm:ss')) : []
	}

	const dataServersDuration = {
		labels: timestamp_servers_duration,
		datasets: [
			{ ...patternDataset, ...{ label: 'FileServer', data: file_server_avg_duration, backgroundColor: patternColors[0], borderColor: patternColors[0], pointBorderColor: patternColors[0], pointHoverBackgroundColor: patternColors[0] } },
		]
	}

	const modems_duration = (duration.modems_duration) ? duration.modems_duration.data : [],
		timestamp = _.uniq(modems_duration.map((item) => moment.utc(item.timestamp).local().format('YYYY-MM-DD HH:mm:ss'))).sort()

	let datasets = [],
		emptyobj = {},
		dataModemsDuration = {
			labels: timestamp,
			datasets: []
		}

	timestamp.map((item) => (emptyobj[item] = 0))

	//* Creates object with model's names and avg_duration per each timestamp
	let result = {}
	modems_duration.map((item, index) => {
		if (!result[item.model]) {
			result[item.model] = _.clone(emptyobj)
		}

		result[item.model][item.timestamp] = item.duration
	})
	//***
	// result >>> {
	// "null":{"2019-10-27 01:00:00":0,"2019-10-28 01:00:00":75,"2019-10-28 02:00:00":0,"2019-10-28 03:00:00":35,"2019-10-28 15:00:00":0},
	// "LE910-EUG":{"2019-10-27 01:00:00":60.5,"2019-10-28 01:00:00":50.25,"2019-10-28 02:00:00":55,"2019-10-28 03:00:00":0,"2019-10-28 15:00:00":85},
	// "model3":{"2019-10-27 01:00:00":0,"2019-10-28 01:00:00":140,"2019-10-28 02:00:00":0,"2019-10-28 03:00:00":0,"2019-10-28 15:00:00":0}
	// }

	_.keys(result).forEach((item, index) => {
		//* Set up the color of the graph line 
		let color = generateColor()
		if (index < patternColors.length) {
			color = patternColors[index]
		}
		//***

		//* Get durations for the modem model
		const modelDurations = _.values(result[item])
		//***

		//* Set up dataset for the modem model
		const ds = { ...patternDataset, ...{ label: (item) ? item : 'None', data: modelDurations, backgroundColor: color, borderColor: color, pointBorderColor: color, pointHoverBackgroundColor: color } }
		datasets.push(ds)
		//***
	})
	dataModemsDuration.datasets = datasets;

	return (
		<div className="fleet-admin-server-stats">
			<GreyHeader title='Server Stats' hideSubtitle />
			<div className='empty-div'></div>

			<div className='page-subheader'>
				<div className="stats-panel">
					<ul role="toolbar" className="daterange-control">
						{
							DATERANGES.map(item => (
								<DaterangeNode
									key={item.title}
									active={selectedRange}
									{...item}
									onClick={daterangeClick}
								/>
							))
						}

						<RefreshNode
							refresh_auto={refreshAuto}
							refresh_interval={refreshInterval}
							autorefreshClick={autorefreshClick}
							refreshClick={getServerStatsHistory}
						/>
					</ul>
				</div>
			</div>

			<main className="main-content-block">
				<section>
					<h3 className="category-title">Quantity of connects/disconnects fileserver</h3>
					<div style={{ clear: 'both' }}>
						<Line
							data={dataQuantityСonnects}
							height={350}
							options={optionsLine}
						/>
					</div>
				</section>

				<section>
					<h3 className="category-title">Quantity of event clips in queue for uploading</h3>
					<div style={{ clear: 'both' }}>
						<Line
							data={dataEventsInQueue}
							height={350}
							options={optionsLine}
						/>
					</div>
				</section>

				<section>
					<h3 className="category-title">Quantity of downloaded clips</h3>
					<div style={{ clear: 'both' }}>
						<Line
							data={dataDownloadedClips}
							height={350}
							options={optionsLine}
						/>
					</div>
				</section>

				<section>
					<h3 className="category-title">Quantity of all online/offline devices</h3>
					<div style={{ clear: 'both' }}>
						<Line
							data={dataOnOffDevices}
							height={350}
							options={optionsLine}
						/>
					</div>
				</section>

				<section>
					<h3 className="category-title">Quantity of live video streams</h3>
					<div style={{ clear: 'both' }}>
						<Line
							data={dataLiveStreams}
							height={350}
							options={optionsLine}
						/>
					</div>
				</section>

				<section>
					<h3 className="category-title">Average socket duration for FileServer</h3>
					<div style={{ clear: 'both' }}>
						<Line
							data={dataServersDuration}
							height={350}
							options={optionsLineDuration}
						/>
					</div>
				</section>

				<section>
					<h3 className="category-title">Average socket duration for each type of modem</h3>
					<div style={{ clear: 'both' }}>
						<Line
							data={dataModemsDuration}
							height={350}
							options={optionsLineDuration}
						/>
					</div>
				</section>
			</main>
		</div>
	)
}

export default connect(
	state => ({
		user: state.user
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(FleetAdminServerStatsContainer);

const DaterangeNode = (props) => {

	const daterangeClick = () => {
		const { range, onClick } = props
		onClick(range)
	}

	const { title, value, active, range } = props
	return (
		<li>
			<a role="button" title={title} onClick={daterangeClick} className={classnames({ 'active': active == range })}>{value}</a>
		</li>
	)
};

const RefreshNode = (props) => {

	const chbToggle = () => {
		const { refresh_auto, autorefreshClick } = props;
		console.log('chbToggle', refresh_auto, !refresh_auto)
		autorefreshClick(!refresh_auto)
	}

	const radioClick = (e) => {
		const { autorefreshClick } = props,
			interval = e.currentTarget.value
		console.log('radioClick', interval)
		autorefreshClick(true, interval)
	}

	const { refresh_auto, refresh_interval, refreshClick } = props;

	return (
		<li className='refresh-buttons'>
			<SplitButton
				title={<img heigth="18" width="18" src={imgRefresh} />}
				id={'split-button-basic'}
				onClick={refreshClick}
			>
				<div className="checkbox">
					<label title="">
						<input
							type="checkbox"
							name="autoRefresh"
							checked={refresh_auto}
							onChange={chbToggle} />
						Auto refresh
					</label>
				</div>

				<hr />

				<div className="menu-item">Refresh interval</div>

				<div className="menu-items">
					{REFRESH_INTERVALS.map(item => {
						return (
							<div className="radio" key={item.title}>
								<label title="">
									<input
										type="radio"
										name="refreshInterval"
										value={item.value}
										checked={item.value == refresh_interval}
										onChange={(e) => radioClick(e)}
									/>
									{item.title}
								</label>
							</div>
						)
					})
					}
				</div>
			</SplitButton>
		</li >
	)
};
