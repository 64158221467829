import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CustomButton from '../../CustomButton';

const AddProfileModal = (props) => {

	const { onHide, profiles, addProfile } = props;

	const [selectedProfile, setSelectedProfile] = useState(profiles.find(profile => profile.name === 'Default'))

	const selectNewProfile = (e) => {
		const newProfile = profiles.find(profile => profile.id.toString() === e.target.value)
		setSelectedProfile(newProfile)
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Add Fleet Profile</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='config-modal-body add-profile-modal'>
					<label>
						Select a fleet profile to use as a starting point for your new profile. If you're
						not sure what to pick, you can always start with the Default profile.
					</label>
					<div className="text-center">
						<select name="profile-select" id="profile-select" className="rosco-select" value={selectedProfile.id} onChange={selectNewProfile}>
							{profiles.map((item, index) =>
								<option key={index} value={item.id} className="menu-item">{item.name}</option>
							)}
						</select>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={() => addProfile(selectedProfile.name)}>Create New Profile</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

export default AddProfileModal;