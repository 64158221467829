import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Link, browserHistory } from 'react-router'
import moment from 'moment'
import classnames from 'classnames'
import _ from 'lodash'
import { connect } from 'react-redux'
import { CSVLink } from 'react-csv'

import { renderCustomHeader, paginationExportOptions, tableAdjustableColExportOptions } from '../../helpers/table'
import { isInternalUser } from '../../helpers/constants'
import { eventStatusFormatter, eventTypeDetection } from '../../helpers/helperEvents'
import { formatTimestamp } from '../../helpers/tablesFuncHelpers'
import momenttz from '../../helpers/momenttz'
import { generalIcons, exportIcon } from '../../helpers/iconHelpers'
import CustomButton from '../../CustomButton'

const COLUMNS_WIDTH = {
	0: {
		'Date / Time': '12%', Type: '12%', Status: '12%', Vehicle: '15%', Groups: '14%', Note: '7%', Reviewed: '10%', ID: '8%', Duration: '8%', RequestedAt: '12%',
	},
	1: {
		'Date / Time': '12%', Type: '12%', Status: '12%', Vehicle: '12%', Groups: '10%', Note: '6%', Reviewed: '12%', ID: '7%', Duration: '7%', RequestedAt: '12%', Driver: '8%',
	},
}

const CSV_HEADERS =[
	{ label: "Time", key: "timestamp" },
	{ label: "Event Type", key: "type" },
	{ label: "Status", key: "uploading_status" },
	{ label: "Vehicle Name", key: "vehicle_name" },
	{ label: "Groups", key: "groups" },
	{ label: "Reviewed By", key: "last_viewed" },
	{ label: "ID", key: "id" },
	{ label: "Duration", key: "duration" },
	{ label: "Requested At", key: "createtime" },
	{ label: "Driver Name", key: "driver_name" },
	{ label: "Note", key: "custom_event_type" },
]

const EventClipsTable = (props) => {
	// console.log('PROPS EventClipsTable: ', props)
	const { user, company, events, /*allEvents,*/ isWSCCompany, selectedRow, totalSize, eventDate, setDashboardRedirect, openEventNote, onFlagEvent, openEventVideoAll, downloadNVR, openShareEvent, openEventSnapshot, openModalDelete, downloadFlood, handlePageChange, handleSizePerPageChange, handleSortChange, page, sizePerPage, sortName, sortOrder, editVehicle, restoreWCSEvent, webfleetDeviceId, getAllFleetEvents } = props
	const userRole = user.roles[0]
	const userPermissions = user.permissions
	const options = {
		...paginationExportOptions,
		onPageChange: handlePageChange,
		onSizePerPageList: handleSizePerPageChange,
		page,
		sizePerPage,
		sortName,
		sortOrder,
		onSortChange: handleSortChange,
	}
	const driverFeatureAccess = (company.driver_recognition !== 'manual' || ['system_admin'].indexOf(userRole) > -1)
	const reviewedByFeatureAccess = (['system_admin', 'customer_service', 'partner', 'fleet_manager', 'fleet_maintainer', 'partner_view', 'group_manager'].indexOf(userRole) > -1)
	const tableColumnsWidth = COLUMNS_WIDTH[driverFeatureAccess * 1]

	// Export button
	const refExportBtn = useRef()
	const [csvData, setCsvData] = useState([])
	useEffect(() => {
		if (csvData.length > 0) {
		  	refExportBtn.current.link.click()
		}
	}, [csvData])

	const timestampFormatter = (cell) => {
		if (cell) {
			return momenttz(cell).format('M/DD/YYYY hh:mm:ss A');
		}
		return 'Not reviewed';
	}

	const csvTimestampFormatter = (cell) => {
		if (cell) {
			// return momenttz(cell).format('hh:mm:ss A')
			return momenttz(cell).format('M/DD/YYYY hh:mm:ss A')
		}
		return 'Not available'
	}

	const durationFormatter = (cell, row) => {
		// FWSD-4819
		if (eventStatusFormatter(row.uploading_status) !== 'Uploaded') {
			return '0'
		}

		let clipDuration = row.duration
		// FWSD-4558
		if (row.accurate_duration) {
			clipDuration = row.accurate_duration
		}

		return moment.duration(clipDuration, 'seconds').format('h [hrs] m [min] s [sec]', { trim: 'both' })
	}

	const driverFormatter = (cell, row) => row.driver_name || row.geotab_driver

	const vehicleFormatter = (cell, row) => {
		return isInternalUser(userRole)
			? <CustomButton variant="link" onClick={() => editVehicle(row.device_id)}>{cell || row.device_id}</CustomButton>
			: (cell || row.device_id);
	}

	const vehicleCSVFormatter = (cell, row) => row.vehicle_name || row.device_id

	const videostatusFormatter = (cell, row) => {
		if (row.size) {
			return `${(row.size).toFixed(2)} MB `
		}
		if (row.uploading_status && row.uploading_status.toLowerCase() === 'event_reported') {
			return 'pending'
		}
		if (!row.uploading_status || row.uploading_status.toLowerCase() === 'video_non_exist') {
			return 'Unavailable'
		}

		const re = /_/gi;
		return row.uploading_status.replace(re, ' ')
	}

	const typeFormatter = (cell, row) => eventTypeDetection(row);

	const reviewedByFormatter = (cell, row) => {
		const res = []
		if (row.last_viewed.viewer.first_name) {
			res.push(row.last_viewed.viewer.first_name)
		}
		if (row.last_viewed.viewer.last_name) {
			res.push(row.last_viewed.viewer.last_name)
		}
		if (res.length > 0 && row.last_viewed.viewed_ts) {
			res.push('on')
		}
		if (row.last_viewed.viewed_ts) {
			res.push(momenttz(row.last_viewed.viewed_ts).format('M-D-YYYY'))
		}

		if (!res.length) {
			return 'Not Reviewed'
		}

		return res.join(' ')
	}

	const driverNameSort = name => (a, b, order) => {
		const first = a.driver_name || a.geotab_driver || '';
		const second = b.driver_name || b.geotab_driver || '';

		return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)
	}

	const reviewedBySort = name => (a, b, order) => {
		const first = a.last_viewed.viewer.first_name || a.last_viewed.viewer.last_name || '';
		const second = b.last_viewed.viewer.first_name || b.last_viewed.viewer.last_name || '';

		return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)
	}

	const redirectToTripReplay = (eventData) => {
		setDashboardRedirect({
			type: 'redirectFromDailyEvents',
			device_id: eventData.device_id,
			timestamp: moment(eventData.timestamp).utc().format('YYYY-MM-DD HH:mm:ss'),
		})
		browserHistory.push({ pathname: '/' });
	}

	const tripFormatter = (cell, row) => {
		return <Link to="/reports" onClick={(e) => { e.preventDefault(); redirectToTripReplay(row); }}>View Trip</Link>
	}

	const actionsFormatter = (cell, row) => {
		let playButton, snapshotButton, downloadButton, deleteButton, shareButton, flaggedButton, noteButton, requestVideoButton;
		if (!(['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_edit': false }))) {
			flaggedButton = <CustomButton prefix={`imageButton flaggedButton ${row.flagged && "on"}`} id={row.id} title={`${row.flagged ? "Unflag" : "Flag"} Event`} onClick={() => onFlagEvent(row, 'Grid')} />
			noteButton = (
				<CustomButton
					size="icon"
					variant="tertiary"
					prefix={classnames("note-button", { 'with-note': row.custom_event_type })}
					active={row.custom_event_type}
					onClick={() => openEventNote(row)}
				>
					Note
				</CustomButton>
			)
		}

		if (row.uploading_status === 'VIDEO_UPLOADED') {
			playButton = <CustomButton prefix="imageButton playButton" title="Play Video" onClick={() => openEventVideoAll(row, 'Table')} />
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadNVR(row.id)} />
			shareButton = <CustomButton prefix="imageButton shareButton" title="Share Event" onClick={() => openShareEvent(row)} />
		} else if (row.snapshot_status !== 'SNAPSHOT_UPLOADED') {
			playButton = <CustomButton prefix="imageButton playButton" title="Play Video" disabled="disabled" />
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" disabled="disabled" />
		}

		if (row.snapshot_status === 'SNAPSHOT_UPLOADED') {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title="View Snapshot" onClick={() => openEventSnapshot(row)} />
			if (!shareButton) {
				shareButton = <CustomButton prefix="imageButton shareButton" title="Share Event" onClick={() => openShareEvent(row)} />
			}
		} else {
			snapshotButton = <CustomButton prefix="imageButton snapshotButton" title="View Snapshot" disabled="disabled" />
		}

		if (['system_admin', 'customer_service', 'partner', 'fleet_manager',].indexOf(userRole) > -1 || (['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 2, 'a_delete': true }))) {
			deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Event" onClick={() => openModalDelete(row.id)}>
				{generalIcons.crossDelete}
			</CustomButton>
		} else if (row.uploading_status === 'VIDEO_UPLOADED' && row.type.toLowerCase() === 'flood') {
			downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadFlood(row)} />
		}

		if (['custom_user', 'custom_partner'].includes(userRole)) {
			if (!_.find(userPermissions, { perm_category_id: 9, a_view: true })) {
				downloadButton = null
			}
		} else if (userRole === 'sales_demo') {
			downloadButton = downloadButton ? <CustomButton prefix="imageButton downloadButton disabled" title="Download Video" disabled="disabled" /> : ''
			shareButton = shareButton ? <CustomButton prefix="imageButton shareButton disabled" title="Share Event" disabled="disabled" /> : ''
		}


		return (
			<div className="event-actions">
				{playButton}
				{snapshotButton}
				{shareButton}
				{downloadButton}
				{requestVideoButton}
				{flaggedButton}
				{noteButton}
				{deleteButton}
			</div>
		)
	}

	const actionsFormatterWCS = (cell, row) => {
		let buttons, playButton, downloadButton, deleteButton, shareButton;

		let noteButton = (
			<CustomButton size="icon" variant="tertiary" prefix={classnames("note-button", "btn", "btn-default", { 'with-note': row.custom_event_type })} active={row.custom_event_type} onClick={() => openEventNote(row)}>
				Note
			</CustomButton>
		)

		if (row.type.toLowerCase() === 'custom clip' && row.uploading_status === 'EVENT_REPORTED') {
			buttons = (
				<div>
					<CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Custom Request" onClick={() => openModalDelete(row.id)}>
						{generalIcons.crossDelete}
					</CustomButton>
				</div>
			);

			if (['custom_user', 'custom_partner'].includes(userRole) && !_.find(userPermissions, { perm_category_id: 2, a_delete: true })) {
				buttons = null
			}
		}
		else {
			switch (row.status) {
				case 'archived':
					buttons = (
						<CustomButton prefix="imageButton requestButton" title="Request" onClick={() => restoreWCSEvent(row)} />
					);
					break;
				case 'restoring':
					buttons = (
						<div>
							<p>Restoring in progress</p>
						</div>
					);
					break;
				default:
					if (row.uploading_status === 'VIDEO_UPLOADED') {
						playButton = <CustomButton prefix="imageButton playButton" title="Play Videos" onClick={() => openEventVideoAll(row)} />
						downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadNVR(row.id)} />
						shareButton = <CustomButton prefix="imageButton shareButton" title="Share Event" onClick={() => openShareEvent(row)} />
					}
					else {
						playButton = <CustomButton prefix="imageButton playButton" title="Play Videos" disabled="disabled" />
						downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" disabled="disabled" />
					}

					if (['system_admin', 'customer_service', 'partner', 'fleet_manager'].indexOf(userRole) > -1) {
						deleteButton = <CustomButton id="deleteButton" prefix="imageButton deleteButton" title="Delete Custom Request" onClick={() => openModalDelete(row.id)}>
							{generalIcons.crossDelete}
						</CustomButton>
					}
					else if (row.uploading_status === 'VIDEO_UPLOADED' && row.type.toLowerCase() === 'flood') {
						downloadButton = <CustomButton prefix="imageButton downloadButton" title="Download Video" onClick={() => downloadFlood(row)} />
					}

					if (['custom_user', 'custom_partner'].includes(userRole)) {
						if (!_.find(userPermissions, { perm_category_id: 9, a_view: true })) {
							downloadButton = null
						}
					}

					buttons = (
						<div className="event-actions">
							{playButton}
							{downloadButton}
							{shareButton}
							{noteButton}
							{deleteButton}
						</div>
					);
					break;
			}
		}

		return buttons;
	}

	const onExportClick = async() => {
		// debugger
		const events = await getAllFleetEvents()
		const newCsvData = []
		events.forEach((item) => {
			const obj = {
				id: item.id,
				device_id: item.device_id,
				vehicle_name: item.vehicle_name || item.device_id,
				timestamp: csvTimestampFormatter(item.timestamp),
				type: eventTypeDetection(item),
				uploading_status: eventStatusFormatter(item.uploading_status),
				groups: item.groups,
				last_viewed: reviewedByFormatter(item.last_viewed, item),
				duration: durationFormatter(item.duration, item),
				createtime: csvTimestampFormatter(item.createtime),
				driver_name: driverFormatter(item.driver_name, item),
				custom_event_type: item.custom_event_type || '',
			}

			newCsvData.push(obj)
		})

		setCsvData(newCsvData)
    }

	return (
		<div className="event-page-table">
			{!isWSCCompany
				? (
				<>
					{/* Export button */}
					<div className="react-bs-table-tool-bar btn-group">
						<CustomButton onClick={onExportClick} variant='primary-outline' size='sm' prefix='export-button'>
							{exportIcon}
							<span className='export-label'>Export</span>
						</CustomButton>
					</div>
					<CSVLink
						ref={refExportBtn} 
						filename={`daily_events_${moment(eventDate).format('M-D-YY')}.csv`} 
						headers={CSV_HEADERS}
						data={csvData} 
					/>

					<BootstrapTable
						data={events}
						{...tableAdjustableColExportOptions}
						remote
						fetchInfo={{ dataTotalSize: totalSize }}
						selectRow={selectedRow}
						// options={{ ...options, onExportToCSV: () => allEvents }}
						// options={{ ...options, onExportToCSV:  async () => await handleExportToCSV().then(res => res.data.response.events) }}
						options={{ ...options}}
						// csvFileName={`daily_events_${moment(eventDate).format('M-D-YY')}.csv`}
						exportCSV={false}
					>
						<TableHeaderColumn
							width={tableColumnsWidth['Date / Time']}
							dataField="timestamp"
							dataSort
							dataFormat={timestampFormatter}
							csvFormat={csvTimestampFormatter}
							csvHeader={moment(eventDate).format('MM/DD/YYYY')}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(0, 'Date / Time')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width={tableColumnsWidth.Type}
							dataField="type"
							dataSort
							dataFormat={typeFormatter}
							csvFormat={typeFormatter}
							csvHeader="Event Type"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(1, 'Type')}
						</TableHeaderColumn>
						<TableHeaderColumn
							hidden={!isInternalUser(userRole)}
							width={tableColumnsWidth.Status}
							dataField="uploading_status"
							dataSort
							dataFormat={eventStatusFormatter}
							csvFormat={eventStatusFormatter}
							csvHeader="Status"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(2, 'Status')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width={tableColumnsWidth.Vehicle}
							dataField="vehicle_name"
							dataSort
							dataFormat={vehicleFormatter}
							csvFormat={vehicleCSVFormatter}
							csvHeader="Vehicle Name"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(2, 'Vehicle')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width={tableColumnsWidth.Groups}
							dataField="groups"
							csvHeader="Groups"
							dataSort
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(3, 'Groups')}
						</TableHeaderColumn>
						<TableHeaderColumn
							hidden={!reviewedByFeatureAccess}
							width={tableColumnsWidth.Reviewed}
							dataField="last_viewed"
							dataSort
							dataFormat={reviewedByFormatter}
							csvHeader="Reviewed By"
							csvFormat={reviewedByFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
							sortFunc={reviewedBySort()}
						>
							{renderCustomHeader(4, 'Reviewed By')}
						</TableHeaderColumn>
						<TableHeaderColumn width={tableColumnsWidth.ID} dataField="id" csvHeader="ID" dataSort isKey>
							{renderCustomHeader(4, 'ID')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width={tableColumnsWidth.Duration}
							dataField="duration"
							dataSort
							dataFormat={durationFormatter}
							csvFormat={durationFormatter}
							csvHeader="Duration"
						>
							{renderCustomHeader(6, 'Duration')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width={tableColumnsWidth.RequestedAt}
							dataField="createtime"
							dataSort
							dataFormat={timestampFormatter}
							csvFormat={csvTimestampFormatter}
							csvHeader="Requested At"
						>
							{renderCustomHeader(7, 'Requested At')}
						</TableHeaderColumn>
						<TableHeaderColumn
							hidden={!driverFeatureAccess}
							width={tableColumnsWidth.Driver}
							dataField="driver_name"
							dataSort
							dataFormat={driverFormatter}
							csvHeader="Driver Name"
							tdStyle={{ whiteSpace: 'normal' }}
							sortFunc={driverNameSort()}
						>
							{renderCustomHeader(8, 'Driver')}
						</TableHeaderColumn>
						<TableHeaderColumn
							hidden={webfleetDeviceId.length > 0 || (['custom_user', 'custom_partner'].includes(userRole) && _.find(userPermissions, { 'perm_category_id': 1, 'a_view': false }))}
							width="7%"
							dataFormat={tripFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
							export={false}
							className="hidden"
						>
							{renderCustomHeader(9, 'Trip')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="15%"
							dataFormat={actionsFormatter}
							dataField="custom_event_type"
							csvFormat={cell => cell || ''}
							csvHeader="Note"
							tdStyle={{ whiteSpace: 'normal' }}
						>
							<div className="custom-table-header-row">
								<div className="custom-table-header-title">Actions</div>
							</div>
						</TableHeaderColumn>
					</BootstrapTable>
				</>
				)
				: (
					<BootstrapTable
						data={events}
						{...tableAdjustableColExportOptions}
						remote
						fetchInfo={{ dataTotalSize: totalSize }}
						selectRow={selectedRow}
						options={options}
						csvFileName={`daily_events_${moment(eventDate).format('M-D-YY')}.csv`}
					>
						<TableHeaderColumn
							width="11%"
							dataField="timestamp"
							dataSort
							dataFormat={timestampFormatter}
							csvFormat={csvTimestampFormatter}
							csvHeader={moment(eventDate).format('MM/DD/YYYY')}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							{renderCustomHeader(0, 'Date / Time')}
						</TableHeaderColumn>

						<TableHeaderColumn
							width="10%"
							dataField="type"
							dataSort
							tdStyle={{ whiteSpace: 'normal' }}
							csvHeader="Event Type"
							csvFormat={typeFormatter}
							dataFormat={typeFormatter}
						>
							{renderCustomHeader(1, 'Type')}
						</TableHeaderColumn>
						<TableHeaderColumn width="9%" dataField="vehicle_name" dataSort dataFormat={vehicleFormatter} csvFormat={vehicleCSVFormatter} tdStyle={{ whiteSpace: 'normal' }}>
							{renderCustomHeader(2, 'Vehicle')}
						</TableHeaderColumn>
						<TableHeaderColumn width="6%" dataField="groups" dataSort csvHeader="Groups" tdStyle={{ whiteSpace: 'normal' }}>
							{renderCustomHeader(3, 'Groups')}
						</TableHeaderColumn>
						<TableHeaderColumn width="8%" dataField="size" dataSort dataFormat={videostatusFormatter} csvHeader="File Size" csvFormat={videostatusFormatter} tdStyle={{ whiteSpace: 'normal' }}>
							{renderCustomHeader(4, 'File Size')}
						</TableHeaderColumn>
						<TableHeaderColumn
							width="11%"
							dataField="last_viewed"
							dataSort
							dataFormat={reviewedByFormatter}
							csvHeader="Reviewed By"
							csvFormat={reviewedByFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
							sortFunc={reviewedBySort()}
						>
							{renderCustomHeader(6, 'Reviewed By')}
						</TableHeaderColumn>
						<TableHeaderColumn width="5%" dataField="id" dataSort csvHeader="ID" isKey>
							{renderCustomHeader(7, 'ID')}
						</TableHeaderColumn>
						<TableHeaderColumn width="7%" dataField="start_ts" dataSort dataFormat={formatTimestamp} csvHeader="Start" csvFormat={formatTimestamp} tdStyle={{ whiteSpace: 'normal' }}>
							{renderCustomHeader(8, 'Start')}
						</TableHeaderColumn>
						<TableHeaderColumn width="7%" dataField="end_ts" dataSort dataFormat={formatTimestamp} csvHeader="End" csvFormat={formatTimestamp} tdStyle={{ whiteSpace: 'normal' }}>
							{renderCustomHeader(9, 'End')}
						</TableHeaderColumn>
						<TableHeaderColumn width="11%" dataField="custom_event_type" dataFormat={actionsFormatterWCS} tdStyle={{ whiteSpace: 'normal' }}>
							<div className="custom-table-header-row">
								<div className="custom-table-header-title">Actions</div>
							</div>
						</TableHeaderColumn>
					</BootstrapTable>
				)}
		</div>
	)
}

EventClipsTable.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	events: PropTypes.arrayOf(PropTypes.any).isRequired,
	isWSCCompany: PropTypes.bool.isRequired,
	selectedRow: PropTypes.objectOf(PropTypes.any).isRequired,
	totalSize: PropTypes.number.isRequired,
	eventDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(moment),
	]),
	page: PropTypes.number.isRequired,
	sizePerPage: PropTypes.number.isRequired,
	sortName: PropTypes.string.isRequired,
	sortOrder: PropTypes.string.isRequired,
	setDashboardRedirect: PropTypes.func.isRequired,
	openEventNote: PropTypes.func.isRequired,
	onFlagEvent: PropTypes.func.isRequired,
	openEventVideoAll: PropTypes.func.isRequired,
	downloadNVR: PropTypes.func.isRequired,
	openShareEvent: PropTypes.func.isRequired,
	openEventSnapshot: PropTypes.func.isRequired,
	openModalDelete: PropTypes.func.isRequired,
	downloadFlood: PropTypes.func.isRequired,
	handlePageChange: PropTypes.func.isRequired,
	handleSizePerPageChange: PropTypes.func.isRequired,
	handleSortChange: PropTypes.func.isRequired,
	editVehicle: PropTypes.func.isRequired,
	restoreWCSEvent: PropTypes.func.isRequired,
	webfleetDeviceId: PropTypes.string,
}

EventClipsTable.defaultProps = {
	webfleetDeviceId: '',
}

const mapStateToProps = ({ user, company, webfleetData }) => ({
	user: user.user,
	company: company.company,
	webfleetDeviceId: webfleetData.deviceId,
});

const mapDispatchToProps = dispatch => ({
	setDashboardRedirect: (state) => {
		dispatch({ type: 'SET_DASHBOARD_REDIRECT', payload: state })
	},
})

export default connect(mapStateToProps, mapDispatchToProps)(EventClipsTable)
