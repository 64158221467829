import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import _ from 'lodash'
import { renderCustomHeader, tableAdjustableColOptions } from '../../helpers/table'
import ModalDelete from '../modals/ModalDelete'
import CustomButton from '../../CustomButton'
import { deleteModem } from '../../../action/FleetAdminActions'

const ModemFirmwaresListBlock = (props) => {

    const { toggleLoader } = props;

    const [delete_modem, setDelete_modem] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const deleteFormatter = (cell, row) => {
        return (
            <div className="actions-wrapper">
                <CustomButton variant="link" onClick={() => openDeleteModal(row)}>
                    <i style={{ color: 'red' }} className="fas fa-times-circle" />
                    Delete
                </CustomButton>
            </div>
        )
    };

    const openDeleteModal = (row) => {
        setDelete_modem(row);
        setShowDeleteModal(true);
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const deleteModemFirmware = () => {
        toggleLoader(true);
        setShowDeleteModal(false);

        deleteModem({
            modem_id: delete_modem.id
        }).then((res, err) => {
            console.log('!deleteModem res:', res, err)
            toggleLoader(false);
            toastr.success('', `Modem firmware "${delete_modem.url}" removed`)
            setDelete_modem({});
            props.getModemsList()
        })
            .catch((error) => {
                toggleLoader(false)
                console.log('!deleteModem error:', error.response)
                let errDescription = 'An unexpected error occurred. Please try again later'
                if (error.response.data.response.error) {
                    errDescription = error.response.data.response.error
                }
                toastr.error(errDescription)
            })
    }

    return (
        <div>
            <div className="manage-users-table">
                <BootstrapTable
                    data={props.modems}
                    {...tableAdjustableColOptions}
                >
                    <TableHeaderColumn dataField='id' isKey={true} hidden>ID</TableHeaderColumn>

                    <TableHeaderColumn width="15%" dataField='model' dataSort={true}>
                        {renderCustomHeader(0, 'Modem Model')}
                    </TableHeaderColumn>
                    <TableHeaderColumn width="15%" dataField='version' dataSort={true}>
                        {renderCustomHeader(1, 'Modem Version')}
                    </TableHeaderColumn>
                    <TableHeaderColumn width="20%" dataField='url'>
                        {renderCustomHeader(2, 'Firmware URL')}
                    </TableHeaderColumn>
                    <TableHeaderColumn width="40%" dataField='release_note' tdStyle={{ whiteSpace: 'normal' }}>
                        {renderCustomHeader(3, 'Release Note')}
                    </TableHeaderColumn>
                    <TableHeaderColumn width="10%" dataField='url' dataFormat={deleteFormatter}>
                        Actions
                    </TableHeaderColumn>
                </BootstrapTable>
            </div>

            {showDeleteModal &&
                <ModalDelete
                    content={`Are you sure you want to delete this modem firmware "${delete_modem.url}" ?`}
                    closeModal={closeDeleteModal}
                    deleteModal={deleteModemFirmware}
                />
            }
        </div>
    )
}

export default connect(
    null,
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(ModemFirmwaresListBlock);
