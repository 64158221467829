import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { Modal, Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import { changeEventNote } from '../../../action/AlertActions'
import CustomButton from '../../CustomButton';

const EventNoteModal = (props) => {
	const { onSave, user, event, show, onHide, toggleLoader } = props;

	const [content, setContent] = useState('');

	const fillForm = (e) => {
		const { target } = e;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		setContent(value);
	}

	const onEnterModal = () => {
		const content = event.custom_event_type || '';

		setContent(content);
	}

	const Save = () => {
		toggleLoader(true);

		changeEventNote({
			event_id: event.id,
			partner_name: props.partner_name,
			company_name: props.company_name,
			description: content
		})
			.then((res, err) => {
				toggleLoader(false)

				onSave(content)
				toastr.success('', 'Note Added')
			})
			.catch((error) => {
				toggleLoader(false);
				onHide();
				console.log(error);

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const hasPermissions = () => {
		const userRole = user.user.roles[0]

		if (userRole === 'sales_demo'
			|| userRole === 'reviewer'
			|| (userRole === 'user' && event && event.created_by ? event.created_by !== user.user.id : null)
		) {
			return false
		}

		return true
	}

	let opts = {};
	if (!hasPermissions()) {
		opts.readOnly = 'readOnly';
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			onEnter={onEnterModal}
			className="modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Note</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<textarea
						name="content"
						value={content}
						onChange={fillForm}
						style={{ width: '100%', height: '80px', resize: 'vertical' }}
						{...opts}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				{hasPermissions() && <CustomButton onClick={Save} variant="primary">Save</CustomButton>}
			</Modal.Footer>
		</Modal>
	);
};

export default connect(
	state => ({
		user: state.user
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(EventNoteModal);
