import React from 'react'
import { Link, IndexLink } from 'react-router'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const MenuNode = (props) => {

	const { linkIndex, linkTo, linkTitle, itemIcon, onClick, open, bubbleShow } = props;

	if (linkIndex) {
		return (
			<li>
				<IndexLink to="/" className="menu-item" activeClassName="active" onClick={onClick}>
					{(!open && !navigator.userAgent.match(/(iPad)/))
						? <OverlayTrigger
							placement="right"
							delayHide={100}
							overlay={
								<Tooltip id="sidemenu-tooltip" className="tooltip custom-tooltips">
									{linkTitle}
								</Tooltip>
							}
						>
							{itemIcon}
						</OverlayTrigger>
						: itemIcon
					}
					<div className="text">{linkTitle}</div>
					{bubbleShow && <label className="bubble-icon" />}
				</IndexLink>
			</li>
		)
	}

	return (
		<li>
			<Link to={linkTo} className="menu-item" activeClassName="active" onClick={onClick}>
				{(!open && !navigator.userAgent.match(/(iPad)/))
					? <OverlayTrigger
						placement="right"
						delayHide={100}
						overlay={
							<Tooltip id="sidemenu-tooltip" className="tooltip custom-tooltips">
								{linkTitle}
							</Tooltip>
						}
					>
						{itemIcon}
					</OverlayTrigger>
					: itemIcon
				}
				<div className="text">{linkTitle}</div>
				{bubbleShow && <label className="bubble-icon" />}
			</Link>
		</li>
	)
}

export default MenuNode;
