import moment from 'moment-timezone';
import store from '../../store/store'

const momenttz = (val) => {
        // const userTimeZone = store ? store.getState().user.user.settings.time_zone : ''
	const userTimeZone = store.getState().user.user.settings.time_zone

	if (val && userTimeZone) {
		return moment.tz(val, userTimeZone)
	}

	if (val) {
		return moment(val)
	}

	if (userTimeZone) {
		return moment.tz(userTimeZone)
	}

	return moment()
}

export default momenttz
