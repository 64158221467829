import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import DateTime from 'react-datetime'
import moment from 'moment'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'

import { tableAdjustableColOptions, renderCustomHeader } from '../../component/helpers/table'
import { formatTimestamp } from '../../component/helpers/tablesFuncHelpers'
import momenttz from '../../component/helpers/momenttz'
import ModalProjectVersion from './ModalProjectVersion'
import { apiCall, apiCallGet } from '../../action/RouterActions'
import GreyHeader from '../../component/GreyHeader'
import CustomButton from '../../component/CustomButton';

const ReleasesPage = (props) => {

	const { toggleLoader } = props;

	const [releases, setReleases] = useState([])
	const [timestamp, setTimestamp] = useState(moment(moment().format('YYYYMMDD0900'), 'YYYYMMDDHHmm'))
	const [wikilink, setWikilink] = useState('')
	const [errMessage, setErrMessage] = useState({
		errtimestamp: '',
		errwikilink: '',
	})
	const [showProjectVersion, setShowProjectVersion] = useState(false)

	useEffect(() => {
		getReleasesList()
	}, [])

	const getReleasesList = () => {
		toggleLoader(true)

		apiCallGet('/releases')
			.then(res => {
				toggleLoader(false)

				let { releases } = res.data.response
				releases = _.orderBy(releases, ['timestamp'], ['desc']);
				setReleases(releases)
			})
			.catch(err => {
				toggleLoader(false)

				toastr.error('Unable to get releases. Please try again later');
			})
	}

	const onDescriptionChange = (e) => {
		setWikilink(e.target.value)
	}

	const onDateChange = (current) => {
		setTimestamp(current)
	}

	const checkData = (data) => {
		const updatedErrMessage = { ...errMessage }
		let error = false;

		Object.keys(data).forEach((key) => {
			if (!data[key]) {
				error = true;
				updatedErrMessage[`err${key}`] = `Enter your ${key}!`;
			} else if (updatedErrMessage[key]) {
				updatedErrMessage[`err${key}`] = null;
			}
		})

		setErrMessage(updatedErrMessage)
		return error
	}

	const onAddRelease = (event) => {
		event.preventDefault()

		const data = {
			timestamp: momenttz(timestamp.format('YYYY-MM-DD HH:mm:ss')).utc().format('YYYYMMDDHHmmss'),
			wikilink,
		}
		const error = checkData(data)

		if (!error) {
			toggleLoader(true)

			apiCall('PUT', '/releases', data)
				.then((res, err) => {
					getReleasesList()
					toastr.success('Success', 'The release schedule was successfully added')
				})
				.catch((error) => {
					toggleLoader(false)
					let errDescription = 'An unexpected error occurred. Please try again later'
					if (error.response.data.response.error) {
						errDescription = error.response.data.response.error
					}
					toastr.error(errDescription);
				})
		}
	}

	const handleClickUpdateVersion = () => {
		setShowProjectVersion(true)
	}

	const handleCloseProjectVersion = () => {
		setShowProjectVersion(false)
	}

	const formatDescription = (cell) => {
		return <a target='_blank' href={cell} className='has-external-link'>Release Link</a>
	}

	const { errwikilink } = errMessage;

	return (
		<div className="fleet-admin-releases">
			<GreyHeader title="Releases" hideSubtitle />

			<div className='page-subheader'>
				<div className="subheader-section date-flex">
					<label className="search-label">Date/Time:</label>
					<DateTime
						timeFormat="hh:mm A"
						dateFormat="MM/DD/YYYY"
						inputProps={{ readOnly: true }}
						value={moment(timestamp)}
						closeOnSelect
						onChange={onDateChange}
					/>
				</div>
				<div className={`subheader-section search-flex ${errwikilink && 'has-error'}`}>
					<label className="search-label">Description:</label>
					<input
						type="text"
						name="wikilink"
						className="vehicle-search"
						value={wikilink}
						onChange={onDescriptionChange}
					/>
					{errwikilink && <span className="help-block">{errwikilink}</span>}
				</div>
				<div className='subheader-section'>
					<CustomButton
						size='sm'
						onClick={onAddRelease}
						variant="primary-outline"
					>
						Add Release
					</CustomButton>
					<CustomButton
						size='sm'
						onClick={handleClickUpdateVersion}
						variant="primary-outline"
					>
						Update Version
					</CustomButton>
				</div>
			</div>

			<main className="main-content-block">
				<BootstrapTable
					data={releases}
					{...tableAdjustableColOptions}
				>
					<TableHeaderColumn isKey width="30%" dataField="timestamp" dataSort dataFormat={formatTimestamp}>
						{renderCustomHeader(0, 'Date / Time')}
					</TableHeaderColumn>
					<TableHeaderColumn width="70%" dataField="wikilink" dataFormat={formatDescription}>
						Description
					</TableHeaderColumn>
				</BootstrapTable>
			</main>

			{showProjectVersion
				&& (
					<ModalProjectVersion
						onHide={handleCloseProjectVersion}
					/>
				)}
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ReleasesPage);
