// FWSD-2842
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import { InfoWindow } from 'react-google-maps'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

import './MarkerInfoPopup.sass'

momentDurationFormatSetup(moment);

const MarkerHistoryPopup = (props) => {
	// debugger
	const {
		lat,
		lng,
		address,
		driver_name,
		speed_unit,
		dir,
		icon_direction,
		zIndex,
		location_lat,
		location_lng,
	} = props;

	const [showAddress, setShowAddress] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			$('.gm-ui-hover-effect').remove()
		}, 10)
	}, [])

	useEffect(() => {
		setShowAddress(false);
	}, [lat, lng])

	const handleClickAddress = (event) => {
		if (!showAddress) {
			const { device_id, onClickAddress } = props
			onClickAddress(device_id)
		}
		setShowAddress(!showAddress)
	}

	const vehicleSpeed = () => {
		const {
			spd,
			speed,
			speed_unit
		} = props;

		let speedRes = speed * 1
		if (speed_unit !== 'mph') {
			speedRes *= 1.609344
		}

		// return speedRes.toFixed(2)
		return speedRes.toFixed(0)
	}

	return (
		<InfoWindow
			// ref={(c) => { this.InfoWindowRef = c }}
			// position={{ lat: lat + 0.0150, lng }}
			position={{ lat: lat || location_lat, lng: lng || location_lng }}
			zIndex={zIndex}
		>
			<div className="markerInfoPopup markerHistoryPopup">
				<div className="headerWrapper">
					<div className="header">
						<Row>
							<Col className="menu" md={12} sm={12} xs={12}>
								<Col
									md={12}
									sm={12}
									xs={12}
									style={{
										display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', padding: '1px 8px 0px 3px',
									}}
								>
									<div
										className="menuItem speed"
									/>
									<div>
										<div style={{ color: '#fff', fontSize: '22px', fontWeight: 'bold' }}>
											{vehicleSpeed()}
										</div>
										<div style={{ color: '#fff', fontSize: '14px' }}>
											{speed_unit.toUpperCase()}
										</div>
									</div>
								</Col>
							</Col>
						</Row>
						<div className="addressWrapper">
							<Row style={{ minHeight: '60px' }}>
								<Col className="menu" md={12} sm={12} xs={12}>
									<Col md={showAddress ? 3 : 12} sm={3} xs={3} style={{ paddingLeft: '15px', paddingRight: '0px' }}>
										<button
											className="btn-address"
											type="button"
											title="Lookup Address"
											onClick={handleClickAddress}
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
												<path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
											</svg>
										</button>
									</Col>
									<Col md={7} sm={9} xs={9} style={{ fontSize: '14px', lineHeight: '18px' }}>
										{showAddress ? address : ''}
									</Col>
								</Col>
							</Row>
						</div>
					</div>
				</div>
			</div>
		</InfoWindow>
	)
}

export default MarkerHistoryPopup;
