import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const GreyHeader = (props) => {
	const { title, subtitle, renderSubtitle, hideSubtitle, company } = props;

	const onSandwichClick = () => {
		$('.sideMenu').toggleClass('menuOpenMobile')
		$('body').toggleClass('hasMobileMenu')
	}

	return (
		<header className="grey-header">
			{/* Mobile Nav */}
			<button className="mobile-nav" onClick={onSandwichClick}>
				<svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
				</svg>
			</button>

			<h1 className="page-title">{title}</h1>
			{renderSubtitle || (!hideSubtitle && <h2 className="page-subtitle">{subtitle || company.name}</h2>)}
		</header>
	)
}

GreyHeader.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	hideSubtitle: PropTypes.bool,
}

GreyHeader.defaultProps = {
	hideSubtitle: false,
}

export default connect(
	state => ({
		company: state.company.company,
	}),
)(GreyHeader);
