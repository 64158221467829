import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import CalibrationItem from '../../dump/menuComponents/CalibrationItem'
import AdasCalibrationSettingsModal from './AdasCalibrationSettingsModal'
import CustomButton from '../../CustomButton';

const CalibrationNeededModal = (props) => {
	// debugger
	const { profile, devices, onHide, onContinue } = props
	const [uncalibratedDevices, setUncalibratedDevices] = useState([])
	const [selectedDevice, setSelectedDevice] = useState(null)
	const [showAdasCalibrationSettungsModal, setShowAdasCalibrationSettungsModal] = useState(false)
	const hasUncalibratedDevices = !!uncalibratedDevices.filter(item => item.device_type === 'dv6' && item.uploaded_company_profile === profile.name && (!item.adas_camera_height || !item.adas_vehicle_width)).length

	useEffect(() => {
		const uncalibratedDevicesNew = devices.filter(item => item.device_type === 'dv6' && item.uploaded_company_profile === profile.name && (!item.adas_camera_height || !item.adas_vehicle_width))
		setUncalibratedDevices(uncalibratedDevicesNew)
	}, [])

	const saveAdasCalibrationSettings = (argDevice) => {
		setSelectedDevice({ ...selectedDevice, ...argDevice })
		setUncalibratedDevices(uncalibratedDevices.map(item => item.device_id === argDevice.device_id ? {...item, ...argDevice} : item))
	}

	// console.log('PROPS CalibrationNeededModal: ', props)

	return (
		<Modal
			show
			onHide={onHide}
			className="config-modal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Calibration Needed</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="calibration-needed">
					<p>In order to enable ADAS events for your cameras, they must first be calibrated by entering measurements for your vehicle.</p>
					<p><b>The following vehicle(s) need calibration info entered before these updates can be applied:</b></p>

					{uncalibratedDevices.length > 0 && (
						<div className="items-list">
							{uncalibratedDevices.map((item) => {
								return (
									<CalibrationItem
										key={item.device_id}
										device={item}
										onClick={() => {setSelectedDevice(item); setShowAdasCalibrationSettungsModal(true);}}
									/>
								)
							})}
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary-outline"
					onClick={onHide}
				>
					Cancel
				</CustomButton>
				<CustomButton
					variant="primary"
					disabled={hasUncalibratedDevices}
					onClick={() => { onHide(); onContinue(); }}
				>
					Continue
				</CustomButton>
			</Modal.Footer>

			{showAdasCalibrationSettungsModal && selectedDevice.device_id && (
				<AdasCalibrationSettingsModal
					device={selectedDevice}
					saveAdasCalibrationSettings={saveAdasCalibrationSettings}
					onHide={() => setShowAdasCalibrationSettungsModal(false)}
				/>
			)}
		</Modal>
	)
}

CalibrationNeededModal.propTypes = {
	devices: PropTypes.arrayOf(PropTypes.any).isRequired,
	profile: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired,
	onContinue: PropTypes.func.isRequired,
}

export default CalibrationNeededModal
