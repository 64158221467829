// FWSD-6482, If a device cannot be reached to complete the request (Offline/Disabled), display a modal informing the user the request cannot be completed that this time. 
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import CustomButton from '../../component/CustomButton'

import './CameraHealth.sass'

const ModalDeviceOfflineDisabled = (props) => {
	// console.log('PROPS ModalDeviceOfflineDisabled: ', props)
	const { devicesOfflineDisabled, onHide } = props

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="custom-request-modal modal-error-status"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					Device Offline
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{devicesOfflineDisabled.length === 1 
				? <>
				Vehicle <b>{devicesOfflineDisabled[0]}</b> is currently offline and cannot be reached to complete the request at this time. Once this vehicle comes back online, the requested action will be sent. A notification will be sent once the request is completed.
				</>
				: <>
					The following vehicles are currently offline and cannot be reached to complete the request at this time. Once the vehicle comes back online, the requested action will be sent. A notification will be sent once the request is completed
					<br/><br/>
					<ul>
					{devicesOfflineDisabled.map((name) => <li>{name}</li>)}
					</ul>
				</>
			}
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				<CustomButton variant="primary" onClick={onHide}>
					Ok
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalDeviceOfflineDisabled.propTypes = {
	devicesOfflineDisabled: PropTypes.arrayOf(PropTypes.string).isRequired,
	onHide: PropTypes.func.isRequired,
}

export default ModalDeviceOfflineDisabled
