// FWSD-3788
import React from 'react'

const ConfirmationPage = () => (
	<div className='account-form-heading'>
		Your account has been verified.
		<br />
		Expect to start receiving notifications from RoscoLive.
		<div className='account-form-text'>
			You can now close this page.
		</div>
	</div>
)

export default ConfirmationPage
