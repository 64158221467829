import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';

import SelectReportDateModal from '../../smart/reportsComponents/Modals/SelectReportDateModal';
import CustomButton from '../../CustomButton';
import { CustomButtonToggle } from '../../DropdownComponents';

const DateDropdown = (props) => {
    const { start_date, end_date, tabKey, id, onSelectDate, allowFutureDates } = props;

    const options = [
        {
            name: 'Today',
            index: 1,
        },
        {
            name: 'Yesterday',
            index: 2,
        },
        {
            name: 'This Week',
            index: 3,
        },
        {
            name: 'Last Week',
            index: 4,
        },
        {
            name: 'This Month',
            index: 5,
        },
        {
            name: 'Last Month',
            index: 6,
        }
    ];

    let dateOptionsInitial = [];
    if (props.limitDates) {
        options.map(opt => props.limitDates.map(selection => {
            if (selection === opt.name) {
                dateOptionsInitial.push(opt)
            }
        }))
    } else {
        dateOptionsInitial = options;
    }

    const [isOpen, setIsOpen] = useState(false)
    const [showSelectDate, setShowSelectDate] = useState(false)
    const [singleDate, setSingleDate] = useState(props.singleDate)
    const [dateOptions, setDateOptions] = useState(dateOptionsInitial)

    const selectDate = (tabKey, start_date, end_date) => {
        props.onSelectDate(tabKey, start_date, end_date);
        setIsOpen(false)
    }

    return (
        <Dropdown
            id={id}
            className='date-dropdown'
            role='menuitem'
            show={isOpen}
            onToggle={(isOpen) => setIsOpen(isOpen)}
            drop
        >
            <Dropdown.Toggle as={CustomButtonToggle} variant="primary">
                {start_date === end_date 
                    ? moment(start_date).format('M/D/YY')
                    : (moment(start_date).format('M/D/YY') + ' - ' + moment(end_date).format('M/D/YY'))
                }
            </Dropdown.Toggle>
            <Dropdown.Menu className="date-select">
                <div className='date-select-grid'>
                    {dateOptions.map(timeFrame =>
                        <CustomButton
                            key={timeFrame.index}
                            variant={tabKey !== timeFrame.index ? 'primary-outline' : 'primary'}
                            onClick={() => selectDate(timeFrame.index)}
                        >
                            {timeFrame.name}
                        </CustomButton>
                    )}
                </div>
                <div className='manual-date-select'>
                    <CustomButton
                        variant='primary-outline'
                        size="sm"
                        onClick={() => {
                            setShowSelectDate(true)
                            setIsOpen(false)
                        }}
                    >
                        Manually Select Date
                    </CustomButton>
                </div>
            </Dropdown.Menu>

            {showSelectDate &&
                <SelectReportDateModal
                    onHide={() => setShowSelectDate(false)}
                    currentDate={{ start_date, end_date }}
                    changeDate={onSelectDate}
                    allowFutureDates={allowFutureDates}
                    singleDate={singleDate}
                />
            }
        </Dropdown>
    )
}

DateDropdown.propTypes = {
    isOpen: PropTypes.bool,
    allowFutureDates: PropTypes.bool
}

DateDropdown.defaultProps = {
    id: 'date-select-dropdown',
    allowFutureDates: false
}

export default connect(
    state => ({
        company: state.company.company
    }),
)(DateDropdown);
