import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { restorePassword } from '../../../action/AccountActions';
import GreyHeader from '../../GreyHeader'
import CustomButton from '../../CustomButton';

const AccountPasswordRecoveryContainer = (props) => {

    const { user, toggleLoader } = props;

    const sendForm = (e) => {
        e.preventDefault();

        toggleLoader(true);

        restorePassword(user.user.email).then((res, err) => {
            toggleLoader(false);

            toastr.success('', 'Password was changed');
        })
            .catch((error) => {
                toggleLoader(false);

                toastr.error('Unable to change user password. Please try again later.');
            });
    };

    return (
        <>
            <GreyHeader title='Password Recovery' hideSubtitle />

            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <form>
                    <div className="form-group">
                        <label className="control-label" style={{ fontSize: '20px' }}>Email: {user.user.email}</label>
                    </div>

                    <div className="form-group" style={{ fontSize: '16px' }}>
                        In case you forget your password, we can send you an email with a new password so you can still log in to RoscoLive.<br />
                        Also, please make sure your email address is correct.
                    </div>

                    <div className="form-group">
                        <CustomButton variant='primary' onClick={sendForm}>RESET</CustomButton>
                    </div>
                </form>
            </div>
        </>
    );
};

export default connect(
    state => ({
        user: state.user.user
    }),
    dispatch => ({
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(AccountPasswordRecoveryContainer);
