import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const CategoryItem = (props) => {
	const { text, onClick, small, withArrow, rightLabel, className } = props

	return (
		<button className={classNames('category-item', { [className]: className, 'flex-item' : (withArrow || rightLabel), 'thin': small })} onClick={onClick}>
			{ text }
			{ withArrow && 
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right item-arrow" viewBox="0 0 16 16">
					<path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			}
			{ rightLabel &&
				<label className='right-label'>{rightLabel}</label>
			}
		</button>
	)
}

CategoryItem.propTypes = {
	text: PropTypes.string.isRequired,
	withArrow: PropTypes.bool,
	onClick: PropTypes.func,
}

CategoryItem.defaultProps = {
	withArrow: false,
};

export default CategoryItem;
