import React from 'react'
import { Marker, InfoWindow } from 'react-google-maps'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import classnames from 'classnames'

import MarkerWithLabel from 'react-google-maps/lib/components/addons/MarkerWithLabel';

import MarkerInfoPopup from './MarkerInfoPopup'
import MarkerHistoryPopup from './MarkerHistoryPopup';

const MarkerNode = (props) => {

	const handleSelectMarkerClick = (event) => {
		const { device_id, onSelectMarker } = props
		onSelectMarker(device_id)
	}

	// console.log('PROPS MarkerNode: ', props.name, props)

	const {
		map,
		location_lat,
		location_lng,
		icon_url,
		visible_icon,
		zIndex,
		name,
		online,
		showTripsHistory,
		ignition,
		status,
	} = props;

	return (
		<MarkerWithLabel
			// position={{
			// 	lat: poly.suburbCentre.lat,
			// 	lng: poly.suburbCentre.lng,
			// }}
			map={map}
			position={{ lat: location_lat, lng: location_lng }}
			icon={{ url: icon_url }}
			// visible={visible_icon}
			// opacity={1}
			// labelClass="map-price-container"
			// label={name}
			// title={name}
			// zIndex={zIndex}
			// optimized={false}
			// draggable={false}
			// onClick={handleSelectMarkerClick}
			// onClick={onClick}
			// labelAnchor={new window.google.maps.Point(0, 0)}
			// labelAnchor={new window.google.maps.Point(10, 35)}
			// labelContent={`<div class="${classNames('map-price-marker', { offline: !online }, { parking: online && !ignition })}"><span>${name}</span></div>`}
			// labelStyle={{
			// 	backgroundColor: "white",
			// 	fontSize: "12px",
			// 	padding: "8px",
			// 	borderRadius: "5px"
			// }}
			visible={visible_icon}
			opacity={1}
			// labelClass="map-price-container"
			labelAnchor={new window.google.maps.Point(10, 35)}
			// labelContent={`<div class="${classnames('map-price-marker', { offline: !online }, { parking: online && !ignition })}"><span>${name}</span></div>`}
			labelStyle={{ opacity: '0.8' }}
			labelInBackground
			zIndex={zIndex}
			optimized={false}
			draggable={false}
			onClick={handleSelectMarkerClick}
		>
			<>
				{/* <div className={`map-price-marker ${!online && 'offline'} ${online && !ignition && 'parking'}`}> */}
				<div className={`map-price-marker ${status === 'parked' ? 'parking' : status === 'driving' ? '' : 'offline'}`}>
					<span>{name}</span>
				</div>

				{showTripsHistory
				&& (
					<MarkerHistoryPopup
						{...props}
					/>
				)}
				{/* <MarkerHistoryPopup
					{...props}
				/> */}
				{/* <InfoWindow
					// ref={(c) => { this.InfoWindowRef = c }}
					position={{ lat: location_lat, lng: location_lng }}
					zIndex={zIndex}
				>
					<div className="markerInfoPopup markerHistoryPopup">
						<div className="headerWrapper">
							<div className="header">
								<Row>
									<Col className="menu" md={12} sm={12} xs={12}>
										<Col
											md={12}
											sm={12}
											xs={12}
											style={{
												display: 'inline-flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px', padding: '1px 8px 0px 3px',
											}}
										>
											<div
												className="menuItem speed"
											/>
											<div>
												<div style={{ color: '#fff', fontSize: '22px', fontWeight: 'bold' }}>
													100
												</div>
												<div style={{ color: '#fff', fontSize: '14px' }}>
													222
												</div>
											</div>
										</Col>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</InfoWindow> */}
			</>
		</MarkerWithLabel>
		// <Marker
		// 	position={{ lat: location_lat, lng: location_lng }}
		// 	markerWithLabel={window.MarkerWithLabel}
		// 	// markerWithLabel={MarkerWithLabel}
		// 	icon={{ url: icon_url }}
		// 	visible={visible_icon}
		// 	opacity={1}
		// 	labelClass="map-price-container"
		// 	labelAnchor={new window.google.maps.Point(10, 35)}
		// 	labelContent={`<div class="${classNames('map-price-marker', { offline: !ads_online }, { parking: ads_online && !ignition })}"><span>${name}</span></div>`}
		// 	label={name}
		// 	title={name}
		// 	//={{ opacity: '0.8' }}
		// 	//labelInBackground
		// 	zIndex={zIndex}
		// 	optimized={false}
		// 	draggable={false}
		// 	onClick={handleSelectMarkerClick}
		// >
		// 	{showTripsHistory
		// 		&& (
		// 			<MarkerHistoryPopup
		// 				{...props}
		// 			/>
		// 		)}
		// </Marker>
	)
}

const mapStateToProps = ({ dashboardData }) => ({
	showTripsHistory: dashboardData.showTripsHistory
});

export default connect(mapStateToProps)(MarkerNode);
