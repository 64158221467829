import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import CustomButton from '../../CustomButton'
import '../../dump/menuComponents/menuComponents.sass'

const ModalUploadFile = ({ title, description, btnUploadTitle, exampleFileTitle, exampleFileLink, acceptFileExtensions, onHide, onFileChange, onUploadFile }) => {
	return (
		<Modal
			show
			onHide={onHide}
			className="modal-batch-function"
		>
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="manage-users row">
					<div className="col-md-12">
						<div className="col-md-12 form-group">
							<div>{description}</div>
							<a href={exampleFileLink} style={{ float: 'right' }}>{exampleFileTitle}</a>
						</div>

						<div className="col-md-12 form-group">
							<div className="upload-file-form" style={{ marginTop: '35px', marginLeft: '25%', width: '280px' }}>
								<div id="upload-form-label" style={{ width: '176px' }} />
								<div className="selectbutton">Select a file</div>
								<input type="file" accept={acceptFileExtensions} style={{ width: '278px' }} name="upload" id="upload-input" onChange={onFileChange} />
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary"
					onClick={onUploadFile}>
					{btnUploadTitle}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	)
}

ModalUploadFile.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	exampleFileTitle: PropTypes.string,
	exampleFileLink: PropTypes.string,
	btnUploadTitle: PropTypes.string,
	acceptFileExtensions: PropTypes.string,

	onFileChange: PropTypes.func.isRequired,
	onUploadFile: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
};

ModalUploadFile.defaultProps = {
	title: 'Upload Drivers',
	description: 'Upload a CSV/Excel file with driver names and card IDs. Required columns are first name and last name, group and card id are optional.',
	exampleFileTitle: 'Example Driver File',
	exampleFileLink: '/files/rosco_drivers_example.csv',
	btnUploadTitle: 'Add New Drivers',
	acceptFileExtensions: '.csv, .xlsx, .xls',
};

export default ModalUploadFile;
