import React from 'react'
import PropTypes from 'prop-types'
import { searchIcon } from './helpers/iconHelpers';

const SearchBar = (props) => {

	const { term, onSearch, placeholder, children } = props;

	return (
		<div className='search-bar-wrapper'>
			<input
				value={term}
				onChange={onSearch}
				placeholder={placeholder}
				className='vehicle-search'
			/>
			{ searchIcon }
			{ children }
		</div>
	)
}

SearchBar.propTypes = {
	term: PropTypes.string.isRequired,
	onSearch: PropTypes.func.isRequired,
	placeholder: PropTypes.string
}

SearchBar.defaultProps = {
	placeholder: 'Search...'
}

export default SearchBar;
