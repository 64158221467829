import React, { useEffect } from 'react'
import { apiCallGet } from '../action/RouterActions'
import momenttz from './helpers/momenttz'

const SessionNotificationsPolling = ({ notification, setSessionDevicesIds, setGlobalPopupData, toggleGlobalPopup, setSessionNotifications }) => {
	useEffect(() => {
		// debugger
		if (notification.sessionDevicesIds.length === 0) return;

		const intervalId = setInterval(() => {
			apiCallGet('/devices/general_status', { device_ids: notification.sessionDevicesIds })
				.then((res) => {
					// console.log('!getDevicesStatuses res: ', res)
					const {devices} = res.data.response
					if (devices.length) {
						let showGlobalPopup = false
						devices.forEach((device) => {
							// If the device woke up
							if (['awake', 'driving', 'parked'].includes(device.status)) {
								// Delete the device_id of the device that woke up from sessionDevicesIds
								const newSessionDevicesIds = notification.sessionDevicesIds.filter((device_id) => +device_id !== +device.device_id)
								setSessionDevicesIds(newSessionDevicesIds)
								// const aa = [...notification.sessionNotifications]
								const newSessionNotifications = notification.sessionNotifications.map((item) => {
									if (+item.device_id === +device.device_id) {
										item.status = 'active'
										item.createtime = momenttz().utc().format('YYYY-MM-DD HH:mm:ss+00')

										setGlobalPopupData({ vehicle_name: item.vehicle_name })
									}

									return item
								})
								setSessionNotifications(newSessionNotifications)
								
								if (!showGlobalPopup) {
									showGlobalPopup = true
									toggleGlobalPopup(true)
								}
							}
						})
					}
					else {
						setSessionDevicesIds([])
					}
				})
				.catch((error) => {
					console.log('!getDevicesStatuses error: ', error.response, error)
				})
		}, 5000); // Poll every 5 seconds

		return () => clearInterval(intervalId); // Clean up on component unmount
	}, [notification.sessionDevicesIds, notification.sessionNotifications])

	const fetchDeviceStatus = async (deviceId) => {
		// Simulated API call for fetching status
		const response = await fetch(`/api/device/${deviceId}/status`);
		const data = await response.json();
		return data.status; // Assuming API response structure
	};

	return null; // No UI for this component
};

export default SessionNotificationsPolling
