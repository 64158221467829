import React from 'react'
import PropTypes from 'prop-types'

import Auth from '../../Auth'
import { defineEnvironmentDokuWiki } from '../../helpers/labels'

const PatternsWikiLinks = (props) => {
	const WIKI_URL = process.env.REACT_APP_dokuwikiLinks
	const { params } = props

	location.href = `https://${defineEnvironmentDokuWiki()}/${WIKI_URL[params.linkType]}?s_t=${new Auth().getSessionToken()}&env=${location.hostname}`

	return <div />
}

PatternsWikiLinks.propTypes = {
	params: PropTypes.string.isRequired,
}

export default PatternsWikiLinks;
