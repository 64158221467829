import React, { useState } from 'react'
import Autocomplete from 'react-autocomplete'
import { get, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import constants from '../../helpers/helperEventAlertsSearch'
import EventsAlertsList from './EventsAlertsList';

const EventsAlertsTab = (props) => {

	const { getSelectedData, getSelectedAlerts, getSelectedEvents, name, title, items, withSearch } = props;

	let autoValueInitial, selectedDeviceIDInitial, selectedAlertTypesInitial, selectedEventTypesInitial;

	if (props.alertsearch && (['tt_link', 'storage_link'].indexOf(props.type) > -1)) {
		autoValueInitial = props.alertsearch.autoValue || '';
		selectedDeviceIDInitial = props.alertsearch.selectedDeviceID || null;
		selectedAlertTypesInitial = props.alertsearch.selectedAlertTypes || [];
		selectedEventTypesInitial = props.alertsearch.selectedEventTypes || [];
	} else {
		autoValueInitial = '';
		selectedDeviceIDInitial = null;
		selectedAlertTypesInitial = [];
		selectedEventTypesInitial = [];
	};

	const [autoValue, setAutoValue] = useState(autoValueInitial);
	const [selectedDeviceID, setSelectedDeviceID] = useState(selectedDeviceIDInitial);
	const [selectedAlertTypes, setSelectedAlertTypes] = useState(selectedAlertTypesInitial);
	const [selectedEventTypes, setSelectedEventTypes] = useState(selectedEventTypesInitial);

	const handleInputChange = (value, item) => {
		const selectedDeviceID = item.id || item.device_id; // TODO if it need - add definition by prop 'name'

		setAutoValue(value);
		setSelectedDeviceID(selectedDeviceID);

		getSelectedData({
			autoValue: value,
			selectedDeviceID,
			selectedAlertTypes,
			selectedEventTypes
		});
	};

	const selectedAlerts = (types) => {
		setSelectedAlertTypes(types);

		if (name === 'vehicle' || name === 'driver') {
			getSelectedData({
				autoValue,
				selectedDeviceID,
				selectedEventTypes,
				selectedAlertTypes: types
			});
		} else getSelectedAlerts(types);
	};

	const selectedEvents = (types) => {
		setSelectedEventTypes(types);

		if (name === 'vehicle' || name === 'driver') {
			getSelectedData({
				autoValue,
				selectedDeviceID,
				selectedAlertTypes,
				selectedEventTypes: types
			});
		} else getSelectedEvents(types);
	}

	const getItem = (item) => {
		let result;
		switch (name) {
			case 'vehicle':
				result = item.vehicle_name || item.device_id;
				break;
			case 'driver':
				result = `${item.first_name} ${item.last_name}`
				break;
			default:
				result = '-';
		}
		return result;
	};

	const matchStateToTerm = (item, value) => {
		let keys;
		switch (name) {
			case 'vehicle':
				keys = ['vehicle_name', 'device_id'];
				break;
			case 'driver':
				keys = ['first_name', 'last_name'];
				break;
			default:
				keys = [];
		}
		const isIncluded = key => get(item, key, '') && get(item, key, '').toString().toLowerCase().includes(value.toLowerCase())
		return isIncluded(keys[0]) || isIncluded(keys[1]);
	};

	// console.log('PROPS EventsAlertsTab: ', props)

	return (
		<div>
			<div className="select-asset">
				{withSearch && (
					<div style={{ maxWidth: '400px', margin: 'auto' }}>
						<Autocomplete
							autoHighlight={false}
							value={autoValue}
							wrapperProps={{ className: 'search-autocomplete' }}
							inputProps={{ id: 'vehicle-search', autoComplete: "off", className: 'vehicle-search' }}
							wrapperStyle={{ position: 'relative', zIndex: 1 }}
							inputStyle={{ width: '100%' }}
							getItemValue={getItem}
							shouldItemRender={matchStateToTerm}
							onChange={(e, value) => {
								setAutoValue(value);
								setSelectedDeviceID(null);
								getSelectedData({ selectedDeviceID: null })
							}}
							onSelect={handleInputChange}
							renderMenu={function (item, value, style) {
								return <div style={{ ...style, ...this.menuStyle }} children={item} />
							}}
							menuStyle={{
								borderRadius: '3px',
								boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
								background: 'rgba(255, 255, 255, 0.95)',
								padding: '2px 0',
								fontSize: '90%',
								position: 'absolute',
								overflow: 'auto',
								maxHeight: '260px',
								left: '0 !important',
								top: '0 !important',
							}}
							renderItem={(item, isHighlighted) => {
								return(
									<div
										className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
										key={item.id || item.device_id}
									>
										{getItem(item)}
									</div>
								)
							}}
							renderInput={props => (
								<input {...props} placeholder={title} />
							)}
							items={items}
						/>
					</div>
				)}
			</div>

			<EventsAlertsList
				selectedAlerts={selectedAlerts}
				selectedEvents={selectedEvents}
				selectedAlertTypes={selectedAlertTypes}
				selectedEventTypes={selectedEventTypes}
				constants={constants}
			/>
		</div>
	)
};

EventsAlertsTab.defaultProps = {
	name: '',
	items: [],
	alertsearch: {},
	getSelectedData: undefined,
	getSelectedAlerts: undefined,
	getSelectedEvents: undefined,
	withSearch: false
}
EventsAlertsTab.propTypes = {
	name: PropTypes.string,
	title: PropTypes.string.isRequired,
	items: PropTypes.arrayOf(PropTypes.object),
	alertsearch: PropTypes.object, // TODO define correct type
	getSelectedData: PropTypes.func,
	getSelectedAlerts: PropTypes.func,
	getSelectedEvents: PropTypes.func,
	withSearch: PropTypes.bool,
	type: PropTypes.string.isRequired
};

export default EventsAlertsTab;
