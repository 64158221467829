import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Select from 'react-select';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import momenttz from '../../helpers/momenttz'
import { arrowIcon, exportIcon } from '../../helpers/iconHelpers'
import CustomButton from '../../CustomButton';

const ModalCompareHistory = (props) => {

	const { profile, onHide, onClickBack, version, history, loaderModal } = props;
	const [selectedProfileVersion, setSelectedProfileVersion] = useState(null);
	const [comparedProfileVerison, setComparedProfileVerison] = useState(null);
	const [selectedConfig, setSelectedConfig] = useState("configurationdv6");
	const [showTableCompare, setShowTableCompare] = useState(false);

	useEffect(() => {
		const data = history.filter(elm => elm.profile_version === version)[0];
		setSelectedProfileVersion(data);
	}, [])

	const handleInputChange = (e) => {
		const data = history.filter(elm => elm.profile_version === e.value)[0];
		setComparedProfileVerison(data);
		setShowTableCompare(true);
	}

	const csvFileName = () => {
		return `comparehistory_${selectedProfileVersion.profile_version}_${comparedProfileVerison.profile_version}_${selectedConfig === "configuration" ? "dv4" : "dv6"}.csv`;
	}

	const dataCompare = () => {
		let data = [],
			data1 = [],
			data2 = [],
			a = JSON.parse(selectedProfileVersion[selectedConfig]),
			b = JSON.parse(comparedProfileVerison[selectedConfig]);
		const event_upload_types_obj_a = JSON.parse(selectedProfileVersion.event_upload_types);
		const event_upload_types_obj_b = JSON.parse(comparedProfileVerison.event_upload_types);

		const d = (obj, types_obj, e) => {
			let event_upload_types = {};
			const config = selectedConfig === "configuration" ? "dv4" : "dv6";
			if (obj.Event.Adas.Headway.SpeedUnit === 'mph') {
				obj.Event.Adas.Headway.SpeedThreshold = Math.round(obj.Event.Adas.Headway.SpeedThreshold / 1.609344);
				if (config === "dv6") obj.Event.Adas.FollowingDistance.SpeedThreshold = Math.round(obj.Event.Adas.FollowingDistance.SpeedThreshold / 1.609344);
			}
			if (obj.System.SpeedUnit === 1) {
				obj.Event.HighSpeed.Threshold = Math.round(obj.Event.HighSpeed.Threshold / 1.609344);
				obj.System.SpeedUnit = "mph";
			} else {
				obj.System.SpeedUnit = "kph";
			}
			for (let key in types_obj) {
				if (key.includes(config)) event_upload_types[key] = types_obj[key];
			}
			if (e) {
				a = obj;
				a["EventUploadTypes"] = event_upload_types
			} else {
				b = obj;
				b["EventUploadTypes"] = event_upload_types;
			}
		}

		d(a, event_upload_types_obj_a, true);
		d(b, event_upload_types_obj_b, false);

		const f = (obj, e, name = []) => {
			for (let key in obj) {
				if (typeof obj[key] === 'object') {
					name.push(!(+key || +key === 0) ? (key !== "Channels" ? key : "Channel") : +key + 1);
					f(obj[key], e, name);
					name.pop();
				} else {
					name.push(key);
					e ? data1.push([name.join(" "),
					obj[key] !== "" ? (!name.join("").includes("Audio") ? obj[key] : (obj[key] === 0 ? false : true)) : 0]) :
						data2.push([name.join(" "),
						obj[key] !== "" ? (!name.join("").includes("Audio") ? obj[key] : (obj[key] === 0 ? false : true)) : 0]);
					name.pop();
				}
			}
		}

		f(a, true);
		f(b, false);

		data1.map(([key1, value1], i) => {
			data2.map(([key2, value2], j) => {
				if ((key1 === key2) && (value1.toString() !== value2.toString()) && (i === j)) data.push({ setting_name: key1, selected_version: value1, compared_verison: value2 });
			})
		})

		return data;
	}

	let options = [];
	history.filter(e => e.profile_version !== version).map(elm => {
		if (elm !== "") {
			options.push({ value: elm.profile_version, label: elm.profile_version });
		}
	});

	const tableNoPaginationExportOptions = {
		//WRAPPER CLASSES
		tableContainerClass: 'rosco-table',
		headerContainerClass: 'rosco-table-header',
		bodyContainerClass: 'rosco-table-body',
		trClassName: (row, rowIdx) => {
			return rowIdx % 2 === 0 ? 'tr-column-even-white' : 'tr-column-odd-grey'
		},
		//STYLING
		hover: true,
		bordered: false,
		exportCSV: true,
		options: {
			exportCSVBtn: (onClick) => (
				<div className="config-compare-group">
					<div className="toggle-wrapper button-group">
						<div className="toggle-buttons">
							<CustomButton
								variant='toggle'
								active={selectedConfig === "configurationdv6"}
								value="configurationdv6"
								onClick={() => setSelectedConfig("configurationdv6")}
								style={{ height: '35px' }}
							>
								DV6
							</CustomButton>
							<CustomButton
								variant='toggle'
								active={selectedConfig === "configuration"}
								value="configuration"
								onClick={() => setSelectedConfig("configuration")}
								style={{ height: '35px' }}
							>
								DV4
							</CustomButton>
						</div>
					</div>
					<CustomButton
						variant='primary-outline'
						size='sm'
						prefix='export-button'
						onClick={onClick}
						style={{ height: '35px' }}
					>
						{exportIcon}
						<span className='export-label'>Export</span>
					</CustomButton>
				</div >
			)
		},
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="config-profile-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg" className="action-title">
					Compare History
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<div>
						<label>Select a version to compare to version: <span>{version}</span></label>
						<Select
							className="rosco-select"
							placeholder="Select..."
							onChange={handleInputChange}
							options={options}
							value={comparedProfileVerison ? { value: comparedProfileVerison.profile_version, label: comparedProfileVerison.profile_version } : ""}
						/>
					</div>
					{showTableCompare &&
						<main className='main-content-compareHistory'>
							<BootstrapTable
								data={dataCompare()}
								{...tableNoPaginationExportOptions}
								csvFileName={csvFileName}
							>
								<TableHeaderColumn
									width="40%"
									dataField="setting_name"
									dataAlign="center"
									csvHeader="Setting Name"
									tdStyle={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
									isKey={true}
								>
									Setting Name
								</TableHeaderColumn>
								<TableHeaderColumn
									width="30%"
									dataField="selected_version"
									dataAlign="center"
									csvHeader={`Version ${selectedProfileVersion.profile_version}`}
								>
									Version {selectedProfileVersion.profile_version}
								</TableHeaderColumn>
								<TableHeaderColumn
									width="30%"
									dataField="compared_verison"
									dataAlign="center"
									csvHeader={`Version ${comparedProfileVerison.profile_version}`}
								>
									Version {comparedProfileVerison.profile_version}
								</TableHeaderColumn>
							</BootstrapTable>
						</main>}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={onClickBack}>
					Back
				</CustomButton>
			</Modal.Footer>
			{loaderModal}
		</Modal>
	);
};

ModalCompareHistory.propTypes = {
	profile: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired,
};

export default connect(
	null,
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(ModalCompareHistory);
