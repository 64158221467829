const errorsList = [
	{ title: 'company_phone_number incorrect format', description: 'Wrong the company phone number format' },
	{ title: 'event doesn\'t exist', description: 'Event doesn\'t exist.' },
	{ title: 'group incorrect format', description: 'Enter a valid group name.' },
	{ title: 'group exist', description: 'Entered group name already exists.' },
	{ title: 'company_new_name incorrect format', description: 'Enter a valid company name.' },
]

export const errorDetection = (errTitle) => {
	let result = errTitle

	const obj = errorsList.find(({ title }) => title === errTitle)
	if (obj) {
		result = obj.description
	}

	return result
}
