import React from 'react';
import PropTypes from 'prop-types'
import { Link, browserHistory } from 'react-router';

const PatternsDeviceInstalledReport = (props) => {
	console.log('!!!!!PatternsDeviceInstalledReport:', props)
	const { params } = props
	const reportId = params.report_id
	let error = ''
	if (!reportId) {
		error = 'The device install report number is required.'
	} else {
		browserHistory.push({ pathname: '/managevehicles/installation/report', state: { reportId } });
	}

	return (
		<article>
			{error
				&& (
					<div>
						<h3>Error 404!</h3>
						<div>{error}</div>
						<div>Go back to <Link to="/" className="btn">Home</Link></div>
					</div>
				)}
		</article>
	)
}

PatternsDeviceInstalledReport.propTypes = {
	params: PropTypes.object.isRequired,
}

export default PatternsDeviceInstalledReport
