import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'

momentDurationFormatSetup(moment)

export const getSpeedEventsFiltersColors = {
	'Low Speeding': '#FB8070',
	'Moderate Speeding': '#DE5442',
	'High Speeding': '#B23D2E',
	'Severe Speeding': '#590b00',
}

export const getDurationFormat = (sec) => {
	if (!sec) {
		return ''
	}
	if (sec > 3600) {
		return `${moment.duration(sec, 'seconds').format('H:mm:ss')} H`
	}
	if (sec > 60) {
		return `${moment.duration(sec, 'seconds').format('m:ss')} M`
	}

	return `${sec} S`
}

export const getDurationTimeFormat = (sec) => {
	if (!sec) {
		return ''
	}
	if (sec > 3600) {
		return moment.duration(sec, 'seconds').format('H:mm:ss')
	}
	if (sec > 60) {
		return moment.duration(sec, 'seconds').format('m:ss')
	}

	return sec
}

export const getDurationUnitFormat = (sec) => {
	if (!sec) {
		return ''
	}
	if (sec > 3600) {
		return 'H'
	}
	if (sec > 60) {
		return 'M'
	}

	return 'S'
}
