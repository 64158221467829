import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

export function getConfigurationLogs(data) {
	console.log('!getConfigurationLogs data', data);

	let url;
	switch (data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/log/zip/${data.device_id}?action=request`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/log/zip/${data.device_id}?action=request`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/log/zip/${data.device_id}?action=request`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'partner_view':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/log/zip/${data.device_id}?action=request`;
		break
	default:
		break
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
       	// "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}

export function configurationRequestLog(data) {
	console.log('!configurationRequestLog data', data);

	let url;
	switch (data.user_role) {
	case 'system_admin':
		url = `${API_URL}/system_admin/log/zip/${data.device_id}?start_date=${data.start_date}&end_date=${data.end_date}&action=${data.action}&type=${data.type}`;
		break;
	case 'customer_service':
		url = `${API_URL}/customer_service/log/zip/${data.device_id}?start_date=${data.start_date}&end_date=${data.end_date}&action=${data.action}&type=${data.type}`;
		break;
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/log/zip/${data.device_id}?start_date=${data.start_date}&end_date=${data.end_date}&action=${data.action}&type=${data.type}`;
		break;
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'partner_view':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/log/zip/${data.device_id}?start_date=${data.start_date}&end_date=${data.end_date}&action=${data.action}&type=${data.type}`;
		break
	default:
		break
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
       	// "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}

export function getLiveStatusLogs(device_id, start_ts, end_ts) {
	let url = `${API_URL}/devices/${device_id}/status/log?start_ts=${start_ts}&end_ts=${end_ts}`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		withCredentials: true,
	}

	return axios(settings);
}

export function getDevicesFeatureUsage(data, user_role, company_name=null, partner_name=null) {
	 console.log('!getDevicesFeatureUsage action data', data, user_role, company_name, partner_name);

	let url;
	switch(user_role) {
		case 'system_admin':
			// url = `${API_URL}/system_admin/companies/${company_name}/statistics/feature_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/statistics/feature_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
			break;
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/statistics/feature_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${company_name}/statistics/feature_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
			break;
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${company_name}/statistics/feature_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/statistics/feature_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
			break;
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/statistics/feature_usage?start_date=${data.start_date}&end_date=${data.end_date}`;
			break
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}

export function getFleetAdminEventsLog(data) {
	console.log('!getFleetAdminEventsLog action data:', data)

	let page_number = 0
	if (data.page_number) {
		page_number = data.page_number
	}

	let url
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/devices/${data.device_id}/events/log?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${page_number}`
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/devices/${data.device_id}/events/log?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${page_number}`
			break
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/devices/${data.device_id}/events/log?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${page_number}`
			break
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'partner_view':
		case 'sales_demo':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/devices/${data.device_id}/events/log?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${page_number}`
			break
	}

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}

export function getDevicesDataUsage(data, user_role, company_name, partner_name) {
	 console.log('!getDevicesDataUsage action data', data, user_role, company_name, partner_name)

	let url
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/devices/usage?start_date=${data.start_date}&end_date=${data.end_date}`
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/devices/usage?start_date=${data.start_date}&end_date=${data.end_date}`
			break
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${company_name}/devices/usage?start_date=${data.start_date}&end_date=${data.end_date}`
			break
		case 'partner_view':
			url = `${API_URL}/partner_view/companies/${company_name}/devices/usage?start_date=${data.start_date}&end_date=${data.end_date}`
			break
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/devices/usage?start_date=${data.start_date}&end_date=${data.end_date}`
			break
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/devices/usage?start_date=${data.start_date}&end_date=${data.end_date}`
			break
	}

	if (data.type) {
		url += `&type=${data.type}`
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}

export function getAvailableFirmwareList(userRole) {
	// console.log('!getAvailableFirmwareList', user_role)
	let url
	switch (userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/available_firmware`
		break
	case 'customer_service':
		url = `${API_URL}/customer_service/available_firmware`
		break
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/available_firmware`
		break;
	case 'sales_demo':
		url = `${API_URL}/sales_demo/available_firmware`
		break
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'user':
	case 'partner_view':
	case 'storage_manager':
	case 'storage_user':
	case 'custom_user':
		url = `${API_URL}/available_firmware`
		break
	default:
		break
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
       	// headers: {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}

// export function getFirmwares() {
//     console.log('!getFirmwares')
//
//     const url = `${API_URL}/customer_service/firmware`;
//     // url = `${API_URL}/customer_service/companies/{{COMPANY_NAME}}/devices/firmware`;
//     // https://api.roscocloud.com/api/v0.5/customer_service/companies/{{COMPANY_NAME}}/devices/firmware
//     let settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": "https://api.roscocloud.com/api/v0.5/customer_service/firmware",
//         "method": "GET",
//         "headers": {},
//         "withCredentials": true
//     }
//
//     return axios(settings);
// }

// export function applyFirmwareAction(data){
//     console.log('!applyFirmware action data', data);
//
//     const url = `${API_URL}/firmware`;
//
//     const form = new FormData();
//     form.append("version", data.version);
//     form.append("device_id", data.device_id);
//
//     const settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": url,
//         "method": "POST",
//         "headers": {},
//         "processData": false,
//         "contentType": false,
//         "mimeType": "multipart/form-data",
//         "data": form,
//         "withCredentials": true
//     }
//
//     return axios(settings);
// }

export function applyFirmwareAction(userRole, data) {
	console.log('!applyFirmware action data', userRole, data);

	let url;
	switch (userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/devices/firmware?ids=${data.device_id}`;
		break
	case 'customer_service':
		url = `${API_URL}/customer_service/devices/firmware?ids=${data.device_id}`;
		break
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/devices/firmware?ids=${data.device_id}`;
		break
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'storage_manager':
		url = `${API_URL}/devices/firmware?ids=${data.device_id}`;
		break
	default:
		break
	}

	const form = new FormData();
	form.append('version', data.version);
	form.append('restart', data.restart);

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// headers: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function firmwareCancel(user_role, data) {
	// console.log('!applyFirmwareCancel action data', data);

	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/devices/firmware?ids=${data}`;
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/devices/firmware?ids=${data}`;
			break
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/devices/firmware?ids=${data}`;
			break
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'storage_manager':
			url = `${API_URL}/devices/firmware?ids=${data}`;
			break
	}

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
//        "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}
// export function cancelFirmwareAction(device_id){
//     console.log('!cancelFirmware action data', device_id);
//
//     const url = `${API_URL}/customer_service/devices/firmware?ids=${device_id}`;
//
//
//     const settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": url,
//         "method": "DELETE",
//         "headers": {},
//         "dataType": 'jsonp',
//         "withCredentials": true
//     }
//
//     return axios(settings);
// }

// export function cancelFirmwareAction(device_id){
//     console.log('!cancelFirmware action data', device_id);
//
//     const url = `${API_URL}/firmware`;
//
//     var form = new FormData();
//     form.append("device_id", device_id);
//
//     const settings = {
//         "async": true,
//         "crossDomain": true,
//         "url": url,
//         "method": "DELETE",
//         "headers": {},
//         "processData": false,
//         "contentType": false,
//         "mimeType": "multipart/form-data",
//         "data": form,
//         "withCredentials": true
//     }
//
//     return axios(settings);
// }

export function getFleetAdminEventsSummary(user_role, company_name=null, partner_name=null ){
	console.log('!getFleetAdminEventsSummary action data', user_role, company_name, partner_name);
	console.log('API_URL', API_URL);

	let url;
	switch(user_role) {
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'user':
		case 'storage_manager':
		case 'storage_user':
		case 'custom_user':
			url = `${API_URL}/devices/events/summary`;
			break;
		case 'customer_service':
			// url = `${API_URL}/customer_service/companies/${company_name}/devices/events/summary`;
			url = `${API_URL}/customer_service/partner/${partner_name}/companies/${company_name}/devices/events/summary`;
			break;
		case 'system_admin':
			url = `${API_URL}/system_admin/partner/${partner_name}/companies/${company_name}/devices/events/summary`;
			break;
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/companies/${company_name}/devices/events/summary`;
			break;
		case 'partner_view':
			url = `${API_URL}/partners/companies/${company_name}/devices/events/summary`;
			break;
		case 'sales_demo':
			url = `${API_URL}/sales_demo/partner/${partner_name}/companies/${company_name}/devices/events/summary`;
			break;
	}

	var settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}

export function getConfigFile(user_role, device_id, checkNewConfig = false) {
	console.log('!getConfigFile action', user_role, device_id)
	let url;
	switch (user_role) {
		case 'fleet_maintainer':
		case 'fleet_manager':
		case 'group_manager':
		case 'storage_manager':
		case 'custom_user':
			url = `${API_URL}/config/${device_id}/file`;
			break
		case 'system_admin':
			url = `${API_URL}/system_admin/config/${device_id}/file`;
			break
		case 'customer_service':
			url = `${API_URL}/customer_service/config/${device_id}/file`;
			break
		case 'partner':
		case 'custom_partner':
			url = `${API_URL}/partners/config/${device_id}/file`;
		break
	}

	if (checkNewConfig) {
		url += `?checknewconfig=${checkNewConfig}`
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}

export function pushConfigFile(args) {
	console.log('!pushConfigFile args: ', args);
	const { userRole, deviceId, file, restart, profileId, profileName } = args

	let url;
	switch (userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/config/${deviceId}/file`;
		break
	case 'customer_service':
		url = `${API_URL}/customer_service/config/${deviceId}/file`;
		break
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/config/${deviceId}/file`;
		break
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'storage_manager':
	case 'custom_user':
		url = `${API_URL}/config/${deviceId}/file`;
		break
	default:
		break;
	}

	const form = new FormData();
	form.append('file', file);
	form.append('restart', restart);
	if (profileId) {
		form.append('profile_id', profileId);
	}
	if (profileName) {
		form.append('profile_name', profileName);
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// headers: {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function pushFirmwareFile(user_role, data) {
	// console.log('!pushFirmwareFile data', data)
	// console.log('!pushFirmwareFile user_role', user_role)
	let url;
	switch(user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/firmware/file`;
			break
	}

	const form = new FormData();
	form.append('file', data.file);
	form.append('release_note', data.release_note);

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
//        "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function deleteFirmware(data) {
	// console.log('!action deleteFirmware data', data);

	let url = `${API_URL}/system_admin/firmware/file/${data.version}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
//        "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function pushModemFile(data) {
	console.log('!pushModemFile action data:', data)

	const url = `${API_URL}/modem/file`,
		  form = new FormData()
	form.append('file', data.file)
	form.append('model', data.model)
	form.append('version', data.version)
	form.append('release_note', data.release_note)

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
//        "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings)
}

export function sendNotification() {
	console.log('!action sendNotification');

	let url = `${API_URL}/email/new-firmware`;

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
//        "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function formatSD(userRole, deviceId) {
	console.log('!formatSD action', userRole, deviceId)

	let url
	switch (userRole) {
	case 'system_admin':
		url = `${API_URL}/system_admin/devices/formatcard`
		break
	case 'customer_service':
		url = `${API_URL}/customer_service/devices/formatcard`
		break
	case 'partner':
	case 'custom_partner':
		url = `${API_URL}/partners/devices/formatcard`
		break
	case 'fleet_maintainer':
	case 'fleet_manager':
	case 'group_manager':
	case 'storage_manager':
	case 'custom_user':
		url = `${API_URL}/devices/formatcard`
		break
	default:
		break;
	}

	const form = new FormData()
	form.append('device_id', deviceId)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
       	// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings)
}

export function changeFirmwareViewingOptions(firmware_name, is_public){
	console.log('!changeFirmwareViewingOptions firmware_name', firmware_name);
	console.log('!changeFirmwareViewingOptions is_public', is_public);

	let url = `${API_URL}/system_admin/firmware/file/${firmware_name}`;

	const form = new FormData();
	form.append('is_public', is_public);

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
//        "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function getAvailableModemList() {
	console.log('!getAvailableModemList action')

	const url = `${API_URL}/available_modems`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings);
}

export function getUnpairedRoutersList(data) {
	console.log('!getUnpairedRoutersList action:', data)

	const url = `${API_URL}/company/${data.company_id}/router`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}

export function deleteModem(data) {
	console.log('!deleteModem action data:', data);

	const url = `${API_URL}/modem/file/${data.modem_id}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
//        "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function upgradeGroupModemFirmware(data){
	console.log('!upgradeModemFirmware action data:', data)

	const url = `${API_URL}/partner/${data.partner_name}/companies/${data.company_name}/modem/firmware/group/${data.group_name}`

	const form = new FormData()
	form.append('ids', data.devices_id)

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
//        "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings);
}

export function upgradeModemFirmware(data){
	console.log('!upgradeModemFirmware action data:', data)

	const url = `${API_URL}/modem/firmware?ids=${data}`

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		//"headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function upgradeModemFirmwareCancel(data){
	console.log('!upgradeModemFirmwareCancel action data:', data);

	const url = `${API_URL}/modem/firmware?ids=${data}`

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
//        "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getServerStats(data) {
	console.log('!getServerStats action data', data)

	let url
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/stats`
			break
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}

export function getServerStatsHistory(data) {
	console.log('!getServerStatsHistory action data', data)

	let url
	switch(data.user_role) {
		case 'system_admin':
			url = `${API_URL}/system_admin/stats/history?start_ts=${data.start_ts}&end_ts=${data.end_ts}&group_range=${data.group_range}`
			break
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": {Pragma: 'no-cache'},
		withCredentials: true,
	}

	return axios(settings)
}