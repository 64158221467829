// FWSD-6457
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'

import CustomButton from '../../component/CustomButton'

import { errorStatusCodes } from '../../component/helpers/helperVehicle'

import './CameraHealth.sass'

const ModalErrorStatus = (props) => {
	// console.log('PROPS ModalErrorStatus: ', props)
	const { selectedAlert: { id, err_code, is_ack, device_id }, setCameraHealthAlert, onHide } = props
	
	const errorStatus = errorStatusCodes.find(({ code })=> code.split(',').includes(err_code)) || {}
	const { code, name, type, definitiona, resolutions } = errorStatus
	const resolutionsList = resolutions.trim().split('.').slice(0,-1)

	// flag of clicking on the Clear button of acknowledged critical alert
	const [clearCriticalConfirmation, setClearCriticalConfirmation] = useState(false)

	return (
		<Modal
			show
			onHide={onHide}
			dialogClassName="custom-request-modal modal-error-status"
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{ clearCriticalConfirmation
						? <>Clear Critical Issue?</>
						: (
							<>
								<CustomButton
									variant="primary"
									style={{ marginRight: '15px' }}
									size="hs"
									prefix={`no-cursor ${type}`}
								>
									{type === 'crit' ? 'CRITICAL' : 'WARN'}
								</CustomButton>
								{err_code} - {name}
							</>
						)
					}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{ clearCriticalConfirmation
					? (
						<>
							<div className="mb-20">
								By clearing this critical error, you are confirming that the issue has been fixed and the camera is now operating normally.
							</div>
							<div>
								Clear critical error?
							</div>
						</>
					)
					: (
						<>
							<div className="mb-20">
								<label>DESCRIPTION</label>
								<div>{definitiona}</div>
							</div>

							<div className="mb-20">
								<label>RESOLUTION CHECKLIST</label>
								{
									resolutionsList.length > 0
									? <ul>
										{resolutionsList.map((item) => (
											<li>{item.trim()}.</li>
										))}
									</ul>
									: <div>NO RESOLUTION</div>
								}
							</div>

							{ type === 'crit'
								? <div>Notifications will continue to be sent for this critical error until acknowledged.</div>
								: <div>Notifications will continue to be sent for this warning until acknowledged. After acknowledgment, warning will clear on next camera boot.</div>
							}
						</>
					)
				}
			</Modal.Body>
			<Modal.Footer bsPrefix="default-modal-footer modal-footer">
				{
					clearCriticalConfirmation
					? (
						<>
							<CustomButton
								variant='primary-outline'
								onClick={() => { setCameraHealthAlert({ device_error_id: id, is_clr: true}) }}
							>
								Confirm Clear
							</CustomButton>
							<CustomButton
								variant='primary'
								onClick={() => setClearCriticalConfirmation(false)}
							>
								Back
							</CustomButton>
						</>
					)
					: (
						is_ack 
						? 
							type === 'crit'
							? 	<CustomButton
									variant='primary'
									onClick={(e) => { e.target.blur(); /* removing focus on Confirm Clear button*/ setClearCriticalConfirmation(true);}}
								>
									Clear
								</CustomButton>
							:	<div style={{ color: '#0FA739' }}>Acknowledged</div>
						: 	<CustomButton
								variant='primary'
								onClick={() => { setCameraHealthAlert({ err_code, device_id }) }}
							>
								Acknowledge
							</CustomButton>
					)
				}
			</Modal.Footer>
		</Modal>
	)
}

ModalErrorStatus.propTypes = {
	selectedAlert: PropTypes.objectOf(PropTypes.any).isRequired,
	setCameraHealthAlert: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
}

export default ModalErrorStatus
