import React, { useState, useEffect, useRef } from 'react'
import { Button } from 'react-bootstrap'
import { browserHistory } from 'react-router'
import ColorPicker from 'rc-color-picker'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
// import FontPicker from 'font-picker-react'

// import { Editor } from "react-draft-wysiwyg";
// import { EditorState, convertFromHTML, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
// import draftToHtml from 'draftjs-to-html'
// import htmlToDraft from 'html-to-draftjs';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import CKEditor from 'ckeditor4-react';

import GreyHeader from '../../../component/dump/menuComponents/GreyHeader'
// import { readImage } from '../../../component/helpers/helperAccount'
import { apiCallGet, apiCall } from '../../../action/RouterActions'
import {
	constants, faviconsFolder, headerLogosFolder, headerLogosExtendedFolder, loadersFolder, faviconsList, headerLogosList, headerLogosExtendedList, loadersList, googleMapStylesList,
} from '../../../component/helpers/constants'

import 'rc-color-picker/assets/index.css';
import CustomButton from '../../../component/CustomButton';

const themeColors = [
	{ title: 'Primary Color', name: 'themecolors.primary' },
	{ title: 'Secondary Color', name: 'themecolors.secondary' },
	{ title: 'Tertiary Color', name: 'themecolors.tertiary' },
	{ title: 'Alternate/Parking Color', name: 'themecolors.alternate' }
]

const PartnerWhitelabelSettings = (props) => {

	const { toggleLoader } = props;
	// const headerLogoUrl = '';
	// const headerLogoExtendedUrl = '';
	// const loaderUrl = '';
	const [initialSettings, setInitialSettings] = useState(JSON.parse(JSON.stringify(constants.defaultPartnerWhitelabelSettings)));
	const [settings, setSetings] = useState(JSON.parse(JSON.stringify(constants.defaultPartnerWhitelabelSettings)));
	const font = useRef(null);

	useEffect(() => {
		getPartnerWhitelabelSettings();
	}, []);

	const getPartnerWhitelabelSettings = () => {
		toggleLoader(true);
		apiCallGet('/partner/{company_id}/settings', { company_id: props.location.state.partnerId })
			.then((res) => {
				// console.log('getPartnerWhitelabelSettings res: ', res)
				toggleLoader(false);
				const { settings } = res.data.response;
				if (!_.isEmpty(settings)) {
					setInitialSettings(JSON.parse(settings.settings));
					setSetings(JSON.parse(settings.settings));
				}
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('getPartnerWhitelabelSettings /partner/{partner_id}/settings error: ', error.response, error)
				toastr.error('Unable to get the partner whitelabel settings. Please try again later');
			})
	}

	const handleSaveClick = () => {
		if (_.isEqual(settings, initialSettings)) {
			toastr.warning('', 'Whitelabel settings have no changes')
			return
		}

		const params = {
			partner_id: props.location.state.partnerId,
			settings: JSON.stringify(settings)
		};

		toggleLoader(true);
		apiCall('POST', '/partner/{partner_id}/settings', params)
			.then((res) => {
				toggleLoader(false);

				const updatedSettings = res.data.response.settings;
				if (!_.isEmpty(updatedSettings)) {
					setInitialSettings(JSON.parse(updatedSettings.settings));
					setSetings(JSON.parse(updatedSettings.settings));
				}

				toastr.success('Changes were successfully saved');
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!PartnerWhitelabelSettings /partner/{partner_id}/settings error: ', error.response, error)
				toastr.error('Unable to update whitelabel settings. Please try again later');
			});
	};

	const handleResetClick = () => {
		if (_.isEqual(settings, initialSettings)) {
			toastr.warning('', 'Whitelabel settings have no changes')
			return
		}
		setSetings(JSON.parse(JSON.stringify(initialSettings)));
	}

	const handleDefaultClick = () => {
		if (_.isEqual(settings, constants.defaultPartnerWhitelabelSettings)) {
			toastr.warning('', 'Whitelabel settings are default')
			return
		}

		setSetings(JSON.parse(JSON.stringify(constants.defaultPartnerWhitelabelSettings)));
	}

	const [imgId, setImgId] = useState();
	// updateLogoFile = (e, inputId, imgId) => {
	// 	readImage(inputId, imgId)
	// 	setImgId(e.target.files[0]);
	// }

	const handleSettingChange = (settingValue, settingPath) => {
		const newSettings = _.set({ ...settings }, settingPath, settingValue);
		setSetings(newSettings);
	}

	const addFont = () => {
		let valueFont = font.current.value;
		const fontArr = settings.font.length ? settings.font.split(',') : []

		if (!fontArr.includes(valueFont)) {
			fontArr.push(valueFont)
			handleSettingChange(fontArr.join(','), 'font')
		}

		// () => handleSettingChange(settings.font.length ? settings.font+','+font.current.value : font.current.value,  'font')
	}

	// console.log('PROPS PartnerWhitelabelSettings: ', this.props)
	// console.log('STATES PartnerWhitelabelSettings: ', this.state)
	// const { settings /* headerLogoUrl, headerLogoExtendedUrl, loaderUrl,*/ }
	// const defultHeaderLogoUrl = '/images/roscovision-icon.png'
	// const defultHeaderLogoExtendedUrl = '/images/rosco-logo-horizontal.png'

	return (
		<div className="partner-whitelabel">
			<GreyHeader title="Partner Settings" subtitle={props.location.state.partnerName} />

			<div className="page-subheader">
				<div className="subheader-section search-flex" />
				<div className="subheader-section">
					<CustomButton variant="secondary" onClick={handleSaveClick}>SAVE</CustomButton>
					<CustomButton variant="secondary-outline" onClick={browserHistory.goBack}>BACK</CustomButton>
					<CustomButton variant="delete" onClick={handleResetClick}>RESET</CustomButton>
					<CustomButton variant="delete" onClick={handleDefaultClick} title="Set Rosco default settings">DEFAULT</CustomButton>
				</div>
			</div>

			<main className="main-content-block">
				<section>
					<h3 className="category-title">Color Scheme</h3>
					<div className="four-column-grid with-margin">
						{themeColors.map(({ title, name }) => {
							return (
								<div key={name}>
									<div>
										<label className="control-label">{title}:</label>
									</div>
									<ColorPicker
										color={_.get(settings, name)}
										alpha={100}
										enableAlpha={false}
										onChange={color => handleSettingChange(color.color, name)}
									>
										<span className="rc-color-picker-trigger custom-style" />
									</ColorPicker>
									<div>{_.get(settings, name)}</div>
								</div>
							)
						})
						}
					</div>
				</section>

				<section>
					<h3 className="category-title">Font Family</h3>
					<div className="two-column-grid with-margin">
						<div>
							<label className="control-label">Available Font:</label>
							{/* <FontPicker
									apiKey="AIzaSyB0EHySiNhLB8UPm-yXmk4oiMxZyfL3vyU"
									activeFontFamily={settings.font || 'Arimo'}
									onChange={font => { console.log('font: ', font); handleSettingChange(font.family, 'font'); }}
									categories={["sans-serif"]}
									limit={1000}
								/> */}
							<div className="upload-button-wrapper">
								<select
									ref={font}
									className="rosco-select block"
									style={{ marginRight: '20px' }}
								// value={settings.favicon}
								// onChange={event => handleSettingChange(event.target.value, 'font')}
								>
									{constants.availableFonts.map(name => (
										<option key={name} value={name} className="menu-item">
											{name}
										</option>
									))}
								</select>
								<CustomButton
									variant="primary-outline"
									onClick={addFont}
								>
									Add
								</CustomButton>
							</div>
						</div>
						<div>
							<label className="control-label">Selected Font:</label>
							<div className="upload-button-wrapper">
								<input
									type="text"
									className="vehicle-search"
									readOnly
									value={settings.font}
									style={{ marginRight: '20px' }}
								// onChange={event => () => {}}
								/>
								<CustomButton
									variant="primary-outline"
									onClick={() => handleSettingChange('', 'font')}
								>
									Clear
								</CustomButton>
							</div>
						</div>
					</div>
				</section>

				<section>
					<h3 className="category-title">Tab Attributes</h3>
					<div className="two-column-grid with-margin">
						<div>
							<label className="control-label">Tab Title:</label>
							<input
								type="text"
								className="vehicle-search"
								onChange={event => handleSettingChange(event.target.value, 'title')}
								value={settings.title}
							// maxLength={50}
							/>
						</div>
						<div>
							<label className="control-label">Tab Favicon:</label>
							<div className="upload-button-wrapper">
								<select
									className="rosco-select block"
									style={{ marginRight: '20px', marginTop: '1px' }}
									value={settings.favicon}
									onChange={event => handleSettingChange(event.target.value, 'favicon')}
								>
									<option />
									{faviconsList.map(name => (
										<option key={name} value={name} className="menu-item">
											{name}
										</option>
									))}
								</select>
								{settings.favicon && (
									<img
										className="image-favicon"
										src={faviconsFolder + settings.favicon}
										// onErrorCapture={e => e.target.src = noImageUrl}
										alt="Tab Favicon"
									/>
								)}
							</div>
						</div>
					</div>
				</section>

				<section>
					<h3 className="category-title">Custom Logo</h3>
					<div className="three-column-grid with-margin">
						<div>
							<label className="control-label">Header Logo:</label>
							<div className="upload-button-wrapper">
								<select
									className="rosco-select block"
									style={{ marginRight: '20px' }}
									value={settings.header_logo}
									onChange={event => handleSettingChange(event.target.value, 'header_logo')}
								>
									<option />
									{headerLogosList.map(name => (
										<option key={name} value={name} className="menu-item">
											{name}
										</option>
									))}
								</select>
								{settings.header_logo && (
									<img
										className="image-favicon"
										src={headerLogosFolder + settings.header_logo}
										// onErrorCapture={e => e.target.src = noImageUrl}
										alt="Header Logo"
									/>
								)}
							</div>
						</div>

						<div>
							<label className="control-label">Header Extended Logo:</label>
							<div className="upload-button-wrapper">
								<select
									className="rosco-select block"
									style={{ marginRight: '20px' }}
									value={settings.header_logo_ext}
									onChange={event => handleSettingChange(event.target.value, 'header_logo_ext')}
								>
									<option />
									{headerLogosExtendedList.map(name => (
										<option key={name} value={name} className="menu-item">
											{name}
										</option>
									))}
								</select>
								{settings.header_logo_ext && (
									<img
										className="image-favicon"
										src={headerLogosExtendedFolder + settings.header_logo_ext}
										// onErrorCapture={e => e.target.src = noImageUrl}
										alt="Header Extended Logo"
									/>
								)}
							</div>
						</div>

						<div>
							<label className="control-label">Loader:</label>
							<div className="upload-button-wrapper">
								<select
									className="rosco-select block"
									style={{ marginRight: '20px' }}
									value={settings.loader}
									onChange={event => handleSettingChange(event.target.value, 'loader')}
								>
									<option />
									{loadersList.map(name => (
										<option key={name} value={name} className="menu-item">
											{name}
										</option>
									))}
								</select>
								{settings.loader && (
									<img
										className="image-favicon"
										src={loadersFolder + settings.loader}
										// onErrorCapture={e => e.target.src = noImageUrl}
										alt="Header Extended Logo"
									/>
								)}
							</div>
						</div>

						{/* <div className="upload-button-wrapper">
								<input
									type="file"
									ref={(e) => { headerLogoFile = e }}
									id="headerLogoFile"
									style={{ display: 'none' }}
									onChange={(e) => updateLogoFile(e, 'headerLogoFile', 'headerLogoUrl')}
								/>
								<CustomButton variant="primary-outline" size="sm" onClick={() => headerLogoFile.click()}>
									Add Custom Header Logo
								</CustomButton>
								<img
									className="image-logo"
									src={headerLogoUrl || defultHeaderLogoUrl}
									// onErrorCapture={e => e.target.src = noImageUrl}
									id="headerLogoUrl"
									alt="Header Logo"
								/>
							</div>

							<div className="upload-button-wrapper">
								<input
									type="file"
									ref={(e) => { headerLogoExtendedFile = e }}
									id="headerLogoExtendedFile"
									style={{ display: 'none' }}
									onChange={(e) => updateLogoFile(e, 'headerLogoExtendedFile', 'headerLogoExtendedUrl')}
								/>
								<CustomButton variant="primary-outline" size="sm" onClick={() => headerLogoExtendedFile.click()}>
									Add Custom Header Extended Logo
								</CustomButton>
								<img
									src="/images/rosco-logo-horizontal.png"
									className="image-logo"
									src={headerLogoExtendedUrl || defultHeaderLogoExtendedUrl}
									// onErrorCapture={e => e.target.src = noImageUrl}
									id="headerLogoExtendedUrl"
									alt="Header Extended Logo"
								/>
							</div>

							<div className="upload-button-wrapper">
								<input
									type="file"
									ref={(e) => { loaderFile = e }}
									id="loaderFile"
									style={{ display: 'none' }}
									onChange={(e) => updateLogoFile(e, 'loaderFile', 'loaderUrl')}
								/>
								<CustomButton variant="primary-outline" size="sm" onClick={() => loaderFile.click()}>
									Add Custom Loader
								</CustomButton>

								{loaderUrl
									? (
										<img
											className="image-logo"
											src={loaderUrl}
											// onErrorCapture={e => e.target.src = noImageUrl}
											id="loaderUrl"
											alt="Header Extended Logo"
										/>
									) : (
										<div className="default-loader">
											<div className="loader">
												<i className="fas fa-circle-notch fa-spin" />
											</div>
										</div>
									)}
							</div>
							*/}
					</div>
				</section>

				<section>
					<h3 className="category-title">Footer</h3>
					<div className="two-column-grid with-margin">
						<div className="two-columns">
							<textarea
								value={_.get(settings, 'footer') || ''}
								onChange={event => handleSettingChange(event.target.value, 'footer')}
								style={{ width: '100%', height: '80px', resize: 'vertical' }}
							/>
							<div dangerouslySetInnerHTML={{ __html: _.get(settings, 'footer') || '' }} />
						</div>
					</div>
				</section>

				{/* <CKEditor
						// config={ {
						// 	toolbar: [ [ 'Bold', 'Italik', 'Strike', '-', 'Link', 'SpecialChar' ] ]
						// } }
						data="<p>Hello from CKEditor 4!</p>"
						onChange={evt => console.log( evt.editor.getData(), evt )}
					/> */}

				{/* <CKEditor
							editor={ClassicEditor}
							data="<p>Hello from CKEditor 5!</p>"
							onReady={editor => {
								// You can store the "editor" and use when it is needed.
								console.log('Editor is ready to use!', editor);
							}}
							onChange={(event, editor) => {
								const data = editor.getData();
								console.log('onChange : ', event, editor, data);
							}}
							onBlur={(event, editor) => {
								console.log('onBlur.', editor);
							}}
							onFocus={(event, editor) => {
								console.log('onFocus.', editor);
							}}
						/>
					*/}

				{/* <section>
						<h3 className="category-title">Map Color Scheme</h3>
						<div className="two-column-grid with-margin">
							<div>
								<select
									className="rosco-select block"
									style={{ marginRight: '20px' }}
									value={settings.map_style}
									onChange={event => handleSettingChange(event.target.value, 'map_style')}
								>
									<option />
									{_.keys(googleMapStylesList).map(name => (
										<option key={name} value={name} className="menu-item">
											{name}
										</option>
									))}
								</select>
							</div>
							<div className="map-color-scheme-tree">
								{settings.map_style && (
									googleMapStylesList[settings.map_style].map((item, i) => {
										return <pre key={i} style={{ display: 'inline-block' }}>{JSON.stringify(item)}</pre>
									})
								)}
							</div>
						</div>
					</section> */}
			</main>
		</div>
	)
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(PartnerWhitelabelSettings);
