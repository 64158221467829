// import { ActionTypes } from '../action/types'

const initialState =  {
    alertsearch: {},
    fetching: false,
};

export default function alertsearch(state = initialState, action) {
    switch (action.type) {
        // case ActionTypes.SET_ALERTSEARCH:
        //     return { ...state, alertsearch: action.payload };
        case 'GET_ALERTSEARCH_SUCCESS':
            return {...state, ...{alertsearch: action.payload, fetching: false}};
//            return Object.assign({}, state, {user: action.payload, fetching: false});
//            return {
//                user: action.payload,
//                fetching: false,
//            };
        default:
            return state
    }
}