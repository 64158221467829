import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import DateDropdown from '../../dump/menuComponents/DateDropdown'
import { getDateRangeFromTabKey } from '../reportsComponents/ReportsHelpers'

const EventClipsDateRange = (props) => {
	const { eventDate, startDate, endDate, onSelectDate, tabKey, onDateChanged, getPreviousEvents, getNextEvents } = props

	const onSelectDateClick = (argTabKey, argStartDate, argEndDate) => {
		// console.log('onSelectDateClick argTabKey, argStartDate, argEndDate: ', argTabKey, argStartDate, argEndDate)
		let newStartDate
		let newEndDate

		if (argTabKey) {
			[newStartDate, newEndDate] = getDateRangeFromTabKey(argTabKey)
		}
		else {
			newStartDate = argStartDate ? moment(argStartDate).format('YYYYMMDD') : argStartDate
			newEndDate = argEndDate ? moment(argEndDate).format('YYYYMMDD') : argEndDate
		}

		onSelectDate(argTabKey, newStartDate, newEndDate);
	}

	// console.log('PROPS EventClipsDateRange: ', props)

	return (
		<div className="event-date date">
			<div className="input event-date">
				<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-caret-left-fill" viewBox="0 0 16 16" onClick={getPreviousEvents}>
					<path d="M3.86 8.753l5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
				</svg>
				<DateDropdown
					start_date={startDate || moment(eventDate).format('YYYY-MM-DD')}
					end_date={endDate || moment(eventDate).format('YYYY-MM-DD')}
					tabKey={tabKey}
					onSelectDate={onSelectDateClick}
					onChange={onDateChanged}
				/>
				<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" className="bi bi-caret-right-fill" viewBox="0 0 16 16" onClick={getNextEvents}>
					<path d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
				</svg>
			</div>
		</div>
	)
}

EventClipsDateRange.propTypes = {
	// tabKey: PropTypes.number.isRequired,
	eventDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(moment),
	]),
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onSelectDate: PropTypes.func.isRequired,
	onDateChanged: PropTypes.func.isRequired,
	getPreviousEvents: PropTypes.func.isRequired,
	getNextEvents: PropTypes.func.isRequired,
}

EventClipsDateRange.defaultProps = {
	eventDate: '',
	startDate: '',
	endDate: '',
}

export default EventClipsDateRange;
