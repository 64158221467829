import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CategoryItem from '../../dump/menuComponents/CategoryItem';
import SearchBar from '../../SearchBar'
import './Reports.sass'
import CustomReportTable from './CustomReportTable';
import ReportSubscriptionsModal from './Modals/ReportSubscriptionsModal';
import NewReportModal from './Modals/NewReportModal';
import { getCustomReports } from '../../../action/ReportsActions';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import { standardReportData } from './ReportsHelpers';
import CustomButton from '../../CustomButton';

const CustomReports = (props) => {

    const { showReport, user, company, toggleLoader } = props;
    const userRole = user.roles[0]
    const user_permissions = user.permissions || []

    const [showSubscriptions, setShowSubscriptions] = useState(false);
    const [showNewReport, setShowNewReport] = useState(false);
    const [report, setReport] = useState('');
    const [reportSearch, setReportSearch] = useState('');
    const [userReports, setUserReports] = useState([]);
    const [sharedReports, setSharedReports] = useState([]);

    useEffect(() => {
        if (props.selectedReport && props.selectedReport.name) {
            selectReport(props.selectedReport)
        }
        getAllCustomReports()
    }, [])

    useEffect(() => {
        getAllCustomReports();
        setReportSearch('');
        if (showReport) props.handleNavigation(2);
    }, [company.company_id])

    const getAllCustomReports = (reportId) => {
        //ReportId is not undefined if the user edited a report
        const { id, email } = user;
        toggleLoader(true);
        getCustomReports(email, company.company_id)
            .then(res => {
                const customReports = res.data.response;
                const userReports = []
                const sharedReports = []
                for (let report of customReports) {
                    let vehicles = report.device_ids;
                    let groups = report.group_ids;
                    report.vehicles = vehicles;
                    report.groups = groups;
                    if (report.owner_id === id) {
                        userReports.push(report)
                    }
                    else {
                        sharedReports.push(report)
                    }
                    if (report.id === reportId) {
                        //updates edited report
                        setReport(report)
                    }
                }
                setUserReports(userReports)
                setSharedReports(sharedReports);
                toggleLoader(false);
            })
            .catch(err => {
                toggleLoader(false);
                toastr.error('There was an error getting your custom reports. Please try again later.')
            })
    }

    const selectReport = (report) => {
        setReport(report)
        props.handleShowReport();
    }

    const viewReport = (report) => {
        if (report.report_type === 'standard') {
            props.handleNavigation(1, standardReportData.find(standardReport => report.report_id.toString() === standardReport.id))
        }
        else {
            const formattedReport = {
                id: report.report_id,
                name: report.report_name,
                fields: report.report_fields,
                vehicles: report.report_vehicles,
                owner_id: report.report_owner === user.email ? user.id : null,
                user: report.report_users
            }
            setShowSubscriptions(false)
            selectReport(formattedReport)
        }
    }

    const newReportAdded = () => {
        setShowNewReport(false)
        getAllCustomReports();
    }

    const showUpdatedReports = (report = null) => {
        report ? getAllCustomReports(report.id) : getAllCustomReports();
    }

    return (
        <div className="reports-container">
            {!showReport ?
                <>
                    <div className='page-subheader'>
                        <div className='subheader-section search-flex'>
                            <SearchBar term={reportSearch} onSearch={(e) => setReportSearch(e.target.value)} />
                            {(!['custom_user', 'custom_partner'].includes(userRole) || _.find(user_permissions, { 'perm_category_id': 3, 'a_edit': true })) &&
                                <CustomButton
                                    variant='primary'
                                    size="sm"
                                    onClick={() => setShowNewReport(true)}
                                >
                                    + Add Report
                                </CustomButton>
                            }
                        </div>
                        <div className='subheader-section'>
                            <CustomButton
                                variant="primary-outline"
                                onClick={() => setShowSubscriptions(true)}
                            >
                                Automated Reports
                            </CustomButton>
                        </div>
                    </div>
                    <main className='reports-body two-columns main-content-block'>
                        {(!['custom_user', 'custom_partner'].includes(userRole) || _.find(user_permissions, { 'perm_category_id': 3, 'a_edit': true })) &&
                            <section className='reports-category'>
                                <h3 className='category-title'>My Custom Reports</h3>
                                <div className='category-subheader'>Custom reports created by you.</div>
                                {userReports && userReports.length ?
                                    <div className='category-list'>
                                        {userReports.map(report => {
                                            if (!reportSearch || report.name.toLowerCase().includes(reportSearch.toLowerCase())) {
                                                return <CategoryItem
                                                    key={report.id}
                                                    onClick={() => selectReport(report)}
                                                    text={report.name}
                                                    withArrow
                                                />
                                            }
                                        })}
                                    </div> :
                                    <div className='add-report'>
                                        <CustomButton variant='link' size='lg' prefix="no-underline" onClick={() => setShowNewReport(true)}>+ Add New Report</CustomButton>
                                    </div>
                                }
                            </section>
                        }
                        <section className='reports-category'>
                            <h3 className='category-title'>Shared Custom Reports</h3>
                            <div className='category-subheader'>Custom reports created by other users, that have been shared with you.</div>
                            {sharedReports && sharedReports.length ?
                                <div className='category-list'>
                                    {sharedReports.map(report => {
                                        if (!reportSearch || report.name.toLowerCase().includes(reportSearch.toLowerCase())) {
                                            return <CategoryItem
                                                key={report.id}
                                                onClick={() => selectReport(report)}
                                                text={report.name}
                                                withArrow
                                            />
                                        }
                                    })}
                                </div> :
                                <div className='no-reports-message'>
                                    There doesn't seem to be anything here...
                                </div>
                            }
                        </section>
                    </main>
                </> :
                <CustomReportTable goBack={() => props.handleNavigation(2)} reportInfo={report} showUpdatedReports={showUpdatedReports} />
            }
            {showSubscriptions &&
                <ReportSubscriptionsModal
                    onHide={() => setShowSubscriptions(false)}
                    viewReport={viewReport}
                />}
            {showNewReport &&
                <NewReportModal
                    onHide={() => setShowNewReport(false)}
                    userReports={userReports.map(e => e = e.name)}
                    goToCustomReports={newReportAdded}
                />}
        </div>
    )
}

CustomReports.propTypes = {
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    company: PropTypes.objectOf(PropTypes.any).isRequired
};

const mapStateToProps = (state) => ({
    user: state.user.user,
    company: state.company.company
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomReports);
