import axios from 'axios'
import _ from 'lodash'

const API_URL = process.env.REACT_APP_API_URL

export function getIdlingSummaryReport(company_id, start_date, end_date) {
    const url = `${API_URL}/reports/idling/summary?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getIdlingBreakdownReport(company_id, start_date, end_date) {
    const url = `${API_URL}/reports/idling/breakdown?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getSpeedingBreakdownReport(company_id, start_date, end_date) {
    const url = `${API_URL}/company/${company_id}/reports/speeding/breakdown?start_date=${start_date}&end_date=${end_date}`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getSpeedingSummaryReport(company_id, start_date, end_date) {

    const url = `${API_URL}/company/${company_id}/reports/speeding/summary?start_date=${start_date}&end_date=${end_date}`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getCustomReports(email) {

    const url = `${API_URL}/reports/custom/user/${email}`

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getCustomReportById(id, start_date, end_date, company_id) {

	const url = `${API_URL}/reports/custom/${id}/generate?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function addCustomReport(data) {
	
    const url = `${API_URL}/reports/custom/add`;

	let form = new FormData();
	form.append('name', data.name);
    form.append('type', data.type);
    form.append('fields', data.fields);
    _.has(data, 'vehicles') ? form.append('device_ids', data.vehicles) : false
    _.has(data, 'users') ? form.append('users', data.users) : false

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	};
	return axios(settings);
}

export function updateReport(id, data) {

	let url = `${API_URL}/reports/custom/${id}`;

	let form = new FormData()

    _.has(data, 'name') ? form.append('name', data.name) : false
    _.has(data, 'fields') ? form.append('fields', data.fields) : false
    _.has(data, 'users') ? form.append('users', data.users) : false
    _.has(data, 'vehicles') ? form.append('vehicles', data.vehicles) : false

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings)
}

export function deleteCustomReport(id){
	let url = `${API_URL}/reports/custom/${id}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getReportSubscriptions(email, fields = null) {

	let url = `${API_URL}/reports/subscriptions/${email}`;

	if (fields) {
		url += ('?' + fields)
	}

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function addReportSubscription(data) {
	
    const url = `${API_URL}/reports/subscriptions`;

	let form = new FormData();
	form.append('report_id', data.report_id);
    form.append('report_type', data.report_type);
    form.append('subscribers', data.subscribers);
    form.append('frequency', data.frequency);
    _.has(data, 'day_run') ? form.append('day_run', data.day_run) : false
    form.append('company_id', data.company_id);

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	};
	return axios(settings);
}

export function updateReportSubscription(data) {
	let url = `${API_URL}/reports/subscriptions/${data.id}`;

	let form = new FormData()

	_.has(data, 'frequency') ? form.append('frequency', data.frequency) : false
    _.has(data, 'day_run') ? form.append('day_run', data.day_run) : false
    _.has(data, 'subscribers') ? form.append('subscribers', data.subscribers) : false

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'POST',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings)
}

export function deleteReportSubscription(id){
	let url = `${API_URL}/reports/subscriptions/${id}`;

	let settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'DELETE',
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}