import axios from 'axios'
import { constants } from '../component/helpers/helperGeotab'

const API_URL = process.env.REACT_APP_API_URL

export function deviceAddGeotabSerialN(data) {
	console.log('!deviceAddGeotabSerialN action data', data);

	const url = `${API_URL}/geotab/device/${data.device_id}`;

	var form = new FormData();
	form.append('serial_number', data.serial_number);

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
//        "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true
	}

	return axios(settings);
}

export function getCompanyGeotabData(data) {
	console.log('!getCompanyGeotabData action data', data);

	let url = `${API_URL}/company/geotab/partner/${data.partner_name}/companies/${data.company_name}`
	if (data.get_p) {
		url += '?p=true'
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		withCredentials: true,
	}

	return axios(settings);
}

export function companyHash(hash) {
	console.log('!companyHash action data', hash);
	const url = `${API_URL}/company/hash/${hash}`;

	var settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": { Pragma: 'no-cache'},
		withCredentials: true
	}

	return axios(settings);
}

export function companyCheckFakeEmails(hash){
	console.log('!companyCheckFakeEmails action data', hash);
	const url = `${API_URL}/company/hash/${hash}/fakeemails`;

	var settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": { Pragma: 'no-cache'},
		withCredentials: true
	}

	return axios(settings);
}

// https://mypreview.geotab.com/apiv1/Get?typeName=SystemSettings&credentials={%22database%22:%22rosco_demo%22,%22userName%22:%22romabogdan@gmail.com%22,%22sessionId%22:%22TR4SAItioZy3Ct0JvU0C0A%22}
export function getSystemSettings(data){
	console.log('!getSystemSettings action data', data);
	const url = `https://${data.path}/apiv1/Get?typeName=SystemSettings&credentials={database:"${data.database}",userName:"${data.email}",password:"${data.password}"}`;

	var settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'GET',
//        "headers": { Pragma: 'no-cache'},
//        "withCredentials": true
	}

	return axios(settings);
//    return axios.get(url);
}

export function insertGeotabCompany (data) {
	console.log('!insertGeotabCompany action data', data);
	const url = `${API_URL}/company/geotab/${data.company_id}`;

	var form = new FormData();
	if (data.database)
		form.append('database', data.database);
	if (data.email)
		form.append('email', data.email);
	if (data.password)
		form.append('password', data.password);
	if (data.sessionId)
		form.append('session_id', data.sessionId);
	if (data.path)
		form.append('path', data.path);
	if (data.fleet_token)
		form.append('token', data.fleet_token);

	const settings = {
		async: true,
		crossDomain: true,
		url: url,
		method: 'PUT',
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function deleteGeotabCompany(data) {
	console.log('!deleteGeotabCompany action data:', data);
	const url = `${API_URL}/company/geotab/${data.company_id}`;

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'DELETE',
		// "headers": {},
		dataType: 'jsonp',
		withCredentials: true,
	}

	return axios(settings);
}

export function getGeotabCompanies(data) {
	console.log('!getGeotabCompanies action data', data)

	let url = `${API_URL}/geotab/companies`
	if (data.get_password) {
		url += '?p=true'
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		withCredentials: true,
	}

	return axios(settings)
}

// FWSD-5109
export function getGeotabCompanyByCompanyId(data) {
	let url = `${API_URL}/company/geotab/${data.company_id}`
	if (data.get_p) {
		url += '?p=true'
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		withCredentials: true,
	}

	return axios(settings)
}

// WEBFLEET APIs
export function loginUserExternalService(data) {
	const url = `${API_URL}/external_service/login`

	const form = new FormData()
	form.append('access_hash', data.access_hash)

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings)
}

export function getWebfleetToken(data) {
	const url = `https://csv.webfleet.com/extern?account=${data.account}&username=${data.username}&password=${data.password}&lang=en&action=createSession&outputformat=json&apikey=${constants.webfleet_apikey}`

	const settings = {
		// async: true,
		// crossDomain: true,
		url,
		method: 'GET',
		// withCredentials: true,
	}

	return axios(settings)
}

export function getWebfleetVehicles(data) {
	let url = ''
	if (data.sessiontoken) {
		url = `https://csv.webfleet.com/extern?sessiontoken=${data.sessiontoken}&outputformat=json&action=showVehicleReportExtern&apikey=${constants.webfleet_apikey}`
	}
	else {
		url = `https://csv.webfleet.com/extern?account=${data.account}&username=${data.username}&password=${data.password}&lang=en&outputformat=json&action=showVehicleReportExtern&apikey=${constants.webfleet_apikey}`
	}

	const settings = {
		// async: true,
		// crossDomain: true,
		url,
		method: 'GET',
		// withCredentials: true,
	}

	return axios(settings)
}

// FWSD-5295
export function setWebfleetCard(data) {
	const cardConfig = constants.WEBFLEET_CARD_CONFIG
	const env = location.hostname
	let domain = 'fleet.roscolive.com';
	switch (env) {
		case 'localhost':
		case 'test.roscolive.com':
			domain = 'test.roscolive.com'
			break
		case 'staging.roscolive.com':
			domain = 'staging.roscolive.com'
			break
		default:
			break
	}

	cardConfig.externalLinks.value[0].href['en-US'] = `https://${domain}/webfleet/device/${data.access_hash}/${data.webfleet_sn}`
	// https://fleet.roscolive.com/webfleet/device/gAAAAABkLGhnJ4y-ecm9jSn1kDEH_3MbsdLm9lkpyumMrDChkcVWo44uBtRa8ZD22auvAu5NTua31kZpdhBR1RN-imxnKHZyk8h4XDlGDNURYttwQpXn4V3FVJlUrXiOBWU1SgAt_pyw/1-48217-4660622C3A
	cardConfig.data[0][0].value['en-US'] = data.vehicle_name || ''
	cardConfig.data[1][0].value['en-US'] = data.online ? 'Online' : 'Offline'

	const url = `https://csv.webfleet.com/extern?account=${data.account}&username=${data.username}&password=${data.password}&lang=en&action=setExternalObjectData&objectuid=${data.webfleet_sn}&apikey=${constants.webfleet_plugin_card_apikey}&data=${JSON.stringify(cardConfig)}`

	// https://csv.webfleet.com/extern?account=roscoy&username=Roman&password=Roman2012&lang=en&action=setExternalObjectData&objectuid=1-48217-0503015F1E&apikey=40d126f5-ef7a-4ba7-bafb-0c267e2cf78d&data={"version":"1.0.0","defaultLocale":"en-US","title":{"en-US":"Rosco Live"},"externalLinks":{"name":{"en-US":"External links"},"value":[{"href":{"en-US":"https://fleet.roscolive.com"},"title":{"en-US":"Go to Rosco Live"},"description":{"en-US":"By clicking you will be redirected to the Rosco Live App."}}]},"data":[[{"name":{"en-US":"Camera Name"},"value":{"en-US":"Truck 004"}}],[{"name":{"en-US":"Network Status"},"value":{"en-US":"Off"}}]]}

	const settings = {
		// async: true,
		// crossDomain: true,
		url,
		method: 'POST',
		// "headers": {},
		// processData: false,
		// contentType: false,
		// mimeType: 'multipart/form-data',
		// data: new FormData(),
		// withCredentials: true,
	}

	return axios(settings)
}
