// FWSD-4893, useed for company, vehicle and profile redirection from Partner Portal to RL UI
const defaultState = {
	type: '', // company, vehicle or profile
	id: '', // company id, device_id or profile id
	company_id: '', // company id, we need it to enable corresponding item in the Companies dropdown of right top corner
	portal_url: '', // partner portal url where user came from
	user_was_redirected: false, // flag that user was redirected to corresponding page which we change when he was redirected to needed page.
	// We need it to display Partner Portal button when user clicks to other RL UI pages.
}

const partnerPortalRedirect = (state = defaultState, action) => {
	switch (action.type) {
		case 'SET_PARTNER_PORTAL_REDIRECT': {
			return { ...state, ...action.payload }
		}
		default:
			return state
	}
}

export default partnerPortalRedirect
