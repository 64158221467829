import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import _ from 'lodash'
import VehicleSelectBox from '../../dump/menuComponents/VehicleSelectBox'
import { getDevicesDriverID, getDevicesList, toggleDevicesDriverID } from '../../../action/DeviceActions'
import 'react-phone-input-2/lib/style.css'
import Toggle from 'react-toggle'
import { updateCompany } from '../../../action/CompaniesActions'
import { toastr } from 'react-redux-toastr'
import CustomButton from '../../CustomButton'

const FleetDriverIdModal = (props) => {

    const { user, company, editCompany, editCompanyCollection, toggleLoaderModal, loaderModal, onHide } = props;

    const [driverIdEnabled, setDriverIdEnabled] = useState(props.driverIdEnabled);
    const [totalVehicles, setTotalVehicles] = useState([]);
    const [selectedVehicleIds, setSelectedVehicleIds] = useState([]);
    const [initDriverIDVehicles, setInitDriverIDVehicles] = useState([]);

    useEffect(() => {
        getDriverIDVehicles();
    }, []);

    const getDriverIDVehicles = () => {
        const { driverIdEnabled } = props;
        toggleLoaderModal(true);
        const reqArray = [getDevicesList(company.company_id)]
        driverIdEnabled && reqArray.push(getDevicesDriverID(company.company_id))
        Promise.all(reqArray)
            .then(res => {
                const selectedVehicleIds = driverIdEnabled ? res[1].data.response.devices.map(vehicle => vehicle.device_id) : [];
                setTotalVehicles(res[0].data.response.devices);
                setSelectedVehicleIds(selectedVehicleIds);
                setInitDriverIDVehicles(selectedVehicleIds);
                toggleLoaderModal(false);
            })
            .catch(err => {
                toastr.error("There was an issue getting some Driver ID parameters. Please try again later.")
                toggleLoaderModal(false);
            })
    };

    const setVehicles = (vehicles) => {
        setSelectedVehicleIds(vehicles);
    };

    const applySettings = () => {
        const { company_id } = company;
        toggleLoaderModal(true);
        const updatedParam = driverIdEnabled ? "cardreader" : "manual"
        const reqArray = [];
        if (driverIdEnabled !== props.driverIdEnabled) {
            const requestData = {
                data: {
                    driver_recognition: updatedParam
                },
                company: {
                    company_name: company.name,
                    owner_company: company.partner
                }
            }
            reqArray.push(updateCompany(requestData, user.roles[0]))
        }
        if (driverIdEnabled && !_.isEqual([...selectedVehicleIds].sort(), [...initDriverIDVehicles].sort())) {
            const enabledVehicles = [];
            const disabledVehicles = [];
            selectedVehicleIds.forEach(vehicle => {
                if (!initDriverIDVehicles.includes(vehicle)) {
                    enabledVehicles.push(vehicle)
                }
            })
            initDriverIDVehicles.forEach(vehicle => {
                if (!selectedVehicleIds.includes(vehicle)) {
                    disabledVehicles.push(vehicle)
                }
            })
            enabledVehicles.length && reqArray.push(toggleDevicesDriverID({ enabled: true, devices: enabledVehicles.join(',') }, company_id))
            disabledVehicles.length && reqArray.push(toggleDevicesDriverID({ enabled: false, devices: disabledVehicles.join(',') }, company_id))
        }
        Promise.all(reqArray)
            .then(res => {
                if (driverIdEnabled !== props.driverIdEnabled) {
                    editCompany({ driver_recognition: updatedParam })
                    editCompanyCollection({ id: company_id, driver_recognition: updatedParam })
                }
                toastr.success("Successfully updated Driver ID settings")
                toggleLoaderModal(false);
                onHide();
            })
            .catch(err => {
                toastr.error("There was an issue updating Driver ID settings. Please try again later")
                toggleLoaderModal(false);
                onHide();
            })
    };

    return (
        <Modal
            show={true}
            onHide={onHide}
            className="notifications-modal modal-lg-size"
        >
            <Modal.Header closeButton>
                <Modal.Title>{`${company.name} Driver ID Settings`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='notifications-modal-body'>
                    <section>
                        <h4 className='category-title'>Enable Driver ID</h4>
                        <Toggle
                            checked={driverIdEnabled}
                            onChange={() => setDriverIdEnabled(!driverIdEnabled)}
                        />
                        <span>Enabled</span>
                    </section>

                    <section>
                        <h4 className='category-title'>Vehicles With Driver ID </h4>
                        <div className='modal-column'>
                            {totalVehicles.length > 0 &&
                                <VehicleSelectBox
                                    vehicles={totalVehicles}
                                    selectedVehicleIds={selectedVehicleIds}
                                    readOnly={!driverIdEnabled}
                                    onToggle={setVehicles}
                                />
                            }
                        </div>
                    </section>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <CustomButton variant='primary' size="sm" type='submit' onClick={applySettings}>
                    Apply
                </CustomButton>
            </Modal.Footer>
            {loaderModal}
        </Modal>
    )
};

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal
    }),
    dispatch => ({
        editCompany: data => dispatch({ type: 'EDIT_COMPANY', payload: data }),
        editCompanyCollection: data => dispatch({ type: 'EDIT_COMPANY_COLLECTION', payload: data }),
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    })
)(FleetDriverIdModal);
