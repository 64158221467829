import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import Autocomplete from 'react-autocomplete'
import DateTime from 'react-datetime'
import moment from 'moment'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'

import momenttz from '../../helpers/momenttz'
import { tableAdjustableColOptions, renderCustomHeader } from '../../helpers/table'
import { apiCallGet } from '../../../action/RouterActions'

const ModalUnknownDriverTrips = (props) => {

	const { location, devices, companyData, refreshModalUnknownDriver, selectdriverShow, onHide, loaderModal, toggleLoaderModal } = props;

	const [unknownDrivers, setUnknownDrivers] = useState([]);
	const [initialData, setInitialData] = useState([]);
	const [autoValue, setAutoValue] = useState('');
	const [selectVehicle, setSelectVehicle] = useState(null);
	const [startDate, setStartDate] = useState(moment().format('YYYYMMDD'));
	const [endDate, setEndDate] = useState(moment().format('YYYYMMDD'));
	const [upd, setUpd] = useState(false);
	const prevProps = useRef(props).current;

	useEffect(() => {
		if (location && location.event && location.type === 'redirectFromTripReplay') {
			const obj = devices.find((elem) => elem.device_id === location.event.event_device_id)

			if (obj) {
				setAutoValue(obj.vehicle_name || obj.device_id);
			}
		}

		getUnknownDrivers();
	}, [])

	useEffect(() => {
		if (prevProps.location && prevProps.location.event && prevProps.location.type === 'redirectFromTripReplay' && (prevProps.devices !== devices)) {
			const obj = prevProps.devices.find(({ device_id }) => device_id === prevProps.location.event.event_device_id);
			if (obj) {
				setAutoValue(obj.vehicle_name || obj.device_id);
				setStartDate(moment(prevProps.location.event.event_ts).format('YYYYMMDD'));
				setEndDate(moment(prevProps.location.event.event_ts).format('YYYYMMDD'));
				getUnknownDrivers();
			}
		} else if (
			prevProps.companyData.company_id
			&& prevProps.companyData.partner
			&& (
				(prevProps.companyData.company_id !== companyData.company_id)
				|| (prevProps.companyData.partner !== companyData.partner)
			)
		) {
			getUnknownDrivers(prevProps.companyData.company_id);
		} else if ((prevProps.refreshModalUnknownDriver !== refreshModalUnknownDriver) || ((prevProps.selectdriverShow !== selectdriverShow) && selectdriverShow === false)) {
			getUnknownDrivers();
		}
	}, [devices, companyData.company_id, companyData.partner, refreshModalUnknownDriver, selectdriverShow])

	useEffect(() => {
		if (selectVehicle) {
			getUnknownDrivers();
		}
	}, [selectVehicle])

	const getUnknownDrivers = (argCompanyId = null, start_ts = false, end_ts = false) => {
		start_ts = start_ts || startDate;
		end_ts = end_ts || endDate;

		if (!start_ts
			|| !end_ts
			|| (moment(end_ts).diff(start_ts) < 0)
		) {
			toastr.error('End date should be bigger or equal than Start date');
			return
		}

		toggleLoaderModal(true)
		const params = {
			companyId: argCompanyId || companyData.company_id,
			start_ts: moment(start_ts).format('YYYYMMDD000000'),
			end_ts: moment(end_ts).format('YYYYMMDD235959'),
		}
		apiCallGet('/company/{companyId}/unknown_drivers/trip', params)
			.then(res => {
				const drivers = res.data.response
				let filter = drivers
				if (autoValue) {
					const value = autoValue.toLowerCase();
					filter = drivers.filter(item => (
						(item.device_id && item.device_id.toString().toLowerCase().includes(value))
						|| (item.vehicle_name && item.vehicle_name.toLowerCase().includes(value))
					))

				}
				setUnknownDrivers(filter);
				setInitialData([...drivers]);
				setUpd(!upd);
				toggleLoaderModal(false)
			})
			.catch((error) => {
				toggleLoaderModal(false)
				console.log('!getEventDriver error: ', error.response, error)

				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription);
			})
	}

	const onDateChanged = (current, date) => {
		let start_ts = false;
		let end_ts = false;

		if (date === 'startDate') {
			start_ts = current.format('YYYYMMDD');
			setStartDate(start_ts);
		}
		if (date === 'endDate') {
			end_ts = current.format('YYYYMMDD');
			setEndDate(end_ts);
		}

		getUnknownDrivers(null, start_ts, end_ts);
	};

	const displayItemName = (item) => {
		return item.vehicle_name || item.device_id
	}

	const getItemValue = (item) => {
		return item.vehicle_name || item.device_id;
	}

	const matchStateToTerm = (item, value) => {
		if (item.vehicle_name) {
			return item.vehicle_name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
		}

		return item.device_id.toString().toLowerCase().indexOf(value.toLowerCase()) !== -1;
	}

	const vehicleNameFormatter = (cell, row) => row.vehicle_name || row.device_id;

	const vehicleNameSortFunc = (a, b, order) => {
		const first = (a.vehicle_name || a.device_id.toString())
		const second = (b.vehicle_name || b.device_id.toString())
		return order === 'asc' ? second.localeCompare(first) : first.localeCompare(second)
	}

	const timestampFormatter = (cell) => {
		if (cell) {
			return momenttz(`${cell}+00`).format('M/DD/YYYY hh:mm:ss A');
		}
	}

	const searchVehicle = () => {
		// debugger
		const value = autoValue.toLowerCase();

		let filter = initialData;

		if (value) {
			filter = initialData.filter(item => (
				(item.device_id && item.device_id.toString().toLowerCase().includes(value))
				|| (item.vehicle_name && item.vehicle_name.toLowerCase().includes(value))
			))
		}

		setUnknownDrivers(filter);
	}

	const handleViewTripClick = (e, row) => {
		e.preventDefault()

		const { redirectToTripReplay } = props;
		redirectToTripReplay(row)
	}

	const handleIdDriverClick = (e, row) => {
		e.preventDefault()

		const { onOpenSelectDriver } = props;
		onOpenSelectDriver(row.id)
	}

	const actionsFormatter = (cell, row) => {
		return (
			<span>
				<a href="/" onClick={e => handleViewTripClick(e, row)} style={{ marginRight: '15px' }}>
					View Trip
				</a>
				<a href="/" onClick={e => handleIdDriverClick(e, row)}>
					ID Driver
				</a>
			</span>
		)
	}

	return (
		<Modal
			show
			onHide={onHide}
			className="modal-batch-function modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>Unknown Driver Trips</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					<Autocomplete
						autoHighlight
						value={autoValue}
						inputProps={{ id: 'states-autocomplete' }}
						wrapperProps={{ className: 'search-autocomplete' }}
						wrapperStyle={{ position: 'relative', zIndex: 1 }}
						getItemValue={getItemValue}
						shouldItemRender={matchStateToTerm}
						menuStyle={{
							borderRadius: '3px',
							boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
							background: 'rgba(255, 255, 255, 0.9)',
							padding: '2px 0',
							fontSize: '90%',
							position: 'absolute',
							overflow: 'auto',
							maxHeight: '200px',
							left: '2.5%',
							top: 'auto'
						}}
						onChange={(e, value) => {
							setAutoValue(value);
							searchVehicle();
						}}
						onSelect={(value, item) => {
							setAutoValue(value);
							setSelectVehicle(value);
							searchVehicle();
						}}
						renderMenu={function (items, value, style) {
							return <div className="items-list" style={{ ...style, ...this.menuStyle }} children={items} />
						}
						}
						renderItem={(item, isHighlighted) => (
							<div
								className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
								key={item.device_id}
							>
								{displayItemName(item)}
							</div>
						)}
						renderInput={(props) => (
							<input {...props} placeholder="Search Vehicle..." className="vehicle-search" />
						)}
						items={devices}
					/>
				</div>

				<div className="date-container-wrapper">
					<div className='date-container'>
						<label className="date-label">From:</label>
						<div className="datetime">
							<DateTime
								timeFormat={false}
								inputProps={{ readOnly: true }}
								value={moment(startDate).format('MM/DD/YYYY')}
								closeOnSelect
								onChange={momentVal => onDateChanged(momentVal, 'startDate')}
							/>
						</div>
					</div>

					<div className='date-container'>
						<label className="date-label">To:</label>
						<div className="datetime">
							<DateTime
								timeFormat={false}
								inputProps={{ readOnly: true }}
								value={moment(endDate).format('MM/DD/YYYY')}
								closeOnSelect
								onChange={momentVal => onDateChanged(momentVal, 'endDate')}
							/>
						</div>
					</div>
				</div>

				<div className="manage-users-table">
					<BootstrapTable
						data={unknownDrivers}
						{...tableAdjustableColOptions}
					>
						<TableHeaderColumn
							dataField="id"
							isKey
							hidden
						/>

						<TableHeaderColumn
							width="35%"
							dataField="device_id"
							dataFormat={vehicleNameFormatter}
							dataSort
							sortFunc={vehicleNameSortFunc}
						>
							{renderCustomHeader(0, 'Vehicle Name')}
						</TableHeaderColumn>

						<TableHeaderColumn
							width="35%"
							dataField="timestamp"
							dataFormat={timestampFormatter}
							dataSort
						>
							{renderCustomHeader(1, 'Trip Timestamp')}
						</TableHeaderColumn>

						<TableHeaderColumn
							width="30%"
							dataFormat={actionsFormatter}
							tdStyle={{ whiteSpace: 'normal' }}
						>
							<div className="custom-table-header-row">
								<div className="custom-table-header-title">Actions</div>
							</div>
						</TableHeaderColumn>
					</BootstrapTable>
				</div>
			</Modal.Body>
			{loaderModal}
		</Modal>
	)
}

ModalUnknownDriverTrips.propTypes = {
	companyData: PropTypes.objectOf(PropTypes.any).isRequired,
	devices: PropTypes.arrayOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired,
	redirectToTripReplay: PropTypes.func.isRequired,
	onOpenSelectDriver: PropTypes.func.isRequired,
	refreshModalUnknownDriver: PropTypes.bool.isRequired
};

export default connect(
	state => ({
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(ModalUnknownDriverTrips);
