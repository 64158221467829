export const readImage = (inputId, imgId) => {
	const input = document.getElementById(inputId);
	const image = document.getElementById(imgId);
	const reader = new FileReader();

	if (input.files && input.files[0]) {
		reader.onload = e => image.src = e.target.result
		reader.readAsDataURL(input.files[0]);
	}
}

export const passwordValidation = (password) => {
	const checkUppercase = (str) => {
		for (let i = 0; i < str.length; i++) {
			if (str.charAt(i) === str.charAt(i).toUpperCase() && str.charAt(i).match(/[a-z]/i)) {
				return true;
			}
		}
		return false;
	}

	const checkLowercase = (str) => {
		for (let i = 0; i < str.length; i++) {
			if (str.charAt(i) === str.charAt(i).toLowerCase() && str.charAt(i).match(/[a-z]/i)) {
				return true;
			}
		}
		return false;
	}

	const checkNumber = (str) => {
		return /[0-9]/.test(str)
	}

	if (password.length < 8) {
		return {
			result: false,
			error: 'Password must be at least 8 characters',
		}
	}

	if (!checkUppercase(password)) {
		return {
			result: false,
			error: 'Password must contain uppercase letter',
		}
	}

	if (!checkLowercase(password)) {
		return {
			result: false,
			error: 'Password must contain lowercase letter',
		}
	}

	if (!checkNumber(password)) {
		return {
			result: false,
			error: 'Password must contain a number',
		}
	}

	return {
		result: true
	}
}

// Random password generator: 8 characters, 1 upper, 1 digit
export const getRandomPassword = () => {
	let newPassword = Math.random().toString(36).slice(-10)
	let hasUpper = false
	let hasNum = false
	const arr = newPassword.split('');
	for (let i = 0; i < arr.length; i++) {
		if (!hasUpper && isNaN(arr[i])) {
			arr.splice(i, 1, arr[i].toUpperCase());
			hasUpper = true
		}
		if (!isNaN(arr[i])) {
			hasNum = true
		}
	}
	if (!hasUpper) {
		const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
		const upper = charset.charAt(Math.ceil(charset.length * Math.random()*Math.random()))
		arr.splice(0, 1, upper)
	}
	if (!hasNum) {
		const numset = '123456789'
		const num = numset.charAt(Math.ceil(numset.length * Math.random() * Math.random()))
		arr.splice(newPassword.length - 1, 1, num)
	}
	newPassword = arr.join('')

	return newPassword
}
