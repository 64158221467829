import React, { useState, useEffect } from 'react'
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash'
import CategoryItem from '../../dump/menuComponents/CategoryItem';

const ManageAssetsGroupsContainer = (props) => {
    const { user, company } = props;
    const userRole = user.roles[0];
    const editUser = (userRole !== 'sales_demo' && (!['custom_partner'].includes(userRole) || _.find(user.permissions, { 'perm_category_id': 6, 'a_edit': true })))

    const [groups, setGroups] = useState([]);

    useEffect(() => {
        setGroups(props.groups)
    }, [props.groups]);

    const editGroup = (group) => {
        browserHistory.push({ pathname: "/managegroups/edit", state: { group, company } });
    }

    return (
        <div className="manage-groups">
            {groups.map(group =>
                <CategoryItem
                    key={group.id}
                    onClick={editUser ? () => editGroup(group) : null}
                    text={group.group_name || '[Unnamed]'}
                    rightLabel={`Vehicles assigned to group: ${group.active_devices}`}
                />
            )}
        </div>
    );
}

export default connect(
    state => ({
        user: state.user.user
    })
)(ManageAssetsGroupsContainer);
