import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import classnames from 'classnames'
import { toastr } from 'react-redux-toastr'

import CustomButton from '../../CustomButton';
import { getCompanyProfiles } from '../../../action/CompaniesActions'
import { apiCall } from '../../../action/RouterActions'
import { sendGA } from '../../helpers/helperUsers'

const ModalChangeConfBatch = (props) => {
	console.log('PROPS ModalChangeConfBatch: ', props)
	const { userRole, companyData, selectedDevices, loaderModal, toggleLoaderModal } = props;

	const [profiles, setProfiles] = useState([]);
	const [selectedProfile, setSelectedProfile] = useState('')
	const [errSelectedProfile, setErrSelectedProfile] = useState('')

	useEffect(() => {
		toggleLoaderModal(true);
		getCompanyProfiles({
			user_role: userRole,
			partner_name: companyData.partner_company,
			company_name: companyData.company_name
		})
			.then((response, err) => {
				const { profiles } = response.data.response
				setProfiles(profiles)
				setSelectedProfile(profiles[0].id.toString())

				toggleLoaderModal(false);
			})
			.catch((error) => {
				toggleLoaderModal(false);
				toastr.error('An unexpected error occurred. Please try again later');
			});
	}, [])

	const fillForm = (e) => {
		setSelectedProfile(e.target.value)
	}

	const checkForm = () => {
		let error = false;
		let errSelectedProfileUpdate = errSelectedProfile;
		if (selectedProfile === '') {
			error = true;
			errSelectedProfileUpdate = 'Select your profile!'
		} else if (errSelectedProfileUpdate) {
			errSelectedProfileUpdate = null
		}

		setErrSelectedProfile(errSelectedProfileUpdate)
		return error
	}

	const updateProfile = () => {
		const { clearSelection, getCompanyDevices } = props;
		const error = checkForm()
		if (!error) {
			toggleLoaderModal(true)
			const params = {
				profile_id: selectedProfile,
				device_ids: selectedDevices.join(','),
				restart: true,
			}

			apiCall('POST', '/profile/{profile_id}/devices/config', params)
				.then((res, err) => {
					toggleLoaderModal(false)
					toastr.success('Success', 'Fleet profile has been sent to selected devices.');

					sendGA({
						category: userRole,
						action: 'Manage Vehicles Modal',
						label: 'Submit Update Profile'
					})

					clearSelection();
					getCompanyDevices && getCompanyDevices();
				})
				.catch((error) => {
					toggleLoaderModal(false)
					// toastr.success('Success', 'Fleet profile has been sent to selected devices.');
					toastr.error(error.response.data.response.error);	//FWSD-4600

					clearSelection();
					getCompanyDevices && getCompanyDevices();
				})
		}
	}

	return (
		<Modal
			show
			onHide={props.onHide}
			className="manage-vehicles-modal modal-lg-size"
		>
			<Modal.Header closeButton>
				<Modal.Title>Update Profile</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="text-center">
					<div className={classnames('form-group has-feedback', { 'has-error': errSelectedProfile })}>
						<p className='modal-text'>Select a Fleet Profile to apply</p>
						<div>
							<select
								placeholder="Profile..."
								className="rosco-select block"
								name="selectedProfile"
								onChange={fillForm}
								value={selectedProfile}
							>
								<option value="" />
								{
									profiles.map(({ id, name }) => {
										return (<option key={id} value={id}>{name}</option>)
									})
								}
							</select>
							{errSelectedProfile && <span className="help-block">{errSelectedProfile}</span>}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton variant="primary" onClick={updateProfile}>
					{`Update${selectedDevices.length > 1 ? (' ' + selectedDevices.length + ' Devices') : ''}`}
				</CustomButton>
			</Modal.Footer>
			{loaderModal}
		</Modal>
	)
}

export default connect(
	state => ({
		loaderModal: state.loader.loaderModal
	}),
	dispatch => ({
		toggleLoaderModal: (show) => {
			dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
		}
	})
)(ModalChangeConfBatch);
