import React from 'react';
import { Link } from 'react-router';

const NotFoundComponent = () => {
  return(
    <div className='panel-body'>
      <article>
        <h3>Error 404!</h3>
        <h5>Page not found.</h5>
        <div>Go back <Link to="/">Home</Link></div>
      </article>
    </div>
  );
}

export default NotFoundComponent;