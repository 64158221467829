import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { toastr } from 'react-redux-toastr'

import { tableExportOptions } from '../../component/helpers/table'
import ModalEditDriver from './ModalEditDriver'
import ModalDeleteDriver from './ModalDeleteDriver'

import { apiCall } from '../../action/RouterActions'
import { sendDriverQRCode } from '../../action/DriverActions'

import '../../component/dump/menuComponents/menuComponents.sass'
import { formatName } from '../../component/helpers/tablesFuncHelpers'
import CustomButton from '../../component/CustomButton'
import IconTooltip from '../../component/dump/menuComponents/IconTooltip'

const DriversTable = (props) => {

	const { toggleLoader, drivers, initialData, setPropStates, groups, user } = props;
	const userRole = user.roles[0];

	const [selectedDriver, setSelectedDriver] = useState(null);
	const [showEdit, setShowEdit] = useState(false);
	const [showDelete, setShowDelete] = useState(false);

	const handleDriverEdit = (driverParams, sendQRCode = null) => {
		toggleLoader(true);
		apiCall('POST', `/driver/${selectedDriver.id}`, driverParams)
			.then((res, err) => {
				const parInitialData = initialData.map((item) => {
					if (item.id === selectedDriver.id) {
						return { ...item, ...driverParams }
					}
					return item;
				})
				const parDrivers = drivers.map((item) => {
					if (item.id === selectedDriver.id) {
						return { ...item, ...driverParams }
					}
					return item;
				})
				if (sendQRCode) {
					sendDriverQRCode(selectedDriver.id)
						.then(res => {
							toastr.success("Successfully sent QR Code")
						})
						.catch(err => {
							toastr.error("Unable to send QR Code. Please try again later.")
						})
				}

				setPropStates({
					initialData: parInitialData,
					drivers: parDrivers,
				})
				closeEdit();

				toggleLoader(false);
				toastr.success('Changes made successfully!')
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!handleDriverEdit error: ', error.response, error)

				toastr.error('Unable to edit driver. Please try again later');
			})
	};

	const handleDriverDelete = () => {
		toggleLoader(true);
		apiCall('DELETE', '/driver/{driverId}', { driverId: selectedDriver.id })
			.then((res, err) => {
				setPropStates({
					initialData: [...(initialData.filter(({ id }) => id !== selectedDriver.id))],
					drivers: [...(drivers.filter(({ id }) => id !== selectedDriver.id))],
				})
				closeDelete()
				closeEdit()

				toggleLoader(false);
				toastr.success('Successfully deleted driver')
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('handleDriverDelete error: ', error.response, error)

				toastr.error('Unable to delete driver. Please try again later');
			})
	};

	const displayEdit = (row) => {
		setShowEdit(true);
		setSelectedDriver(row);
	};

	const closeEdit = () => {
		setShowEdit(false);
	};

	const displayDelete = (row) => {
		setShowDelete(true);
		setSelectedDriver(row);
	};

	const closeDelete = () => {
		setShowDelete(false);
	};

	const goToTripReplay = (driver) => {
		browserHistory.push({ pathname: '/', state: { id: driver.last_vehicle_id } });
	};

	const formatDriverName = (cell, row) => {
		return <CustomButton variant="link" onClick={() => displayEdit(row)}>{formatName(cell, row)}</CustomButton>
	};

	const formatScore = (cell) => {
		return <span className={cell > 100 ? 'error-label' : ''}>{cell ? cell.toFixed(0) : '-'}</span>
	};

	const formatStatus = (cell, row) => {
		switch (cell) {
			case "current_trip":
				return "Online"
			// return <CustomButton variant='btn-link' onClick={() => goToTripReplay(row)}>Current Trip</CustomButton>
			default:
				return cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : '-';
		}
	};

	const formatVehicleName = (cell, row) => {
		return cell ? 
			<CustomButton variant="link" onClick={() => editVehicle(row)}>{cell}</CustomButton> :
			'-'
	};

	const editVehicle = (row) => {
		browserHistory.push({
			pathname: '/managevehicles/edit',
			state: { id: row.last_vehicle_id, company: props.company, type: '/managedrivers' }
		});
	};

	return (
		<div className="drivers-page-table">
			<BootstrapTable
				data={drivers}
				{...tableExportOptions}
				csvFileName='drivers.csv'
			>
				<TableHeaderColumn
					dataField="id"
					isKey
				>
					ID
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="first_name"
					dataFormat={['partner_view', 'sales_demo'].includes(userRole) ? formatName : formatDriverName}
					csvFormat={formatName}
					csvHeader='Driver Name'
				>
					Driver Name
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="driver_score"
					dataFormat={formatScore}
					csvHeader='Score'
				>
					Current Score
					<IconTooltip
						description="This is the driver's score for the current month, ranging
							from 0 (best) to 200 (worst). To find out more about how this is calculated,
							please see the driver scoring page."
						id="score-tooltip"
					/>
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="status"
					dataFormat={formatStatus}
					csvHeader='Status'
				>
					Status
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="last_vehicle_name"
					dataFormat={formatVehicleName}
					csvHeader='Last Vehicle'
				>
					Vehicle
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="group_name"
					csvHeader='Group'
					csvFormat={(cell) => cell || '-'}
				>
					Group
				</TableHeaderColumn>
				<TableHeaderColumn
					width="20%"
					dataSort
					dataField="card_id"
					csvHeader='Card ID'
				>
					Card ID
				</TableHeaderColumn>
			</BootstrapTable>

			{showEdit
				&& (
					<ModalEditDriver
						driver={selectedDriver}
						groups={groups}
						handleDriverEdit={handleDriverEdit}
						deleteDriver={displayDelete}
						onHide={closeEdit}
					/>
				)}

			{showDelete
				&& (
					<ModalDeleteDriver
						driver={selectedDriver}
						handleDriverDelete={handleDriverDelete}
						onHide={closeDelete}
					/>
				)}
		</div>
	)
}

DriversTable.propTypes = {
	drivers: PropTypes.arrayOf(PropTypes.any).isRequired,
	initialData: PropTypes.arrayOf(PropTypes.any).isRequired,
	groups: PropTypes.arrayOf(PropTypes.any).isRequired,
	setPropStates: PropTypes.func.isRequired
};

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(DriversTable);
