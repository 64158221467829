import _ from 'lodash'

import { constants, headerLogosFolder, headerLogosExtendedFolder, loadersFolder } from '../component/helpers/constants'

const initialState = {
	user: {},
	version: '2.0.0',
	// winOpen: {},
	fetching: false,
	whitelabel: constants.defaultPartnerWhitelabelSettings, // list of the partner whitelabel settings - FWSD-4278
};

const user = (state = initialState, action) => {
	switch (action.type) {
	case 'GET_USER_SUCCESS':
		return { ...state, ...{ user: action.payload } };
		// return Object.assign({}, state, {user: action.payload, fetching: false});
		// return {
		// 	user: action.payload,
		// 	fetching: false,
		// };
	case 'SET_PROJECT_VERSION':
		return { ...state, ...{ version: action.payload } }
	case 'SET_WHITELABEL_SETTINGS':
		const settings = action.payload
		const root = document.querySelector(':root')

		root.style.setProperty('--theme-colors-primary', _.get(settings, 'themecolors.primary') || _.get(constants.defaultPartnerWhitelabelSettings, 'themecolors.primary'));
		root.style.setProperty('--theme-colors-secondary', _.get(settings, 'themecolors.secondary') || _.get(constants.defaultPartnerWhitelabelSettings, 'themecolors.secondary'));
		root.style.setProperty('--theme-colors-tertiary', _.get(settings, 'themecolors.tertiary') || _.get(constants.defaultPartnerWhitelabelSettings, 'themecolors.tertiary'));
		root.style.setProperty('--theme-colors-alternate', _.get(settings, 'themecolors.alternate') || _.get(constants.defaultPartnerWhitelabelSettings, 'themecolors.alternate'));

		root.style.setProperty('--header-logo-extended', _.get(settings, 'header_logo_ext') ? (`url(${headerLogosExtendedFolder}${_.get(settings, 'header_logo_ext')})`) : _.get(constants.defaultPartnerWhitelabelSettings, 'header_logo_ext'));
		root.style.setProperty('--header-logo', _.get(settings, 'header_logo') ? (`url(${headerLogosFolder}${_.get(settings, 'header_logo')})`) : _.get(constants.defaultPartnerWhitelabelSettings, 'header_logo'));
		root.style.setProperty('--loader-url', _.get(settings, 'loader') ? (`url(${loadersFolder}${_.get(settings, 'loader')})`) : _.get(constants.defaultPartnerWhitelabelSettings, 'loader'));

		if (_.get(settings, 'font')) {
			root.style.setProperty('--theme-font', _.get(settings, 'font'));
		}

		return { ...state, ...{ whitelabel: settings } };
	default:
		return state
	}
}

export default user
