import classNames from 'classnames';
import React from 'react'
import CustomButton from './CustomButton';

export const CustomButtonToggle = React.forwardRef(({ children, onClick, variant, size, wrapperClassName, prefix }, ref) => (
    <div ref={ref} className={classNames({ [wrapperClassName]: wrapperClassName })}>
        <CustomButton
            onClick={onClick}
            variant={variant}
            size={size || "sm"}
            prefix={prefix}
        >
            {children}
        </CustomButton>
    </div>
))

export const CustomMenu = React.forwardRef(({ className, style, children, 'aria-labelledby': labeledBy }, ref) => {
    return (
        <div
            ref={ref}
            style={style}
            className={classNames(className, 'action-menu')}
            aria-labelledby={labeledBy}
        >
            {children}
        </div>
    );
})