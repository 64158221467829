/* eslint max-classes-per-file: ["error", 2] */
import React from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
// import { Button } from 'react-bootstrap'
// import { isFirefox } from 'react-device-detect'

import CustomButton from '../../CustomButton'
// import { isInternalUser } from '../../helpers/constants'

const FleetConfigurationChannel = (props) => {
    const { settingsType, configuration, configurationdv6, fillFormEnableChannel, fillForm } = props;
    const isDV6 = settingsType === 'dv6'
    const isDV4 = settingsType !== 'dv6'
    const currentConfig = isDV6 ? configurationdv6 : configuration
    const channelsCount = isDV6 ? 3 : 4

    const isQualityActive = (channelItem, nameQuality) => {
        const { MainQuality, MainFPS, index } = channelItem

        if (isDV6) {
            switch (nameQuality) {
                case 'Most Storage':
                    return MainQuality === (index && 1) && MainFPS === 24 ? ' active' : ''
                case 'Best Video':
                    return MainQuality === 3 && MainFPS === 30 ? ' active' : ''
                default:
                    return MainQuality === 2 && MainFPS === 24 ? ' active' : ''
            }
        }
        else {
            switch (nameQuality) {
                case 'Most Storage':
                    return (MainQuality === 1 && MainFPS === 7) ? ' active' : ''
                case 'Best Video':
                    return (MainQuality === 3 && MainFPS === 30) ? ' active' : ''
                default:
                    return (MainQuality === 2 && MainFPS === 15) ? ' active' : ''
            }
        }
    }

    const setChannelQuality = (e) => {
        const index = parseInt(e.target.name);
        const value = e.target.value;
        const newChannel = { ...currentConfig.Channels[index] }

        if (isDV6) {
            switch (value) {
                case 'Most Storage':
                    newChannel.MainFPS = 24
                    newChannel.MainQuality = index === 0 ? 0 : 1
                    break;
                case 'Best Video':
                    newChannel.MainFPS = 30
                    newChannel.MainQuality = 3
                    break;
                default:
                    newChannel.MainFPS = 24
                    newChannel.MainQuality = 2
                    break;
            }
        }
        else {
            switch (value) {
                case 'Most Storage':
                    newChannel.MainFPS = 7
                    newChannel.MainQuality = 1
                    break;
                case 'Best Video':
                    newChannel.MainFPS = 30
                    newChannel.MainQuality = 3
                    break;
                default:
                    newChannel.MainFPS = 15
                    newChannel.MainQuality = 2
                    break;
            }
        }
        
        fillForm(e, isDV6, `Channels.${index}`, newChannel)
    }

    const getFileSize = (model) => {
        let fileSize = 0;
        switch (model) {
            case 'dv6':
                for (const [index, channel] of configurationdv6.Channels.entries()) {
                    if (channel.Enabled) {
                        switch (channel.MainQuality) {
                            case 0:
                            case 1:
                                fileSize += index === 0 ? 6.336 : (channel.Audio ? 10.48 : 10.08)
                                break;
                            case 2:
                                fileSize += index === 0 ? 14.976 : (channel.Audio ? 11.728 : 11.328)
                                break;
                            case 3:
                                fileSize += index === 0 ? 28.8 : (channel.Audio ? 19.1 : 18.7)
                                break;
                            default:
                                return 0;
                        }
                    }
                }
                break;
            default:
                for (const [index, channel] of configuration.Channels.entries()) {
                    if (channel.Enabled) {
                        switch (channel.MainQuality) {
                            case 1:
                                fileSize += index === 0 ? 2.33 : (channel.Audio ? 2.18 : 1.22)
                                break;
                            case 2:
                                fileSize += index === 0 ? 8.15 : (channel.Audio ? 5.11 : 4.15)
                                break;
                            case 3:
                                fileSize += index === 0 ? 22.21 : (channel.Audio ? 12.19 : 11.23)
                                break;
                            default:
                                return 0;
                        }
                    }
                }
                break;
        }
        return fileSize * 60;
    };

    console.log("PROPS FleetConfigurationChannel: ", props);
    // const edit_only = (user_role === 'sales_demo' || (user_role === 'custom_user' && !_.find(user_permissions, { 'perm_category_id': 4, 'a_edit': true })));
    const dv6fileSize = getFileSize('dv6');
    const dvxc4fileSize = getFileSize('dvxc4');

    return (
        <main className="channel-settings main-content-block">
            <h3 className="category-title">Video Settings</h3>
            <div className="category-subheader">
                These settings control the video resolution captured by the recording channels on a camera. <strong>Best Video</strong> settings provide better video quality, <strong>Most Storage</strong> provides lesser quality but better storage.
            </div>

            <div className="settings-card">
				<div className="card-header">
					Estimated Recording Hours
				</div>
				<div className="card-body"> 
                    <p>The following are approximate hours of recording you should expect from your Rosco branded SD card at the current settings.</p>

                    <div className="stats-container">
                        { isDV6
                        ? (
                            <>
                                <div className="stat-wrapper">
                                    <div className="file-size">128GB</div>
                                    <div className="recording-hours">
                                        {dv6fileSize ? Math.round(112400 / (dv6fileSize)) + 'h' : 'N/A'}
                                    </div>
                                </div>
                                <div className="stat-wrapper">
                                    <div className="file-size">256GB</div>
                                    <div className="recording-hours">
                                        {dv6fileSize ? Math.round(228800 / (dv6fileSize)) + 'h' : 'N/A'}
                                    </div>
                                    
                                </div>
                                <div className="stat-wrapper">
                                    <div className="file-size">512GB</div>
                                    <div className="recording-hours">
                                        {dv6fileSize ? Math.round(461600 / (dv6fileSize)) + 'h' : 'N/A'}
                                    </div>
                                </div>
                                <div className="stat-wrapper">
                                    <div className="file-size">1TB</div>
                                    <div className="recording-hours">
                                        {dv6fileSize ? Math.round(923200 / (dv6fileSize)) + 'h' : 'N/A'}
                                    </div>
                                    
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="stat-wrapper">
                                    <div className="file-size">64GB</div>
                                    <div className="recording-hours">
                                        {dvxc4fileSize ? Math.round(64000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                    </div>
                                </div>
                                <div className="stat-wrapper">
                                    <div className="file-size">128GB</div>
                                    <div className="recording-hours">
                                        {dvxc4fileSize ? Math.round(128000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                    </div>
                                </div>
                                <div className="stat-wrapper">
                                    <div className="file-size">256GB</div>
                                    <div className="recording-hours">
                                        {dvxc4fileSize ? Math.round(256000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                    </div>
                                    
                                </div>
                                <div className="stat-wrapper">
                                    <div className="file-size">512GB</div>
                                    <div className="recording-hours">
                                        {dvxc4fileSize ? Math.round(512000 / (0.9 * dvxc4fileSize)) + 'h' : 'N/A'}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {currentConfig.Channels.slice(0, channelsCount).map(item => {
                const { index } = item
                const channelNumber = index + 1
                return (
                    <div key={index} className="settings-card">
                        <div className="card-header">
                            Channel {channelNumber}
                            <Toggle
                                name={`Channels.${index}.Enabled`}
                                checked={currentConfig.Channels[index].Enabled}
                                icons={false}
                                onChange={(e) => fillFormEnableChannel(e, isDV6)}
                            />
                        </div>
                        {/* anumation of card-body content displaing with delay 0.5s */}
                        <div className="card-body"> 
                            <p>If enabled, video will be recorded for channel {channelNumber}.</p>
                            <div className={`${!currentConfig.Channels[index].Enabled ? 'hide' : ''}`}> 
                                <div className="toggle-wrapper button-group">
                                    <label className="settings-title">Recording Quality</label>
                                    <div className="button-group">
                                        <CustomButton
                                            variant='toggle-group'
                                            active={!!isQualityActive(item, 'Most Storage')}
                                            value='Most Storage'
                                            name={index}
                                            onClick={setChannelQuality}
                                        >
                                            Most Storage
                                        </CustomButton>
                                        <CustomButton
                                            variant='toggle-group'
                                            active={!!isQualityActive(item, 'Default')}
                                            value='Default'
                                            name={index}
                                            onClick={setChannelQuality}
                                        >
                                            Default
                                        </CustomButton>
                                        <CustomButton
                                            variant='toggle-group'
                                            active={!!isQualityActive(item, 'Best Video')}
                                            value='Best Video'
                                            name={index}
                                            onClick={setChannelQuality}
                                        >
                                            Best Video
                                        </CustomButton>
                                    </div>
                                </div>
                                {((isDV6 && [1,2].includes(index)) || (isDV4 && index !== 0)) &&
                                    <div className="toggle-wrapper button-group">
                                        <label className="settings-title">Record Audio</label>
                                        {isDV6 && index === 2 && (<label className="sublabel">REQUIRED: DV607 Rev. B</label>)}
                                        <Toggle
                                            name={`Channels.${index}.Audio`}
                                            checked={currentConfig.Channels[index].Audio === 1}
                                            icons={false}
                                            onChange={(e) => fillForm(e, isDV6)}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            })}
        </main>
    )
}

FleetConfigurationChannel.propTypes = {
    webfleetDeviceId: PropTypes.string,
}

FleetConfigurationChannel.defaultProps = {
    webfleetDeviceId: '',
}

export default FleetConfigurationChannel;
