import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import SearchBar from '../../../SearchBar'
import { defaultTableOptions } from '../../../helpers/table'
import { deleteReportSubscription, getReportSubscriptions, updateReportSubscription } from '../../../../action/ReportsActions'
import { toastr } from 'react-redux-toastr'
import '../../../dump/menuComponents/menuComponents.sass'
import { formatTimestamp } from '../../../helpers/tablesFuncHelpers'
import CustomButton from '../../../CustomButton'

const ReportSubscriptionsModal = (props) => {

    const { user, onHide, loaderModal, toggleLoaderModal } = props;

    const [reportSearch, setReportSearch] = useState('');
    const [subscriptions, setSubscriptions] = useState([]);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState({});

    useEffect(() => {
        getSubscriptions()
    }, [])

    const getSubscriptions = () => {
        toggleLoaderModal(true);
        getReportSubscriptions(user.email)
            .then(res => {
                toggleLoaderModal(false);
                setSubscriptions(res.data.response.subscriptions)
            })
            .catch(err => {
                toggleLoaderModal(false);
            })
    }

    const unsubscribe = () => {
        if (props.user.id === selectedReport.owner_id || selectedReport.subscribers.length === 1) {
            toggleLoaderModal(true);
            deleteReportSubscription(selectedReport.id)
                .then(res => {
                    toastr.success(`Successfully unsubscribed from ${selectedReport.report_name}`)
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
                .catch(err => {
                    toastr.error('Unable to unsubscribe. Please contact customer support.')
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
        }
        else {
            toggleLoaderModal(true);
            const data = {
                id: selectedReport.id,
                subscribers: selectedReport.subscribers.filter(user => user !== props.user.email),
            }
            updateReportSubscription(data)
                .then(res => {
                    toastr.success(`Successfully unsubscribed from ${selectedReport.report_name}`)
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
                .catch(err => {
                    toastr.error('Unable to unsubscribe. Please contact customer support.')
                    setSelectedReport({})
                    setShowConfirmModal(false)
                    getSubscriptions();
                })
        }
    }

    const filteredSubscriptions = reportSearch ? subscriptions.filter(sub => sub.report_name.toLowerCase().includes(reportSearch.toLowerCase())) : subscriptions;

    const formatActions = (cell, row) => {
        if (row) {
            return <span>
                <CustomButton variant="link" onClick={() => props.viewReport(row)}>View</CustomButton>
                <CustomButton variant="link" onClick={() => {
                    setShowConfirmModal(true)
                    setSelectedReport(row)
                }}>
                    Unsubscribe
                </CustomButton>
            </span>
        }
    }

    const formatReportType = (cell, row) => {
        if (cell === 'custom') {
            const customReportType = row.custom_report_type.charAt(0).toUpperCase() + row.custom_report_type.substring(1) + ' Report'
            return cell.charAt(0).toUpperCase() + cell.substring(1) + ' ' + customReportType
        }
        else {
            return cell.charAt(0).toUpperCase() + cell.substring(1)
        }
    }

    return (
        <Modal
            size='xl'
            show
            onHide={onHide}
            className="reports-modal email-report-modal table-modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Email Report Subscriptions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="subscriptions-body">
                    <div className='modal-subtitle'>Here are the reports you are subscribed to for email. To view a report's contents, click View.</div>
                    <div className='modal-search'>
                        <SearchBar term={reportSearch} onSearch={(e) => setReportSearch(e.target.value)} />
                    </div>
                    <BootstrapTable
                        data={filteredSubscriptions}
                        {...defaultTableOptions}
                    >
                        <TableHeaderColumn
                            width="20%"
                            dataField="report_name"
                            isKey
                        >
                            Report Name
                        </TableHeaderColumn>

                        <TableHeaderColumn width="12%" dataField="report_type" dataFormat={formatReportType}>
                            Report Type
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            width="12%"
                            dataField="frequency"
                            dataFormat={(cell) => cell === 'biweekly' ? 'Bi-Weekly' : (cell.charAt(0).toUpperCase() + cell.substring(1))}
                        >
                            Time Period
                        </TableHeaderColumn>

                        <TableHeaderColumn
                            width="10%"
                            dataField="day_run"
                            dataFormat={(cell) => cell || '-'}
                        >
                            Weekday
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="10%"
                            dataField="last_run"
                            dataFormat={formatTimestamp}
                        >
                            Last Run
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            width="15%"
                            dataField=""
                            dataFormat={formatActions}
                        >
                            Actions
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </Modal.Body>
            {loaderModal}

            <Modal
                show={showConfirmModal}
                onHide={() => setShowConfirmModal(false)}
                className="reports-modal modal-lg-size"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Are You Sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='text-center'>
                        {`Are you sure you wish to unsubscribe from ${selectedReport.report_name}?`}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <CustomButton variant="delete" onClick={unsubscribe}>Unsubscribe</CustomButton>
                </Modal.Footer>
            </Modal>
        </Modal>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company,
        loaderModal: state.loader.loaderModal,
    }),
    dispatch => ({
        toggleLoaderModal: (show) => {
            dispatch({ type: 'TOGGLE_LOADER_MODAL', payload: show });
        }
    }),
)(ReportSubscriptionsModal);
