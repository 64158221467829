import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'
import assets from './assets'
import user from './user'
import company from './company' //added
import loader from './loader'
import partnerList from './partnerList'
import alertsearch from './alertsearch'
import tripreplay from './tripreplay'
import driver from './driver'
import notification from './notification'
import reports from './reports'
import dashboardData from './dashboardData'
import webfleetData from './webfleetData'
import partnerPortalRedirect from './partnerPortalRedirect'
import eventsData from './eventsData'

// const reducers = {
//  // ... other reducers ...
//  toastr: toastrReducer // <- Mounted at toastr.
// }
// const reducer = combineReducers(reducers)
// const store = createStore(reducer)

export default combineReducers({
	assets: assets,
	user,
	company,
	partnerList,
	alertsearch,
	tripreplay,
	toastr: toastrReducer,
	driver,
	notification,
	reports,
	dashboardData,
	loader,
	webfleetData,
	partnerPortalRedirect,
	eventsData,
})
