import React from 'react'
import PropTypes from 'prop-types'

import './CustomCheckbox.sass'

const CustomCheckbox = ({ checked, color, ...props }) => (
	<div className="custom-checkbox-wrapper">
		<input type="checkbox" checked={checked} {...props} />
		<div className="styled-checkbox" style={{ background: checked ? color : '#fff' }}>
			<svg viewBox="0 0 24 24" style={{ visibility: checked ? 'visible' : 'hidden' }}>
				<polyline points="19 7 11 16 6 11" />
			</svg>
		</div>
	</div>
)

CustomCheckbox.propTypes = {
	checked: PropTypes.bool.isRequired,
	color: PropTypes.string.isRequired,
}

export default CustomCheckbox
