import { addAuditLog } from '../../action/ClientActions'

export const addAuditLogFunc = (action, actionData) => {
	const params = {
		action,
		action_data: actionData,
	}
	addAuditLog(params)
		.then((res, err) => {
			// console.log('!addAuditLog res:', res)
		})
		.catch((error) => {
			console.log('!addAuditLog error:', error)
		})
}
