const defaultState = {
	undrivers: [],
	drivers: [],
	showDriverIdentifier: false,
	selectedDriverTrip: {},
	showDriverRenamer: false,
	selectedDriver: {},
}

const driver = (state = defaultState, action) => {
	switch (action.type) {
	case 'SET_UNIDENTIFIED_DRIVERS': {
		return { ...state, ...{ undrivers: action.payload } }
	}
	case 'SET_IDENTIFIED_DRIVERS': {
		return { ...state, ...{ drivers: action.payload } }
	}
	case 'SHOW_DRIVER_IDENTIFIER': {
		return Object.assign({}, state, {
			showDriverIdentifier: action.payload
		})
	}
	case 'SET_DRIVER_TRIP': {
		return { ...state, ...{ selectedDriverTrip: action.payload } }
	}
	case 'SHOW_DRIVER_RENAMER': {
		return Object.assign({}, state, {
			showDriverRenamer: action.payload
		})
	}
	case 'SET_DRIVER': {
		return { ...state, ...{ selectedDriver: action.payload } }
	}
	default:
		return state
	}
}

export default driver
