import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const SearchBar = (props) => {
	const { term, onSearch, placeholder, children } = props;

	return (
		<div className='search-bar-wrapper'>
			<input
				value={term}
				onChange={onSearch}
				placeholder={placeholder}
				className='vehicle-search'
			/>
			<svg width="1.75em" height="1.75em" viewBox="0 0 16 16" className="bi bi-search search-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z" />
				<path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z" />
			</svg>
			{children}
		</div>
	)
};

SearchBar.propTypes = {
	company: PropTypes.objectOf(PropTypes.any).isRequired,
	term: PropTypes.string.isRequired,
	onSearch: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
}

SearchBar.defaultProps = {
	placeholder: 'Search...',
}

export default connect(
	state => ({
		company: state.company.company,
	}),
)(SearchBar);
