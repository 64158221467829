// FWSD-6093
import React from 'react';
import ResetPasswordForm from '../../../smart/forms/login/ResetPasswordForm';

const CreatePasswordPage = (props) => {
    return (
        <ResetPasswordForm data={props} type="create" />
    )
}

export default CreatePasswordPage