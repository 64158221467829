import store from './client/js/store/store'
import { Provider } from "react-redux";
import { Router, browserHistory } from 'react-router'
import routes from './client/js/router/router'
import ReduxToastr from 'react-redux-toastr'
import ReactGA from "react-ga";
import HeadParams from './client/js/component/common/HeadParams'

// import { config } from "./config.development";

//* Enables Google Analytics just for the PROD server
// const trackingId = config.GAtrackingId;
const trackingId = process.env.NODE_ENV === 'production' && process.env.REACT_APP_GAtrackingId
if (trackingId) {
    ReactGA.initialize(trackingId);

    // Initialize google analytics page view tracking
    browserHistory.listen((location) => {
        ReactGA.set({ page: location.pathname + location.search }); // Update the user's current page
        ReactGA.pageview(location.pathname + location.search); // Record a pageview for the given page
    });
}

const App = () => {
    return (
        <Provider store={store}>
            <div>
                <HeadParams />
                <Router history={browserHistory} routes={routes} />
                <ReduxToastr
                    timeOut={5000}
                    newestOnTop={false}
                    preventDuplicates
                    position="top-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                />
            </div>
        </Provider>
    )
}

export default App;
