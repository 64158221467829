import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'

import { faviconsFolder } from '../helpers/constants'

const HeadParams = ({ whitelabel }) => {
	const { title, favicon } = whitelabel
	return (
		<Helmet
			title={title || 'RoscoLive'}
			link={[
				{ rel: 'shortcut icon', href: (favicon) ? (faviconsFolder + favicon) : '/favicon.ico' },
			]}
		/>
	)
}

HeadParams.propTypes = {
	whitelabel: PropTypes.objectOf(PropTypes.any).isRequired,
}

const mapStateToProps = ({ user: { whitelabel } }) => ({
	whitelabel,
})

export default connect(mapStateToProps)(HeadParams)
