import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

//* {{API_DOMAIN}}/api/{{API_VERSION}}/router/{{ROUTER_IMEI}}/config

export const apiCallGet = (type, data = null) => {
	// console.log('apiCallGet action:', type, data)
	for (const key in data) {
		const re = RegExp(`{${key}}`, 'i');

		if (type.match(re)) {
			type = type.replace(re, data[key])
			delete data[key]
		}
	}

	let params = ''
	for (const key in data) {
		params += `${(!params ? '?' : '&')}${key}=${data[key]}`
	}

	const url = `${API_URL + type + params}`

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method: 'GET',
		withCredentials: true,
	}

	return axios(settings)
}

export const apiCall1 = (method, type, data = null) => {
	// console.log('apiCall action:', method, type, data)

	for (const key in data) {
		const re = RegExp(`{${key}}`, 'i');

		if (type.match(re)) {
			type = type.replace(re, data[key])
			delete data[key]
		}
	}

	const url = `${API_URL + type}`

	const form = new FormData()
	for (const key in data) {
		form.append(key, data[key])
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method,
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
	}

	return axios(settings)
}

export const apiCall = (method, type, data = null) => {
	// console.log('apiCall action:', method, type, data)
	for (const key in data) {
		const re = RegExp(`{${key}}`, 'i');

		if (type.match(re)) {
			type = type.replace(re, data[key])
			delete data[key]
		}
	}

	let params = ''
	if (method.toLowerCase() === 'get') {
		for (const key in data) {
			params += `${(!params ? '?' : '&')}${key}=${data[key]}`
		}
	}

	const url = `${API_URL + type + params}`

	// const CancelToken = axios.CancelToken.source();

	const form = new FormData();
	for (let key in data) {
		form.append(key, data[key])
	}

	const settings = {
		async: true,
		crossDomain: true,
		url,
		method,
		processData: false,
		contentType: false,
		mimeType: 'multipart/form-data',
		data: form,
		withCredentials: true,
		// "withCredentials": type === 'login' ? false : true,
		// cancelToken: CancelToken.token
	};

	// setTimeout(() => {  //Canceling request if it too long
	//     CancelToken.cancel('Too long waiting');
	//     return []
	// }, 300000); //Waiting delay

	// return axios(settings)
	//     .then(response => response.data.response)
	return axios(settings)
}
