import React from 'react'
import PropTypes from 'prop-types'
import { tooltipIcon } from '../../helpers/iconHelpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const IconTooltip = (props) => {
    const { description, placement, id } = props;

    return (
        <OverlayTrigger
            placement={placement}
            delayHide={100}
            overlay={<Tooltip id={id} className="tooltip custom-tooltips">
                {description}
            </Tooltip>}
        >
            <label className="info-tooltip">
                {tooltipIcon}
            </label>
        </OverlayTrigger>
    )
}

IconTooltip.propTypes = {
    description: PropTypes.string.isRequired,
    placement: PropTypes.string,
    id: PropTypes.string,
}

IconTooltip.defaultProps = {
	placement: "right",
    id: "info-tooltip",
}

export default IconTooltip;