import React, { useState, useEffect, useRef } from 'react'
import { browserHistory } from 'react-router'
import { Button } from 'react-bootstrap'
import classnames from 'classnames'
import Toggle from 'react-toggle'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import NumericInput from 'react-numeric-input'
import DateTime from 'react-datetime'
import moment from 'moment'
import _ from 'lodash'

import { createCompanyCustomer, getCompaniesList } from '../../../action/CompaniesActions'

import '../../dump/menuComponents/menuComponents.sass'
import { isInternalUser } from '../../helpers/constants'
import { errorDetection } from '../../helpers/helperErrors'
import CustomButton from '../../CustomButton'

const CompanyAddContainer = (props) => {

	const { user, company, toggleLoader } = props;
	const userRole = user.roles[0];

	const [trialAvailableUsers, setTrialAvailableUsers] = useState([]);
	const [data, setData] = useState({
		company_name: '',
		company_city: '',
		company_state: '',
		company_zipcode: '',
		company_address: '',
		company_country: '',
		billing_date: '1',
		company_type: 'CUSTOMER',
		live_active: false,
		cutoff_when_overage: true,
		storage_active: true,
		trace_active: true,
		data_limit: '0',
		trial_status: false,
		industry_type: ''
	})
	const [modal, setModal] = useState({
		show: false,
		content: '',
		error: false
	});
	const [errMessage, setErrMessage] = useState({
		company_name: '',
		company_city: '',
		company_state: '',
		company_zipcode: '',
		company_address: '',
		company_country: '',
		billing_date: '',
		company_type: '',
		data_limit: '',
		industry_type: ''
	})
	const [companies, setCompanies] = useState([])
	const [partners, setPartners] = useState(null)
	const [companySaved, setCompanySaved] = useState(false)
	const [openNewCompany, setOpenNewCompany] = useState(false)
	const [created_company, setCreated_company] = useState(false)
	const [allow_create_company, setAllow_create_company] = useState(true)

	useEffect(() => {
		switch (userRole) {
			case 'system_admin':
			case 'customer_service':
				setData({ ...data, partner_company: '' });
				break;
			case 'partner':
				setData({ ...data, partner_company: user.company_name });
				break;
			default:
				break;
		}

		getPartners();
	}, [])

	const onCancel = (e) => {
		e.preventDefault();
		const data = {
			company_name: '',
			company_city: '',
			company_state: '',
			company_zipcode: '',
			company_address: '',
			company_country: '',
			company_phone_number: '',
			billing_date: '1',
			company_type: 'CUSTOMER',
			partner_company: '',
			live_active: false,
			cutoff_when_overage: true,
			storage_active: true,
			trace_active: true,
			data_limit: '0',
			trial_status: false,
			industry_type: ''
		};

		setCompanySaved(false);
		setData(data);
		setOpenNewCompany(true);
	}

	const sendForm = (e) => {
		e.preventDefault();
	}

	const saveCompany = (e) => {
		const error = checkForm(data);
		const partner_name = data.partner_company || '';

		if (!error) {
			toggleLoader(true);
			const state = { data };
			createCompanyCustomer(state, userRole, partner_name)
				.then((res, err) => {
					const created_company = res.data.response.company;

					toggleLoader(false);
					setCompanySaved(true);
					setCreated_company(created_company);
					props.getCompanies();

					toastr.success('Success', 'Company was added');
					if (partner_name === company.partner) {
						props.addCompany({ company: data.company_name });
					}
				})
				.catch((error) => {
					toggleLoader(false);
					console.log('!createCompanyCustomer error: ', error.response);

					let errDescription = ''
					if (error.response.data.body) {
						errDescription = errorDetection(error.response.data.body) || error.response.data.body
					}
					toastr.error(`Unable to create company. ${errDescription || 'Please try again later'}`);
				})
		}
	}

	const checkForm = (data) => {
		let error = false;
		const updatedErrMessage = { ...errMessage };

		const toUpperCase = (i) => {
			i = i.replace("company_", "").replace("_", " ");
			i = i.replace(i[0], i[0].toUpperCase());
			return i;
		}

		for (let i in data) {
			if (typeof data[i] === "string" && (data[i].trim() === '' || data[i] !== data[i].trim())) {
				error = true;
				updatedErrMessage[i] = `${toUpperCase(i)} is required`;
			} else if (!/^[a-zA-Z0-9 ]+$/.test(data[i]) && i !== "industry_type" && i !== "company_address" && i !== "company_address2" && i !== "company_phone_number" && i !== "partner_company") {
				error = true;
				updatedErrMessage[i] = `${toUpperCase(i)} cannot contain special characters`;
			} else if (i === "company_state") {
				if (data[i].length < 2) {
					error = true;
					updatedErrMessage[i] = 'State is too short'
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = '';
			} else if (i === "company_zipcode") {
				if (data[i].length > 8) {
					error = true;
					updatedErrMessage[i] = 'ZIP is too long'
				} else if (data[i].length < 3) {
					error = true;
					updatedErrMessage[i] = 'ZIP is too short'
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = '';
			} else if (i === "company_country") {
				if (data[i].length < 2) {
					error = true;
					updatedErrMessage[i] = 'Country is too short'
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = '';
			} else if (i === "company_phone_number") {
				if (isNaN(data[i])) {
					error = true;
					updatedErrMessage[i] = 'Phone Number should only contain numbers';
				} else if (data[i].length < 9) {
					error = true;
					updatedErrMessage[i] = 'Phone number too short'
				} else if (data[i].length > 10) {
					error = true;
					updatedErrMessage[i] = 'Phone number too long'
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = '';
			} else if (updatedErrMessage[i]) updatedErrMessage[i] = '';
		}

		if (!updatedErrMessage['company_name'] && !/^[a-zA-Z0-9 \_\-\.]+$/.test(data.company_name)) {
			error = true;
			updatedErrMessage['company_name'] = 'Company name can only contain letters, numbers, period and hyphen characters';
		}

		if (!data["company_phone_number"] && updatedErrMessage["company_phone_number"]) updatedErrMessage["company_phone_number"] = '';

		setErrMessage(updatedErrMessage)
		return error;
	}

	const getPartners = () => {
		const company_type = 'PARTNER';
		toggleLoader(true);
		getCompaniesList(userRole, company_type)
			.then(res => {
				toggleLoader(false);
				const companiesUpdate = _.sortBy(res.data.response['companies list'], num => num.toLowerCase());
				setCompanies(companiesUpdate)
			})
			.catch((error) => {
				toggleLoader(false);
				console.log('!getPartners error: ', error)
				let errDescription = 'An unexpected error occurred. Please try again later'
				if (error.response.data.response.error) {
					errDescription = error.response.data.response.error
				}
				toastr.error(errDescription)
			});
	}

	const handleInputChange = (event, optional = null) => {
		const { target } = event;
		const { name } = target
		let value = target.type === 'checkbox' ? target.checked : target.value

		if (name === 'download_files') {
			if (value) {
				value = 2; // all files
			} else {
				value = 1; // event files
			}
		}

		const updatedData = { ...data, [name]: target.type === 'checkbox' ? value : value.trim() }

		if (name === 'company_type' && value === 'WCS') {
			setData({ ...updatedData, download_files: 1, file_retention_time: 12 });
		} else {
			if (updatedData.partner_company !== 'Rosco Vision WCS' && updatedData.company_type !== 'WCS') {
				delete updatedData.download_files;
				delete updatedData.file_retention_time;
			}

			if (optional && value === '') {
				delete updatedData[name]
			}

			setData(updatedData)
		}
	}

	const handleSelectChange = (event) => {
		const { target } = event
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		const updatedData = { ...data, [name]: value }

		if (value === 'Rosco Vision WCS') {
			updatedData.company_type = 'WCS';
			setData({ ...updatedData, download_files: 1, file_retention_time: 12 });
		} else {
			if (updatedData.company_type !== 'WCS') {
				delete updatedData.download_files;
				delete updatedData.file_retention_time;
			}

			setData(updatedData);
		}
	}

	const handleNumericChange = (valint, valstr, e) => {
		const name = $(e).attr('name');
		const value = valint;

		setData({ ...data, [name]: value });
	}

	const handleDateChanged = (current) => {
		if (!moment(current, 'MM/DD/YYYY', true).isValid()) {
			toastr.error('', 'Please enter valid date.')
			return
		}

		setData({ ...data, trial_date: current })
	}

	const editCompany = () => {
		browserHistory.push({ pathname: '/company/edit', state: { company: created_company } });
	}

	const toggleAddBlock = () => {
		setErrMessage({
			company_name: '',
			company_city: '',
			company_state: '',
			company_zipcode: '',
			company_address: '',
			company_country: '',
			billing_date: '',
			company_type: '',
			data_limit: '',
			industry_type: ''
		});

		setOpenNewCompany(!openNewCompany);
	}

	const companyList = () => {
		let option = [];
		if (companies) {
			companies.map((elem, index) => {
				option[index] = (<option key={index} value={elem}>{elem}</option>)
			});
			return (
				<select placeholder="select" type="text" className="rosco-select block" name="partner_company" id="partner_company" defaultValue=""
					onChange={handleSelectChange}
				>
					<option></option>
					{option}
				</select>
			);
		}
	};

	const validDate = (current) => {
		return current.isAfter(moment())
	}

	if (companySaved) {
		return (
			<div className="add-asset-block">
				<CustomButton
					variant="secondary-outline"
					onClick={onCancel}
				>
					Add Another Company
				</CustomButton>
				<div className="label-wrapper">
					<label>
						The new company, <b>{data.company_name}</b>, has been created.
					</label>
				</div>
				<div>
					<CustomButton
						variant="primary"
						onClick={editCompany}
					>
						Edit Company
					</CustomButton>
				</div>
			</div>
		)
	}

	const { trial_status, trial_date } = data;
	const {
		company_name,
		company_city,
		company_state,
		company_zipcode,
		company_address,
		company_country,
		company_phone_number,
		billing_date,
		company_type,
		partner_company,
		data_limit,
		file_retention_time,
		industry_type
	} = errMessage;

	const industryTypeDisplayOptions = [
		'FTL/LTL/Courier',
		'Medical/Health',
		'School/Education',
		'Construction',
		'Waste Management',
		'Mass Transit',
		'Agricultural',
		'Government',
		'Industrial',
		'Other'
	]
	const industryTypeValueOptions = [
		'ftl_ltl_courier',
		'medical_health',
		'school_education',
		'construction',
		'waste_management',
		'mass_transit',
		'agricultural',
		'government',
		'industrial',
		'other'
	]

	return (
		<div className="add-asset-block">
			<CustomButton
				variant="secondary-outline"
				onClick={toggleAddBlock}
				disabled={!allow_create_company}
			>
				{openNewCompany ? 'Cancel' : 'Add a New Company'}
			</CustomButton>
			{openNewCompany
				&& (
					<div className="edit-content-block">
						<form onSubmit={sendForm}>
							<div className='two-column-grid'>
								<div className={classnames({ 'has-error': company_name })}>
									<label className="control-label">Name:</label>
									<input type="text" name="company_name" className="vehicle-search" onChange={handleInputChange} maxLength={50} />
									{company_name && <span className="help-block">{company_name}</span>}
								</div>

								<div className={classnames({ 'has-error': company_city })}>
									<label className="control-label">City:</label>
									<input type="text" name="company_city" className="vehicle-search" onChange={handleInputChange} />
									{company_city && <span className="help-block">{company_city}</span>}
								</div>

								<div className={classnames({ 'has-error': company_state })}>
									<label className="control-label">State:</label>
									<input type="text" name="company_state" className="vehicle-search" onChange={handleInputChange} />
									{company_state && <span className="help-block">{company_state}</span>}
								</div>

								<div className={classnames({ 'has-error': company_zipcode })}>
									<label className="control-label">Zip Code:</label>
									<input type="text" name="company_zipcode" className="vehicle-search" onChange={handleInputChange} />
									{company_zipcode && <span className="help-block">{company_zipcode}</span>}
								</div>

								<div className={classnames({ 'has-error': company_address })}>
									<label className="control-label">Address:</label>
									<input type="text" name="company_address" className="vehicle-search" onChange={handleInputChange} />
									{company_address && <span className="help-block">{company_address}</span>}
								</div>

								<div className={'optional'}>
									<label className="control-label">Address 2: <span>(optional)</span></label>
									<input type="text" name="company_address2" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
								</div>

								<div className={classnames({ 'has-error': company_country })}>
									<label className="control-label">Country:</label>
									<input type="text" name="company_country" className="vehicle-search" onChange={handleInputChange} />
									{company_country && <span className="help-block">{company_country}</span>}
								</div>

								<div className={classnames("optional", { 'has-error': company_phone_number })}>
									<label className="control-label">Phone Number: <span>(optional)</span></label>
									<input type="text" name="company_phone_number" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
									{company_phone_number && <span className="help-block">{company_phone_number}</span>}
								</div>

								{/* {isInternalUser(userRole) && (
									<div className={classnames({ 'has-error': billing_date })}>
										<label className="control-label">Billing date:</label>
										<select placeholder="select" className="rosco-select block" name="billing_date" id="billing_date" onChange={handleInputChange}>
											<option value="" />
											{_.range(1, 29).map((item, i) => (<option key={i} value={i + 1}>{item}</option>))}
										</select>
										{billing_date && <span className="help-block">{billing_date}</span>}
									</div>
								)} */}

								{isInternalUser(userRole) && (
									<div className={classnames({ 'has-error': partner_company })}>
										<label htmlFor="partner_company" className="control-label">Partner Name</label>
										{companyList()}
										{partner_company && <span className="help-block">{partner_company}</span>}
									</div>
								)}
								{/* {isInternalUser(userRole) && (
									<div className={classnames({ 'has-error': data_limit })}>
										<label className="control-label">Vehicle data limit:</label>
										<select
											placeholder="select"
											className="rosco-select block"
											name="data_limit"
											id="data_limit"
											onChange={handleInputChange}
										>
											<option value="0">Unlimited</option>
											<option value="2">500 MB</option>
											<option value="3">1 GB</option>
										</select>
										{data_limit && <span className="help-block">{data_limit}</span>}
									</div>
								)} */}
								{/* {isInternalUser(userRole) && (
									<div className="optional">
										<label className="control-label">Custom APN: <span>(optional)</span></label>
										<input type="text" name="custom_apn" className="vehicle-search" onChange={handleInputChange} />
									</div>
								)} */}

								{(isInternalUser(userRole) &&
									<div className={classnames({ 'has-error': company_type })}>
										<label className="control-label">Company Type:</label>
										<select
											placeholder="select"
											className="rosco-select block"
											name="company_type"
											id="company_type"
											onChange={handleInputChange}
											value={data.company_type}
											disabled={data.partner_company === 'Rosco Vision WCS'}
										>
											<option value="CUSTOMER">Standard</option>
											<option value="WCS">WCS</option>
										</select>
										{company_type && <span className="help-block">{company_type}</span>}
									</div>
								)}

								<div className={classnames({ 'has-error': industry_type })}>
									<label className="control-label">Industry Type:</label>
									<select
										placeholder="select"
										className="rosco-select block"
										name="industry_type"
										id="industry_type"
										onChange={handleInputChange}>
										<option value="" />
										{industryTypeDisplayOptions.map((item, i) => (
											<option key={i} value={industryTypeValueOptions[i]}>{item}</option>
										))}
									</select>
									{industry_type && <span className="help-block">{industry_type}</span>}
								</div>
							</div>

							{isInternalUser(userRole) && (
								<div>
									<div className="two-column-grid">
										<div className="toggle-date-wrapper">
											<div className="toggle-label">
												<div>
													<label htmlFor="trial_company">Trial Company:</label>
												</div>
												<label>
													<span className="toggle-left-title">Disable</span>
													<Toggle
														name="trial_status"
														checked={trial_status || false}
														onChange={handleInputChange}
													/>
													<span>Enable</span>
												</label>
											</div>
											{trial_status && (
												<div>
													<label htmlFor="trial_date">Trial End Date:</label>
													<DateTime
														id="trial_date"
														name="trial_date"
														inputProps={{ readOnly: true }}
														value={moment(trial_date).format('MM/DD/YYYY')}
														dateFormat="MM/DD/YYYY"
														timeFormat={false}
														closeOnSelect
														isValidDate={validDate}
														onChange={handleDateChanged}
													/>
												</div>
											)}
										</div>
										{trial_status && (
											<div>
												<label htmlFor="trial_subscribers">Trial Subscribers:</label>
												<div>You have to add a new Sales Support user after this company saving and add one to the company trial subscribers value.</div>
											</div>
										)}
									</div>
									<div className="two-column-grid">
										<div className="toggles-container">
											<label className="toggle-wrapper">
												<Toggle
													name="live_active"
													onChange={handleInputChange}
													checked={data.live_active}
												/>
												<span>Live Stream Active</span>
											</label>
										</div>

										<div className="toggles-container">
											<label className="toggle-wrapper">
												<Toggle
													name="trace_active"
													onChange={handleInputChange}
													checked={data.trace_active}
												/>
												<span>Trace Active</span>
											</label>
										</div>

										{(data.partner_company === 'Rosco Vision WCS' || data.company_type === 'WCS')
											&& (
												<div className="toggles-container">
													<div className={classnames('toggle-wrapper', { 'has-error': file_retention_time })}>
														<label>File Retention Time (Months)</label>
														<div>
															<NumericInput
																step={1}
																min={1}
																max={24}
																precision={0}
																maxLength={2}
																size={2}
																className="rosco-select"
																name="file_retention_time"
																value={data.file_retention_time}
																onChange={handleNumericChange}
															/>
														</div>
														{file_retention_time && <span className="help-block">{file_retention_time}</span>}
													</div>
													{userRole === 'system_admin'
														&& (
															<label className="toggle-wrapper">
																<span className="toggle-left-title">Download Event Files</span>
																<div>
																	<Toggle
																		name="download_files"
																		icons={false}
																		checked={data.download_files === '2'}
																		onChange={handleInputChange}
																	/>
																	<span>All Files</span>
																</div>
															</label>
														)}
												</div>
											)}
									</div>
								</div>
							)}
						</form>

						<CustomButton
							variant='primary'
							onClick={saveCompany}
						>
							Add Company
						</CustomButton>
					</div>
				)}
		</div>
	)
}

export default connect(
	state => ({
		user: state.user.user,
		company: state.company.company
	}),
	dispatch => ({
		addCompany: (company) => {
			dispatch({ type: 'ADD_COMPANY_SUCCESS', payload: company });
		},
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(CompanyAddContainer);
