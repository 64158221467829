import React from "react";
import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import promiseMiddleware  from 'redux-promise-middleware'
import thunkMiddleware from 'redux-thunk'
import reducers from '../reducers/combineReducers'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

//const enhancer = composeEnhancers(
//  applyMiddleware(...middleware),
//  // other store enhancers if any
//);
//const store = createStore(reducer, enhancer);



const store = composeEnhancers(
  applyMiddleware(thunkMiddleware),
)(createStore)(reducers)

export default store;

// const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
// const middlewares = [thunk, promiseMiddleware]
// const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)))
// // const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)))
// export default store;


// import thunk from 'redux-thunk'
// import promiseMiddleware  from 'redux-promise-middleware'
// import reducers from '../reducers/combineReducers'

// const middlewares = [thunk, promiseMiddleware];
// const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

// const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)));

// export default store;