import React, { useState } from 'react';
import { browserHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { createPartner } from '../../../action/CompaniesActions';
import '../../dump/menuComponents/menuComponents.sass'
import CustomButton from '../../CustomButton';

const PartnerAddContainer = (props) => {

    const { user, toggleLoader } = props;
    const user_role = user.roles[0];

    const [data, setData] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_country: '',
        company_address2: '',
        company_phone_number: ''
    })
    const [modal, setModal] = useState({
        show: false,
        content: '',
        error: false
    })
    const [errMessage, setErrMessage] = useState({
        company_name: '',
        company_city: '',
        company_state: '',
        company_zipcode: '',
        company_address: '',
        company_country: '',
        company_address2: '',
        company_phone_number: '',
        billing_date: ''
    })
    const [companySaved, setCompanySaved] = useState(false)
    const [open, setOpen] = useState(false)
    const [createdPartner, setCreatedPartner] = useState('')

    const addAnotherPartner = () => {
        const data = {
            company_name: '',
            company_city: '',
            company_state: '',
            company_zipcode: '',
            company_address: '',
            company_country: '',
            billing_date: '',
            live_active: true,
            cutoff_when_overage: true,
            storage_active: true,
            trace_active: true
        };
        setCompanySaved(false)
        setData(data)
        setOpen(true)
    }

    const sendForm = (e) => {
        e.preventDefault();
    }

    const savePartner = (e) => {
        const error = checkForm(data);

        if (!error) {
            toggleLoader(true);
            createPartner({ data }, user_role)
                .then((res, err) => {
                    toggleLoader(false);
                    console.log('!createPartner res', res);
                    const created_partner = res.data.response.company;
                    setCompanySaved(true)
                    setCreatedPartner(created_partner)
                    props.getPartners();
                    props.addPartner(created_partner.company_name);
                    toastr.success("Success", "Successfully added new partner");
                })
                .catch((error) => {
                    toggleLoader(false);
                    console.log('!createPartner error', error.response);
                    toastr.error('Unable to create Partner. Please try again later.')
                });
        }
    }

    const checkForm = (data) => {
        let error = false;
        let updatedErrMessage = { ...errMessage };
        
        for (let i in data) {
            if (i === "company_name") {
                if (data[i]===''){
                    error=true;
                    updatedErrMessage[i] = 'Name is required';
                } else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
                    error=true;
                    updatedErrMessage[i] = 'Name cannot contain special characters';
                } else if (data[i].length < 3) {
                    error=true;
                    updatedErrMessage[i] = 'Name must be at least 3 characters';
                } else if (updatedErrMessage[i]) updatedErrMessage[i]=null;
            }
            if (i === "company_city") {
				if (data[i] === '') {
					error = true;
					updatedErrMessage[i] = 'City is required';
				} else if (data[i].length < 3) {
					error = true;
					updatedErrMessage[i] = 'City must be at least 3 characters';
				} else if (data[i].length > 50) {
					error = true;
					updatedErrMessage[i] = 'City must be no more than 50 characters';
				} else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
					error = true;
					updatedErrMessage[i] = 'City cannot contain special characters';
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
			}
            if (i === "company_state") {
				if (data[i] === '') {
					error = true;
					updatedErrMessage[i] = 'State is required';
				} else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
					error = true;
					updatedErrMessage[i] = 'State cannot contain special characters';
				} else if (data[i].length < 2) {
					error = true;
					updatedErrMessage[i] = 'State must be at least 2 characters';
				} else if (data[i].length > 50) {
					error = true;
					updatedErrMessage[i] = 'State must be no more than 50 characters';
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
			}
            if (i === "company_zipcode") {
				if (data[i] === '') {
					error = true;
					updatedErrMessage[i] = 'ZIP is required';
				} else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
					error = true;
					updatedErrMessage[i] = 'ZIP cannot contain special characters';
				} else if (data[i].length < 3){
                    error=true;
                    updatedErrMessage[i]='ZIP must be at least 3 characters'
                } else if (data[i].length > 20) {
					error = true;
					updatedErrMessage[i] = 'ZIP must be no more than 20 characters'
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
			}
            if (i === "company_address") {
				if (data[i] === '') {
					error = true;
					updatedErrMessage[i] = 'Address is required';
				} else if (data[i].length < 3) {
					error = true;
					updatedErrMessage[i] = 'Address must be at least 3 characters';
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
			}
            if (i === "company_address2" && data[i]!='') {
				if (data[i] === '') {
					error = true;
					updatedErrMessage[i] = 'Address is required';
				} else if (data[i].length < 3) {
					error = true;
					updatedErrMessage[i] = 'Address must be at least 3 characters';
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
			}
            if (i === "company_country") {
				if (data[i] === '') {
					error = true;
					updatedErrMessage[i] = 'Country is required';
				} else if (!/^[a-zA-Z0-9 ]+$/.test(data[i])) {
					error = true;
					updatedErrMessage[i] = 'Country cannot contain special characters';
				} else if (data[i].length < 3) {
					error = true;
					updatedErrMessage[i] = 'Country must be at least 3 characters';
				} else if (data[i].length > 50) {
					error = true;
					updatedErrMessage[i] = 'Country is too long';
				} else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
			}
            if (i === "company_phone_number" && data[i]!='') {
				if (isNaN(data[i])) {
					error = true;
					updatedErrMessage[i] = 'Phone Number should only contain numbers';
				} else if (data[i].length < 9) {
					error = true;
					updatedErrMessage[i] = 'Phone number must be 9 digits (e.g. 123456789)'
				} else if (data[i].length > 10) {
					error = true;
					updatedErrMessage[i] = 'Phone number too long'
				}
				else if (updatedErrMessage[i]) updatedErrMessage[i] = null;
			}
        }
        if (!updatedErrMessage['company_name'] && !/^[a-zA-Z0-9 \_\-\.]+$/.test(data.company_name)) {
			error = true;
			updatedErrMessage['company_name'] = 'Partner name can only contain letters, numbers, period and hyphen characters';
		}
        
        setErrMessage(updatedErrMessage);
        return error;
    }

    const handleInputChange = (event, optional = null) => {
        const target = event.target,
            value = target.type === 'checkbox' ? target.checked : target.value,
            name = target.name

        const updatedData = { ...data }
        updatedData[name] = value;

        if (optional && value === '') {
            delete updatedData[name]
        }

        setData(updatedData);
    }

    const editPartner = () => {
        browserHistory.push({ pathname: "/partner/edit", state: { company: createdPartner } });
    }

    const toggleAddBlock = () => {
        setErrMessage({
            company_name: '',
            company_city: '',
            company_state: '',
            company_zipcode: '',
            company_address: '',
            company_country: '',
            billing_date: ''
        });

        setOpen(!open)
    }

    const { company_name, company_city, company_state, company_zipcode, company_address, company_address2, company_country, company_phone_number } = errMessage;

    if (companySaved) {
        return (
            <div className='add-asset-block'>
                <CustomButton variant="secondary-outline" onClick={addAnotherPartner}>Add Another Partner</CustomButton>
                <div className="label-wrapper">
                    <label>The new partner, <b>{data.company_name}</b>, has been created.</label>
                </div>
                <div>
                    <CustomButton variant="primary" onClick={editPartner}>Edit Partner</CustomButton>
                </div>
            </div>
        )
    }

    return (
        <div className='add-asset-block'>
            <CustomButton variant="secondary-outline" onClick={toggleAddBlock}>{open ? 'Cancel' : 'Add a New Partner'}</CustomButton>
            {open &&
                <div className='edit-content-block'>
                    <form onSubmit={sendForm}>
                        <div className='two-column-grid'>
                            <div className={classnames({ 'has-error': company_name })}>
                                <label className="control-label">Name:</label>
                                <input type="text" name="company_name" className="vehicle-search" onChange={handleInputChange} maxLength={50} />
                                {company_name && <span className="help-block">{company_name}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_city })}>
                                <label className="control-label">City:</label>
                                <input type="text" name="company_city" className="vehicle-search" onChange={handleInputChange} />
                                {company_city && <span className="help-block">{company_city}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_state })}>
                                <label className="control-label">State:</label>
                                <input type="text" name="company_state" className="vehicle-search" onChange={handleInputChange} />
                                {company_state && <span className="help-block">{company_state}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_zipcode })}>
                                <label className="control-label">Zip Code:</label>
                                <input type="text" name="company_zipcode" className="vehicle-search" onChange={handleInputChange} />
                                {company_zipcode && <span className="help-block">{company_zipcode}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_address })}>
                                <label className="control-label">Address:</label>
                                <input type="text" name="company_address" className="vehicle-search" onChange={handleInputChange} />
                                {company_address && <span className="help-block">{company_address}</span>}
                            </div>

                            <div className={classnames('optional', {'has-error': company_address2})}>
                                <label className="control-label">Address 2: <span>(optional)</span></label>
                                <input type="text" name="company_address2" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
                                {company_address2 && <span className="help-block">{company_address2}</span>}
                            </div>

                            <div className={classnames({ 'has-error': company_country })}>
                                <label className="control-label">Country:</label>
                                <input type="text" name="company_country" className="vehicle-search" onChange={handleInputChange} />
                                {company_country && <span className="help-block">{company_country}</span>}
                            </div>

                            <div className={classnames('optional', {'has-error': company_phone_number})}>
                                <label className="control-label">Phone Number: <span>(optional)</span></label>
                                <input type="text" name="company_phone_number" className="vehicle-search" onChange={(e) => handleInputChange(e, true)} />
                                {company_phone_number && <span className="help-block">{company_phone_number}</span>}

                            </div>
                        </div>

                    </form>

                    <CustomButton variant="primary" onClick={savePartner}>Add Partner</CustomButton>
                </div>
            }
        </div>
    )
}

export default connect(
    state => ({
        user: state.user.user,
        company: state.company.company
    }),
    dispatch => ({
        addPartner: (partner) => {
            dispatch({ type: 'ADD_PARTNER_SUCCESS', payload: partner });
        },
        toggleLoader: (show) => {
            dispatch({ type: 'TOGGLE_LOADER', payload: show });
        }
    })
)(PartnerAddContainer);
