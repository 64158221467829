import React, { useState, useRef } from 'react';
import Toggle from 'react-toggle';
import Select from 'react-select';
import { Button, Modal } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';
import classnames from 'classnames';
import moment from 'moment';
import DateTime from 'react-datetime';
import _ from 'lodash';

import { transformationLabel } from '../../helpers/labels';
import { isInternalUser } from '../../helpers/constants';
import CustomButton from '../../CustomButton';

const EditReport = (props) => {
	const [errMessage, setErrMessage] = useState({
		events: '',
		alerts: '',
		type: '',
		name: '',
		date_range: '',
		devices: '',
		end_date: ''
	});
	const [chart, setChart] = useState({
		active: true,
		stacked: true,
		events: '',
		alerts: '',
		type: 'bar_top_10',
		name: '',
		devices_: [],
		devices_custom: {},
		start_date: moment().subtract(7, 'days').startOf('day').format('YYYYMMDD'),
		end_date: moment().format('YYYYMMDD')
	});
	const [title, setTitle] = useState('');
	const [data, setData] = useState({});
	const [upd, setUpd] = useState(false);

	const name_ref = useRef(null);
	const type_ref = useRef(null);
	const devices_ref = useRef(null);
	const refs = { name: name_ref, type: type_ref, devices: devices_ref };

	const onEnterModal = () => {
		const { modalType, chart } = props;
		switch (modalType) {
			case 'add':
				setTitle('Add New Report');
				break;
			case 'edit':
				setChart(chart);
				setTitle('Edit Report');
				break;
			default:
				break;
		}
	}

	const handleInputChange = (event) => {
		const { target } = event;
		const { name } = target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		// let chartUpdate = chart
		// chartUpdate[name] = value
		setChart((prevState) => ({ ...prevState, [name]: value }))
		setUpd(!upd);
	}

	const setDevices = (chart) => {
		const partner_name = props.company.partner;
		const company_name = props.company.name;
		const user_role = props.user_role

		switch (user_role) {
			case 'system_admin':
			case 'customer_service':
				if (chart.devices_custom[partner_name]
					&& chart.devices_custom[partner_name][company_name]
				) {
					return chart.devices_custom[partner_name][company_name];
				}
				return [];
			case 'partner':
			case 'partner_view':
			case 'custom_partner':
				if (chart.devices_custom[company_name]) {
					return chart.devices_custom[company_name];
				}
				return [];
			case 'fleet_maintainer':
			case 'fleet_manager':
			case 'group_manager':
			case 'user':
			case 'storage_manager':
			case 'storage_user':
			case 'custom_user':
			case 'sales_demo':
				return chart.devices || [];
			default:
				break;
		}
	}

	const checkForm = (data) => {
		const { user_charts, chart, user_role, company } = props;
		const filter_charts = _.reject(user_charts, { i: chart.i });
		const filter_name = _.filter(filter_charts, function (item) { return item.name.toLowerCase().trim() === data['name'].toLowerCase().trim() })

		let error = false
		let errMessageUpdate = errMessage

		if (props.modalType === 'edit') {
			let filter_chart
			user_charts.map(e => { if (e.i === data.i) filter_chart = e })

			if (_.isEqual(filter_chart, data)) {
				toastr.warning('', 'This report has no changes')
				error = true
			}
		}

		for (let i in data) {
			if (data[i] == '' && refs[i] && i !== 'devices_new' && i !== 'devices_new_custom' && i !== 'devices' || (i === "name" && !data[i].trim())) {
				error = true;
				errMessageUpdate[i] = 'Enter your ' + transformationLabel(i) + '!';
			} else if (errMessageUpdate[i]) {
				errMessageUpdate[i] = null
			}
		}

		if (!errMessageUpdate['name'] && data['name'].length > 40) {
			error = true;
			errMessageUpdate['name'] = 'Length must be no more than 40 characters';
		}

		if (!errMessageUpdate.name) {
			const re = /^([a-zA-Z0-9\s\-\.\+]+)$/;
			if (!re.test(data.name)) {
				error = true;
				errMessageUpdate['name'] = 'Only Latin letters!';
			}
		}

		if (('events' in data) && !errMessageUpdate.events && data.events.length < 1 && ('alerts' in data) && !errMessageUpdate.alerts && data.alerts.length < 1) {
			error = true;
			errMessageUpdate.events = 'Select your events';
			errMessageUpdate.alerts = 'Select your alerts';
		}

		if (filter_name.length > 0) {
			error = true;
			errMessageUpdate.name = 'Entered report name already exists';
		}

		const checkDevices = (devices) => {
			if (devices.length < 1) {
				error = true;
				errMessageUpdate.devices = 'Select your devices';
			} else {
				errMessageUpdate.devices = '';
			}
		}

		if (data.type === "bar_custom" || data.type === "pie_custom") {
			let company_name = _.get(company, 'name', []);
			let partner_name = _.get(company, 'partner', []);
			let devices_custom;

			switch (user_role) {
				case 'system_admin':
				case 'customer_service':
					checkDevices(_.get(data, `devices_custom.${partner_name}.${company_name}`, []));
					break;
				case 'partner':
				case 'partner_view':
				case 'custom_partner':
					checkDevices(_.get(data, `devices_custom.${company_name}`, []));
					break;
				case 'fleet_maintainer':
				case 'fleet_manager':
				case 'group_manager':
				case 'user':
				case 'storage_manager':
				case 'storage_user':
				case 'custom_user':
				case 'sales_demo':
					checkDevices(_.get(data, `devices`, []));
					break;
				default:
					break;
			}
		}

		if (moment(data.end_date).diff(data.start_date) < 0) {
			error = true;
			errMessageUpdate.end_date = 'End date should be bigger than Start date';
		}

		setErrMessage(errMessageUpdate)
		return error
	}

	const onSaveClick = () => {
		let error = checkForm(chart);
		setUpd(!upd);
		// console.log('onSaveClick: ', chart, error)

		if (!error) {
			switch (props.modalType) {
				case 'add':
					props.addOneChart(chart)
					break
				case 'edit':
					props.beforeSave(chart)
					break
				default:
					break
			}

			props.onHide()
		}
	}

	const onDateChanged = (e, date) => {
		// setChart({ ...chart, [date]: e.format('YYYYMMDD') });
		setChart((prevState) => ({ ...prevState, [date]: e.format('YYYYMMDD') }))
	}

	const user_role = props.user_role;
	const partner_name = props.company.partner;
	const company_name = props.company.name;
	const { events, alerts, type, name, end_date, devices } = errMessage;

	let options1 = [];
	props.events.map(event =>
		options1.push({ value: event, label: event }),
	);

	let options2 = [];
	props.alerts.map(alert =>
		options2.push({ value: alert, label: alert }),
	);

	let options_devices_list = [];
	props.devices.map(item =>
		options_devices_list.push({ value: item.device_id, label: item.vehicle_name || item.device_id }),
	);

	// console.log('STATE EditReport chart: ', chart)

	return (
		<Modal
			size='lg'
			show={props.show}
			onHide={props.onHide}
			onEnter={onEnterModal}
			aria-labelledby="contained-modal-title-lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">
					{title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="edit-report-chart">
					<div className="two-column-grid form-group">
						<div className={classnames('input', { 'has-error': name })}>
							<label className="control-label">Name:</label>
							<input
								placeholder="Report Name..."
								type="text"
								name="name"
								maxLength="40"
								ref={name_ref}
								className="vehicle-search"
								value={chart.name}
								onChange={handleInputChange}
							/>
							{name && <span className="help-block">{name}</span>}
						</div>

						<div className={classnames('input', { 'has-error': type })}>
							<label htmlFor="type" className="control-label">Type:</label>
							<select
								placeholder="Select..."
								className="rosco-select block"
								name="type"
								id="type"
								ref={type_ref}
								value={chart.type}
								onChange={handleInputChange}
							>
								<option value="bar_top_10">Bar Graph - Vehicles with Least Events Generated</option>
								<option value="bar_bottom_10">Bar Graph - Vehicles with Most Events Generated</option>
								<option value="bar_custom">Bar - select devices</option>

								<option value="pie_top_10">Pie Chart - Vehicles with Least Events Generated</option>
								<option value="pie_bottom_10">Pie Chart - Vehicles  with Most Events Generated</option>
								<option value="pie_custom">Pie - select devices</option>
							</select>
							{type && <span className="help-block">{type}</span>}
						</div>
					</div>

					<div className='form-group'>
						{props.modalType === 'edit'
							&& (
								<div className="input">
									<label>
										<span className="toggle-left-title">Disable</span>
										<Toggle
											name="active"
											icons={false}
											checked={chart.active}
											onChange={handleInputChange}
										/>
										<span>Enable</span>
									</label>
								</div>
							)}

						{
							(chart.type === 'bar_top_10'
								|| chart.type === 'bar_bottom_10'
								|| chart.type === 'bar_custom')
							&& (
								<div className="input">
									<label>
										<span className="toggle-left-title">Not Stacked</span>
										<Toggle
											name="stacked"
											icons={false}
											checked={chart.stacked}
											onChange={handleInputChange}
										/>
										<span>Stacked</span>
									</label>
								</div>
							)}
					</div>
					{
						(chart.type === 'bar_custom'
							|| chart.type === 'pie_custom')
						&& (
							<div className={classnames('form-group input', { 'has-error': devices })}>
								<label htmlFor="user-groups" className="control-label">
									Devices:
									{isInternalUser(user_role) &&
										<span> (Partner: <b>{partner_name}</b> / Company: <b>{company_name}</b>)</span>
									}
								</label>
								<Select
									ref={devices_ref}
									isMulti={true}
									name="devices"
									options={options_devices_list}
									id="devices"
									onChange={(value) => {
										if (value.length <= 10) {
											const val = value.map(item => {
												return item.value;
											})
											const data = chart;
											switch (user_role) {
												case 'system_admin':
												case 'customer_service':
													if (!data['devices_custom'][partner_name]) {
														data['devices_custom'][partner_name] = {};
														data['devices_custom'][partner_name][company_name] = val;
														setData(data);
													} else {
														data['devices_custom'][partner_name][company_name] = val;
														setData(data);
													}
													break;

												case 'partner':
												case 'partner_view':
												case 'custom_partner':
													if (!data['devices_custom'][company_name]) {
														data['devices_custom'][company_name] = val;
														setData(data);
													} else if (data['devices_custom'][company_name]) {
														data['devices_custom'][company_name] = val;
														setData(data);
													}
													break;
												case 'fleet_maintainer':
												case 'fleet_manager':
												case 'group_manager':
												case 'user':
												case 'storage_manager':
												case 'storage_user':
												case 'custom_user':
												case 'sales_demo':
													data['devices'] = val;
													setData(data);
													break;
												default:
													break;
											}
										} else {
											toastr.warning(`Warning`, `You can select only up to 10 devices`);
										}
										setUpd(!upd);
									}}
									placeholder="Select Devices..."
									className="rosco-select"
									value={setDevices(chart).map(i => { if (!!i) return { value: i, label: options_devices_list.map(e => { if (e.value === i) return e.label }) } })}
									noOptionsMessage={() => null}
								/>
								{devices && <span className="help-block">{devices}</span>}
							</div>
						)}

					<div className={classnames('two-column-grid form-group input', { 'has-error': end_date })}>
						<div className="date-container">
							<label className="control-label">Start Date:</label>
							<div className="datetime">
								<DateTime
									timeFormat={false}
									defaultValue={moment(_.get(props, 'chart.start_date', chart.start_date)).format('MM/DD/YYYY')}
									closeOnSelect
									onChange={(e) => { onDateChanged(e, 'start_date') }}
								/>
							</div>
						</div>
						<div className="date-container">
							<label className="control-label">End Date:</label>
							<div className="datetime">
								<DateTime
									timeFormat={false}
									defaultValue={moment(_.get(props, 'chart.end_date', chart.end_date)).format('MM/DD/YYYY')}
									closeOnSelect
									onChange={(e) => { onDateChanged(e, 'end_date') }}
								/>
							</div>
						</div>
						{end_date && <span className="help-block">{end_date}</span>}
					</div>

					<div className="form-group">
						<div className={classnames('input', { 'has-error': events })}>
							<label htmlFor="user-groups" className="control-label">
								Events
							</label>
							<Select
								isMulti={true}
								name="events"
								options={options1}
								id="events"
								onChange={(value) => {
									const val = value.map(e => e.value)
									const data = chart;
									data.events = val;
									setData(data);
									setUpd(!upd);
								}}
								placeholder="Select Events..."
								className="rosco-select"
								value={chart.events !== "" ? chart.events.map(i => { if (!!i) return { value: i, label: i } }) : ""}
								noOptionsMessage={() => null}
							/>
							{events && <span className="help-block">{events}</span>}
						</div>
					</div>

					<div className="form-group">
						<div className={classnames('input', { 'has-error': alerts })}>
							<label htmlFor="user-groups" className="control-label">
								Alerts
							</label>
							<Select
								isMulti={true}
								name="alerts"
								options={options2}
								id="alerts"
								onChange={(value) => {
									let val = value.map(e => e.value)
									let data = chart;
									data["alerts"] = val;
									setData(data);
									setUpd(!upd);
								}}
								placeholder="Select Alerts..."
								className="rosco-select"
								value={chart.alerts !== "" ? chart.alerts.map(i => { if (!!i) return { value: i, label: i } }) : ""}
								noOptionsMessage={() => null}
							/>
							{alerts && <span className="help-block">{alerts}</span>}
						</div>

					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className="modal-footer two-buttons default-modal-footer">
				<CustomButton variant='primary' onClick={onSaveClick}>
					{props.modalType === 'add' ? 'Create Report' : 'Update Report'}
				</CustomButton>
			</Modal.Footer>
		</Modal>
	);
};

export default EditReport;
