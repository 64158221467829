import React from  'react';
import RestorePasswordForm from '../../../smart/forms/login/RestorePasswordForm';

const RestorePasswordPage = () => {
    return(
        <RestorePasswordForm />
    )
}

export default RestorePasswordPage
