import React from 'react';
import { Checkbox, CheckboxGroup } from 'react-checkbox-group';
import PropTypes from 'prop-types';
import { map, upperFirst } from 'lodash';
import { connect } from 'react-redux';

import { isInternalUser } from '../../helpers/constants';

const EventsAlertsList = ({
	user, selectedAlerts, selectedEvents, selectedAlertTypes, selectedEventTypes, constants: { alertcheckboxes, eventcheckboxes, dmscheckboxes, adascheckboxes },
}) => {
	// FWSD-4420
	const userRole = user.roles[0]
	let alertcheckboxesFiltered = alertcheckboxes
	if (!isInternalUser(userRole)) {
		alertcheckboxesFiltered = alertcheckboxes.filter(({ id }) => id !== 'hdderror')
	};

	const list = [
		{ name: 'alerts', event: selectedAlerts, value: selectedAlertTypes, checkboxes: alertcheckboxesFiltered },
		{ name: 'events', event: selectedEvents, value: selectedEventTypes, checkboxes: eventcheckboxes },
		{ name: 'DMS Events', event: selectedEvents, value: selectedEventTypes, checkboxes: dmscheckboxes },
		{ name: 'ADAS Events', event: selectedEvents, value: selectedEventTypes, checkboxes: adascheckboxes },
	];

	return (
		<div className="asset-alerts-list">
			{
				map(list, ({
					name, event, value, checkboxes,
				}) => (
					<div className={`alerts-column ${name}`} key={name}>
						<label className='alerts-label'>{`${upperFirst(name)}`}</label>
						<CheckboxGroup
							name={name}
							onChange={event}
							value={value}
						>
							{
								map(checkboxes, ({ id, title }) => (
									<span className="checkbox" key={id}>
										<label>
											<Checkbox value={id} />
											{title}
										</label>
									</span>
								))
							}
						</CheckboxGroup>
					</div>
				))
			}
		</div>
	)
}

EventsAlertsList.propTypes = {
	user: PropTypes.objectOf(PropTypes.any).isRequired,
	selectedAlerts: PropTypes.func.isRequired,
	selectedEvents: PropTypes.func.isRequired,
	selectedAlertTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	selectedEventTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
	constants: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
		id: PropTypes.string,
		title: PropTypes.string,
	}))).isRequired,
};

const mapStateToProps = ({ user }) => ({
	user: user.user,
})

export default connect(mapStateToProps)(EventsAlertsList)

// export default EventsAlertsList;
