import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import moment from 'moment-timezone'
import { jsPDF } from 'jspdf'

import momenttz from '../../helpers/momenttz'
import { apiCallGet } from '../../../action/RouterActions'
import CustomButton from '../../CustomButton'
import logo from '../../../../../assets/rosco-logo-horizontal.jpg'

const deviceInstallImages = [
	{ name: 'channel_1', description: 'Channel 1' },
	{ name: 'channel_2', description: 'Channel 2' },
	{ name: 'channel_3', description: 'Channel 3' },
	{ name: 'channel_4', description: 'Channel 4' },
	{ name: 'windshield_img', description: 'Camera on Windshield' },
	{ name: 'obd_mount', description: 'OBD Mount' },
	{ name: 'obd_zip_tie', description: 'OBD Zip Tie' },
	{ name: 'antenna_img', description: 'Antenna Installation' },
	{ name: 'power_img', description: 'Power Connection Zip Tie' },
	{ name: 'pdc_img', description: 'PDC' },
	{ name: 'modem_img', description: 'PDC / Modem Secure' }
];

const ModalInstallReport = (props) => {

	const { device, onHide, toggleLoader } = props;

	const [deviceinstall, setDeviceinstall] = useState({});
	const [showModalImageView, setShowModalImageView] = useState(false);
	const [imageViewSRC, setImageViewSRC] = useState('');


	useEffect(() => {
		getDeviceInstall();
	}, [])

	const getDeviceInstall = () => {
		toggleLoader(true);
		const deviceId = device.serial_number ? device.serial_number : device.device_id

		apiCallGet('/installerapp/device/{deviceId}', { deviceId })
			.then((res, err) => {
				toggleLoader(false);

				setDeviceinstall(res.data.response)
			})
			.catch((error) => {
				toggleLoader(false);

				if (error.response && error.response.data.response.error) {
					toastr.error(error.response.data.response.error)
				} else {
					toastr.error(JSON.stringify(error))
				}
				onHide()
			})
	}

	const hideModalImageView = () => {
		setShowModalImageView(false)
	}

	const handleImageViewClick = (e) => {
		setImageViewSRC(e.target.getAttribute('src'));
		setShowModalImageView(true);
	}

	const handleExportPDFClick = (rows) => {
		const stylePDF = {
			rows: {
				paddingTop: 35,
				paddingLeft: 25,
				fontSize: 11,
				lineHeight: 7,
				width: 150,
				chapter: {
					fontSize: 18,
				},
			},
			images: {
				width: 80,
				height: 40,
			}
		}

		const doc = new jsPDF()

		// Report Header
		doc.setFontSize(10);
		doc.addImage(`${logo}?r=${Math.floor(Math.random() * 100000)}`, 'JPEG', 5, 5, 55, 15);
		doc.setFont(undefined, 'bold');
		doc.text('Rosco Vision', 55 + 15, 5 + 5);
		doc.setFont(undefined, 'normal');
		doc.text('90-21144th Place, Jamaica, New York 11435', 55 + 15, 5 + 5 + 5);
		doc.text('(718) 526-2601', 55 + 15, 5 + 5 + 5 * 2);

		// Rows
		let rowsCount = -2
		Object.keys(rows).forEach((itemTitle) => {
			// Rows Header
			rowsCount += 2
			doc.setFontSize(stylePDF.rows.chapter.fontSize)
			doc.setTextColor(41, 169, 224)
			doc.setFont(undefined, 'bold')
			doc.text(itemTitle, stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);

			// Rows Cell
			doc.setFontSize(stylePDF.rows.fontSize)
			doc.setTextColor('black')
			doc.setFont(undefined, 'normal')
			Object.keys(rows[itemTitle]).forEach((itemCell) => {
				rowsCount += 1
				doc.text(itemCell, stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);
				doc.text(rows[itemTitle][itemCell], stylePDF.rows.width, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount, null, null, 'right');
				const cellTitleLength = doc.getTextWidth(itemCell)
				const cellValueLength = doc.getTextWidth(rows[itemTitle][itemCell])
				doc.setLineDash([0.3, 0.3])
				doc.line(
					stylePDF.rows.paddingLeft + cellTitleLength + 1, // x1
					stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount, // y1
					stylePDF.rows.width - cellValueLength - 1, // x2
					stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount, // y2
				)
			})
		})

		// Images Header
		rowsCount += 2
		doc.setFontSize(stylePDF.rows.chapter.fontSize)
		doc.setTextColor(41, 169, 224)
		doc.setFont(undefined, 'bold')
		doc.text('Installation Images', stylePDF.rows.paddingLeft, stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount);

		let imagesPaddingTop = stylePDF.rows.paddingTop + stylePDF.rows.lineHeight * rowsCount + 10

		// Images
		doc.setFontSize(stylePDF.rows.fontSize + 1)
		doc.setTextColor('black')
		doc.setFont(undefined, 'normal')

		let imagesCount = -1
		deviceInstallImages.forEach(({ name, description }) => {
			if (deviceinstall[name]) {
				// if images more 4 then adds new page and draws other images there
				if (imagesCount === 3) {
					imagesPaddingTop = 10
					imagesCount = -1
					doc.addPage();
				}

				imagesCount += 1
				const imageRowNumber = Math.trunc(imagesCount / 2)

				if (imagesCount % 2) { // if is !odd (even) image then draw it in second/right column
					doc.text(description, stylePDF.rows.paddingLeft + stylePDF.images.width + 10, imagesPaddingTop + 50 * imageRowNumber);

					const imgData = new Image();
					imgData.crossOrigin = 'anonymous'
					imgData.src = `${deviceinstall[name]}&r=${Math.floor(Math.random() * 100000)}`
					// console.log('!!!imgData: ', imgData)

					doc.addImage(
						imgData,
						'JPEG',
						stylePDF.rows.paddingLeft + stylePDF.images.width + 10, // x
						imagesPaddingTop + 50 * imageRowNumber + 3, // y
						stylePDF.images.width, // width
						stylePDF.images.height, // height
					)
				} else {
					doc.text(description, stylePDF.rows.paddingLeft, imagesPaddingTop + 50 * imageRowNumber);

					const imgData = new Image();
					imgData.crossOrigin = 'anonymous'
					imgData.src = `${deviceinstall[name]}&r=${Math.floor(Math.random() * 100000)}`
					// console.log('!!!imgData: ', imgData)

					doc.addImage(
						imgData,
						'JPEG',
						stylePDF.rows.paddingLeft, // x
						imagesPaddingTop + 50 * imageRowNumber + 3, // y
						stylePDF.images.width, // width
						stylePDF.images.height, // height
					)
				}
			}
		})

		doc.save('install_report.pdf');
	}

	const getInstallDate = () => {
		const { install_ts, created_at } = deviceinstall;

		if (install_ts) {
			return moment(install_ts).format('M/D/YYYY hh:mm:ss a')
		}

		if (created_at) {
			return momenttz(deviceinstall.created_at).format('M/D/YYYY hh:mm:ss a')
		}

		return ''
	}

	const rows = {
		'Installation Info': {
			'Form ID': deviceinstall.installed_id ? deviceinstall.installed_id.toString().padStart(5, '0') : '',
			'Install Date': getInstallDate(),
			VIN: deviceinstall.vin_number || '',
			'Plate #': deviceinstall.tag_plate || '',
			'Camera Model': deviceinstall.device_type ? deviceinstall.device_type.toUpperCase() : '',
			'Camera S/N': deviceinstall.serial_number || '',
			'First Connection Date': deviceinstall.activated_timestamp ? momenttz(deviceinstall.activated_timestamp).format('M/D/YYYY hh:mm:ss a') : '',
		},
		'Installer Info': {
			Company: deviceinstall.company_name || '',
			Name: [deviceinstall.first_name, deviceinstall.last_name].join(' ') || '',
			Email: deviceinstall.email || '',
			Phone: deviceinstall.phone_number || '',
			'MECP Certified': deviceinstall.mecp_cert ? 'Yes' : 'No',
		},
	}

	return (
		<Modal
			show
			onHide={onHide}
			size="lg"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">
					Install Report
					<span className="sub-title">{`FORM ID: ${rows['Installation Info']['Form ID']}`}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section>
					<h4 className="category-title">Installation Info</h4>
					<div className="three-column-grid with-margin">
						<div>
							<label className="control-label">Camera Model:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installation Info']['Camera Model']}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">Time of install:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installation Info']['Install Date']}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">First connection date:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installation Info']['First Connection Date']}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">Camera S/N:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installation Info']['Camera S/N']}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">VIN:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installation Info']['VIN']}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">TAG/PLATE:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installation Info']['Plate #']}
								readOnly
							/>
						</div>
					</div>
				</section>

				<section>
					<h4 className="category-title">Installer Info</h4>
					<div className="three-column-grid with-margin">
						<div>
							<label className="control-label">Name:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installer Info'].Name}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">Email:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installer Info'].Email}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">Phone:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installer Info'].Phone}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">Company:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installer Info'].Company}
								readOnly
							/>
						</div>
						<div>
							<label className="control-label">MECP Certified:</label>
							<input
								type="text"
								className="vehicle-search"
								value={rows['Installer Info']['MECP Certified']}
								readOnly
							/>
						</div>
					</div>
				</section>

				<section>
					<h4 className="category-title">Installation Images</h4>
					<div className="device-install-images">
						{
							deviceInstallImages.map(({ name, description }) => {
								if (deviceinstall[name]) {
									return (<img
										role="button"
										key={name}
										src={deviceinstall[name]}
										className={name}
										alt={description}
										title={description}
										onClick={handleImageViewClick}
										onError={(e) => {
											console.log('Image load error name: ', name)
											e.target.onerror = null
											setDeviceinstall({ ...deviceinstall, [name]: null })
										}}
									/>)
								}
							})
						}
					</div>
				</section>
			</Modal.Body>
			<Modal.Footer>
				<CustomButton
					variant="primary"
					onClick={() => handleExportPDFClick(rows)}
				>
					Export PDF
				</CustomButton>
			</Modal.Footer>

			{showModalImageView
				&& (
					<ModalImageView
						src={imageViewSRC}
						onHide={hideModalImageView}
					/>
				)}
		</Modal>
	)
}

ModalInstallReport.propTypes = {
	device: PropTypes.objectOf(PropTypes.any).isRequired,
	onHide: PropTypes.func.isRequired
}

export default connect(
	null,
	dispatch => ({
		toggleLoader: (show) => {
			dispatch({ type: 'TOGGLE_LOADER', payload: show });
		}
	})
)(ModalInstallReport);

const ModalImageView = (props) => {
	const { src, onHide } = props
	return (
		<Modal
			show
			onHide={onHide}
			backdropClassName="upper"
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-lg">Image View</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div>
					<img src={src} width="100%" id="event-snapshot" alt="" />
				</div>
			</Modal.Body>
		</Modal>
	);
}
